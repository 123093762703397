// @flow
import React from 'react';
import SongActionButton from '../SongActionButton/SongActionButton';
import HideIcon from '../../../../../../../assets/inline-assets/hide-icon.svg';
import HiddenIcon from '../../../../../../../assets/inline-assets/hidden-icon.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  isHidden?: boolean,
};

const HideSongButton = ({ isHidden = false, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <SongActionButton
      icon={isHidden ? <HideIcon /> : <HiddenIcon />}
      label={isHidden ? t('general.button.unhide', '') : t('general.button.hideTrack', '')}
      {...props}
    />
  );
};

export default HideSongButton;
