import { get } from 'lodash';
import { getSongMicroparts, isSongFullMix, isSongSfx, isSongStem } from '../api/algolia/song';
import type { AlgoliaSongMdl } from '../api/algolia/song';
import { useTranslation } from 'react-i18next';

export type SongMap = {
  [songType: string]: SongMapEntry,
};

type SongMapEntry = {
  count: number,
  songs: Array<AlgoliaSongMdl>,
};

export const SONG_TAB_TYPES = {
  full_mixes: 'full_mixes',
  stems: 'stems',
  loops: 'loops',
  sfx: 'sfx',
};

export const getSongTabCopy = (tabType: string): string => {
  const { t } = useTranslation();

  if (tabType === SONG_TAB_TYPES.full_mixes) {
    return t('resultsPage.text.fullMixesFilter', '');
  }
  if (tabType === SONG_TAB_TYPES.stems) {
    return t('resultsPage.text.stemsFilter', '');
  }
  if (tabType === SONG_TAB_TYPES.loops) {
    return t('resultsPage.text.loopsFilter', '');
  }
  if (tabType === SONG_TAB_TYPES.sfx) {
    return t('resultsPage.text.sfxFilter', '');
  }

  return '';
};

const getTabMappedSongsObject = (songMap: SongMap, category: string): SongMapEntry => {
  return get(songMap, `${category}`, {}) || {};
};

export const getTabMappedSongsList = (
  songMap: SongMap,
  category: string
): Array<AlgoliaSongMdl> => {
  const map = getTabMappedSongsObject(songMap, category);
  return get(map, 'songs', []) || [];
};

export const getTabMappedSongsCount = (songMap: SongMap, category: string): number => {
  const map = getTabMappedSongsObject(songMap, category);
  return get(map, 'count', 0) || 0;
};

export const getTabbedLoopsCount = (songs: Array<AlgoliaSongMdl>): number => {
  if (!songs.length) return 0;

  return songs.map(song => getSongMicroparts(song).length).reduce((a, b) => a + b);
};

export const getTabbedLoopStartingIndex = (songs, songIndex) => {
  let startingIndex = 0;

  if (songIndex === 0) {
    return startingIndex;
  }

  for (let i = 0; i < songIndex; i++) {
    startingIndex += songs[i].microparts.length;
  }
  return startingIndex;
};

const generateSongsTabContent = (mapKey: string, songs: Array<AlgoliaSongMdl>) => {
  const count = mapKey === SONG_TAB_TYPES.loops ? getTabbedLoopsCount(songs) : songs.length;

  if (count < 1) return {};

  return {
    [mapKey]: {
      count,
      songs,
    },
  };
};

export const generateSongsTabMap = (
  songs: Array<AlgoliaSongMdl>,
  allowFullMixes: boolean,
  allowLoops: boolean,
  allowSfx: boolean
): SongMap => {
  const fullMixSongs = allowFullMixes ? songs.filter(song => isSongFullMix(song)) : [];
  const stemSongs = songs.filter(song => isSongStem(song));
  const loopSongs = allowLoops ? songs : [];
  const sfxSongs = allowSfx ? songs.filter(song => isSongSfx(song)) : [];

  const fullMixMap = generateSongsTabContent(SONG_TAB_TYPES.full_mixes, fullMixSongs);
  const stemMap = generateSongsTabContent(SONG_TAB_TYPES.stems, stemSongs);
  const loopsMap = generateSongsTabContent(SONG_TAB_TYPES.loops, loopSongs);
  const sfxMap = generateSongsTabContent(SONG_TAB_TYPES.sfx, sfxSongs);

  const songsMap = {
    ...fullMixMap,
    ...stemMap,
    ...loopsMap,
    ...sfxMap,
  };

  return songsMap;
};

const isFallbackTabNeeded = (songMap, defaultTab): string => {
  const tabData = get(songMap, `${defaultTab}`, {}) || {};
  const tabCount = get(tabData, 'count', 0) || 0;

  return tabCount === 0;
};

export const getTabbedSongsDefaultTab = (songMap, defaultTab) => {
  const fallBackRequired = isFallbackTabNeeded(songMap, defaultTab);

  if (fallBackRequired) {
    const orderedTabs = ['fullMixes', 'stems', 'loops', 'sfx'];
    const songCount = orderedTabs.map(tab => {
      return songMap[tab] ? songMap[tab].count : 0;
    });

    const firstNonEmptyElement = orderedTabs.find((tab, index) => songCount[index] > 0);

    if (firstNonEmptyElement === -1) {
      return defaultTab;
    }
    return firstNonEmptyElement;
  }

  return defaultTab;
};
