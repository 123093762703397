// @flow
import React, { useEffect, useState } from 'react';
import {
  getCuratedCollectionImage,
  getCuratedCollectionTitle,
  getCuratedCollectionId,
  getCuratedCollectionSongs,
  getRecentlyUpdatedCollections,
} from '../../../../../../../cms/curatedCollection';
import { useCuratedCollections } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useNavigate } from '../../../../../../hooks';
import { ROUTES } from '../../../../../../routes';
import { useGlobalPlayerContext } from '../../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import PreviewGridTile from '../../../BrowseView/components/PreviewGridTile';
import type { CuratedCollectionGraphMdl } from '../../../../../../../cms/curatedCollection';
import PreviewTileGrid from '../../../BrowseView/components/PreviewTileGrid';
import { analyticsMixpanelPreviewSongs } from '../../../../../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../../../../../analytics/components/MixpanelWrapper';
import { useUserId } from '../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { searchAlgoliaCollections } from '../../../../../../../api/algolia/collections';
import { useLocale } from '../../../../../../components/LocaleWrapper/LocaleWrapper';
import { capitalize } from '../../../../../../../utils/text';
import CollectionsFilter from '../CollectionsFilter/CollectionsFilter';
import { useTranslation } from 'react-i18next';

// TODO: パフォーマンス改善 --> 全てを出さずにページを分けるとか 1,2,3,4,...
// TODO: songListのslink化
const AllCollections = () => {
  const { t } = useTranslation();

  const curatedCollections = useCuratedCollections();
  const {
    changeSongIDs,
    updateGlobalPlayerPlaybackDetails,
    handleUpdateGlobalAnalyticsData,
  } = useGlobalPlayerContext();
  const navigate = useNavigate();
  const { mixpanel, moengage, handleUpdateCollectionAnalytics } = useAnalyticsMixpanelContext();
  const userId = useUserId();
  const locale = useLocale();

  const [tagFilter, setTagFilter] = useState('all');
  const [sortedCollections, setSortedCollections] = useState([]);
  const [collectionTags, setCollectionTags] = useState([]);
  const [loadedCollections, setLoadedCollections] = useState(false);

  const handlePreviewCollection = (collection: CuratedCollectionGraphMdl) => {
    const songs = getCuratedCollectionSongs(collection);
    if (songs.length < 1) return;

    const collectionTitle = getCuratedCollectionTitle(collection);
    const collectionSlug = getCuratedCollectionId(collection);
    handleUpdateGlobalAnalyticsData([], [], [], '', 'allCollections', '', '');
    changeSongIDs(songs);
    updateGlobalPlayerPlaybackDetails('collection', collectionTitle, collectionSlug);
  };

  const navigateCollection = (collectionSlug: string) => {
    handleUpdateCollectionAnalytics([], [], [], [], '', 'allCollections', '');
    navigate(ROUTES.collection.navigatePath({ collectionSlug }));
  };

  const handleSorting = (event: any) => {
    setLoadedCollections(false);
    setTagFilter(event.currentTarget.value);
  };

  useEffect(() => {
    const allTags = curatedCollections
      .filter(c => c.data.collection_tags.text)
      .map(c => c.data.collection_tags.text)
      .join(',')
      .split(',')
      .filter(tag => tag.trim())
      .map(tag => {
        return capitalize(tag.trim());
      })
      .sort();

    const collectionTagSet = new Set(allTags);
    const uniqueCollectionTags = [...collectionTagSet];

    setCollectionTags(uniqueCollectionTags);
  }, []);

  useEffect(() => {
    if (tagFilter === 'all') {
      setSortedCollections(getRecentlyUpdatedCollections(curatedCollections));
      setLoadedCollections(true);
    } else {
      const filterQuery = `tags: "${tagFilter}"`;

      searchAlgoliaCollections('', filterQuery, 1000, locale)
        .then(results => {
          const filteredResultsSlugs = results.hits.map(hit => hit.collectionSlug);
          const filteredCollections = curatedCollections.filter(c =>
            filteredResultsSlugs.includes(c.uid)
          );
          setSortedCollections(getRecentlyUpdatedCollections(filteredCollections));
        })
        .finally(() => {
          setLoadedCollections(true);
        });
    }
  }, [tagFilter]);

  return (
    <PreviewTileGrid
      title={t('general.text.collections')}
      showAllTiles
      rows={1}
      hasSorting
      sortingMenu={
        <CollectionsFilter tags={collectionTags} sortValue={tagFilter} sort={handleSorting} />
      }
      loading={!loadedCollections}
    >
      {sortedCollections.map(collection => {
        const slug = getCuratedCollectionId(collection);
        return (
          <PreviewGridTile
            key={slug}
            title={getCuratedCollectionTitle(collection)}
            slug={slug}
            bgImage={getCuratedCollectionImage(collection)}
            listType="collection"
            oversizedTile
            previewTile={() => {
              analyticsMixpanelPreviewSongs(
                mixpanel,
                moengage,
                'Collection',
                slug,
                'All Collections',
                userId
              );
              handlePreviewCollection(collection);
            }}
            navigateTile={() => {
              navigateCollection(slug);
            }}
          />
        );
      })}
    </PreviewTileGrid>
  );
};

export default AllCollections;
