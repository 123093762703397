// @flow
import React from 'react';
import { useSetRecoilState } from 'recoil';
import SongActionButton from '../SongActionButton/SongActionButton';
import PackIcon from '../../../../../../../assets/inline-assets/pack-icon.svg';
import { useSong } from '../../../../../SongContextWrapper/SongContextWrapper';
import { getSongShareSlug } from '../../../../../../../api/algolia/song';
import { useNavigate } from '../../../../../../../routing/hooks';
import { ROUTES } from '../../../../../../../routing/routes';
import { useDownloadContext } from '../../../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import {
  directSearchKeywordsAtom,
  directSearchMagicKeywordsAtom,
  directSearchMixpanelKeywordsAtom,
  directSearchYouTubeKeywordsAtom,
  directTriggerAtom,
} from '../../../../../../../store/mixpanelAnalytics';
import { useTranslation } from 'react-i18next';

type Props = {
  condensed?: boolean,
  closeModal?: () => void,
};

const ViewPackButton = ({ condensed = false, closeModal }: Props) => {
  const { t } = useTranslation();

  const song = useSong();
  const shareSlug = getSongShareSlug(song);
  const navigate = useNavigate();
  const setDirectSearchTriggerLocation = useSetRecoilState(directTriggerAtom);
  const setDirectSearchKeywords = useSetRecoilState(directSearchKeywordsAtom);
  const setDirectSearchMixpanelKeywords = useSetRecoilState(directSearchMixpanelKeywordsAtom);
  const setDirectSearchMagicKeywords = useSetRecoilState(directSearchMagicKeywordsAtom);
  const setDirectSearchYouTubeKeywords = useSetRecoilState(directSearchYouTubeKeywordsAtom);

  const {
    location,
    keywords,
    mixpanelKeywords,
    magicKeywords,
    youtubeKeywords,
  } = useDownloadContext();

  const setPackAnalytics = () => {
    setDirectSearchKeywords(keywords);
    setDirectSearchMixpanelKeywords(mixpanelKeywords);
    setDirectSearchMagicKeywords(magicKeywords);
    setDirectSearchYouTubeKeywords(youtubeKeywords);
    setDirectSearchTriggerLocation(location);
  };

  const handleNavigatePack = () => {
    setPackAnalytics();
    navigate(ROUTES.musicPack.navigatePath({ shareSlug }));

    if (closeModal) {
      closeModal();
    }
  };

  return (
    <SongActionButton
      icon={<PackIcon />}
      label={t('general.button.packDetail', '')}
      onClick={handleNavigatePack}
      condensed={condensed}
    />
  );
};

export default ViewPackButton;
