// @flow
import React, { useEffect, useState } from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { CenteredLargeContainer } from '../../../../../../../styles/layout';
import { useLocale } from '../../../../../../components/LocaleWrapper/LocaleWrapper';
import { useAuthContext } from '../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useGenericApiState } from '../../../../../../../api/hooks';
import { getUserDownloadsWithTracks } from '../../../../../../../api/firebase/user/downloads';
import type { DownloadHistoryWrapperMdl } from '../../../../../../../api/firebase/user/downloads';
import AudioPlayerWrapper from '../../../../../../../audio/components/AudioPlayerWrapper/AudioPlayerWrapper';
import DownloadContextWrapper from '../../../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import ExpandableSongsWrapper from '../../../../../../../song/components/ExpandableSongCard/components/ExpandableSongsWrapper/ExpandableSongsWrapper';
import LoadingDisplay, {
  LoadingContainer,
} from '../../../../../../../components/LoadingDisplay/LoadingDisplay';
import type { AlgoliaSongMdl } from '../../../../../../../api/algolia/song';
import NoResultsLayout from '../../../../../ResultsScreen/components/ResultsView/components/NoResults/components/NoResultsLayout/NoResultsLayout';
import { useDownloadsManagerContext } from '../DownloadHistoryManager/DownloadHistoryManager';
import DownloadHistorySongs from '../DownloadHistorySongs/DownloadHistorySongs';
import { useTranslation } from 'react-i18next';

export const useGetUserDownloads = (): [Array<DownloadHistoryWrapperMdl>, boolean, boolean] => {
  const locale = useLocale();
  const { userID } = useAuthContext();
  const [busy, setBusy, error] = useGenericApiState();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (userID && !busy) {
      setBusy(true);
      getUserDownloadsWithTracks(userID, locale)
        .then(response => {
          setData(response);
        })
        .finally(() => {
          setBusy(false);
        });
    }
  }, [userID]);

  return [data, busy, error];
};

export type GroupedSongs = {
  date: string,
  keywords: Array<string>,
  tracks: Array<AlgoliaSongMdl>,
};

const LOCATION_DOWNLOADS = 'downloads';

export const DownloadHistoryContainer = styled(CenteredLargeContainer)`
  max-width: unset;
`;

const NavTabs = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 75px;
  z-index: 100;
  background-color: #fff;

  ul {
    display: flex;
    margin: auto 0px;
    padding: 15px 0px;

    & li {
      margin: 0px 15px;

      &:hover {
        cursor: pointer;

        & span {
          text-decoration: underline;
        }
      }

      &:first-of-type {
        margin-left: 0px;
      }
    }
  }
`;

const Tab = styled('li')`
  font-family: Roboto;
  font-size: 19px;
  letter-spacing: 0.53px;
  line-height: 23px;
  opacity: 0.4;
`;

const activeBookmarksTab = css`
  opacity: 1;
`;

const hiddenBookmarksTab = css`
  display: none;
`;

const DownloadHistory = () => {
  const { t } = useTranslation();

  const {
    downloadsBusy,
    viewingDownloads,
    handleDownloadsTab,
    fullMixAmount,
    stemsAmount,
    sfxAmount,
    loading,
  } = useDownloadsManagerContext();

  if (downloadsBusy) {
    return (
      <LoadingContainer fixed>
        <LoadingDisplay />
      </LoadingContainer>
    );
  }

  if (fullMixAmount === 0 && stemsAmount === 0) {
    return (
      <NoResultsLayout
        heading={t('profile.download.noResults.header', '')}
        description={t('profile.download.noResults.message', '')}
      />
    );
  }

  return (
    <DownloadContextWrapper location={LOCATION_DOWNLOADS}>
      <ExpandableSongsWrapper>
        <AudioPlayerWrapper>
          <DownloadHistoryContainer responsiveFullWidth>
            <NavTabs>
              <ul>
                <Tab
                  className={cx({
                    [activeBookmarksTab]: viewingDownloads === 'fullMixes',
                    [hiddenBookmarksTab]: fullMixAmount < 1,
                  })}
                  onClick={() => handleDownloadsTab('fullMixes')}
                >
                  <span>{t('resultsPage.text.fullMixesFilter', '')}</span>
                  {` (${fullMixAmount})`}
                </Tab>
                <Tab
                  className={cx({
                    [activeBookmarksTab]: viewingDownloads === 'stems',
                    [hiddenBookmarksTab]: stemsAmount < 1,
                  })}
                  onClick={() => handleDownloadsTab('stems')}
                >
                  <span>{t('resultsPage.text.stemsFilter', '')}</span>
                  {` (${stemsAmount})`}
                </Tab>
                <Tab
                  className={cx({
                    [activeBookmarksTab]: viewingDownloads === 'sfx',
                    [hiddenBookmarksTab]: sfxAmount < 1,
                  })}
                  onClick={() => handleDownloadsTab('sfx')}
                >
                  <span>{t('resultsPage.text.sfxFilter', '')}</span>
                  {` (${sfxAmount})`}
                </Tab>
              </ul>
            </NavTabs>
            {loading ? (
              <LoadingContainer fixed>
                <LoadingDisplay />
              </LoadingContainer>
            ) : (
              <DownloadHistorySongs />
            )}
          </DownloadHistoryContainer>
        </AudioPlayerWrapper>
      </ExpandableSongsWrapper>
    </DownloadContextWrapper>
  );
};

export default DownloadHistory;
