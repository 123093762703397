// @flow
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useSignInFormSchema = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    password: Yup.string().required(t('general.error.signInSignUp.passwordRequired', '')),
    email: Yup.string()
      .email(t('general.error.signInSignUp.validEmailRequired', ''))
      .required(t('general.error.signInSignUp.emailRequired', '')),
  });
};
