// @flow
import React from 'react';
import { css } from 'emotion';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../ClassicModalLayout/ClassicModalLayout';
import Button from '../../../components/Button/Button';
import { SubscriptionModalFooter } from '../../../user/subscriptions/components/CancelSubscriptionModal/CancelSubscriptionModal';
import { useSubscriptionsContext } from '../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { SUBSCRIPTIONS } from '../../../user/subscriptions/data';
import { useTranslation } from 'react-i18next';

const addButtonClass = css`
  background-color: #4d4d4d;
`;

type Props = {
  onClose: () => void,
};

const SubscriptionsRequiredModal = ({ onClose }: Props) => {
  const { t } = useTranslation();

  const { selectPlan } = useSubscriptionsContext();

  const handleAdd = () => {
    selectPlan(SUBSCRIPTIONS.creatorPro.key);
    onClose();
  };

  return (
    <ClassicModalLayout onClose={onClose} fullSized={false}>
      <ClassicModalContentLayout
        heading={t('modal.subscription.required.header', '')}
        description={
          <>
            <p>{t('modal.subscription.required.introductions.message', '')}</p>
            <ul>
              {t('modal.subscription.required.introductions.list', {
                defaultValue: [],
                returnObjects: true,
              }).map((text, index) => (
                <li key={index}>{text}</li>
              ))}
            </ul>
          </>
        }
        footer={
          <SubscriptionModalFooter>
            <Button className={addButtonClass} onClick={handleAdd}>
              {t('modal.subscription.required.button.addSubscription', '')}
            </Button>
          </SubscriptionModalFooter>
        }
      />
    </ClassicModalLayout>
  );
};

export default SubscriptionsRequiredModal;
