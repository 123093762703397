// @flow
import React, { useState, useEffect } from 'react';
import styled from 'react-emotion';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import AudioPlayerWrapper from '../../../../../../../../../audio/components/AudioPlayerWrapper/AudioPlayerWrapper';
import { useResultsSongs, useResultsManagerContext } from '../../../ResultsManager/ResultsManager';
import AudioCardWrapper from '../../../../../../../../../song/components/AudioCardWrapper/AudioCardWrapper';
import { getSongID } from '../../../../../../../../../api/algolia/song';
import FullSongCard from '../../../../../../../../../song/components/FullSongCard/FullSongCard';
import { PLAYER_TYPES } from '../../../../../../../../../song/data';
import ResultsMainPagination from '../ResultsMainPagination/ResultsMainPagination';
import SortResultsDropdown from '../SortResultsDropdown/SortResultsDropdown';
import ResultsNav from '../../../ResultsNav/ResultsNav';
import { DropdownsContainer } from '../../ResultsMain';
import { darkColor } from '../../../../../../../../../styles/config/colors';

const Container = styled('div')`
  margin-bottom: 30px;
  margin-top: 20px;
  padding: 0px 5px;
`;

const PaginationContainer = styled('div')`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const List = styled('ul')`
  background-color: ${darkColor};

  & .carousel-root,
  .carousel,
  .carousel-slider,
  .slide,
  .selected {
    background: ${darkColor} !important;
  }
`;

const FullSongCardContainer = styled('li')`
  width: 100%;
`;

// const LoadWrapper = styled('div')`
//   margin-top: 24px;
//   display: flex;
//   justify-content: center;
// `;

const MobileResults = () => {
  const [visibleSongIndexes, setVisibleSongIndexes] = useState([0, 1]);

  const {
    // mobileResultsNumberVisibleSongs,
    // setMobileResultsNumberVisibleSongs,
    currentIndexSong,
    // handleSwapMobileSongs,
    // selectedSongId,
  } = useResultsManagerContext();
  const songs = useResultsSongs();
  // const remainingSongs = songs.length > mobileResultsNumberVisibleSongs;

  // const handleLoadMore = () => {
  //   setMobileResultsNumberVisibleSongs(mobileResultsNumberVisibleSongs + 3);
  // };

  // const handleSwapSongs = (index, item) => {
  //   if (item.props.song.objectID !== selectedSongId) {
  //     handleSwapMobileSongs(item.props.song.objectID);
  //   }
  // };

  useEffect(() => {
    if (!currentIndexSong) {
      setVisibleSongIndexes([0, 1]);
    } else {
      const startingIndex = Math.max(currentIndexSong, 0);
      const endingIndex = currentIndexSong + 1;
      console.log('start at', startingIndex, 'end at', endingIndex);
      setVisibleSongIndexes([startingIndex, endingIndex]);
    }
  }, [currentIndexSong]);

  return (
    <Container>
      <PaginationContainer>
        <DropdownsContainer>
          <SortResultsDropdown />
          <ResultsMainPagination />
        </DropdownsContainer>
        <ResultsNav />
      </PaginationContainer>
      <List>
        <AudioPlayerWrapper>
          {songs.slice(visibleSongIndexes[0], visibleSongIndexes[1]).map(song => {
            return (
              <AudioCardWrapper
                autoplay
                autoload
                song={song}
                key={getSongID(song)}
                mainAudioCard
                playerType={PLAYER_TYPES.mobileResults}
              >
                <FullSongCardContainer>
                  <FullSongCard song={song} playerType={PLAYER_TYPES.mobileResults} />
                </FullSongCardContainer>
              </AudioCardWrapper>
            );
          })}
        </AudioPlayerWrapper>
      </List>
      {/*
      <List>
        <AudioPlayerWrapper>
          <Carousel
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
            infiniteLoop={false}
            swipeable
            emulateTouch
            onChange={handleSwapSongs}
            selectedItem={currentIndexSong}
          >
            {songs.slice(visibleSongIndexes[0], visibleSongIndexes[1] + 1).map(song => {
              return (
                <AudioCardWrapper
                  autoplay={false}
                  autoload
                  song={song}
                  key={getSongID(song)}
                  mainAudioCard={false}
                  playerType={PLAYER_TYPES.mobileResults}
                >
                  <FullSongCardContainer>
                    <FullSongCard song={song} playerType={PLAYER_TYPES.mobileResults} />
                  </FullSongCardContainer>
                </AudioCardWrapper>
              );
            })}
          </Carousel>
        </AudioPlayerWrapper>
      </List>
       remainingSongs && (
        <LoadWrapper>
          <DarkButton onClick={handleLoadMore}>{getMiscCopyLoadMore(miscCopy)}</DarkButton>
        </LoadWrapper>
      ) */}
    </Container>
  );
};

export default MobileResults;
