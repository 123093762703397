// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { useSetRecoilState } from 'recoil';
import SearchIcon from '../../../../../assets/inline-assets/search-icon.svg';
import {
  MEDIA_BREAKPOINTS,
  hideOnSmallDesktopDeviceCss,
  hideOnSmallDeviceCss,
  LARGE_DESKTOP_BREAKPOINT,
  LARGE_DEVICE_BREAKPOINT,
  MOBILE_DEVICE_BREAKPOINT,
  showOnSmallDesktopDeviceCss,
  SMALL_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../styles/responsive';
import Button, { DarkButton } from '../../../../../components/Button/Button';
import KeywordsSearchInput from './components/KeywordsSearchInput/KeywordsSearchInput';
import KeywordsAside from './components/KeywordsAside/KeywordsAside';
import { useKeywordsManagerContext } from './components/KeywordsManager/KeywordsManager';
import { useNavigate } from '../../../../hooks';
import { ROUTES } from '../../../../routes';
import {
  analyticsKeywordsEditUpdate,
  analyticsKeywordsSearch,
} from '../../../../../analytics/events';
import { useSubscriptionsContext } from '../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import SelectedKeywords from './components/KeywordsAside/components/SelectedKeywords/SelectedKeywords';
import { getInitialKeywordLimit } from '../../../../../utils/keywordLimit';
import { getMagicKeywordQueryValues } from '../../../../../api/algolia/magicKeywords';
import YoutubeKeyword from '../../../ResultsScreen/components/ResultsView/components/ResultsAside/components/YouTubeKeyword/YouTubeKeyword';
import { base64EncodeURLValue } from '../../../../../utils/encode';
import { searchEditModeAtom, searchTriggerAtom } from '../../../../../store/mixpanelAnalytics';
import { useTranslation } from 'react-i18next';
import { isSearchIndexSwitcherEnabled } from '../../../../../utils/env';

const Container = styled('div')`
  height: 100vh;
  display: flex;
  padding: 0 60px;
  position: relative;

  ${MEDIA_BREAKPOINTS.max1260} {
    padding-left: 40px;
    padding-right: 40px;
  }

  ${LARGE_DESKTOP_BREAKPOINT} {
    align-items: center;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    height: auto;
    min-height: 100vh;
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    align-items: flex-start;
  }

  ${LARGE_DEVICE_BREAKPOINT} {
    ${SMALL_DESKTOP_BREAKPOINT} {
      flex-direction: row;
      flex-wrap: wrap;
      align-content: space-between;
      padding-left: 60px;
      padding-right: 60px;
      padding-bottom: 53px;
    }
  }
`;

const Aside = styled('aside')`
  ${LARGE_DESKTOP_BREAKPOINT} {
    width: 155px;
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
`;

const keywordsAsideClass = css`
  ${SMALL_DESKTOP_BREAKPOINT} {
    order: 2;
    margin-top: 115px;
  }

  ${LARGE_DEVICE_BREAKPOINT} {
    ${SMALL_DESKTOP_BREAKPOINT} {
      flex: 1;
      margin-right: 30px;
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 0px;
    padding-bottom: 100px;
  }
`;

const Main = styled('section')`
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${LARGE_DESKTOP_BREAKPOINT} {
    position: relative;
    height: 100%;
    flex: 1;
  }

  ${LARGE_DEVICE_BREAKPOINT} {
    ${SMALL_DESKTOP_BREAKPOINT} {
      width: 100%;
      padding: 0;
    }
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    order: 1;
    height: 60vh;
    width: 100%;
    align-items: center;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    height: unset;
  }
`;

const ActionContainer = styled('div')`
  display: flex;
  justify-content: center;
  z-index: 5; // ensure above dropdown list

  ${LARGE_DESKTOP_BREAKPOINT} {
    position: absolute;
    left: 0;
    bottom: 50px;
    right: 0;

    &.offsetBanner {
      bottom: 125px;
    }
  }

  ${LARGE_DEVICE_BREAKPOINT} {
    ${SMALL_DESKTOP_BREAKPOINT} {
      display: block;
      align-self: flex-end;
      order: 3;
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    position: sticky;
    top: calc(100% - 60px);
    bottom: unset;
    left: 0;
    right: 0;
    width: 100%;
    padding: 5px;
  }
`;
const searchButtonClass = css`
  min-width: 150px;
  transition: transform 200ms ease, opacity 200ms ease;

  svg g {
    stroke: #fff;
  }

  &:not(:first-child) {
    margin-left: 5px;

    ${SMALL_DEVICE_BREAKPOINT} {
      margin-left: 2.5px;
    }
  }

  &:not(:last-child) {
    margin-right: 5px;

    ${SMALL_DEVICE_BREAKPOINT} {
      margin-right: 2.5px;
    }
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    min-width: 115px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    min-width: 0;
    flex: 1;
    display: block;
    padding: 15px;
  }

  &:disabled {
    opacity: 0;
    transform: translateY(15px);
  }
`;

const SelectedKeywordsWrapper = styled('div')`
  position: relative;
  height: 170px;
  top: -150px;
  overflow-y: auto;

  &.fullKeywordsDisplay {
    height: unset;
    min-height: 170px;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: grey;
  }
  ${SMALL_DESKTOP_BREAKPOINT} {
    top: 10px;
    height: 170px;

    &.fullKeywordsDisplay {
      height: unset;
      min-height: 170px;
      margin-bottom: 25px;
    }
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    height: 220px;

    &.fullKeywordsDisplay {
      height: unset;
      min-height: 220px;
    }
  }

  &.offsetBanner {
    top: -120px;
  }
`;

const KeywordsSearchInputWrapper = styled('div')`
  position: relative;
  top: -130px;

  &.fullKeywordsDisplay {
    margin-top: 8px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    top: 0px;
    width: 100%;
  }
`;

const Wrapper = styled('div')`
  ${SMALL_DESKTOP_BREAKPOINT} {
    width: 100%;
  }
`;

const SelectedYoutubeKeywordWrapper = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
`;

type Props = {
  actionText?: string,
  cancelEnabled?: boolean,
  placeholder?: string,
  onCancel?: () => void,
  onSearch?: (Array<string>, Array<string>) => void,
};

const KeywordsSearchView = ({
  actionText = '',
  cancelEnabled = false,
  placeholder = '',
  onCancel,
  onSearch,
}: Props) => {
  const { t } = useTranslation();

  const {
    mergedUIKeywords,
    keywords,
    magicKeywords,
    paramKeywords,
    paramMagicKeywords,
    editMode,
    youtubeKeywords,
    removeYouTubeKeyword,
    youtubeVideoTitle,
    youtubeVideoId,
    youtubeChannelTitle,
    youtubeChannelId,
    paramYouTubeKeywords,
    paramYouTubeChannelId,
    paramYouTubeChannelTitle,
    paramYouTubeVideoId,
    paramYouTubeVideoTitle,
  } = useKeywordsManagerContext();
  const navigate = useNavigate();

  const setResultsSearchTriggerLocation = useSetRecoilState(searchTriggerAtom);
  const setResultsIsEditMode = useSetRecoilState(searchEditModeAtom);
  const { referralCoupon, showReferralBanner } = useSubscriptionsContext();

  const initialKeywordLimit = getInitialKeywordLimit('find');

  const [keywordLimit, setKeywordLimit] = useState(initialKeywordLimit);
  const [showAllKeywords, setShowAllKeywords] = useState(false);
  const [showTruncateButton, setShowTruncateButton] = useState(false);

  const handleTruncate = () => {
    if (mergedUIKeywords.length > keywordLimit) {
      setKeywordLimit(mergedUIKeywords.length);
      setShowAllKeywords(true);
    } else {
      setShowAllKeywords(false);
      setKeywordLimit(initialKeywordLimit);
    }
  };

  /*
  const [initialViewport, setInitialViewport] = useState(0);
  const [finalViewport, setFinalViewport] = useState(0);

  useEffect(() => {

    const isVisualViewportAvailable = getViewport();

    const handleVisualViewportMovement = () => {
      setFinalViewport(visualViewport.height - 60 + window.visualViewport.offsetTop);
    }
  
    const handleTouchMove = () => {
      console.log(visualViewport.height);
      setFinalViewport("100%");
    }

    if (isVisualViewportAvailable === true){
      window.visualViewport.addEventListener('resize', handleVisualViewportMovement);
      window.visualViewport.addEventListener('scroll', handleVisualViewportMovement);
      window.addEventListener('touchstart', handleTouchMove);
      setInitialViewport(window.visualViewport.height);
      setFinalViewport(window.visualViewport.height - 60);
    }else if (isVisualViewportAvailable === false){
      setInitiallViewport(window.innerHeight);
      setFinalViewport(window.innerHeight - 60);
    }else{
      setInitialViewport(0);
    }
  }, []);

  */

  const canSearch =
    (editMode && mergedUIKeywords.length >= 1) ||
    mergedUIKeywords.length >= 1 ||
    youtubeKeywords.length >= 1;

  const handleSearch = () => {
    const magicKeywordQueryValues = getMagicKeywordQueryValues(magicKeywords);
    if (onSearch) {
      onSearch(keywords, magicKeywordQueryValues);
    } else {
      analyticsKeywordsSearch(keywords.join(', '));
      if (editMode) {
        analyticsKeywordsEditUpdate(keywords);
      }
      setResultsSearchTriggerLocation('search');
      setResultsIsEditMode(!!editMode);
      const encodedYouTubeKeywords = youtubeKeywords.map(keyword => {
        return base64EncodeURLValue(keyword, true);
      });
      navigate(
        ROUTES.results.navigatePath({
          keywords,
          magicKeywords: magicKeywordQueryValues,
          ytKeywords: encodedYouTubeKeywords,
          channelId: youtubeChannelId,
          channelTitle: youtubeChannelTitle,
          videoId: youtubeVideoId,
          videoTitle: youtubeVideoTitle,
        })
      );
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      navigate(
        ROUTES.results.navigatePath({
          keywords: paramKeywords,
          magicKeywords: paramMagicKeywords,
          ytKeywords: paramYouTubeKeywords,
          channelId: paramYouTubeChannelId,
          channelTitle: paramYouTubeChannelTitle,
          videoId: paramYouTubeVideoId,
          videoTitle: paramYouTubeVideoTitle,
        })
      );
    }
  };

  const getFindMusicSearchButton = (): string => {
    const findMusicSearchButtonText = t('findMusicPage.searchButton', '');

    if (isSearchIndexSwitcherEnabled() && window.sessionStorage.getItem('stgidx')) {
      return findMusicSearchButtonText + '(stg)';
    }

    return findMusicSearchButtonText;
  };

  actionText =
    actionText || (editMode ? t('editKeywordsPage.button', '') : getFindMusicSearchButton());

  placeholder = placeholder || t('findMusicPage.searchPlaceholder', '');

  const actionButton = (
    <React.Fragment>
      {(cancelEnabled || editMode) && (
        <DarkButton className={searchButtonClass} onClick={handleCancel}>
          {t('general.button.cancel', '')}
        </DarkButton>
      )}
      <Button
        className={searchButtonClass}
        icon={<SearchIcon />}
        disabled={!canSearch}
        onClick={handleSearch}
      >
        {actionText}
      </Button>
    </React.Fragment>
  );

  return (
    <Container>
      <ActionContainer className={cx(showOnSmallDesktopDeviceCss)}>{actionButton}</ActionContainer>

      <Aside className={keywordsAsideClass}>
        <KeywordsAside />
      </Aside>

      <Main>
        <Wrapper>
          <SelectedKeywordsWrapper
            className={cx({
              fullKeywordsDisplay: showAllKeywords,
              youtubeKeywordDisplay: youtubeKeywords.length > 0,
            })}
          >
            <>
              {youtubeKeywords.length > 0 && (
                <SelectedYoutubeKeywordWrapper>
                  <YoutubeKeyword
                    channel={youtubeChannelTitle}
                    video={youtubeVideoTitle}
                    handleClick={removeYouTubeKeyword}
                  />
                </SelectedYoutubeKeywordWrapper>
              )}
            </>
            <SelectedKeywords
              keywordLimit={keywordLimit}
              setKeywordLimit={setKeywordLimit}
              showAllKeywords={showAllKeywords}
              setShowTruncateButton={setShowTruncateButton}
              showTruncateButton={showTruncateButton}
              initialKeywordLimit={initialKeywordLimit}
              handleTruncate={handleTruncate}
            />
          </SelectedKeywordsWrapper>
          <KeywordsSearchInputWrapper className={cx({ fullKeywordsDisplay: showAllKeywords })}>
            <KeywordsSearchInput placeholder={placeholder} handleSearch={handleSearch} />
          </KeywordsSearchInputWrapper>
        </Wrapper>
        <ActionContainer
          className={cx(hideOnSmallDesktopDeviceCss, {
            offsetBanner: referralCoupon && showReferralBanner,
          })}
        >
          {actionButton}
        </ActionContainer>
      </Main>

      <Aside className={hideOnSmallDeviceCss} />
    </Container>
  );
};

export default KeywordsSearchView;
