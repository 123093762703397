// @flow
import React, { useState } from 'react';
import { get } from 'lodash';
import styled from 'react-emotion';
import parse from 'html-react-parser';
import { transparentize } from 'polished';
import { css, cx } from 'emotion';
import {
  smallPlainTextCss,
  mediumFontWeightCss,
} from '../../../../../../styles/typography/typography';
import { darkColor, lightColor } from '../../../../../../styles/config/colors';
import { useYouTubeAPIHandler } from '../../../YouTubeAPIHandler/YouTubeAPIHandler';
import {
  getYouTubeChannelName,
  getYouTubeChannelImage,
  getYouTubeChannelSubscriberCount,
  getYouTubeChannelVideoCount,
} from '../../../../../../api/firebase/user/channels';
import SmallLoadingAnimation from '../../../../../../components/LoadingDisplay/components/LoadingAnimation/SmallLoadingAnimation';

import { SMALL_DEVICE_BREAKPOINT } from '../../../../../../styles/responsive';
import { Trans, useTranslation } from 'react-i18next';

const socialYouTubeHoverClassName = 'socialAccount--hover';
const activeYouTubeResultsClassName = 'activeYouTubeResults';

const Container = styled('div')`
  position: relative;
`;

const SearchContainer = styled('div')`
  display: flex;
  align-items: center;
  padding: 5px;
  border: 0.5px solid ${transparentize(0.9, darkColor)};
  border-radius: 3px;

  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 1.5px 5px;
  }
`;

const Info = styled('div')`
  ${smallPlainTextCss};
  flex: 1;
  display: flex;
  align-items: center;
  margin: 5px 10px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin: 0px 0px 0px 10px;
  }
`;

const Icon = styled('div')`
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  opacity: 0.4;
  align-items: center;
`;

const ChannelSearchContainer = styled('div')`
  margin-left: 14px;
  width: 100%;
  & input {
    background-color: ${transparentize(1, lightColor)};
    color: ${darkColor};
    border: none;
    width: 100%;
    font-size: 12px;
    opacity: 1;
    letter-spacing: 0.5px;
    font-family: 'Roboto', sans-serif;

    ::placeholder {
      color: ${transparentize(0.6, darkColor)};
    }

    &[type='text']:focus {
      outline: none;
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    & input {
      width: 100%;
      position: relative;
      bottom: 4px;
      margin-top: 7px;
      padding: 3px 3px 3px 3px;
    }
  }

  &.${socialYouTubeHoverClassName} ::placeholder {
    color: ${transparentize(0, darkColor)};
  }
`;

const activeSearchContainer = css`
  border-radius: 3px 3px 0px 0px;
`;

const ResultsContainer = styled('div')`
  display: flex;
  align-items: center;
  border: 0.5px solid ${transparentize(0.9, darkColor)};
  border-top: none;
  border-radius: 0px 0px 3px 3px;
  position: absolute;
  z-index: 100;
  background-color: ${lightColor};
  width: 100%;
  padding: 0;
`;

const ChannelResultsContainer = styled('div')`
  display: none;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 5px 15px 0 rgba(255, 255, 255, 0.2);

  &.${activeYouTubeResultsClassName} {
    display: flex;
  }
`;

const ChannelResult = styled('div')`
  display: flex;
  flex-direction: row;
  background-color: ${transparentize(0.95, darkColor)};
  width: 100%;
  padding: 8px 5px;
  border-bottom: 1px solid ${transparentize(0.95, darkColor)};
  color: ${transparentize(0.5, darkColor)};

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover div p {
    color: #000;
  }

  &:last-of-type:hover p {
    color: ${transparentize(0.5, darkColor)};
  }
`;

const YouTubeChannelIcon = styled('div')`
  display: flex;
  align-items: center;
  margin: 0px 8px;

  img {
    border-radius: 100%;
    height: 25px;
    width: 25px;
  }
`;

const YouTubeChannelInfo = styled('div')`
  display: flex;
  flex-direction: column;
  line-height: 20px;
`;

const YouTubeChannelName = styled('p')`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
`;

const YouTubeChannelDetailsWrapper = styled('div')`
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 13px;
  text-transform: uppercase;

  p {
    word-spacing: 5px;

    span {
      font-size: 8px;
    }
  }
`;

const YouTubeChannelHelpWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${smallPlainTextCss};
  ${mediumFontWeightCss};
  text-align: center;
  font-size: 12px;
  color: #000;

  &:hover {
    cursor: auto;
  }

  & p {
    color: ${transparentize(0.5, darkColor)};
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 2px;
  }
`;

const LodaingContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  height: 35px;
  background-color: ${transparentize(0.95, darkColor)};
`;

export const spinnerClass = css`
  position: static;
`;

type Props = {
  busy?: boolean,
  icon: any,
  subscriptionKey: string,
  planKey: string,
  extraChannels: Array<any>,
  isPrimaryConnection: boolean,
  setFreeTrialYTTooltipDisplay: boolean => void,
};

type SearchInputProps = {
  value: string,
  handleChange: () => void,
  handleBlur: () => void,
  handleFocus: () => void,
  disabled: boolean,
};

type YouTubeChannelDetailsProps = {
  subscriberCount: string,
  videoCount: string,
};

type YouTubeChannelResultsProps = {
  channelInfo: any,
  subscriptionKey: string,
  planKey: string,
  isSearchActive: boolean,
  extraChannels: Array<any>,
  isPrimaryConnection: boolean,
  handleInputReset: () => void,
  isUnsupportedFormat: boolean,
};

const parseYouTubeIdentifier = (inputUrl: string, path: string) => {
  let searchInput = inputUrl;
  const subscriberViewParam = '?view_as=subscriber';
  const isSubscriberView = searchInput.includes(subscriberViewParam);

  if (isSubscriberView) {
    const subscriberParamIndexStart = searchInput.indexOf(subscriberViewParam);
    searchInput = searchInput.slice(0, subscriberParamIndexStart);
  }

  const substringIndexStart = searchInput.indexOf(path);
  const substringIndexEnd = substringIndexStart + path.length;
  const youtubeUserName = searchInput.substring(substringIndexEnd).replace('/', '');

  return youtubeUserName;
};

const parseYouTubeChannelUrl = (input: string): any => {
  const inputUrl = input;
  const userPath = 'youtube.com/user/';
  const channelPath = 'youtube.com/channel/';

  if (inputUrl.includes(userPath)) {
    const youtubeUserName = parseYouTubeIdentifier(inputUrl, userPath);
    const parsedResult = {
      resultType: 'userUrl',
      parsedText: youtubeUserName,
    };

    return parsedResult;
  }

  if (inputUrl.includes(channelPath)) {
    const youtubeChannelId = parseYouTubeIdentifier(inputUrl, channelPath);
    const parsedResult = {
      resultType: 'channelUrl',
      parsedText: youtubeChannelId,
    };

    return parsedResult;
  }

  const parsedResult = {
    resultType: 'notUrl',
    parsedText: inputUrl,
  };

  return parsedResult;
};

export const getFormattedCount = (count: string): string => {
  const countNumber = parseInt(count);

  if (countNumber >= 1000000) {
    const formattedCount = countNumber / 1000000;
    const formattedString = `${formattedCount}M`;
    return formattedString;
  }

  if (countNumber >= 1000) {
    const formattedString = countNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedString;
  }

  return count;
};

const YouTubeChannelSearchInput = ({
  value,
  handleChange,
  handleBlur,
  handleFocus,
}: SearchInputProps) => {
  const { t } = useTranslation();

  return (
    <input
      value={value}
      type="text"
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      placeholder={t('general.placeholder.text.youtubeChannelPaid', '')}
    />
  );
};

const YouTubeChannelDetails = ({ subscriberCount, videoCount }: YouTubeChannelDetailsProps) => {
  const { t } = useTranslation();

  return (
    <YouTubeChannelDetailsWrapper>
      <p>
        {t('subscriptionsPage.youtubeConnection.channelDetails', '')
          .replace('$SUBSCRIBERCOUNT$', getFormattedCount(subscriberCount))
          .replace('$DOT$', parse('•'))
          .replace('$VIDEOCOUNT$', getFormattedCount(videoCount))}
      </p>
    </YouTubeChannelDetailsWrapper>
  );
};

const YouTubeChannelHelp = ({ isUnsupportedFormat }: { isUnsupportedFormat: boolean }) => {
  const { t } = useTranslation();

  return (
    <>
      {isUnsupportedFormat ? (
        <YouTubeChannelHelpWrapper>
          <h6>{t('subscriptionsPage.youtubeConnection.wrongFormatTitle', '')}</h6>
          <Trans
            components={{
              support_link: (
                <a
                  href="https://support.evokemusic.ai/en/support/solutions/articles/44001784588-how-do-i-add-a-youtube-channel-to-my-current-subscription-"
                  target="_blank"
                ></a>
              ),
            }}
          >
            {t('subscriptionsPage.youtubeConnection.wrongFormatDescription', '')}
          </Trans>
        </YouTubeChannelHelpWrapper>
      ) : (
        <YouTubeChannelHelpWrapper>
          <h6>{t('subscriptionsPage.youtubeConnection.helpTitle', '')}</h6>
          {t('subscriptionsPage.youtubeConnection.helpDescription', '')}
        </YouTubeChannelHelpWrapper>
      )}
    </>
  );
};

const YouTubeChannelResults = ({
  channelInfo,
  onConnect,
  subscriptionKey,
  planKey,
  isSearchActive,
  extraChannels,
  isPrimaryConnection,
  handleInputReset,
  isUnsupportedFormat,
}: YouTubeChannelResultsProps) => {
  const { title, thumbnail, subscriberCount, videoCount } = channelInfo;
  const { openYouTubeChannelModal } = useYouTubeAPIHandler();

  const onConnectYouTube = () => {
    if (isPrimaryConnection) {
      openYouTubeChannelModal(subscriptionKey, planKey, channelInfo, true, [], () => {});
    } else {
      openYouTubeChannelModal(subscriptionKey, planKey, channelInfo, false, extraChannels);
    }

    handleInputReset();
  };

  return (
    <ChannelResultsContainer className={cx({ [activeYouTubeResultsClassName]: isSearchActive })}>
      {channelInfo.title ? (
        <ChannelResult onMouseDown={onConnectYouTube}>
          <YouTubeChannelIcon>
            <img src={thumbnail} alt="YouTube thumbnail" />
          </YouTubeChannelIcon>
          <YouTubeChannelInfo>
            <YouTubeChannelName>{title}</YouTubeChannelName>
            <YouTubeChannelDetails subscriberCount={subscriberCount} videoCount={videoCount} />
          </YouTubeChannelInfo>
        </ChannelResult>
      ) : (
        ''
      )}
      <ChannelResult>
        <YouTubeChannelHelp isUnsupportedFormat={isUnsupportedFormat} />
      </ChannelResult>
    </ChannelResultsContainer>
  );
};

const YouTubeChannelSearch = ({
  busy = false,
  icon,
  subscriptionKey,
  planKey,
  extraChannels,
  isPrimaryConnection,
  setFreeTrialYTTooltipDisplay,
}: Props) => {
  const { fetchChannelFromChannelUrl, fetchChannelFromUserUrl } = useYouTubeAPIHandler();
  const [channelInfo, setChannelInfo] = useState({
    title: '',
    thumbnail: '',
    subscriberCount: 0,
    videoCount: 0,
  });
  const [channelSearchText, setChannelSearchText] = useState('');
  const [searchingChannel, setSearchingChannel] = useState(false);
  const [isYouTubeSearching, setIsYouTubeSearching] = useState(false);
  const [YouTubeHover, setYouTubeHover] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const getChannelData = (channelInfo): any => {
    const youtubeChannelId = channelInfo.items[0].id;
    const title = getYouTubeChannelName(channelInfo.items[0]);
    const thumbnail = getYouTubeChannelImage(channelInfo.items[0]);
    const subscriberCount = getYouTubeChannelSubscriberCount(channelInfo.items[0]);
    const videoCount = getYouTubeChannelVideoCount(channelInfo.items[0]);
    return { youtubeChannelId, title, thumbnail, subscriberCount, videoCount };
  };

  const isCustomUrlFormat = !(
    channelSearchText.includes('youtube.com/channel/') ||
    channelSearchText.includes('youtube.com/user/')
  );

  const searchChannelsAPI = async ev => {
    if (ev.target.value === '') {
      setSearchingChannel(false);
      return;
    }

    setSearchingChannel(true);

    const parsedResult = parseYouTubeChannelUrl(ev.target.value);

    setIsYouTubeSearching(true);

    if (parsedResult.resultType === 'channelUrl') {
      const channelInfo = await fetchChannelFromChannelUrl(parsedResult.parsedText);
      const isThereChannel = get(channelInfo, 'items', false);

      if (isThereChannel) {
        const { youtubeChannelId, title, thumbnail, subscriberCount, videoCount } = getChannelData(
          channelInfo
        );

        setChannelInfo({
          youtubeChannelId,
          title,
          thumbnail,
          subscriberCount,
          videoCount,
        });

        setIsYouTubeSearching(false);
        return;
      }
    } else if (parsedResult.resultType === 'userUrl') {
      const channelInfo = await fetchChannelFromUserUrl(parsedResult.parsedText);
      const isThereChannel = get(channelInfo, 'items', false);

      if (isThereChannel) {
        const { youtubeChannelId, title, thumbnail, subscriberCount, videoCount } = getChannelData(
          channelInfo
        );

        setChannelInfo({
          youtubeChannelId,
          title,
          thumbnail,
          subscriberCount,
          videoCount,
        });

        setIsYouTubeSearching(false);
        return;
      }
    }

    setChannelInfo({
      title: '',
      thumbnail: '',
      subscriberCount: 0,
      videoCount: 0,
    });

    setIsYouTubeSearching(false);
  };

  const handleHover = () => {
    setYouTubeHover(true);
  };

  const handleHoverOut = () => {
    setYouTubeHover(false);
  };

  const handleChange = ev => {
    setChannelSearchText(ev.target.value);
    searchChannelsAPI(ev);
  };

  const handleFocus = () => {
    setIsSearchActive(true);
  };

  const handleBlur = () => {
    if (isCustomUrlFormat) {
      setTimeout(() => {
        setSearchingChannel(false);
      }, 500);
      return;
    } else {
      setSearchingChannel(false);
    }
  };

  const handlInputReset = () => {
    setSearchingChannel(false);
    setChannelInfo({
      title: '',
      thumbnail: '',
      subscriberCount: 0,
      videoCount: 0,
    });
    setChannelSearchText('');
  };

  return (
    <Container onMouseEnter={handleHover} onMouseLeave={handleHoverOut}>
      <SearchContainer
        className={cx({
          [activeSearchContainer]: searchingChannel,
        })}
      >
        <Info>
          <Icon>
            <img src={icon} alt="account icon" />
          </Icon>
          <ChannelSearchContainer
            className={cx({
              [socialYouTubeHoverClassName]: YouTubeHover,
            })}
          >
            <YouTubeChannelSearchInput
              value={channelSearchText}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleFocus={handleFocus}
            />
          </ChannelSearchContainer>
        </Info>
      </SearchContainer>
      {searchingChannel ? (
        <ResultsContainer>
          {isYouTubeSearching ? (
            <LodaingContainer>
              <SmallLoadingAnimation />
            </LodaingContainer>
          ) : (
            <YouTubeChannelResults
              channelInfo={channelInfo}
              subscriptionKey={subscriptionKey}
              planKey={planKey}
              isSearchActive={isSearchActive}
              extraChannels={extraChannels}
              isPrimaryConnection={isPrimaryConnection}
              handleInputReset={handlInputReset}
              isUnsupportedFormat={isCustomUrlFormat}
            />
          )}
        </ResultsContainer>
      ) : (
        ''
      )}
    </Container>
  );
};

export default YouTubeChannelSearch;
