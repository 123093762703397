// @flow
import React from 'react';
import styled from 'react-emotion';
import PlanPreview from '../PlanPreview/PlanPreview';
import { useSubscriptionsContext } from '../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { SUBSCRIPTIONS } from '../../data';
import {
  isBusinessSubscription,
  isCreatorProSubscription,
  isCreatorSubscription,
  isEnterpriseBasicSubscription,
  isEnterpriseSubscription,
} from '../../../../utils/subscriptions';
import { useTranslation } from 'react-i18next';

const Container = styled('section')`
  ul li {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
`;

const SelectSubscriptions = props => {
  const { t } = useTranslation();

  const { selectPlan } = useSubscriptionsContext();
  const plans = [
    SUBSCRIPTIONS.creatorPro.key,
    SUBSCRIPTIONS.business.key,
    SUBSCRIPTIONS.enterprise.key,
  ];

  const prisingPlans = ['free1', 'pro1', 'business1', 'enterprise1'];
  const subscriptionPricingPlans = prisingPlans.filter(pricingPlan => plans.includes(pricingPlan));

  const viewSubscription = (planKey: string) => {
    let selectedPlan = SUBSCRIPTIONS.creatorPro.key;

    if (SUBSCRIPTIONS.business.plan_codes.includes(planKey)) {
      selectedPlan = SUBSCRIPTIONS.business.key;
    }

    if (SUBSCRIPTIONS.enterprise.plan_codes.includes(planKey)) {
      selectedPlan = SUBSCRIPTIONS.enterprise.key;
    }
    selectPlan(selectedPlan);
  };

  const getPricingPlanBriefDescription = (plan: string) => {
    if (isCreatorSubscription(plan)) {
      return t('pricing.plans.plan.briefDescription.member', '');
    }

    if (isCreatorProSubscription(plan)) {
      return t('pricing.plans.plan.briefDescription.loyalMember', '');
    }

    if (isBusinessSubscription(plan)) {
      return t('pricing.plans.plan.briefDescription.loyalMemberPlus', '');
    }

    if (isEnterpriseSubscription(plan)) {
      return t('pricing.plans.plan.briefDescription.enterprise', '');
    }

    if (isEnterpriseBasicSubscription(plan)) {
      return t('pricing.plans.plan.briefDescription.enterpriseBasic', '');
    }
  };

  const getPricingPlanSubtitle = (plan: string) => {
    if (isCreatorSubscription(plan)) {
      return t('pricing.plans.plan.subTitle.member', '');
    }

    if (isCreatorProSubscription(plan)) {
      return t('pricing.plans.plan.subTitle.loyalMember', '');
    }

    if (isBusinessSubscription(plan)) {
      return t('pricing.plans.plan.subTitle.loyalMemberPlus', '');
    }

    if (isEnterpriseSubscription(plan)) {
      return t('pricing.plans.plan.subTitle.enterprise', '');
    }

    if (isEnterpriseBasicSubscription(plan)) {
      return t('pricing.plans.plan.subTitle.enterpriseBasic', '');
    }
  };

  const getPricingPlanName = (plan: string) => {
    if (isCreatorSubscription(plan)) {
      return t('general.planCode.member', '');
    }

    if (isCreatorProSubscription(plan)) {
      return t('general.planCode.loyalMember', '');
    }

    if (isBusinessSubscription(plan)) {
      return t('general.planCode.loyalMemberPlus', '');
    }

    if (isEnterpriseSubscription(plan)) {
      return t('general.planCode.enterprise', '');
    }

    if (isEnterpriseBasicSubscription(plan)) {
      return t('general.planCode.enterpriseBasic', '');
    }
  };

  return (
    <Container {...props}>
      <ul>
        {subscriptionPricingPlans.map(uid => {
          return (
            <li key={uid}>
              <PlanPreview
                plan={uid}
                name={getPricingPlanName(uid)}
                price={getPricingPlanSubtitle(uid)}
                description={getPricingPlanBriefDescription(uid)}
                onClick={() => {
                  viewSubscription(uid);
                }}
              />
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default SelectSubscriptions;
