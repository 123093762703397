// @flow
import React, { useState } from 'react';
import { Formik } from 'formik';
import styled, { css } from 'react-emotion';
import { analyticsAccountAddPayPal } from '../../../../../../../analytics/events';
import {
  FormInput,
  FormInputContainer,
  FormInputLabel,
} from '../../../../../../../components/forms/general';
import { paypalFormSchema } from './schema';
import Button, { BUTTON_WIDTHS } from '../../../../../../../components/Button/Button';
import { useFirestoreSetUserProfilePaypal } from '../../../../../../../api/firebase/user/user';
import { useAnalyticsMixpanelContext } from '../../../../../../../analytics/components/MixpanelWrapper';
import { updateMixpanelPaypalEmail } from '../../../../../../../analytics/mixpanel';
import { useUserId } from '../../../../../../../auth/components/AuthWrapper/AuthWrapper';

const SubmitWrapper = styled('div')`
  margin-top: 38px;
`;

const buttonCss = css`
  background-color: #4d4d4d;
  color: #fff !important;
`;

type Props = {
  initialEmail?: string,
  inputPlaceholderText: string,
  saveButtonText: string,
  onUpdate?: (email: string) => void,
};

const PaypalEmailForm = ({
  initialEmail = '',
  inputPlaceholderText,
  saveButtonText,
  onUpdate,
}: Props) => {
  const [updatePaypal, updatingPaypal] = useFirestoreSetUserProfilePaypal();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const userId = useUserId();

  const schema = paypalFormSchema;

  const onSubmit = ({ email }) => {
    if (updatingPaypal) return;
    updatePaypal(email).then(() => {
      updateMixpanelPaypalEmail(mixpanel, moengage, userId, email);
      analyticsAccountAddPayPal(email);
      if (onUpdate) {
        onUpdate(email);
      }
    });
  };
  return (
    <Formik
      isInitialValid={!!initialEmail}
      initialValues={{ email: initialEmail }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {formProps => {
        const { values, isValid, handleChange, handleBlur, handleSubmit } = formProps;
        return (
          <form onSubmit={handleSubmit}>
            <FormInputContainer>
              <FormInput
                id="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                type="email"
                placeholder={inputPlaceholderText}
              />
              <FormInputLabel visible={!!values.email}>{inputPlaceholderText}</FormInputLabel>
            </FormInputContainer>
            <SubmitWrapper>
              <Button
                className={buttonCss}
                disabled={!isValid}
                type="submit"
                loading={updatingPaypal}
                mobileWidth={BUTTON_WIDTHS.full}
              >
                {saveButtonText}
              </Button>
            </SubmitWrapper>
          </form>
        );
      }}
    </Formik>
  );
};

export default PaypalEmailForm;
