// @flow
import React, { useContext } from 'react';
import type { MusicPageMdl } from '../../../cms/musicPage';
import { useLocaleContext } from '../../../routing/components/LocaleWrapper/LocaleWrapper';
import { LOCALES } from '../../../locales';
import { getArtistProfileId } from '../../../cms/artistProfile';
import type { CollectionsPageGraphMdl } from '../../../cms/collectionsPage';
import {
  getCuratedCollectionId,
  getCuratedCollectionIsCreatorCollection,
} from '../../../cms/curatedCollection';
import type { CuratedCollectionGraphMdl } from '../../../cms/curatedCollection';
import type { ArtistProfileGraphMdl } from '../../../cms/artistProfile';

export type AppData = {
  prismicMusicPage: MusicPageMdl,
  jpPrismicMusicPage: MusicPageMdl,
  prismicIntervalChangeSubscriptionModal: ChangeIntervalSubscriptionGraphMdl,
  jpPrismicIntervalChangeSubscriptionModal: ChangeIntervalSubscriptionGraphMdl,
  prismic404ErrorPage: NotFoundGraphMdl,
  jpPrismic404ErrorPage: NotFoundGraphMdl,
  prismicCollectionsPage: CollectionsPageGraphMdl,
  jpPrismicCollectionsPage: CollectionsPageGraphMdl,
  allPrismicCuratedCollection: {
    edges: Array<{
      node: CuratedCollectionGraphMdl,
    }>,
  },
  jpAllPrismicCuratedCollection: {
    edges: Array<{
      node: CuratedCollectionGraphMdl,
    }>,
  },
  allPrismicArtistProfile: {
    edges: Array<{
      node: ArtistProfileGraphMdl,
    }>,
  },
  jpAllPrismicArtistProfile: {
    edges: Array<{
      node: ArtistProfileGraphMdl,
    }>,
  },
};

export const CopyContext = React.createContext<AppData | null>(null);

export const useCopyContext = (): AppData => {
  const copy: AppData = (useContext(CopyContext): any);
  return copy;
};

export const useMusicPage = (): MusicPageMdl => {
  const { locale } = useLocaleContext();
  const { jpPrismicMusicPage: jpCopy, prismicMusicPage: enCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

type Props = {
  data: AppData,
  children: any,
};

export const useCollectionsPage = (): CollectionsPageGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicCollectionsPage: enCopy, jpPrismicCollectionsPage: jpCopy } = useCopyContext();

  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useCuratedCollection = (uid: string): Array<CuratedCollectionGraphMdl> => {
  const { locale } = useLocaleContext();
  const { jpAllPrismicCuratedCollection, allPrismicCuratedCollection } = useCopyContext();
  let collections = allPrismicCuratedCollection;
  if (locale === LOCALES.japanese.code) {
    if (
      jpAllPrismicCuratedCollection &&
      jpAllPrismicCuratedCollection.edges &&
      jpAllPrismicCuratedCollection.edges.length > 0
    ) {
      collections = jpAllPrismicCuratedCollection;
    }
  }
  const curatedCollections = collections.edges.map(({ node }) => {
    return node;
  });

  return curatedCollections.filter(collection => {
    return uid === getCuratedCollectionId(collection);
  });
};

// TODO: Prismicなしでどうやって取得するか？
export const useCuratedCollections = (): Array<CuratedCollectionGraphMdl> => {
  const { locale } = useLocaleContext();
  const { jpAllPrismicCuratedCollection, allPrismicCuratedCollection } = useCopyContext();
  let collections = allPrismicCuratedCollection;
  if (locale === LOCALES.japanese.code) {
    if (
      jpAllPrismicCuratedCollection &&
      jpAllPrismicCuratedCollection.edges &&
      jpAllPrismicCuratedCollection.edges.length > 0
    ) {
      collections = jpAllPrismicCuratedCollection;
    }
  }
  return collections.edges
    .map(({ node }) => {
      return node;
    })
    .filter(collection => {
      return (
        getCuratedCollectionIsCreatorCollection(collection) === true ||
        getCuratedCollectionIsCreatorCollection(collection) === null
      );
    });
};

export const useArtistProfile = (uid: string): Array<ArtistProfileGraphMdl> => {
  const { locale } = useLocaleContext();
  const { jpAllPrismicArtistProfile, allPrismicArtistProfile } = useCopyContext();
  let profiles = allPrismicArtistProfile;
  if (locale === LOCALES.japanese.code) {
    if (
      jpAllPrismicArtistProfile &&
      jpAllPrismicArtistProfile.edges &&
      jpAllPrismicArtistProfile.edges.length > 0
    ) {
      profiles = jpAllPrismicArtistProfile;
    }
  }
  const artistProfiles = profiles.edges
    .map(({ node }) => {
      return node;
    })
    .filter(profile => {
      return uid === getArtistProfileId(profile);
    });

  return artistProfiles;
};

export const useArtistProfiles = (): Array<ArtistProfileGraphMdl> => {
  const { locale } = useLocaleContext();
  const { jpAllPrismicArtistProfile, allPrismicArtistProfile } = useCopyContext();
  let profiles = allPrismicArtistProfile;
  if (locale === LOCALES.japanese.code) {
    if (
      jpAllPrismicArtistProfile &&
      jpAllPrismicArtistProfile.edges &&
      jpAllPrismicArtistProfile.edges.length > 0
    ) {
      profiles = jpAllPrismicArtistProfile;
    }
  }
  return profiles.edges.map(({ node }) => {
    return node;
  });
};

const CopyContextWrapper = ({ data, children }: Props) => (
  <CopyContext.Provider value={data}>{children}</CopyContext.Provider>
);

export default CopyContextWrapper;
