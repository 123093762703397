// @flow
import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import { MediumHeading } from '../../../styles/typography/components/Heading/Heading';
import Button, {
  BUTTON_WIDTHS,
  DarkButton,
  shorterButtonClass,
} from '../../../components/Button/Button';
import MessageModal from '../MessageModal/MessageModal';
import { SmallerGenericCopy } from '../../../styles/typography/components/GenericCopy/GenericCopy';
import { ROUTES } from '../../../routing/routes';
import { useNavigate } from '../../../routing/hooks';
import { useAnalyticsMixpanelContext } from '../../../analytics/components/MixpanelWrapper';
import { analyticsMixpanelNewSearch } from '../../../analytics/mixpanel';
import { useTranslation } from 'react-i18next';

const Container = styled('div')``;

const Options = styled('div')`
  display: flex;
  margin-top: 17px;
`;

const Option = styled('div')`
  &:not(:first-child) {
    margin-left: 10px;
  }
`;

const buttonClass = css`
  ${shorterButtonClass};
`;

const Description = styled('div')`
  margin-top: 13px;
`;

type Props = {
  onClose: () => void,
};

const LeaveResultsModal = ({ onClose }: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const handleLeave = () => {
    analyticsMixpanelNewSearch(mixpanel, moengage, 0, 0, 0);
    onClose();
    navigate(ROUTES.musicSearch.navigatePath({}));
  };

  return (
    <MessageModal onClose={onClose}>
      <Container>
        <div>
          <MediumHeading>{t('modal.leaveResults.header', '')}</MediumHeading>
          <Description>
            <SmallerGenericCopy>{t('modal.leaveResults.instruction', '')}</SmallerGenericCopy>
          </Description>
        </div>
        <Options>
          <Option>
            <DarkButton width={BUTTON_WIDTHS.small} className={buttonClass} onClick={onClose}>
              {t('general.button.stay', '')}
            </DarkButton>
          </Option>
          <Option>
            <Button width={BUTTON_WIDTHS.small} className={buttonClass} onClick={handleLeave}>
              {t('general.button.leave', '')}
            </Button>
          </Option>
        </Options>
      </Container>
    </MessageModal>
  );
};

export default LeaveResultsModal;
