// @flow
import React from 'react';
import { ROUTES } from '../../routes';
import { useLocalePath } from '../LocaleWrapper/LocaleWrapper';
import { SiteNavLink } from '../SiteNavLinks/SiteNavLinks';
// import { isDesktopSafariBrowser } from '../../../utils/browser';
import { useTranslation } from 'react-i18next';

type Props = {
  children: any,
};

const ProfileNavLinks = ({ children }: Props) => {
  const { t } = useTranslation();

  const localePath = useLocalePath();

  // const browser = isDesktopSafariBrowser()
  //   ? t('extension.safariLabel', '')
  //   : t('extension.chromeLabel', '');

  // const downloadURL = isDesktopSafariBrowser()
  //   ? t('extension.safariDownloadUrl', '')
  //   : t('extension.downloadUrl', '');

  const links = [
    props => (
      <SiteNavLink to={ROUTES.profile.navigatePath({ localePath })} {...props}>
        {t('general.text.profile', '')}
      </SiteNavLink>
    ),
    props => (
      <SiteNavLink to={ROUTES.subscriptions.navigatePath({ localePath })} {...props}>
        {t('general.text.subscription', '')}
      </SiteNavLink>
    ),
    props => (
      <SiteNavLink to={ROUTES.billing.navigatePath({ localePath })} {...props}>
        {t('general.text.billing', '')}
      </SiteNavLink>
    ),
    props => (
      <SiteNavLink to={ROUTES.downloads.navigatePath({ localePath })} {...props}>
        {t('general.text.downloadHistory', '')}
      </SiteNavLink>
    ),
    props => (
      <SiteNavLink to={ROUTES.bookmarks.navigatePath({ localePath })} matchPartial {...props}>
        {t('general.text.bookmarks', '')}
      </SiteNavLink>
    ),
    props => (
      <SiteNavLink to={ROUTES.email.navigatePath({ localePath })} {...props}>
        {t('general.text.emailSettings', '')}
      </SiteNavLink>
    ),
    // 問い合わせ中のためコメントアウト
    // props => (
    //   <a href={downloadURL} target="_blank" rel="noopener noreferrer" {...props}>
    //     {t('extension.downloadText', '').replace('$VERSION$', browser)}
    //   </a>
    // ),
  ];
  return children(links);
};

export default ProfileNavLinks;
