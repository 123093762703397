// @flow
import React, { useState } from 'react';
import SongActionButton from '../SongActionButton/SongActionButton';
import ShareIcon from '../../../../../../../assets/inline-assets/share-icon.svg';
import ShareSongModal from '../../../../../../../modals/components/ShareSongModal/ShareSongModal';
import { useTranslation } from 'react-i18next';

type Props = {
  condensed?: boolean,
  handleShareModalState: any,
};

const ShareButton = ({ condensed = false, handleShareModalState }: Props) => {
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    setShareModalOpen(false);
    handleShareModalState();
  };

  return (
    <React.Fragment>
      <SongActionButton
        icon={<ShareIcon />}
        label={t('general.button.share', '')}
        condensed={condensed}
      />
      {shareModalOpen && <ShareSongModal onClose={handleClose} />}
    </React.Fragment>
  );
};

export default ShareButton;
