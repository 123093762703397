// @flow
import React from 'react';
import styled from 'react-emotion';
import { lightColor } from '../../../../../../../styles/config/colors';
import { MEDIA_BREAKPOINTS, SMALL_DEVICE_BREAKPOINT } from '../../../../../../../styles/responsive';
import NoResultsLayout from './components/NoResultsLayout/NoResultsLayout';
import { useResultsManagerContext } from '../ResultsManager/ResultsManager';
import ResultsKeywords from '../ResultsAside/components/ResultsKeywords/ResultsKeywords';
import AppliedFiltersList from '../ResultsAside/components/AppliedFiltersList/AppliedFiltersList';
import { useModalsContext } from '../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { TextButton } from '../../../../../../../components/Button/Button';
import SearchIcon from '../../../../../../../assets/inline-assets/search-icon.svg';
import { newSearchClass } from '../ResultsAside/ResultsAside';
import { useTranslation } from 'react-i18next';

const Container = styled('div')`
  ${MEDIA_BREAKPOINTS.min1000} {
    margin-top: 200px;
    margin-top: 27vh;
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    padding-bottom: 50px;
  }
`;

const KeywordsContainer = styled('div')`
  margin-bottom: 30px;

  &.invertedSpacing {
    margin-top: 30px;
    margin-bottom: 0px;
  }
`;

const AppliedFiltersListContainer = styled('div')`
  margin-top: 30px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 0px;
  }
`;

const SearchContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin: 40px 0px;
  width: 100%;

  svg {
    margin-right: 10px;
    position: relative;
    top: 2px;
    path,
    circle {
      stroke: ${lightColor};
    }
  }
`;

const NoResults = () => {
  const { t } = useTranslation();

  const { appliedFilters } = useResultsManagerContext();
  const { showLeaveResultsModal, showLeaveResultFiltersModal } = useModalsContext();

  const handleNewSearch = () => {
    showLeaveResultsModal();
  };

  const handleClearFilters = (newKeywords: Array<string>, confirmKeywordRemoval) => {
    showLeaveResultFiltersModal(newKeywords, confirmKeywordRemoval);
  };

  return (
    <Container>
      {Object.keys(appliedFilters).length > 0 ? (
        <>
          <KeywordsContainer>
            <ResultsKeywords
              onClear={handleNewSearch}
              onClearFilters={handleClearFilters}
              editEnabled
            />
          </KeywordsContainer>
          <NoResultsLayout
            heading={t('noResultsPage.filtering.header', '')}
            description={t('noResultsPage.filtering.message', '')}
          />
          <AppliedFiltersListContainer>
            <AppliedFiltersList />
          </AppliedFiltersListContainer>
        </>
      ) : (
        <>
          <NoResultsLayout
            heading={t('noResultsPage.header', '')}
            description={t('noResultsPage.message', '')}
            subheading={t('noResultsPage.searchTips.header', '')}
            subDescription={
              <ul>
                {t('noResultsPage.searchTips.instruction', {
                  defaultValue: [],
                  returnObjects: true,
                }).map((msg, index) => (
                  <li key={index}>{msg}</li>
                ))}
              </ul>
            }
          >
            <KeywordsContainer className="invertedSpacing">
              <ResultsKeywords onClear={handleNewSearch} />
            </KeywordsContainer>
          </NoResultsLayout>
        </>
      )}
      <SearchContainer>
        <TextButton className={newSearchClass} onClick={showLeaveResultsModal}>
          <SearchIcon />
          {t('resultsPage.button.newSearch', '')}
        </TextButton>
      </SearchContainer>
    </Container>
  );
};

export default NoResults;
