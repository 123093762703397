// @flow
import React from 'react';
import { useAnalyticsMixpanelContext } from '../../../../../../../../analytics/components/MixpanelWrapper';
import {
  analyticsMixpanelViewMicroparts,
  useMixpanelSongAnalyticsDimensions,
} from '../../../../../../../../analytics/mixpanel';
import { getSongShareSlug } from '../../../../../../../../api/algolia/song';
import MicropartsIcon from '../../../../../../../../assets/inline-assets/microparts-icon.svg';
import { useGlobalPlayerContext } from '../../../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { useUserId } from '../../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useDownloadContext } from '../../../../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import { useModalsContext } from '../../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useSubscriptionsContext } from '../../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useSongContext } from '../../../../../../SongContextWrapper/SongContextWrapper';
import InlineButton from './InlineButton';
import { useTranslation } from 'react-i18next';

type Props = {
  hideTooltip: () => void,
};

const ViewLoopsInlineButton = ({ hideTooltip }: Props) => {
  const { t } = useTranslation();

  const { song } = useSongContext();
  const { showLoopsModal } = useModalsContext();
  const { pauseGlobalPlayback } = useGlobalPlayerContext();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const mixpanelSongDimensions = useMixpanelSongAnalyticsDimensions();
  const downloadContext = useDownloadContext();
  const { userRole } = useSubscriptionsContext();
  const userId = useUserId();

  const handleOpenLoopsModal = event => {
    event.stopPropagation();
    event.preventDefault();

    const songFamily = getSongShareSlug(song);

    if (hideTooltip) {
      hideTooltip();
    }

    showLoopsModal(songFamily, downloadContext, () => pauseGlobalPlayback());

    analyticsMixpanelViewMicroparts(
      mixpanel,
      moengage,
      mixpanelSongDimensions,
      downloadContext,
      userRole,
      userId
    );
  };

  return (
    <InlineButton
      label={t('general.button.viewLoops', '')}
      icon={<MicropartsIcon />}
      action={handleOpenLoopsModal}
    />
  );
};

export default ViewLoopsInlineButton;
