// @flow
import React, { useState } from 'react';
import { get } from 'lodash';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import { useRecoilValue } from 'recoil';
import {
  smallPlainTextCss,
  monoNavCss,
} from '../../../../../../../../../styles/typography/typography';
import { SMALL_DESKTOP_BREAKPOINT } from '../../../../../../../../../styles/responsive';
import FilterMenuOption from '../FilterMenuOption/FilterMenuOption';
import type { AppliedAlgoliaFilters } from '../../../../../../../../../api/algolia/search';
import {
  isFilterExcluded,
  isFilterIncluded,
  getFilterLabel,
  getAppliedFiltersExclusiveList,
} from '../../../../../../../../../api/algolia/data';
import {
  algoliaClient,
  getAlgoliaKeywordsIndexName,
} from '../../../../../../../../../api/algolia/client';
import { darkColor } from '../../../../../../../../../styles/config/colors';
import { isAlgoliaAnalyticsEnabled } from '../../../../../../../../../utils/env';
import { useLocale } from '../../../../../../../../components/LocaleWrapper/LocaleWrapper';
import FilterAutocomplete from '../FilterAutocomplete/FilterAutocomplete';
import { songPlayingSelector } from '../../../../../../../../../store/globalPlayer';
import { useTranslation } from 'react-i18next';

export const Body = styled('section')`
  ${smallPlainTextCss};
  padding: 15px;
  position: relative;
  width: 370px;
  height: 540px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    width: 100%;
    max-height: 610px;
    padding: 10px;
  }
`;

export const FilteringSubTitle = styled('h6')`
  ${monoNavCss}
  text-transform: uppercase;
  color: ${transparentize(0.6, darkColor)};
  margin: 4px 4px 2px 4px;
  ${SMALL_DESKTOP_BREAKPOINT} {
    width: 100%;
    max-height: 610px;
    margin: 4px 4px 2px 10px;
  }
`;

type Props = {
  resultFacets: any,
  selectedFilter: string,
  appliedFilters: AppliedAlgoliaFilters,
};

export const OptionsContainer = styled('div')`
  height: 480px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dad8d3;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    height: 540px;
  }
`;

export const FilterOptions = styled('ul')`
  margin-bottom: 18px;
`;

const FilterSelectMenu = ({ resultFacets, selectedFilter, appliedFilters }: Props) => {
  const { t } = useTranslation();

  const locale = useLocale();
  const songPlaying = useRecoilValue(songPlayingSelector);

  const [searchTerm, setSearchTerm] = useState('');

  const facetOptions = resultFacets[selectedFilter];
  const selectedFilterLabel = getFilterLabel(selectedFilter, t);
  const selectedFilterLabelPlural = getFilterLabel(selectedFilter, t, true).toLocaleLowerCase();

  const [keywords, manual, category] = selectedFilter.split('.');
  const currentSongFilterCharacterstics = songPlaying
    ? songPlaying[keywords][manual][category]
    : [];

  const handleSearch = inputTerm => {
    setSearchTerm(inputTerm);
  };

  const indexName = getAlgoliaKeywordsIndexName(locale);
  const appliedExcludedFilters = getAppliedFiltersExclusiveList(appliedFilters, selectedFilter);

  return (
    <Body>
      <InstantSearch searchClient={algoliaClient} indexName={indexName}>
        <Configure hitsPerPage={15} analytics={isAlgoliaAnalyticsEnabled()} />
        <FilterAutocomplete
          addKeyword={() => {}}
          placeholder={t('resultsPage.text.searchFilterHeader', '').replace(
            '$FACET_TYPE_PLURAL$',
            selectedFilterLabelPlural
          )}
          selectedFilter={selectedFilter}
          handleSearch={handleSearch}
        />
      </InstantSearch>
      <OptionsContainer>
        {!searchTerm && currentSongFilterCharacterstics.length > 0 && (
          <>
            <FilteringSubTitle>
              {t('resultsPage.text.currentTrackFilterHeader', '').replace(
                '$FACET_TYPE$',
                selectedFilterLabel
              )}
            </FilteringSubTitle>
            <FilterOptions>
              {currentSongFilterCharacterstics.map(filterCharacteristic => {
                return (
                  <FilterMenuOption
                    key={filterCharacteristic}
                    filterKey={selectedFilter}
                    filterLabel={filterCharacteristic}
                    filterCount={get(facetOptions, filterCharacteristic, 1)}
                    isIncluded={isFilterIncluded(
                      appliedFilters,
                      selectedFilter,
                      filterCharacteristic
                    )}
                    isExcluded={isFilterExcluded(
                      appliedFilters,
                      selectedFilter,
                      filterCharacteristic
                    )}
                  />
                );
              })}
              {appliedExcludedFilters.map(appliedFilter => {
                return (
                  <FilterMenuOption
                    key={appliedFilter}
                    filterKey={selectedFilter}
                    filterLabel={appliedFilter}
                    filterCount={get(facetOptions, appliedFilter, 1)}
                    hideCount
                    isIncluded={isFilterIncluded(appliedFilters, selectedFilter, appliedFilter)}
                    isExcluded={isFilterExcluded(appliedFilters, selectedFilter, appliedFilter)}
                    hideZeroCount
                  />
                );
              })}
            </FilterOptions>
          </>
        )}
        {!searchTerm &&
          facetOptions &&
          Object.keys(facetOptions).filter(
            facetOption => !currentSongFilterCharacterstics.includes(facetOption)
          ).length > 0 && (
            <>
              <FilteringSubTitle>
                {t('resultsPage.text.topFilterResultsHeader').replace(
                  '$FACET_TYPE_PLURAL$',
                  selectedFilterLabelPlural
                )}
              </FilteringSubTitle>
              <FilterOptions>
                {Object.keys(facetOptions)
                  .filter(facetOption => !currentSongFilterCharacterstics.includes(facetOption))
                  .map(option => {
                    return (
                      <FilterMenuOption
                        key={option}
                        filterKey={selectedFilter}
                        filterLabel={option}
                        filterCount={get(facetOptions, option, 1)}
                        isIncluded={isFilterIncluded(appliedFilters, selectedFilter, option)}
                        isExcluded={isFilterExcluded(appliedFilters, selectedFilter, option)}
                        hideZeroCount
                      />
                    );
                  })}
              </FilterOptions>
            </>
          )}
      </OptionsContainer>
    </Body>
  );
};

export default FilterSelectMenu;
