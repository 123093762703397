// @flow
import React from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { css, cx } from 'emotion';
import { buttonResetCss, interactiveButtonCss } from '../../../../components/Button/Button';
import {
  businessBgColor,
  creatorBgColor,
  creatorProBgColor,
  enterpriseBgColor,
  darkColor,
  personalBgColor,
  guestBgColor,
} from '../../../../styles/config/colors';
import {
  mediumFontWeightCss,
  monoNavCss,
  smallMonoCondensedCss,
  smallPlainTextCss,
} from '../../../../styles/typography/typography';
import { BANNERS } from '../../../../images/paths';
import {
  isBusinessSubscription,
  isCreatorProSubscription,
  isCreatorSubscription,
  isEnterpriseSubscription,
  isEnterpriseBasicSubscription,
} from '../../../../utils/subscriptions';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('button')`
  ${buttonResetCss};
  display: block;
  width: 100%;
  background-color: ${transparentize(0.95, darkColor)};
  border-radius: 3px;
  cursor: pointer;

  &:not(:disabled) {
    ${interactiveButtonCss};
  }
`;

const Container = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: ${transparentize(0.95, darkColor)};
  border-radius: 3px;
  padding: 5px 20px;
  background-size: cover;
  background-position: center;
`;

const Name = styled('div')`
  ${smallPlainTextCss};
  ${mediumFontWeightCss};
  flex: 1;
  text-align: left;
  color: #fff;

  span {
    ${monoNavCss};
  }
`;

const ViewButton = styled('div')`
  ${smallMonoCondensedCss};
  text-transform: uppercase;
  color: #fff;

  @media (hover: hover) {
    visibility: hidden;

    button:focus &,
    button:hover & {
      visibility: visible;
    }

    button:disabled & {
      visibility: hidden;
    }
  }
`;

export const creatorPlanCss = css`
  background-color: ${creatorBgColor};
  background-image: url(${BANNERS.creator});
`;

export const creatorProPlanCss = css`
  background-color: ${creatorProBgColor};
  background-image: url(${BANNERS.creatorPro});
`;

export const guestPlanCss = css`
  background-color: ${guestBgColor};
`;

export const personalPlanCss = css`
  background-color: ${personalBgColor};
`;

export const businessPlanCss = css`
  background-color: ${businessBgColor};
`;

export const enterprisePlanCss = css`
  background-color: ${enterpriseBgColor};
`;

export const enterpriseBasicPlanCss = css`
  background-color: ${transparentize(0.95, darkColor)};
`;

const Description = styled('div')`
  ${smallPlainTextCss};
  text-align: left;
  padding: 16px 20px;
`;

type Props = {
  plan: string,
  name: string,
  price?: string,
  description?: string,
  className?: string,
  onClick?: () => void,
};

const PlanPreview = ({
  name,
  price = '',
  plan,
  description = '',
  className = '',
  onClick,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const handleClick = event => {
    if (onClick) {
      onClick(event);
    }
  };

  const getPlanName = (planCode: string) => {
    if (isCreatorProSubscription(planCode)) {
      return t('general.planCode.loyalMember', '');
    }

    if (isBusinessSubscription(planCode)) {
      return t('general.planCode.loyalMemberPlus', '');
    }

    if (isEnterpriseSubscription(planCode)) {
      return t('general.planCode.enterprise', '');
    }

    return '';
  };

  return (
    <Wrapper {...props} onClick={handleClick}>
      <Container
        className={cx(className, {
          [guestPlanCss]: isCreatorSubscription(plan),
          [personalPlanCss]: isCreatorProSubscription(plan),
          [businessPlanCss]: isBusinessSubscription(plan),
          [enterprisePlanCss]:
            isEnterpriseSubscription(plan) || isEnterpriseBasicSubscription(plan),
        })}
      >
        <Name>
          {getPlanName(plan)}{' '}
          <span>
            {isCreatorSubscription(plan) ? t('subscriptionsPage.plan.creatorPrice', '') : ''}
          </span>
        </Name>
        {onClick && <ViewButton>{t('general.button.view', '')}</ViewButton>}
      </Container>
      {description && <Description>{description}</Description>}
    </Wrapper>
  );
};

export default PlanPreview;
