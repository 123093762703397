/* eslint-disable jsx-a11y/no-autofocus */
// @flow
import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { connectAutoComplete } from 'react-instantsearch-dom';
import { css, cx } from 'emotion';
import { transparentize } from 'polished';
import styled from 'react-emotion';
import { darkColor } from '../../../../../../../styles/config/colors';
import {
  maxQuery,
  MEDIA_BREAKPOINTS,
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
  LARGE_DESKTOP_BREAKPOINT,
} from '../../../../../../../styles/responsive';
import { inputResetCss, placeholderCss } from '../../../../../../../styles/shared';
import PlusIcon from '../../../../../../../assets/inline-assets/plus-icon.svg';
import MinusIcon from '../../../../../../../assets/inline-assets/minus-icon.svg';
import {
  getInverseKeywordObjectToAdd,
  getKeywordObjectToAdd,
  isReplacableKeyword,
} from '../../../../../../../api/algolia/magicKeywords';
import type { AlgoliaKeyword } from '../../../../../../../api/algolia/magicKeywords';
import { useModalsContext } from '../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import SearchIcon from '../../../../../../../assets/inline-assets/search-icon.svg';
import { capitalize } from '../../../../../../../utils/text';
import { isMobileDeviceSize } from '../../../../../../../utils/device';
import { useTranslation } from 'react-i18next';

const inputTextCss = css`
  color: ${darkColor};
  letter-spacing: 1px;
  text-align: left;
  height: 40px;
  font-size: 14px;
  letter-spacing: 0.58px;

  ${SMALL_DEVICE_BREAKPOINT} {
    font-size: 20px;
    height: 40px;

    ${maxQuery(350)} {
      font-size: 25px;
      height: 50px;
      line-height: 50px;
    }
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    font-size: 16px;
    height: 50px;
  }
`;

const formCss = css`
  ${MOBILE_DEVICE_BREAKPOINT} {
    padding: 0 25px;
  }
`;

const inputClass = css`
  ${inputResetCss};
  ${inputTextCss};
  display: block;
  width: 640px;
  font-size: 19px;

  ${placeholderCss(`
    color: ${transparentize(0.2, darkColor)};
    font-style: italic;
    font-size: 19px;
  `)};

  &:focus,
  &:hover {
    ${placeholderCss(`
        color: ${transparentize(0.0, darkColor)};
  `)};
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  ${MEDIA_BREAKPOINTS.maxRange1260} {
    width: 550px;
  }

  ${MEDIA_BREAKPOINTS.maxRange1180} {
    width: 500px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    width: 550px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 600px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    margin-left: 25px;
  }
`;

const SuggestionsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  background-color: #c6c4c0;
  width: 640px;
  border-radius: 0px 0px 5px 5px;
  padding: 10px 0px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 4%;
    border-top: 1px solid ${transparentize(0.9, darkColor)};
    height: 5px;
    width: 92%;
    margin-top: 5px;
  }

  & ul {
    width: 100%;

    & li {
      width: 100%;

      ${MOBILE_DEVICE_BREAKPOINT} {
        border-bottom: 1px solid ${transparentize(0.9, darkColor)};

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }

  ${MEDIA_BREAKPOINTS.maxRange1260} {
    width: 550px;
  }

  ${MEDIA_BREAKPOINTS.maxRange1180} {
    width: 500px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    width: 550px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 600px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    top: 115px;
    width: 97%;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border-top: 1px solid ${transparentize(0.9, darkColor)};
      height: 5px;
      width: 100%;
      margin-top: 5px;
    }
  }
`;

const InteractiveOptionWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 38px;
  padding: 0px 25px;

  ${LARGE_DESKTOP_BREAKPOINT} {
    &:hover {
      background-color: ${transparentize(0.8, darkColor)};
    }
  }
`;

const highlightedSuggestionCss = css`
  cursor: pointer;
  ${LARGE_DESKTOP_BREAKPOINT} {
    background-color: ${transparentize(0.8, darkColor)};
  }
`;

const SuggestionText = styled('span')`
  margin-left: 25px;
  font-size: 14px;
  user-select: none;
`;

const SearchWrapper = styled('div')`
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    transform: scale(1.5);
    & g {
      stroke: ${darkColor};
    }
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 16.5px;
    & svg {
      transform: scale(1.5);
      & g {
        stroke: ${darkColor};
        stroke-width: 1;
      }
    }
  }
`;

const Hint = styled('div')`
  font-family: 'Roboto Mono', sans-serif;
  width: 100%;
  text-align: center;
  opacity: 0.6;
  font-size: 10px;
  font-weight: normal;
  letter-spacing: 0.42px;
  line-height: 13px;
  padding: 15px 0px;
`;

type Props = {
  placeholder: string,
  hits: Array<AlgoliaKeyword>,
  currentRefinement: string,
  refine: string => void,
  addKeyword: (keyword: string, isSuggestion: boolean) => void,
  addMagicKeywords: (magicKeyword: AlgoliaKeyword, isSuggestion: boolean) => void,
};

const getSuggestionValue = suggestion => suggestion;

const Form = styled('form')`
  display: flex;
  align-items: center;
`;

const renderInputComponent = ({
  className,
  value,
  addKeyword,
  ...inputProps
}: {
  className: any,
  value: any,
  addKeyword: any,
}) => {
  const handleSubmit = event => {
    event.preventDefault();
    addKeyword(value, true);
  };

  return (
    <Form className={cx(formCss)} onSubmit={handleSubmit} autoComplete="false" id="song-search">
      <SearchWrapper>
        <SearchIcon />
      </SearchWrapper>
      <input className={cx(className, inputClass)} value={value} {...inputProps} id="searchInput" />
    </Form>
  );
};

const getCombinedSuggestions = (suggestions: Array<string>, userInput: string): Array<string> => {
  if (!userInput) return suggestions;
  if (
    suggestions.includes(userInput.toLowerCase()) ||
    (userInput[0] === '-' && userInput.length === 1)
  ) {
    return suggestions;
  }
  if (userInput[0] === '-') {
    return [userInput.toLowerCase().slice(1)].concat(suggestions);
  }
  return [userInput.toLowerCase()].concat(suggestions);
};

const KeywordAutocomplete = ({
  placeholder,
  hits,
  currentRefinement,
  refine,
  addKeyword,
  addMagicKeywords,
}: Props) => {
  const { t } = useTranslation();

  const [lastAddedKeyword, setLastAddedKeyword] = useState();
  const isExcludeSuggestion = currentRefinement[0] === '-';
  const { keyboardShortcutsOpen } = useModalsContext();

  const handleAddKeyword = (keyword: string, custom: boolean = false) => {
    const keywordToAdd = isExcludeSuggestion ? '-'.concat(keyword) : keyword;

    const algoliaKeyword = isExcludeSuggestion
      ? getInverseKeywordObjectToAdd(hits, keyword)
      : getKeywordObjectToAdd(hits, keyword);
    const replaceableKeyword = isReplacableKeyword(algoliaKeyword);

    // prevents double-submission glitch
    if (custom && lastAddedKeyword && lastAddedKeyword > Date.now() - 500) {
      return;
    }

    setLastAddedKeyword(Date.now());

    if (replaceableKeyword && algoliaKeyword) {
      addMagicKeywords(algoliaKeyword, !custom);
      if (custom) {
        refine('');
      }
    } else {
      if (keywordToAdd === '-') {
        return;
      }
      addKeyword(keywordToAdd, !custom);
      if (custom) {
        refine('');
      }
    }
  };

  const inputProps = {
    placeholder,
    value: currentRefinement,
    onChange: event => refine(event.currentTarget.value),
    addKeyword: handleAddKeyword,
  };

  function renderSuggestionsContainer({
    containerProps,
    children,
  }: {
    containerProps: any,
    children: any,
  }) {
    return (
      <>
        {(children ? children.props.items.length > 0 : false) && (
          <SuggestionsContainer
            {...containerProps}
            className={cx({ collapsedSuggestionsContainer: isExcludeSuggestion === true })}
          >
            {isExcludeSuggestion && (
              <Hint>{t('general.siteHeader.instructionAdvancedSyntaxExclude', '')}</Hint>
            )}
            {children}
          </SuggestionsContainer>
        )}
      </>
    );
  }

  const renderSuggestion = (suggestion, { isHighlighted }) => {
    return (
      <InteractiveOptionWrapper className={cx({ [highlightedSuggestionCss]: isHighlighted })}>
        {isExcludeSuggestion ? <MinusIcon /> : <PlusIcon />}
        <SuggestionText>{capitalize(suggestion)}</SuggestionText>
      </InteractiveOptionWrapper>
    );
  };

  const onSuggestionsFetchRequested = () => {};
  const onSuggestionsClearRequested = () => {};
  const onSuggestionHighlighted = () => {};
  const onSuggestionSelected = (event, { method, suggestionValue }) => {
    handleAddKeyword(suggestionValue);
    if (method === 'click') {
      // refine(currentRefinement);
    }
    refine('');
    event.stopPropagation();
  };
  const suggestions = currentRefinement ? hits.map(({ keyword }) => keyword) : [];

  const combinedSuggestions = getCombinedSuggestions(suggestions, currentRefinement);
  const [autoFocus, setAutoFocus] = useState(true);

  const ref = () => {
    const autofocus = !keyboardShortcutsOpen;
    if (autofocus && autoFocus) {
      const element = document.getElementById('searchInput');
      if (element) element.focus();
      setAutoFocus(false);
    } else {
      document.getElementById('searchInput');
    }
  };

  return (
    <Autosuggest
      highlightFirstSuggestion={!isMobileDeviceSize()}
      suggestions={combinedSuggestions}
      inputProps={inputProps}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      renderSuggestionsContainer={renderSuggestionsContainer}
      alwaysRenderSuggestions
      onSuggestionHighlighted={onSuggestionHighlighted}
      onSuggestionSelected={onSuggestionSelected}
      ref={ref}
      autoFocus={autoFocus}
      onBlur={() => setAutoFocus(false)}
    />
  );
};

export default connectAutoComplete(KeywordAutocomplete);
