// @flow
import React from 'react';
import styled from 'react-emotion';
import { cx, css } from 'emotion';
import { transparentize } from 'polished';
import { SmallMonoTextButton } from '../../../../components/Button/Button';
import { darkColor } from '../../../../styles/config/colors';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../styles/responsive';
import { useTranslation } from 'react-i18next';

const StyledFilterListHeader = styled('header')`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 23px 0px;
  margin: 0px 30px;

  &:hover > div:last-of-type button {
    text-decoration: underline;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 0px;
    margin: 0px;
  }
`;

const StyledMainWrapper = styled('div')`
  width: 90%;
  display: flex;
`;

const StyledExpandWrapper = styled('div')`
  width: 10%;
  text-align: right;
`;

const DesktopSelectedTagsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  ${SMALL_DEVICE_BREAKPOINT} {
    display: none;
  }
`;

const MobileSelectedTagsContainer = styled('div')`
  display: none;
  ${SMALL_DEVICE_BREAKPOINT} {
    display: block;
  }
`;

const activeHeaderClass = css`
  border-bottom: 1px solid ${transparentize(0.9, darkColor)};
  margin-bottom: 30px;

  ${SMALL_DEVICE_BREAKPOINT} {
    border-bottom: none;
    margin-bottom: 0px;
    padding: 0px;
  }
`;

const alignCenter = css`
  display: flex;
  align-items: center;
`;

type Props = {
  title: string,
  isExpanded: boolean,
  handleExpand: () => void,
  headerActiveTags: any,
  headerActiveButton: any,
};

const ExpandableGroupedTagHeader = ({
  title,
  isExpanded,
  handleExpand,
  headerActiveTags,
  headerActiveButton,
}: Props) => {
  const { t } = useTranslation();

  const hasActiveTags = headerActiveTags.length > 0;

  const handleExpandHeader = () => {
    handleExpand();
  };

  return (
    <div>
      <StyledFilterListHeader
        onClick={handleExpandHeader}
        className={cx({ [activeHeaderClass]: isExpanded })}
      >
        <StyledMainWrapper>
          <div>{hasActiveTags ? headerActiveButton : <h6>{title}</h6>}</div>
          <DesktopSelectedTagsContainer className={alignCenter}>
            {hasActiveTags && headerActiveTags}
          </DesktopSelectedTagsContainer>
        </StyledMainWrapper>
        <StyledExpandWrapper>
          <SmallMonoTextButton>
            {isExpanded ? t('general.button.less', '') : t('general.button.more', '')}
          </SmallMonoTextButton>
        </StyledExpandWrapper>
      </StyledFilterListHeader>
      <MobileSelectedTagsContainer>{hasActiveTags && headerActiveTags}</MobileSelectedTagsContainer>
    </div>
  );
};

export default ExpandableGroupedTagHeader;
