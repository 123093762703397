// @flow
import React, { useEffect, useState } from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import { Formik } from 'formik';
import ls from 'local-storage';
import { useQueryParam, StringParam } from 'use-query-params';
import { useRecoilValue } from 'recoil';
import {
  hideOnSmallDeviceCss,
  LANDSCAPE_DEVICE,
  LARGE_DEVICE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../styles/responsive';
import {
  shadedContainerCss,
  shadedContainerShadowCss,
  styledListCss,
} from '../../../../../styles/shared';
import {
  monoNavCss,
  smallMonoCss,
  smallPlainTextCss,
} from '../../../../../styles/typography/typography';
import {
  FormHeading,
  FormInput,
  FormInputContainer,
  FormInputLabel,
  FormSection,
} from '../../../../../components/forms/general';
import { HiddenCheckbox } from '../../../../../components/forms/inputs/SquareCheckbox';
import DisplayErrorMessage from '../../../../../components/DisplayErrorMessage/DisplayErrorMessage';
import Auth0ErrorHandler from '../../../../../components/SignInForm/components/Auth0ErrorHandler/Auth0ErrorHandler';
import Button, { BUTTON_WIDTHS } from '../../../../../components/Button/Button';
import CheckMarkIcon from '../../../../../assets/inline-assets/checkmark-icon.svg';
import { darkColor } from '../../../../../styles/config/colors';
import { useSignUpFormSchema } from './schema';
import { useLoginViaAuth0, useSignUpViaAuth0 } from '../../../../../api/auth/account';
import { useLocale } from '../../../../components/LocaleWrapper/LocaleWrapper';
import { useAuthContext } from '../../../../../auth/components/AuthWrapper/AuthWrapper';
import { storeTemporaryLoginDestination } from '../../../../../auth/destination';
import { conversionSourceAtom } from '../../../../../store/mixpanelAnalytics';
import { hasEmoji } from '../../../../../utils/emoji';
import { Trans, useTranslation } from 'react-i18next';

export const usePlanQuery = () => {
  return useQueryParam('plan', StringParam);
};

export const useBillingQuery = () => {
  return useQueryParam('billing', StringParam);
};

const Container = styled('div')`
  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 0 20px;

    ${LANDSCAPE_DEVICE} {
      padding: 0;
    }
  }
`;

const InfoSection = styled('section')`
  ${hideOnSmallDeviceCss};
  ${shadedContainerCss};
  ${shadedContainerShadowCss};
  padding: 24px 30px 30px 30px;

  h4 {
    ${monoNavCss};
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  ul {
    ${smallPlainTextCss};
    ${styledListCss};
  }
`;

const TermsContainer = styled('div')`
  margin: 60px 0;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin: 52px 0;
  }

  label {
    display: flex;
    align-items: center;
  }
`;

const TermsLabel = styled('label')`
  ${smallMonoCss};
`;

const TermsBox = styled('div')`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  border: 1px solid rgba(15, 15, 20, 0.1);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  input:focus + &,
  &:focus,
  &:hover {
    border: 1px solid rgba(15, 15, 20, 0.2);
  }

  svg {
    display: block;
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};

    path {
      stroke: ${darkColor};
    }
  }
`;

const splitInputsContainerClass = css`
  ${LARGE_DEVICE_BREAKPOINT} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    > div {
      margin-top: 25px;
    }
  }
`;

const createAccountButton = css`
  background-color: #4d4d4d;
`;

const SignUpFormView = () => {
  const [busy, setBusy] = useState(false);
  const [notification, setNotification] = useState(false);
  const { t } = useTranslation();

  const [signUpViaAuth0] = useSignUpViaAuth0();
  const [login] = useLoginViaAuth0();
  const schema = useSignUpFormSchema();
  const locale = useLocale();
  const { signUpSourceData, setSignUpSourceData } = useAuthContext();
  const conversionSource = useRecoilValue(conversionSourceAtom);

  useEffect(() => {
    // 原則使用しない。アンマウントされない状態でEvokeアカウントを発行しようとする際にエンタープライズ ベーシックに遷移するのを避ける。
    ls.set('SIGNUP_ENTERPRISE_BASIC_SUBSCRIPTION', false);

    if (!signUpSourceData.signUpSource) {
      setSignUpSourceData({ signUpSource: 'Direct', signUpCampaign: '' });
    }

    // アンマウント時に削除する。
    return () => ls.remove('SIGNUP_ENTERPRISE_BASIC_SUBSCRIPTION');
  }, []);

  const onSubmit = async (
    { email, password, firstName, lastName },
    { setSubmitting, setErrors }
  ) => {
    if (hasEmoji(firstName) || hasEmoji(lastName)) {
      return;
    }

    if (busy) return;
    setBusy(true);
    setSubmitting(true);

    const handleError = error => {
      console.error(error);
      setBusy(false);
      setSubmitting(false);
      setErrors({
        auth0: error,
      });
    };

    try {
      await signUpViaAuth0(email, password, firstName, lastName, locale);
    } catch (error) {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage('Something went wrong when signing up via auth0');
      Sentry.captureException(error);
      handleError(error);
      return;
    }

    try {
      storeTemporaryLoginDestination();
      ls.set('NEW_ACCOUNT_CREATED', true);
      ls.set('NEW_ACCOUNT_DATA', {
        email,
        firstName,
        lastName,
        signUpLocale: locale,
        signUpMethod: 'Email',
        signUpSourceData,
      });
      ls.set('CONVERSION_SOURCE', conversionSource);
      // 問い合わせ中のためコメントアウト
      // ls.set('SHOW_EXTENSION_PROMO', true);

      setSignUpSourceData({ signUpSource: '', signUpCampaign: '' });
      await login(email, password);
    } catch (error) {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage('Something went wrong when logging in after signing up');
      Sentry.captureException(error);
      handleError(error);
    }
  };

  return (
    <Container>
      <Formik
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
          password: '',
          terms: false,
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {formProps => {
          const { values, errors, handleChange, handleBlur, handleSubmit } = formProps;
          const { firstName, lastName, password, email, terms } = values;
          const isEmpty = !firstName || !lastName || !password || !email;

          return (
            <form onSubmit={handleSubmit} noValidate>
              <FormHeading>{t('signUpPage.header.createAccountForm', '')}</FormHeading>
              <InfoSection>
                <h4>{t('general.text.accountInclusions', '')}</h4>
                <ul>
                  {t('signUpPage.accountInclusions', { defaultValue: [], returnObjects: true }).map(
                    text => (
                      <li>{text}</li>
                    )
                  )}
                </ul>
              </InfoSection>
              {notification && isEmpty && (
                <DisplayErrorMessage>
                  {t('general.error.signInSignUp.emptyInputs', '')}
                </DisplayErrorMessage>
              )}
              {notification && !!email && errors.email && (
                <DisplayErrorMessage>
                  {t('general.error.signInSignUp.validEmailRequired', '')}
                </DisplayErrorMessage>
              )}
              {notification && !isEmpty && password && password.length < 8 && (
                <DisplayErrorMessage>
                  {t('general.error.signInSignUp.invalidPassword', '')}
                </DisplayErrorMessage>
              )}
              {notification && (hasEmoji(firstName) || hasEmoji(lastName)) && (
                <DisplayErrorMessage>
                  {t('general.error.signInSignUp.emojiRestricted', '')}
                </DisplayErrorMessage>
              )}
              <FormSection className={splitInputsContainerClass}>
                <FormInputContainer>
                  <FormInput
                    id="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={firstName}
                    type="text"
                    placeholder={t('general.text.firstName', '')}
                  />
                  <FormInputLabel visible={!!firstName}>
                    {t('general.text.firstName', '')}
                  </FormInputLabel>
                </FormInputContainer>
                <FormInputContainer>
                  <FormInput
                    id="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={lastName}
                    type="text"
                    placeholder={t('general.text.lastName', '')}
                  />
                  <FormInputLabel visible={!!lastName}>
                    {t('general.text.lastName', '')}
                  </FormInputLabel>
                </FormInputContainer>
              </FormSection>
              <FormSection>
                <FormInputContainer>
                  <FormInput
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={email}
                    type="email"
                    placeholder={t('general.text.emailAddress', '')}
                  />
                  <FormInputLabel visible={!!email}>
                    {t('general.text.emailAddress', '')}
                  </FormInputLabel>
                </FormInputContainer>
              </FormSection>
              <FormSection>
                <FormInputContainer>
                  <FormInput
                    id="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={password}
                    type="password"
                    placeholder={t('general.text.password', '')}
                  />
                  <FormInputLabel visible={!!password}>
                    {t('general.text.password', '')}
                  </FormInputLabel>
                </FormInputContainer>
              </FormSection>
              <TermsContainer>
                {notification && errors.terms && (
                  <DisplayErrorMessage>
                    {t('general.error.signInSignUp.uncheckedTermsOfService', '')}
                  </DisplayErrorMessage>
                )}
                <TermsLabel>
                  <HiddenCheckbox
                    id="terms"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={terms}
                    type="checkbox"
                  />
                  <TermsBox checked={terms}>
                    <CheckMarkIcon />
                  </TermsBox>
                  <Trans
                    components={{
                      term_ja: (
                        <a
                          href="https://www.amadeuscode.com/musictga-hr/terms/ja"
                          target="_blank"
                        ></a>
                      ),
                      term: (
                        <a href="https://www.amadeuscode.com/musictga-hr/terms" target="_blank"></a>
                      ),
                      privacy_policy_ja: (
                        <a href="https://evokemusic.ai/ja/privacy" target="_blank"></a>
                      ),
                      privacy_policy: <a href="https://evokemusic.ai/privacy" target="_blank"></a>,
                    }}
                  >
                    {t('signUpPage.label.termsAndConditions', '')}
                  </Trans>
                </TermsLabel>
              </TermsContainer>
              {errors && errors.auth0 && (
                <DisplayErrorMessage>
                  <Auth0ErrorHandler error={errors.auth0} />
                </DisplayErrorMessage>
              )}
              <div>
                <Button
                  className={createAccountButton}
                  onClick={() => setNotification(true)}
                  type="submit"
                  loading={busy}
                  mobileWidth={BUTTON_WIDTHS.full}
                >
                  {t('signUpPage.button.createAccount', '')}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default SignUpFormView;
