// @flow

import { get } from 'lodash';
import { graphql } from 'gatsby';

export type ArtistProfileGraphMdl = {
  uid: string,
  type: string,
  data: {
    name: {
      text: string,
    },
    cover_art: {
      url: string,
    },
    cover_art_position: number | null,
    thumbnail: {
      url: string,
    },
    description: {
      html: string,
      text: string,
    },
  },
};

export const getArtistProfileId = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'uid', '') || '';
};

export const getArtistProfileDocumentType = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'type', '') || '';
};

export const getArtistProfileName = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.name.text', '') || '';
};

export const getArtistProfileCoverArt = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.cover_art.url', '') || '';
};

export const getArtistProfileCoverArtPosition = (collection: ArtistProfileGraphMdl): number => {
  return get(collection, 'data.cover_art_position', 50) || 50;
};

export const getArtistProfileThumbnail = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.thumbnail.url', '') || '';
};

export const getArtistProfileDescription = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.description.text', '') || '';
};

export const getArtistProfileDescriptionMarkup = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.description.html', '') || '';
};

export const getArtistProfile = (
  id: string,
  artists: Array<ArtistProfileGraphMdl>
): ArtistProfileGraphMdl | null => {
  const filteredArtists = artists.filter(artist => getArtistProfileId(artist) === id);

  if (filteredArtists.length > 0) {
    return filteredArtists[0];
  }
  return null;
};

export const query = graphql`
  fragment ArtistProfile on PrismicArtistProfile {
    uid
    type
    data {
      name {
        text
      }
      cover_art {
        url
      }
      thumbnail {
        url
      }
      description {
        html
        text
      }
    }
  }
`;
