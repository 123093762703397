// @flow

import React from 'react';
import FeaturedCollectionItem from './FeaturedCollectionItem';
import FeaturedArtistItem from './FeaturedArtistItem';
import type { CarouselItemMdl } from '../../../../../../../utils/featuredContent';

type Props = {
  itemData: CarouselItemMdl,
};

const FeaturedCarouselItem = ({ itemData }: Props) => {
  const { listType } = itemData;

  return listType === 'collection' ? (
    <FeaturedCollectionItem itemData={itemData} />
  ) : (
    <FeaturedArtistItem itemData={itemData} />
  );
};

export default FeaturedCarouselItem;
