// @flow
import React from 'react';
import { useNavigate } from '../../../../../hooks';
import { ROUTES } from '../../../../../routes';
import { useCuratedCollections } from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getCuratedCollectionId,
  getCuratedCollectionImage,
  getCuratedCollectionTitle,
} from '../../../../../../cms/curatedCollection'; // TODO; コレクションのPrismic脱却対応
import PreviewTile from './PreviewTile';
import PreviewTileRow from './PreviewTileRow';
import { analyticsMixpanelPreviewSongs } from '../../../../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../../../../analytics/components/MixpanelWrapper';
import { useUserId } from '../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useWatchUserRecentCollections } from '../../../../../../api/firebase/user/recentCollections';
import { useTranslation } from 'react-i18next';
import type { CuratedCollectionGraphMdl } from '../../../../../../cms/curatedCollection';

type Props = {
  handlePreviewCollection: (collection: CuratedCollectionGraphMdl, origin: string) => void,
};

const RecentUserCollections = ({ handlePreviewCollection }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mixpanel, moengage, handleUpdateCollectionAnalytics } = useAnalyticsMixpanelContext();
  const userId = useUserId();

  const curatedCollections = useCuratedCollections();
  const [recentCollections] = useWatchUserRecentCollections();

  const recentUserCollections = recentCollections.map(recentCollectionId => {
    const recentCollection = curatedCollections.find(curatedCollection => {
      return getCuratedCollectionId(curatedCollection) === recentCollectionId;
    });
    return recentCollection;
  });

  const gridRows = recentUserCollections.length > 7 ? 2 : 1;

  const navigateCollection = (collectionSlug: string) => {
    handleUpdateCollectionAnalytics([], [], [], [], '', 'recentCollections', '');
    navigate(ROUTES.collection.navigatePath({ collectionSlug }));
  };

  return (
    <>
      {recentUserCollections.length > 0 && (
        <PreviewTileRow
          title={t('musicPage.header.recentlyViewed', '')}
          viewAllLink={ROUTES.collections.navigatePath({})}
          showAllTiles={false}
          rows={gridRows}
        >
          {recentUserCollections.map(collection => {
            const slug = getCuratedCollectionId(collection);
            return (
              <PreviewTile
                key={slug}
                title={getCuratedCollectionTitle(collection)}
                slug={slug}
                bgImage={getCuratedCollectionImage(collection)}
                listType="collection"
                previewTile={() => {
                  analyticsMixpanelPreviewSongs(
                    mixpanel,
                    moengage,
                    'Collection',
                    slug,
                    'recentCollections',
                    userId
                  );
                  handlePreviewCollection(collection, 'recentCollections');
                }}
                navigateTile={() => {
                  navigateCollection(slug);
                }}
              />
            );
          })}
        </PreviewTileRow>
      )}
    </>
  );
};

export default RecentUserCollections;
