// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { cx } from 'emotion';
import { darkColor, greyColor, lightColor } from '../../../../../styles/config/colors';
import { MOBILE_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import CloseIcon from '../../../../../assets/inline-assets/close.svg';
import PlusIcon from '../../../../../assets/inline-assets/plus-icon.svg';
import { Trans } from 'react-i18next';

type Props = {
  question: string,
  answer: Array<string>,
};

const FaqItemContainer = styled('li')`
  background-color: ${darkColor};
  border-radius: 5px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  padding: 24px 30px;
  margin: 30px 0px 15px 0px;
  position: relative;
  color: ${lightColor};

  &:first-of-type {
    margin: 15px 0px;
  }

  &:first-of-type:before,
  &:before,
  &:last-of-type:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 90%;
    border-bottom: 1px solid ${greyColor};
    margin: auto;
  }

  &:before {
    left: 5%;
    top: -15px;
  }

  &:last-of-type:after {
    left: 5%;
    bottom: -15px;
  }

  &:hover {
    cursor: pointer;
    background-color: ${lightColor};
    color: ${darkColor};

    figure svg path {
      fill: ${darkColor};
    }
  }

  &.visibleFaqAnswer {
    background-color: ${lightColor};
    color: ${darkColor};

    figure svg g {
      stroke: ${darkColor};
    }

    & article {
      display: block;
    }
  }

  &.hiddenFaqAnswer {
    & figure {
      right: 36px;
      top: 25px;
      & svg {
        transform: scale(2.5);
      }
    }
    & article {
      display: none;
    }
  }
`;

const IconContainer = styled('figure')`
  position: absolute;
  right: 30px;
  top: 30px;

  svg path {
    fill: ${lightColor};
  }

  svg g {
    stroke: ${lightColor};
  }
`;

const FaqQuestion = styled('h2')`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 28px;
  padding-right: 50px;

  ${MOBILE_DEVICE_BREAKPOINT} {
    font-size: 16px;
  }
`;

const FaqAnswer = styled('article')`
  opacity: 0.5;
  margin-top: 12px;
  padding-right: 20px;

  & p {
    margin-bottom: 8px;

    ${MOBILE_DEVICE_BREAKPOINT} {
      font-size: 12px;
    }
  }
`;

const FaqItem = ({ question, answer }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <FaqItemContainer
      className={cx({
        visibleFaqAnswer: isExpanded,
        hiddenFaqAnswer: !isExpanded,
      })}
      onClick={handleClick}
    >
      <IconContainer>{isExpanded ? <CloseIcon /> : <PlusIcon />}</IconContainer>
      <FaqQuestion>{question}</FaqQuestion>
      <FaqAnswer>
        {answer.map((item, index) => (
          <p>
            <Trans
              key={index}
              components={{
                TGAHRLink: (
                  <a href="https://www.amadeuscode.com/musictga-hr/ja">
                    https://www.amadeuscode.com/musictga-hr/ja
                  </a>
                ),
              }}
            >
              {item}
            </Trans>
          </p>
        ))}
      </FaqAnswer>
    </FaqItemContainer>
  );
};

export default FaqItem;
