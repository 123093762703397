// @flow
import React, { useState } from 'react';
import { chunk } from 'lodash';
import styled from 'react-emotion';
import { cx, css } from 'emotion';
import { darkColor } from '../../../../../../styles/config/colors';
import { buttonResetCss } from '../../../../../../components/Button/Button';
import SmallNextArrowIcon from '../../../../../../assets/inline-assets/arrow-small-right-icon.svg';
import SmallPrevArrowIcon from '../../../../../../assets/inline-assets/arrow-small-left-icon.svg';
import { useNavigate } from '../../../../../hooks';
import {
  isLargeMobileDeviceSize,
  isSmallDeviceSize,
  isMobileDeviceSize,
} from '../../../../../../utils/device';
import {
  MEDIA_BREAKPOINTS,
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../../styles/responsive';
import { spacing } from '../../../../../../styles/config/spacing';
import LoadingDisplay from '../../../../../../components/LoadingDisplay/LoadingDisplay';
import { useTranslation } from 'react-i18next';

type Props = {
  children: any,
  title: string,
  viewAllLink?: string,
  viewAllLinkButton?: any,
  showAllTiles?: boolean,
  rows?: number,
  hasSorting?: boolean,
  sortingMenu?: any,
  loading?: boolean,
};

const Container = styled('div')`
  margin: 29px 0px;

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 100%;
    padding: 0px;
  }
`;

const SectionHeading = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SectionInfo = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  & h3 {
    color: #0f0f14;
    font-family: Roboto;
    font-size: 19px;
    letter-spacing: 0.24px;
    line-height: 23px;
  }
}

${MOBILE_DEVICE_BREAKPOINT} {
  padding: 0px ${spacing.small}px;
}
`;

const SectionTitle = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const SortingMenu = styled('div')``;

const ViewAllLink = styled('h6')`
  color: rgba(15, 15, 20, 0.4);
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 16px;
  margin-left: 15px;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
    color: ${darkColor};
    cursor: pointer;
  }
`;

const SectionNavButton = styled('button')`
  ${buttonResetCss};
  outline: none;
  margin: 0px 5px;
`;

const disabledArrow = css`
  opacity: 0.5;
`;

const Grid = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: grey;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #dad8d3;
      cursor: pointer;
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    &::-webkit-scrollbar {
      height: 0px;
    }
  }
`;

const GridRow = styled('div')`
  display: none;
  flex-direction: row;
  justify-content: space-between;
`;

const visibileRow = css`
  display: flex;
`;

const EmptyTagContainer = styled('div')`
  height: 185px;
  width: 185px;
  opacity: 0;
  border-radius: 5px;
  background-color: #848484;
  margin: 16px 5px;

  ${MEDIA_BREAKPOINTS.min1720} {
    height: 220px;
    width: 220px;
  }

  ${MEDIA_BREAKPOINTS.max1720} {
    height: 185px;
    width: 185px;
  }

  ${MEDIA_BREAKPOINTS.max1440} {
    height: 155px;
    width: 155px;
  }

  ${MEDIA_BREAKPOINTS.max1260} {
    height: 145px;
    width: 145px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    height: 150px;
    width: 150px;
  }
`;

const LoadingWrapper = styled('div')`
  height: 50vh;
`;

// 画面サイズによるコレクション一覧画面のコレクションの表示数
const getTilesPerRow = () => {
  if (isMobileDeviceSize()) {
    return 2;
  }

  if (isSmallDeviceSize()) {
    return 4;
  }

  if (isLargeMobileDeviceSize()) {
    return 6;
  }

  return 8;
};

const PreviewTileGrid = ({
  children,
  title,
  viewAllLink = '',
  viewAllLinkButton = null,
  showAllTiles = false,
  rows = 1,
  hasSorting = false,
  sortingMenu = <></>,
  loading = false,
}: Props) => {
  const [currentPosition, setCurrentPosition] = useState(0);
  const { t } = useTranslation();

  const tilesPerRow = getTilesPerRow();
  const chunkedRows = chunk(children, tilesPerRow);

  const navigate = useNavigate();

  const showArrows = !showAllTiles && !(children.length <= rows * tilesPerRow);
  const previousAvailable = currentPosition !== 0 && !showAllTiles;
  const nextAvailable = currentPosition < chunkedRows.length - 1 && !showAllTiles;

  const handleNext = () => {
    if (!nextAvailable) return;
    setCurrentPosition(currentPosition + rows);
  };

  const handlePrevious = () => {
    if (!previousAvailable) return;
    setCurrentPosition(currentPosition - rows);
  };

  const navigateAll = () => {
    navigate(viewAllLink);
  };

  if (loading) {
    return (
      <Container>
        <SectionHeading>
          <SectionInfo>
            <SectionTitle>
              <h3>{title}</h3>
              {viewAllLink && (
                <ViewAllLink onClick={navigateAll}>{t('general.button.viewAll', '')}</ViewAllLink>
              )}
              {viewAllLinkButton && <>{viewAllLinkButton}</>}
            </SectionTitle>
            {hasSorting && <SortingMenu>{sortingMenu}</SortingMenu>}
            {showArrows && !isLargeMobileDeviceSize() && (
              <div>
                <SectionNavButton
                  onClick={handlePrevious}
                  className={cx({ [disabledArrow]: !previousAvailable })}
                >
                  <SmallPrevArrowIcon />
                </SectionNavButton>
                <SectionNavButton
                  onClick={handleNext}
                  className={cx({ [disabledArrow]: !nextAvailable })}
                >
                  <SmallNextArrowIcon />
                </SectionNavButton>
              </div>
            )}
          </SectionInfo>{' '}
        </SectionHeading>
        <LoadingWrapper>
          <LoadingDisplay />
        </LoadingWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <SectionHeading>
        <SectionInfo>
          <SectionTitle>
            <h3>{title}</h3>
            {viewAllLink && (
              <ViewAllLink onClick={navigateAll}>{t('general.button.viewAll', '')}</ViewAllLink>
            )}
            {viewAllLinkButton && <>{viewAllLinkButton}</>}
          </SectionTitle>
          {hasSorting && <SortingMenu>{sortingMenu}</SortingMenu>}
          {showArrows && !isLargeMobileDeviceSize() && (
            <div>
              <SectionNavButton
                onClick={handlePrevious}
                className={cx({ [disabledArrow]: !previousAvailable })}
              >
                <SmallPrevArrowIcon />
              </SectionNavButton>
              <SectionNavButton
                onClick={handleNext}
                className={cx({ [disabledArrow]: !nextAvailable })}
              >
                <SmallNextArrowIcon />
              </SectionNavButton>
            </div>
          )}
        </SectionInfo>
      </SectionHeading>
      {isLargeMobileDeviceSize() && !showAllTiles ? (
        <Grid>
          <GridRow className={cx({ [visibileRow]: true })}>{chunk(children, 8)[0]}</GridRow>
        </Grid>
      ) : (
        <Grid>
          {chunkedRows.map((row, index) => {
            const emptyTagNumber = row.length === tilesPerRow ? 0 : tilesPerRow - row.length;
            const emptyTags = Array(emptyTagNumber).fill('empty');
            return (
              <GridRow
                key={index}
                className={cx({
                  [visibileRow]:
                    showAllTiles ||
                    (currentPosition === index || currentPosition + rows - 1 === index),
                })}
              >
                {row}
                {emptyTags.map((emptyTag, emptyIndex) => {
                  return <EmptyTagContainer key={`empty_${emptyIndex}`} />;
                })}
              </GridRow>
            );
          })}
        </Grid>
      )}
    </Container>
  );
};

export default PreviewTileGrid;
