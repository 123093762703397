// @flow
import React from 'react';
import { getSongID } from '../../../../../../../../api/algolia/song';
import {
  useBookmark,
  useRemoveBookmark,
} from '../../../../../../../../api/firebase/user/bookmarks';
import { useIsSongBookmarked } from '../../../../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { useSong } from '../../../../../../SongContextWrapper/SongContextWrapper';
import InlineButton from './InlineButton';
import BookmarkActiveIcon from '../../../../../../../../assets/inline-assets/bookmark-active-icon.svg';
import BookmarkIcon from '../../../../../../../../assets/inline-assets/bookmark-icon.svg';
import { useSubscriptionsContext } from '../../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { USER_PERMISSIONS } from '../../../../../../../../user/subscriptions/data';
import { useModalsContext } from '../../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useTranslation } from 'react-i18next';

const BookmarkInlineButton = () => {
  const { t } = useTranslation();

  const song = useSong();
  const { hasSubscription, userRole } = useSubscriptionsContext();
  const canBookmarkSongs = hasSubscription && USER_PERMISSIONS[userRole].canBookmarkSongs;
  const [bookmark, bookmarkBusy] = useBookmark(song);
  const [removeBookmark, removeBookmarkBusy] = useRemoveBookmark(song);
  const isBookmarked = useIsSongBookmarked(getSongID(song));
  const { showSubscriptionRequired } = useModalsContext();

  const busy = bookmarkBusy || removeBookmarkBusy;
  const icon = isBookmarked ? <BookmarkActiveIcon /> : <BookmarkIcon />;

  const label = isBookmarked
    ? t('general.button.unbookmark', '')
    : t('general.button.bookmark', '');

  const action = event => {
    if (busy) return;
    event.preventDefault();
    event.stopPropagation();

    // サブスク無しの場合はサブスク登録のポップアップ表示。
    if (!canBookmarkSongs) {
      showSubscriptionRequired();
      return;
    }

    if (isBookmarked) {
      removeBookmark();
    } else {
      bookmark();
    }
  };

  return <InlineButton label={label} icon={icon} action={action} />;
};

export default BookmarkInlineButton;
