// @flow
import React from 'react';
import { css } from 'emotion';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../ClassicModalLayout/ClassicModalLayout';
import Button, {
  BUTTON_WIDTHS,
  extraVerticalPaddingButtonClass,
  PlainTextButton,
} from '../../../components/Button/Button';
import { useLocalePath } from '../../../routing/components/LocaleWrapper/LocaleWrapper';
import { ROUTES } from '../../../routing/routes';
import { storeCurrentLocationAsAuthDestination } from '../../../auth/destination';
import { useModalsContext } from '../ModalsWrapper/ModalsWrapper';
import { useTranslation } from 'react-i18next';

const signUpButtonLayout = css`
  ${extraVerticalPaddingButtonClass}
  background-color: #4d4d4d;
`;

type Props = {
  onClose: () => void,
};

const SignUpModal = ({ onClose }: Props) => {
  const { t } = useTranslation();

  const { setSignInOpen } = useModalsContext();
  const localePath = useLocalePath();

  const handleSignIn = () => {
    setSignInOpen(true);
    onClose();
  };

  const handleSignUp = () => {
    storeCurrentLocationAsAuthDestination();
    onClose();
  };

  return (
    <ClassicModalLayout onClose={onClose} label={t('modal.signUp.header', '')} dataCy="signUpModal">
      <ClassicModalContentLayout
        heading={t('modal.signUp.header', '')}
        description={
          <>
            <p>{t('modal.signUp.message', '')}</p>
            <ul>
              {t('modal.signUp.list', { defaultValue: [], returnObjects: true }).map(
                (text, index) => (
                  <li key={index}>{text}</li>
                )
              )}
            </ul>
          </>
        }
        headerOption={
          <PlainTextButton uppercase onClick={handleSignIn} dataCy="modalSignInLink">
            {t('general.button.signIn', '')}
          </PlainTextButton>
        }
        footer={
          <Button
            className={signUpButtonLayout}
            onClick={handleSignUp}
            to={ROUTES.signup.navigatePath({ localePath })}
            mobileWidth={BUTTON_WIDTHS.full}
          >
            {t('general.button.freeSignUp', '')}
          </Button>
        }
      />
    </ClassicModalLayout>
  );
};

export default SignUpModal;
