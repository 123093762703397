// @flow
import React from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import parse from 'html-react-parser';
import { css } from 'emotion';
import {
  MEDIA_BREAKPOINTS,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../../../../../styles/responsive';
import { lightColor } from '../../../../../../../../../styles/config/colors';
import {
  LargeHeading,
  MediumHeading,
} from '../../../../../../../../../styles/typography/components/Heading/Heading';
import GenericCopy, {
  COPY_STYLE,
} from '../../../../../../../../../styles/typography/components/GenericCopy/GenericCopy';
import BookmarkIcon from '../../../../../../../../../assets/inline-assets/bookmark-icon.svg';

export const NoResultsContainer = styled('div')`
  ${MEDIA_BREAKPOINTS.min1000} {
    max-width: 500px;
    margin: 0 auto;
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    padding-bottom: 50px;
  }
`;

const Top = styled('div')`
  text-align: center;

  h2 {
    margin-bottom: 40px;
  }
`;

const Bottom = styled('div')`
  padding-top: 48px;
  margin-top: 55px;
  border-top: 1px solid ${transparentize(0.95, lightColor)};
  h3 {
    margin-bottom: 23px;
  }
`;

const bookmarkIconClass = css`
  margin: 0 5px;
  position: relative;
  top: 3px;
`;

const options = {
  replace: ({ attribs }) => {
    if (!attribs) return;

    if (attribs.id === 'bookmark-icon') {
      // eslint-disable-next-line consistent-return
      return <BookmarkIcon className={bookmarkIconClass} />;
    }
  },
};

type Props = {
  children?: any,
  heading: string,
  description: string,
  subheading?: string,
  subDescription?: any,
};

const NoResultsLayout = ({ children, heading, description, subheading, subDescription }: Props) => {
  return (
    <NoResultsContainer>
      <Top>
        <LargeHeading>{heading}</LargeHeading>
        <GenericCopy copyStyle={COPY_STYLE.smaller}>{parse(description, options)}</GenericCopy>
      </Top>
      {children}
      {(subheading || subDescription) && (
        <Bottom>
          <MediumHeading>{subheading}</MediumHeading>
          <GenericCopy copyStyle={COPY_STYLE.smaller}>{subDescription}</GenericCopy>
        </Bottom>
      )}
    </NoResultsContainer>
  );
};

export default NoResultsLayout;
