// @flow
import React from 'react';
import { get } from 'lodash';
import { useSetRecoilState } from 'recoil';
import { useDownloadContext } from '../../../../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import { useDownloadedSongsContext } from '../../../../../../../../components/DownloadedSongsContextWrapper/DownloadedSongsContextWrapper';
import { useShowSignUp } from '../../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useFreeTierRestrictionToast } from '../../../../../../../../toasts/hooks';
import { useSubscriptionsContext } from '../../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useDownloadSong } from '../../../../../../../hooks';
import { useSong } from '../../../../../../SongContextWrapper/SongContextWrapper';
import InlineButton from './InlineButton';
import { getSongID } from '../../../../../../../../api/algolia/song';
import { USER_PERMISSIONS } from '../../../../../../../../user/subscriptions/data';
import DownloadIcon from '../../../../../../../../assets/inline-assets/download-icon.svg';
import DownloadingIcon from '../../../../../../../../components/DownloadingIcon/DownloadingIcon';
import DownloadedSongIcon from '../../../../../../../../assets/inline-assets/downloaded-song-icon.svg';
import DisabledDownloadIcon from '../../../../../../../../assets/inline-assets/download-disabled.svg';
import { conversionSourceAtom } from '../../../../../../../../store/mixpanelAnalytics';
import { useTranslation } from 'react-i18next';

const DownloadInlineButton = () => {
  const { t } = useTranslation();

  const song = useSong();
  const [downloadSong, downloadBusy] = useDownloadSong(song);
  const setConversionSource = useSetRecoilState(conversionSourceAtom);
  const downloadedContext = useDownloadedSongsContext() ? useDownloadedSongsContext() : {};
  const { location = '' } = useDownloadContext();
  const {
    userRole,
    subscriptions,
    openChangeSubscriptionModal,
    selectPlan,
  } = useSubscriptionsContext();
  const showSignUp = useShowSignUp();
  const toastUtilities = {
    subscriptions,
    openChangeSubscriptionModal,
    selectPlan,
    showSignUp,
    setConversionSource,
  };

  const freeTierRestrictionToast = useFreeTierRestrictionToast('parts', toastUtilities);

  const previousDownloads = get(downloadedContext, 'downloadedSongIDs', []);
  const isDownloadHistoryView = location === 'downloads';
  const previouslyDownloaded = previousDownloads.includes(getSongID(song)) || isDownloadHistoryView;

  const handleDisabledClick = () => {
    freeTierRestrictionToast();
  };

  const icon = (() => {
    if (!USER_PERMISSIONS[userRole].canDownloadAllTracks) {
      return <DisabledDownloadIcon />;
    }

    if (downloadBusy) {
      return <DownloadingIcon />;
    }

    if (previouslyDownloaded) {
      return <DownloadedSongIcon />;
    }

    return <DownloadIcon />;
  })();

  const label = (() => {
    if (!USER_PERMISSIONS[userRole].canDownloadAllTracks) {
      return song.isPart
        ? t('general.tooltip.text.downloadableParts', '')
        : t('general.tooltip.text.downloadableMicroparts', '');
    }

    if (previouslyDownloaded) {
      return t('general.button.redownload', '');
    }

    return t('general.button.download', '');
  })();

  const action = event => {
    if (!USER_PERMISSIONS[userRole].canDownloadAllTracks) {
      handleDisabledClick();
    }
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (downloadBusy) {
      return;
    }

    downloadSong();
  };

  return <InlineButton label={label} icon={icon} action={action} />;
};

export default DownloadInlineButton;
