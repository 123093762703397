// @flow
import React from 'react';
import styled from 'react-emotion';
import MessageModal from '../MessageModal/MessageModal';
import Button, {
  BUTTON_WIDTHS,
  DarkButton,
  shorterButtonClass,
} from '../../../components/Button/Button';
import { useDoesUserOnlyHaveBusinessSubscription } from '../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useTranslation } from 'react-i18next';

const OptionsList = styled('ul')`
  display: flex;

  li {
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
`;

export const MessageOptions = styled(OptionsList)`
  margin-top: 25px;
`;

type Props = {
  onClose: () => void,
  onContinue: () => void,
};

const NoConnectionsModal = ({ onClose, onContinue }: Props) => {
  const { t } = useTranslation();

  const businessTime = useDoesUserOnlyHaveBusinessSubscription();

  return (
    <MessageModal onClose={onClose}>
      <h2>{t('modal.noConnections.header', '')}</h2>
      <div>
        {businessTime
          ? t('modal.noConnections.businessOnlyMessage', '')
          : t('modal.noConnections.message', '')}
      </div>
      <MessageOptions>
        <li>
          <DarkButton className={shorterButtonClass} width={BUTTON_WIDTHS.small} onClick={onClose}>
            {t('general.button.stay', '')}
          </DarkButton>
        </li>
        <li>
          <Button
            className={shorterButtonClass}
            width={BUTTON_WIDTHS.small}
            onClick={() => {
              onClose();
              onContinue();
            }}
          >
            {t('general.button.continue', '')}
          </Button>
        </li>
      </MessageOptions>
    </MessageModal>
  );
};

export default NoConnectionsModal;
