// @flow
import React from 'react';
import { css } from 'emotion';
import styled from 'react-emotion';
import { useNavigate } from '../../../../hooks';
import { ROUTES } from '../../../../routes';
import Button, { BUTTON_WIDTHS } from '../../../../../components/Button/Button';
import { hideOnMobileCss } from '../../../../../styles/responsive';
import { smallPlainTextCss } from '../../../../../styles/typography/typography';
import { useTranslation } from 'react-i18next';

export const HeaderOptionsContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const HeaderOptionsInstruction = styled('p')`
  ${hideOnMobileCss};
  ${smallPlainTextCss};
  margin-right: 20px;
`;

const signInButtonLayout = css`
  background-color: #4d4d4d;
`;

const SignInHeaderOptions = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const handleSignUp = () => {
    navigate(ROUTES.signup.navigatePath({}));
  };

  return (
    <HeaderOptionsContainer data-cy="signInPageHeader">
      <HeaderOptionsInstruction>{t('general.signIn.signUpPrompt', '')}</HeaderOptionsInstruction>
      <Button className={signInButtonLayout} onClick={handleSignUp} width={BUTTON_WIDTHS.compact}>
        {t('general.button.signUp', '')}
      </Button>
    </HeaderOptionsContainer>
  );
};

export default SignInHeaderOptions;
