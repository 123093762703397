// @flow
import React from 'react';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../ClassicModalLayout/ClassicModalLayout';
import Button, {
  BUTTON_WIDTHS,
  extraVerticalPaddingButtonClass,
} from '../../../components/Button/Button';
import { useLocalePath } from '../../../routing/components/LocaleWrapper/LocaleWrapper';
import { ROUTES } from '../../../routing/routes';
import { storeCurrentLocationAsAuthDestination } from '../../../auth/destination';
import { useAuthContext } from '../../../auth/components/AuthWrapper/AuthWrapper';
import { useTranslation } from 'react-i18next';

type Props = {
  onClose: () => void,
};

const EnterpriseContactModal = ({ onClose }: Props) => {
  const { t } = useTranslation();

  const { signUpSourceData, setSignUpSourceData } = useAuthContext();
  const localePath = useLocalePath();

  const handleSignUp = () => {
    if (signUpSourceData.signUpSource !== 'Landing Page') {
      setSignUpSourceData({ signUpSource: 'Pricing', signUpCampaign: '' });
    }

    storeCurrentLocationAsAuthDestination();
    onClose();
  };

  return (
    <ClassicModalLayout onClose={onClose}>
      <ClassicModalContentLayout
        heading={t('modal.enterpriseContact.header', '')}
        description={t('modal.enterpriseContact.description', '')}
        footer={
          <Button
            className={extraVerticalPaddingButtonClass}
            onClick={handleSignUp}
            to={ROUTES.signup.navigatePath({
              localePath,
              chosenPlan: 'enterprise',
              selectedInterval: 'monthly',
            })}
            mobileWidth={BUTTON_WIDTHS.full}
          >
            {t('general.button.freeSignUp', '')}
          </Button>
        }
      />
    </ClassicModalLayout>
  );
};

export default EnterpriseContactModal;
