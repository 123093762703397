// @flow
import React from 'react';
import styled from 'react-emotion';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  getMagicQueryKeyword,
  getMagicQueryReplacementKeyword,
  getVisibleResultsKeywords,
} from '../../../../../../../api/algolia/magicKeywords';
import { useModalsContext } from '../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { capitalize } from '../../../../../../../utils/text';
import InteractiveKeyword from '../InteractiveKeyword/InteractiveKeyword';
import { useResultsManagerContext } from '../ResultsManager/ResultsManager';
import {
  MEDIA_BREAKPOINTS,
  SMALL_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
  LARGE_DESKTOP_BREAKPOINT,
} from '../../../../../../../styles/responsive';
import YouTubeKeyword from '../../../../../ResultsScreen/components/ResultsView/components/ResultsAside/components/YouTubeKeyword/YouTubeKeyword';
import { buttonResetCss } from '../../../../../../../components/Button/Button';
import FilterIcon from '../../../../../../../assets/inline-assets/filter-active-icon.svg';
import {
  FILTER_MENU_TYPES,
  FILTER_TYPES,
} from '../../../../../ResultsScreen/components/ResultsView/components/ResultsAside/components/ResultsFilters/data';
import {
  getAppliedFiltersExclusiveList,
  getAppliedFiltersInclusiveList,
} from '../../../../../../../api/algolia/data';
import { lightColor } from '../../../../../../../styles/config/colors';
import MobileResultsFiltersModal from '../../../../../../../modals/components/MobileResultsFiltersModal/MobileResultsFiltersModal';
import { songPlayingSelector } from '../../../../../../../store/globalPlayer';
import { useAnalyticsMixpanelContext } from '../../../../../../../analytics/components/MixpanelWrapper';
import { analyticsMixpanelRemoveKeyword } from '../../../../../../../analytics/mixpanel';
import { searchTriggerAtom } from '../../../../../../../store/mixpanelAnalytics';
import { useTranslation } from 'react-i18next';

export const actionKeywordClass = 'actionKeyword';

const KeywordsLayout = styled('div')`
  padding: 0px 10px;
  display: flex;
  align-items: center;

  ${LARGE_DESKTOP_BREAKPOINT} {
    margin-bottom: 40px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    margin-bottom: 20px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 42px;
    align-items: flex-start;
  }
`;

const KeywordsContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 90%;
  }
`;

const FilterIconWrapper = styled('div')`
  width: 0%;
  display: none;
  height: 100%;

  ${MEDIA_BREAKPOINTS.max850} {
    width: 10%;
    display: flex;
    padding-top: 14px;
  }
`;

const FilterButton = styled('button')`
  ${buttonResetCss};
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items;
`;

const AppliedFilterCounter = styled('div')`
  position: absolute;
  bottom: 15px;
  left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  background-color: #f4c731;
  font-size: 9px;
  color: white;
  border: 1.2px solid ${lightColor};
  border-radius: 1px;
`;

const KeywordWrapper = styled('div')`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${SMALL_DEVICE_BREAKPOINT} {
    justify-content: flex-start;
  }
`;

const getSongTypeKeyword = (type: string): string => {
  const { t } = useTranslation();

  if (type === 'fullMixes') {
    return t('resultsPage.text.fullMixesFilter', '');
  }

  if (type === 'stems') {
    return t('resultsPage.text.stemsFilter', '');
  }

  if (type === 'loops') {
    return t('resultsPage.text.loopsFilter', '');
  }

  if (type === 'stems') {
    return t('resultsPage.text.oneShotFilter', '');
  }

  return '';
};

const getAppliedFilterCount = appliedFilters => {
  let count = 0;

  Object.keys(appliedFilters).forEach(appliedFilter => {
    if (
      FILTER_MENU_TYPES[appliedFilter] === FILTER_TYPES.filterSelect ||
      FILTER_MENU_TYPES[appliedFilter] === FILTER_TYPES.filterBoolean
    ) {
      count += getAppliedFiltersExclusiveList(appliedFilters, appliedFilter).length;
      count += getAppliedFiltersInclusiveList(appliedFilters, appliedFilter).length;
    }

    if (FILTER_MENU_TYPES[appliedFilter] === FILTER_TYPES.filterRange) {
      if (appliedFilters[appliedFilter].max && appliedFilters[appliedFilter].min) {
        count++;
      }
    }
  });

  return count;
};

const SearchKeywords = () => {
  const { t } = useTranslation();

  const {
    displayKeywords,
    algoliaKeywords,
    keywords,
    magicKeywords,
    youtubeKeywords,
    youtubeVideoTitle,
    youtubeChannelTitle,
    setKeywordsQuery,
    setMagicQuery,
    viewingResults,
    handleSectionChange,
    emptySearch,
    noSongResults,
    appliedFilters,
    resultFacets,
    resultFacetStats,
    handleClearSongFilters,
    handleRemoveYouTubeKeyword,
  } = useResultsManagerContext();
  const { showLeaveResultsModal, showLeaveResultFiltersModal } = useModalsContext();
  const {
    showMobileResultsFiltersModal,
    mobileResultsFiltersOpen,
    hideMobileResultsFiltersModal,
  } = useModalsContext();
  const songPlaying = useRecoilValue(songPlayingSelector);
  const setResultsSearchTriggerLocation = useSetRecoilState(searchTriggerAtom);
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();

  const hasFiltersApplied = Object.keys(appliedFilters).length > 0;
  const noSongSearchResults = !emptySearch && !noSongResults;
  const showFiltersMenu = noSongSearchResults || (noSongResults && hasFiltersApplied);

  const handleNewSearch = () => {
    showLeaveResultsModal();
  };

  //
  // confirmKeywordRemoval type in function handleClearFilters
  // Note: Comment out by editor error.
  //
  // confirmKeywordRemoval: {
  //   setKeywordsQuery: (Array<string>) => void,
  //   setMagicQuery: (Array<string>) => void,
  //   handleClearSongFilters: string => void,
  //   isMagicKeyword: boolean,
  //   isYouTubeKeyword?: boolean,
  //   handleRemoveYouTubeKeyword?: () => void,
  // }
  const handleClearFilters = (newKeywords: Array<string>, confirmKeywordRemoval: any) => {
    showLeaveResultFiltersModal(newKeywords, confirmKeywordRemoval);
  };

  const removeKeyword = (keyword: string) => {
    const newKeywords = keywords.slice().filter(filterKeyword => {
      return filterKeyword.toLowerCase() !== keyword.toLowerCase();
    });

    const newDisplayKeywords = getVisibleResultsKeywords(newKeywords, magicKeywords);

    if (newDisplayKeywords.length === 0) {
      handleNewSearch();
      return;
    }
    if (Object.keys(appliedFilters).length > 0) {
      handleClearFilters(newKeywords, {
        setKeywordsQuery,
        setMagicQuery,
        handleClearSongFilters,
        isMagicKeyword: false,
      });
      return;
    }

    analyticsMixpanelRemoveKeyword(mixpanel, moengage, keyword, 'regular', [], 'TBD', 'TBD');
    setKeywordsQuery(newKeywords);
  };

  const removeMagicKeyword = (keyword: string) => {
    const newMagicKeywords = magicKeywords.slice().filter(magicKeyword => {
      return getMagicQueryKeyword(magicKeyword) !== keyword;
    });

    const replacementKeywords = magicKeywords
      .slice()
      .filter(magicKeyword => {
        return getMagicQueryKeyword(magicKeyword) === keyword;
      })
      .map(removedMagicKeyword => {
        return getMagicQueryReplacementKeyword(removedMagicKeyword);
      });

    const newDisplayKeywords = getVisibleResultsKeywords(keywords, newMagicKeywords).length;
    if (newDisplayKeywords === 0) {
      handleNewSearch();
      return;
    }
    if (Object.keys(appliedFilters).length > 0) {
      handleClearFilters(newMagicKeywords, {
        setKeywordsQuery,
        setMagicQuery,
        handleClearSongFilters,
        isMagicKeyword: true,
      });
      return;
    }
    analyticsMixpanelRemoveKeyword(
      mixpanel,
      moengage,
      keyword,
      'magic',
      replacementKeywords,
      'TBD',
      'TBD'
    );
    setMagicQuery(newMagicKeywords);
  };

  const handleRemoveKeyword = (keyword: string) => {
    const isKeywordToRemoveMagic = !algoliaKeywords.includes(keyword);
    setResultsSearchTriggerLocation('removeKeyword');

    if (isKeywordToRemoveMagic) {
      removeMagicKeyword(keyword);
      return;
    }
    removeKeyword(keyword);
  };

  const handleRemoveSongTypeKeyword = () => {
    handleSectionChange('all');
  };

  const handleOpenMobileResultsFilters = () => {
    showMobileResultsFiltersModal();
  };

  const clearYouTubeKeyword = () => {
    if (displayKeywords.length === 0) {
      handleNewSearch();
      return;
    }
    if (Object.keys(appliedFilters).length > 0) {
      handleClearFilters(displayKeywords, {
        setKeywordsQuery,
        setMagicQuery,
        handleClearSongFilters,
        isMagicKeyword: false,
        isYouTubeKeyword: true,
        handleRemoveYouTubeKeyword,
      });
      return;
    }
    handleRemoveYouTubeKeyword();
  };

  const songTypeKeywordLabel =
    getSongTypeKeyword(viewingResults) || t('general.text.collections', '');

  const youtubeSearch = youtubeKeywords ? youtubeKeywords.length > 0 : false;
  const appliedFilterCount = getAppliedFilterCount(appliedFilters);

  return (
    <KeywordsLayout>
      {!emptySearch && (
        <KeywordsContainer>
          {youtubeSearch && (
            <KeywordWrapper>
              <YouTubeKeyword
                handleClick={clearYouTubeKeyword}
                video={youtubeVideoTitle}
                channel={youtubeChannelTitle}
              />
              <>
                {youtubeSearch && (
                  <InteractiveKeyword
                    onClick={showLeaveResultsModal}
                    keywordClass={actionKeywordClass}
                  >
                    {t('general.button.clear', '')}
                  </InteractiveKeyword>
                )}
              </>
            </KeywordWrapper>
          )}
          <KeywordWrapper>
            {displayKeywords.map(keyword => (
              <InteractiveKeyword onClick={() => handleRemoveKeyword(keyword)} key={keyword}>
                {capitalize(keyword)}
              </InteractiveKeyword>
            ))}
            {viewingResults === 'collections' && (
              <InteractiveKeyword onClick={handleRemoveSongTypeKeyword}>
                {songTypeKeywordLabel}
              </InteractiveKeyword>
            )}
            {!youtubeSearch && algoliaKeywords.length > 0 && (
              <InteractiveKeyword onClick={showLeaveResultsModal} keywordClass={actionKeywordClass}>
                {t('general.button.clear', '')}
              </InteractiveKeyword>
            )}
          </KeywordWrapper>
        </KeywordsContainer>
      )}
      {!noSongResults && (
        <FilterIconWrapper>
          {showFiltersMenu && (
            <FilterButton onClick={handleOpenMobileResultsFilters}>
              <FilterIcon />
              {appliedFilterCount > 0 && (
                <AppliedFilterCounter>
                  <span>{appliedFilterCount}</span>
                </AppliedFilterCounter>
              )}
            </FilterButton>
          )}
        </FilterIconWrapper>
      )}

      {mobileResultsFiltersOpen && (
        <MobileResultsFiltersModal
          onClose={() => {
            hideMobileResultsFiltersModal();
          }}
          resultFacets={resultFacets}
          appliedFilters={appliedFilters}
          currentSongData={songPlaying}
          resultFacetStats={resultFacetStats}
          handleClearSongFilters={handleClearSongFilters}
        />
      )}
    </KeywordsLayout>
  );
};

export default SearchKeywords;
