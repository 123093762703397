import React from 'react';
import styled from 'react-emotion';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../../../../components/LocaleWrapper/LocaleWrapper';
import { LOCALES } from '../../../../../../locales';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../../styles/responsive';

const Container = styled('div')`
  width: 370px;
  text-align: left;
  margin: 80px auto 20px auto;

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 100%;
    max-width: 370px;
    padding: 0px 0px;
  }
`;

const PaymentMethodList = styled('div')`
  padding: 10px, 0, 10px, 0;
  text-align: left;

  h4 {
    font-size: 20px;
    padding: 10px, 0, 10px, 0;
    margin-bottom: 10px;
  }

  ul {
    list-style: inherit;
    padding: 0px 20px 0px 45px;
  }

  li {
    line-height: 20px;
    font-size: 20px;
  }
`;

const PricingPaymentMethod = () => {
  const { t } = useTranslation();
  const locale = useLocale();
  const showDirectDebitLabel = locale === LOCALES.japanese.code;

  return (
    <Container>
      {/** JAの場合のみ表示する */}
      {showDirectDebitLabel && (
        <PaymentMethodList>
          <h4>{t('pricing.paymentMethod.header', '')}</h4>
          <ul>
            {t('pricing.paymentMethod.list', {
              returnObjects: true,
              defaultValue: [''],
            }).map(item => (
              <li>{item}</li>
            ))}
          </ul>
        </PaymentMethodList>
      )}
    </Container>
  );
};

export default PricingPaymentMethod;
