// @flow
import React, { useState, useEffect } from 'react';
import styled from 'react-emotion';
import {
  getCuratedCollectionRelatedDisplayInCreator,
  getCuratedCollectionId,
  getCuratedCollectionRelatedCollectionDocument,
  getCuratedCollectionTitle,
  getCuratedCollectionImage,
  getCuratedCollectionSongs,
} from '../../../../cms/curatedCollection';
import type {
  CuratedCollectionRelatedCollectionGraphMdl,
  CuratedCollectionGraphMdl,
} from '../../../../cms/curatedCollection';
import { useNavigate } from '../../../hooks';
import { ROUTES } from '../../../routes';
import {
  MEDIA_BREAKPOINTS,
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
} from '../../../../styles/responsive';
import {
  useCollectionsPage,
  useCuratedCollections,
} from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getCollectionsPageCommonRelatedCollections } from '../../../../cms/collectionsPage';
import PreviewTileRow from '../../MusicScreen/views/BrowseView/components/PreviewTileRow';
import PreviewTile from '../../MusicScreen/views/BrowseView/components/PreviewTile';
import { useGlobalPlayerContext } from '../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { isMobileDeviceSize } from '../../../../utils/device';
import { analyticsMixpanelPreviewSongs } from '../../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../../analytics/components/MixpanelWrapper';
import { useUserId } from '../../../../auth/components/AuthWrapper/AuthWrapper';
import { darkColor } from '../../../../styles/config/colors';
import { searchAlgoliaCollections } from '../../../../api/algolia/collections';
import type { AlgoliaCollectionResults } from '../../../../api/algolia/collections';
import { useLocale } from '../../../components/LocaleWrapper/LocaleWrapper';
import { useTranslation } from 'react-i18next';

const Container = styled('article')`
  margin: 0px auto;
  max-width: 800px;

  ${MEDIA_BREAKPOINTS.max1260} {
    max-width: 800px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    max-width: unset;
    width: 100%;
    padding: 0px 40px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    padding: 0px 20px;
  }
`;

const ViewAllLink = styled('h6')`
  color: rgba(15, 15, 20, 0.4);
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 16px;
  margin-left: 15px;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
    color: ${darkColor};
    cursor: pointer;
  }
`;

type Props = {
  relatedCollections: Array<CuratedCollectionRelatedCollectionGraphMdl>,
  collectionId: string,
  tags: Array<string>,
};

const RelatedCollections = ({ relatedCollections, collectionId, tags }: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const collectionsPage = useCollectionsPage();
  const {
    changeSongIDs,
    updateGlobalPlayerPlaybackDetails,
    handleUpdateGlobalAnalyticsData,
  } = useGlobalPlayerContext();
  const { mixpanel, moengage, handleUpdateCollectionAnalytics } = useAnalyticsMixpanelContext();
  const userId = useUserId();
  const locale = useLocale();
  const [loading, setLoading] = useState(false);

  const [relatedCollectionsList, setRelatedCollectionsList] = useState([]);
  const curatedCollections = useCuratedCollections();

  const navigateCollection = (collectionSlug: string) => {
    handleUpdateCollectionAnalytics([], [], [], [], '', 'relatedCollection', collectionId);
    navigate(ROUTES.collection.navigatePath({ collectionSlug }));
  };

  const handlePreviewCollection = (collection: CuratedCollectionGraphMdl) => {
    const songs = getCuratedCollectionSongs(collection);
    const collectionTitle = getCuratedCollectionTitle(collection);
    const collectionSlug = getCuratedCollectionId(collection);
    handleUpdateGlobalAnalyticsData([], [], [], '', 'relatedCollection', collectionId, '');
    changeSongIDs(songs);
    updateGlobalPlayerPlaybackDetails('collection', collectionTitle, collectionSlug);
  };

  useEffect(() => {
    if (loading) return;
    setLoading(true);

    const searchFilter = `NOT collectionSlug:${collectionId}`;
    searchAlgoliaCollections(tags.join(', '), searchFilter, 12, locale).then(
      (response: AlgoliaCollectionResults) => {
        // Get all IDs from Algolia results and filter out the ID from the collection on view
        const collectionResultIDs =
          response.hits.length > 0
            ? response.hits
                .map(hit => hit.collectionSlug)
                .filter(resultSlug => resultSlug !== collectionId)
            : [];
        // Turn Algolia collection IDs into Prismic collection documents
        const filteredCollectionResults = curatedCollections
          .filter(collection => {
            return collectionResultIDs.includes(getCuratedCollectionId(collection));
          })
          .sort((a, b) => {
            const indexA = collectionResultIDs.findIndex(resultId => {
              return getCuratedCollectionId(a) === resultId;
            });
            const indexB = collectionResultIDs.findIndex(resultId => {
              return getCuratedCollectionId(b) === resultId;
            });
            return indexA - indexB;
          });
        // Turn common related collection into Prismic collection type
        // TODO: uidからコレクションをとる。
        const commonCollections = getCollectionsPageCommonRelatedCollections(collectionsPage).map(
          commonCollection => {
            // TODO: prismic脱却時用メモ
            // commonCollection = {
            //   related_collection: {
            //     document: [
            //       {
            //         uid: 'weekly-staff-highlights',
            //         data: {
            //           collection_image: {
            //             url:
            //               'https://images.prismic.io/amadeus-marketplace/a6ef32d4-bb5d-4b6b-999c-85de126f33b5_Weekly+Staff+Highlights.png?auto=compress,format',
            //           },
            //           collection_title: {
            //             text: 'Weekly Staff Highlights',
            //           },
            //           collection_songs: {
            //             text:
            //               '2020003000022412_0, 2021003000034300_0, 2021103000034988_0, 2022003000039458_0',
            //           },
            //         },
            //       },
            //     ],
            //   },
            // };
            // const relatedDocuments = get(relatedCollection, 'related_collection.document', '');
            // return relatedDocuments[0];

            return getCuratedCollectionRelatedCollectionDocument(commonCollection);
          }
        );

        // commonCollections = [
        //   {
        //     uid: 'weekly-staff-highlights',
        //     data: {
        //       collection_image: {
        //         url:
        //           'https://images.prismic.io/amadeus-marketplace/a6ef32d4-bb5d-4b6b-999c-85de126f33b5_Weekly+Staff+Highlights.png?auto=compress,format',
        //       },
        //       collection_title: {
        //         text: 'Weekly Staff Highlights',
        //       },
        //       collection_songs: {
        //         text:
        //           '2020003000022412_0, 2021003000034300_0, 2021103000034988_0, 2022003000039458_0',
        //       },
        //     },
        //   },
        // ];

        // Turn individual common rrelated collections into Prismic collection documents
        const individualRelatedCollections = relatedCollections
          .filter(mergedCollection => getCuratedCollectionRelatedDisplayInCreator(mergedCollection))
          .map(filteredRelatedCollection =>
            getCuratedCollectionRelatedCollectionDocument(filteredRelatedCollection)
          );

        const mergedCollections = commonCollections
          .concat(individualRelatedCollections)
          .concat(filteredCollectionResults);

        // Remove duplicate collections
        const uniqueRelatedCollections = [
          ...new Map(
            mergedCollections.map(item => {
              console.log(item);
              return [item.uid, item];
            })
          ).values(),
        ].slice(0, 12);

        setRelatedCollectionsList(uniqueRelatedCollections);
        setLoading(false);
      }
    );
  }, [collectionId]);

  if (loading) {
    return <Container></Container>;
  }

  return (
    <Container>
      {relatedCollectionsList.length > 0 && (
        <PreviewTileRow
          title={t('collectionsPage.relatedCollectionTitle', '')}
          columnTitle
          rows={1}
          showAll={isMobileDeviceSize()}
          viewAllLinkButton={
            <ViewAllLink
              onClick={() => {
                navigate(ROUTES.music.navigatePath({}));
              }}
            >
              {t('general.button.backToBrowse', '')}
            </ViewAllLink>
          }
        >
          {relatedCollectionsList.map(collection => {
            const slug = getCuratedCollectionId(collection);
            return (
              <PreviewTile
                key={slug}
                title={getCuratedCollectionTitle(collection)}
                slug={slug}
                bgImage={getCuratedCollectionImage(collection)}
                listType="collection"
                previewTile={() => {
                  analyticsMixpanelPreviewSongs(
                    mixpanel,
                    moengage,
                    'Collection',
                    slug,
                    'related_collections',
                    userId
                  );
                  handlePreviewCollection(collection);
                }}
                navigateTile={() => {
                  navigateCollection(slug);
                }}
              />
            );
          })}
        </PreviewTileRow>
      )}
    </Container>
  );
};

export default RelatedCollections;
