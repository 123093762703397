// @flow
import React, { useState } from 'react';
import { SubscriptionModal } from '../AddSubscriptionModal/AddSubscriptionModal';
import type { UserSubscriptionMdl } from '../../../../api/firebase/user/subscriptions';
import {
  getSubscriptionChannelId,
  getSubscriptionPlanKey,
  getSeatInterval,
} from '../../../../api/firebase/user/subscriptions';
import { SUBSCRIPTIONS, getPlanCodeName, BILLING_CYCLES } from '../../data';
import {
  useSubscriptionsContext,
  isSubscriptionFreeTrial,
} from '../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import {
  getProfileEmail,
  getProfileFirstName,
  getProfileLastName,
} from '../../../../api/firebase/user/profile';
import { useUserProfileDetails } from '../../../components/UserProfileWrapper/UserProfileWrapper';
import { useTranslation } from 'react-i18next';
import { isEnterpriseSubscription } from '../../../../utils/subscriptions';

type Props = {
  subscription: UserSubscriptionMdl,
  selectedPlan: string,
  onClose: () => void,
};

export const ACTIONS = {
  close: 'close',
  upgrade: 'upgrade',
  downgrade: 'downgrade',
  change: 'change',
  add: 'add',
  cancel: 'cancel',
  upgrade_cancelled_trial: 'upgrade_cancelled_trial',
  contact: 'contact',
};

const ChangeSubscriptionModal = ({ subscription, selectedPlan, onClose, ...props }: Props) => {
  const [busy, setBusy] = useState(false);
  const { t } = useTranslation();

  const subscriptionPlanKey = getSubscriptionPlanKey(subscription);
  const subscriptionInterval = getSeatInterval(subscription);
  const isFreeTrialSubscription = isSubscriptionFreeTrial(subscription);
  const {
    showDowngradeModal,
    showChangingIntervalModal,
    upgradeSubscription,
    billingCycle,
    setBillingCycle,
    showFreeTrialChangeModal,
  } = useSubscriptionsContext();

  const profile = useUserProfileDetails();

  const promotionalDefaultInterval = '';

  const defaultInterval = promotionalDefaultInterval || 'annual';

  const getPricingPlanAvailableIntervals = (): string[] => {
    const planIntervals = [{ interval: 'annually' }, { interval: 'annually' }];

    return planIntervals.map(planInterval => planInterval.interval);
  };

  const otherIntervalsAvailable =
    getPricingPlanAvailableIntervals().filter(interval => {
      return BILLING_CYCLES[interval].cycle !== subscriptionInterval;
    }).length > 0;

  let actionState = ACTIONS.close;
  const selectedPlanCodeStart = getPlanCodeName(selectedPlan);
  const subscriptionPlanKeyStart = getPlanCodeName(subscriptionPlanKey);

  if (selectedPlanCodeStart === subscriptionPlanKeyStart) {
    if (otherIntervalsAvailable) {
      actionState = ACTIONS.change;
    } else {
      actionState = ACTIONS.close;
    }
  } else if (SUBSCRIPTIONS.creator.plan_codes.includes(subscriptionPlanKey)) {
    actionState = ACTIONS.upgrade;
  } else if (SUBSCRIPTIONS.creatorPro.plan_codes.includes(subscriptionPlanKey)) {
    if (SUBSCRIPTIONS.business.plan_codes.includes(selectedPlan)) {
      actionState = ACTIONS.upgrade;
    } else {
      actionState = ACTIONS.contact;
    }
  } else if (SUBSCRIPTIONS.business.plan_codes.includes(subscriptionPlanKey)) {
    if (SUBSCRIPTIONS.creatorPro.plan_codes.includes(selectedPlan)) {
      actionState = ACTIONS.downgrade;
    } else {
      actionState = ACTIONS.contact;
    }
  }

  const actions = {
    close: t('general.button.cancel', ''),
    upgrade: t('general.button.upgrade', ''),
    downgrade: t('general.button.downgrade', ''),
    change: t('general.button.change', ''),
    add: t('general.button.upgrade', ''),
    contact: t('general.button.getInContact', ''),
  };

  const actionText = actions[actionState];

  const handleClose = () => {
    if (busy) return;
    setBillingCycle(defaultInterval);
    onClose();
  };

  const handleEnterpriseClick = async () => {
    try {
      const firstName = getProfileFirstName(profile);
      const lastName = getProfileLastName(profile);
      const email = getProfileEmail(profile);
      const baseFormLink = t('pricing.plans.plan.formLink.enterprise', '');
      // エンタープライズ(ベーシックではない方)のお問い合わせはGoogle Formを使用。氏名とEメールは自動入力されるように対応。
      const completeFormLink = `${baseFormLink}?usp=pp_url&entry.153525598=${firstName}&entry.471619541=${lastName}&entry.892833479=${email}`;

      if (window) {
        window.location = completeFormLink;
      }
    } catch (error) {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage(
        'Something went wrong when creating placeholder Enterprise and redirecting user to Formcrafts'
      );
      Sentry.captureException(error);
      console.log(error);
      return;
    }
  };

  const action = () => {
    if (busy) return;
    setBusy(true);
    if (isFreeTrialSubscription) {
      showFreeTrialChangeModal(getSubscriptionChannelId(subscription), selectedPlan, actionState);
      onClose();
      return;
    }
    if (actionState === ACTIONS.close) {
      setBillingCycle(defaultInterval);
      onClose();
      return;
    }
    if (actionState === ACTIONS.downgrade) {
      showDowngradeModal(getSubscriptionChannelId(subscription), selectedPlan);
      onClose();
      return;
    }
    if (actionState === ACTIONS.upgrade) {
      upgradeSubscription(getSubscriptionChannelId(subscription), selectedPlan, billingCycle)
        .then(() => {
          setBillingCycle(defaultInterval);
          onClose();
        })
        .catch(error => {
          // $FlowFixMe: removes type checking for Sentry as provisional solution
          Sentry.captureMessage('Something went wrong when upgrading subscription');
          Sentry.captureException(error);
          console.error(error);
          setBusy(false);
        });
    }

    if (actionState === ACTIONS.change) {
      showChangingIntervalModal(getSubscriptionChannelId(subscription), selectedPlan);
      onClose();
    }

    if (actionState === ACTIONS.contact && isEnterpriseSubscription(selectedPlan)) {
      handleEnterpriseClick();
    }
  };

  return (
    <SubscriptionModal
      onClose={handleClose}
      action={action}
      actionText={actionText}
      selectedPlan={selectedPlan}
      busy={busy}
      disableAction={actionState === ACTIONS.close}
      changingSubscription={subscription}
      subscriptionInterval={subscriptionInterval}
      subscriptionPlanKey={subscriptionPlanKey}
      subscriptionModalType={actionState}
      {...props}
    />
  );
};

export default ChangeSubscriptionModal;
