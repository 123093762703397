// @flow
import React from 'react';
import { getMusicPageFeaturedGenres } from '../../../../../../cms/musicPage';
import { useMusicPage } from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import FeaturedTagSection from './FeaturedTagSection';
import { useTranslation } from 'react-i18next';

type Props = {
  viewAll?: boolean,
  viewAllLink?: boolean,
  oversizedTile?: boolean,
  page: string,
  rows: number,
};

const FeaturedGenres = ({
  viewAll = false,
  viewAllLink = false,
  oversizedTile = false,
  page,
  rows,
}: Props) => {
  const { t } = useTranslation();
  const musicPage = useMusicPage();
  const sectionTitle = t('musicPage.header.featuredGenres', '');
  const tags = getMusicPageFeaturedGenres(musicPage);
  return (
    <FeaturedTagSection
      tagCategory="genre"
      title={sectionTitle}
      tags={tags}
      viewAll={viewAll}
      viewAllLink={viewAllLink}
      oversizedTile={oversizedTile}
      page={page}
      rows={rows}
    />
  );
};

export default FeaturedGenres;
