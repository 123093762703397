// @flow
import { useTranslation } from 'react-i18next';

type Props = {
  error: any,
};

const Auth0ErrorHandler = ({ error }: Props) => {
  const { t } = useTranslation();

  if (error) {
    const { code, description } = error;

    if (code) {
      switch (code) {
        case 'invalid_signup':
          return t('general.error.signInSignUp.invalidSignUp', '');
        case 'access_denied':
          return t('general.error.signInSignUp.signInAccessDenied', '');
        case 'invalid_password':
          return t('general.error.signInSignUp.invalidPassword', '');
        default:
          break;
      }
    }

    if ((description && typeof description === 'string') || description instanceof String) {
      return description;
    }

    if (code) {
      return code;
    }
  }

  return t('general.error.signInSignUp.generic', '');
};

export default Auth0ErrorHandler;
