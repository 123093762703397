// @flow
import React from 'react';
import SearchIcon from '../../../../../../../../../assets/inline-assets/search-icon.svg';
import { useResultsManagerContext } from '../../../ResultsManager/ResultsManager';
import SearchResultsMessageLayout from '../SearchResultsMessageLayout/SearchResultsMessageLayout';
import { useTranslation } from 'react-i18next';

const NoSearchResults = () => {
  const { t } = useTranslation();

  const { appliedFilters } = useResultsManagerContext();
  const hasFiltersApplied = Object.keys(appliedFilters).length > 0;

  const description = hasFiltersApplied
    ? t('noResultsPage.filtering.message', '')
    : t('noResultsPage.message', '');

  return (
    <SearchResultsMessageLayout
      title={t('noResultsPage.filtering.header', '')}
      description={description}
      icon={<SearchIcon />}
    />
  );
};

export default NoSearchResults;
