/* eslint-disable jsx-a11y/no-autofocus */
// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import Autosuggest from 'react-autosuggest';
import { connectAutoComplete } from 'react-instantsearch-dom';
import { css, cx } from 'emotion';
import { transparentize } from 'polished';
import styled from 'react-emotion';
import { lightColor } from '../../../../../../../styles/config/colors';
import {
  LARGE_DEVICE_BREAKPOINT,
  maxQuery,
  SMALL_DEVICE_BREAKPOINT,
  MOBILE_DEVICE_BREAKPOINT,
} from '../../../../../../../styles/responsive';
import { inputResetCss, placeholderCss } from '../../../../../../../styles/shared';
import InteractiveOption from '../../../../../../../components/InteractiveOption/InteractiveOption';
import PlusIcon from '../../../../../../../assets/inline-assets/plus-icon.svg';
import MinusIcon from '../../../../../../../assets/inline-assets/minus-icon.svg';
import {
  monoSmallPlainTextCss,
  bodyTextCss,
} from '../../../../../../../styles/typography/typography';
import { useKeywordsManagerContext } from '../KeywordsManager/KeywordsManager';
import type { AlgoliaKeyword } from '../../../../../../../api/algolia/magicKeywords';
import {
  getKeywordObjectToAdd,
  getInverseKeywordObjectToAdd,
  isReplacableKeyword,
} from '../../../../../../../api/algolia/magicKeywords';
import { useModalsContext } from '../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useTranslation } from 'react-i18next';

const inputTextCss = css`
  font-weight: 300;
  font-size: 60px;
  color: ${lightColor};
  letter-spacing: 1px;
  text-align: center;
  line-height: 60px;
  height: 70px;

  ${SMALL_DEVICE_BREAKPOINT} {
    font-size: 30px;
    line-height: 60px;
    height: 60px;

    ${maxQuery(350)} {
      font-size: 25px;
      height: 50px;
      line-height: 50px;
    }
  }
`;

const inputClass = css`
  ${inputResetCss};
  ${inputTextCss};
  display: block;
  width: 100%;
  position: relative;

  ${placeholderCss(`
    color: ${transparentize(0.6, lightColor)};
    font-style: italic;
    
  `)};

  &:focus,
  &:hover {
    ${placeholderCss(`
        color: ${transparentize(0.4, lightColor)};
  `)};
  }

  &:focus {
    outline: none;
  }
`;

const SuggestionsContainer = styled('div')`
  display: flex;
  justify-content: center;

  ${LARGE_DEVICE_BREAKPOINT} {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    margin-top: 33px;

    &.collapsedSuggestionsContainer {
      top: 150%;
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 20px;

    &.collapsedSuggestionsContainer {
      position: relative;
      margin-top: 50px;
      left: 0;
      right: 0;
      top: 100%;
    }
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    &.collapsedSuggestionsContainer {
      margin-top: 60px;
    }
  }

  > ul {
    display: flex;
    flex-direction: column;
    align-items: center;

    li {
      &:not(:first-child) {
        margin-top: 5px;
      }
    }
  }
`;

type Props = {
  placeholder: string,
  hits: Array<AlgoliaKeyword>,
  currentRefinement: string,
  refine: string => void,
  addKeyword: (string, boolean) => void,
  addMagicKeywords: (AlgoliaKeyword | null, boolean) => void,
  handleSearch: () => void,
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion;

const suggestionCss = css`
  transform: translateX(-10px);
  text-transform: capitalize;

  &:hover {
    transform: translateX(-10px);
  }

  ${LARGE_DEVICE_BREAKPOINT} {
    transform: translateX(-10px);

    &:hover {
      transform: translateX(-10px);
`;

const Form = styled('form')`
  position: relative;
`;

const FormText = styled('div')`
  ${monoSmallPlainTextCss};
  position: absolute;
  top: 100%;
  margin-top: 3px;
  left: 0;
  right: 0;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  transform: translateY(-5px);
  transition: all 200ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: -8px;
  }
`;

const Instruction = styled('p')`
  width: 100%;
  color: ${transparentize(0.6, lightColor)};

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 28px;
    width: 100%;
  }
`;

const Hint = styled('p')`
  ${bodyTextCss}
  margin-top: 20px;
  padding: 5px 0px;
  border-radius: 3px;
  font-size: 12px;
  color: ${transparentize(0.5, lightColor)};
  border: 1px solid ${transparentize(0.5, lightColor)};

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 17px;
  }
`;

const showInstructionCss = css`
  margin-top: 25px;
  opacity: 1;
  transform: translateY(0);
`;

const FormInstruction = ({ value }: any) => {
  const { t } = useTranslation();

  const isExcludingKeyword = value[0] === '-';

  return (
    <FormText className={showInstructionCss}>
      <Instruction>
        {!value || (value && isExcludingKeyword) ? (
          <>
            {!value ? (
              <>
                <p>{t('findMusicPage.instruction', '')}</p>
                {false && <Hint>{t('findMusicPage.advancedSyntaxHint', '')}</Hint>}
              </>
            ) : (
              <p>{t('findMusicPage.instructionAdvancedSyntaxExclude', '')}</p>
            )}
          </>
        ) : null}
      </Instruction>
    </FormText>
  );
};

interface IRenderInputComponentProps {
  className: string;
  value: string;
  addKeyword: (value: string, bool: boolean) => void;
  inputProps: any;
}

const renderInputComponent = ({
  className,
  value,
  addKeyword,
  ...inputProps
}: IRenderInputComponentProps) => {
  const handleSubmit = event => {
    event.preventDefault();
    addKeyword(value, true);
  };
  return (
    <Form onSubmit={handleSubmit}>
      <input
        className={cx(className, inputClass)}
        value={value}
        {...inputProps}
        // autoFocus
        data-cy="keywordInput"
        id="searchInput"
      />
      <FormInstruction value={value} />
    </Form>
  );
};

const getCombinedSuggestions = (suggestions: Array<string>, userInput: string): Array<string> => {
  if (!userInput) return suggestions;
  if (
    suggestions.includes(userInput.toLowerCase()) ||
    (userInput[0] === '-' && userInput.length === 1)
  ) {
    // todo - move userInput to top of array
    return suggestions;
  }
  if (userInput[0] === '-') {
    return [userInput.toLowerCase().slice(1)].concat(suggestions);
  }
  return [userInput.toLowerCase()].concat(suggestions);
};

const KeywordAutocomplete = ({
  placeholder,
  hits,
  currentRefinement,
  refine,
  addKeyword,
  addMagicKeywords,
  handleSearch,
}: Props) => {
  const [lastAddedKeyword, setLastAddedKeyword] = useState();
  // Commented out as not in use
  // const [highlightedSuggestion, setHighlightedSuggestion] = useState();
  const { mergedUIKeywords } = useKeywordsManagerContext();
  const isExcludeSuggestion = currentRefinement[0] === '-';
  const { keyboardShortcutsOpen } = useModalsContext();

  const handleAddKeyword = (keyword: string, custom: boolean = false) => {
    const keywordToAdd = isExcludeSuggestion ? '-'.concat(keyword) : keyword;

    const algoliaKeyword = isExcludeSuggestion
      ? getInverseKeywordObjectToAdd(hits, keyword)
      : getKeywordObjectToAdd(hits, keyword);
    const replaceableKeyword = isReplacableKeyword(algoliaKeyword);
    // prevents double-submission glitch
    if (custom && lastAddedKeyword && lastAddedKeyword > Date.now() - 500) {
      return;
    }
    setLastAddedKeyword(Date.now());

    // Magic Keywords Feature: Replacable keywords are magic keywords and they will be tracked differently
    if ((keywordToAdd === '' || keywordToAdd === '-') && mergedUIKeywords.length > 0) {
      handleSearch();
    } else {
      if (replaceableKeyword) {
        addMagicKeywords(algoliaKeyword, false);
        if (custom) {
          refine('');
        }
      } else {
        if (keywordToAdd === '-') {
          return;
        }
        addKeyword(keywordToAdd, false);
        if (custom) {
          refine('');
        }
      }
    }
  };

  const inputProps = {
    placeholder,
    value: currentRefinement,
    onChange: event => refine(event.currentTarget.value),
    addKeyword: handleAddKeyword,
  };

  interface IRenderSuggestionsContainerProps {
    containerProps: any;
    children: Node;
    query?: any;
  }

  function renderSuggestionsContainer({
    containerProps,
    children,
  }: IRenderSuggestionsContainerProps) {
    return (
      <SuggestionsContainer
        {...containerProps}
        className={cx({ collapsedSuggestionsContainer: isExcludeSuggestion === true })}
      >
        {children}
      </SuggestionsContainer>
    );
  }

  const renderSuggestion = (suggestion, { isHighlighted }) => {
    return (
      <InteractiveOption
        className={suggestionCss}
        icon={isExcludeSuggestion ? <MinusIcon /> : <PlusIcon />}
        iconStyle={isExcludeSuggestion ? 'minusIcon' : 'plusIcon'}
        highlighted={isHighlighted}
      >
        {suggestion}
      </InteractiveOption>
    );
  };

  const onSuggestionsFetchRequested = () => {};
  const onSuggestionsClearRequested = () => {};
  const onSuggestionHighlighted = ({ suggestion }) => {};
  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    handleAddKeyword(suggestionValue);
    if (method === 'click') {
      // refine(currentRefinement);
    }
    refine('');
    event.stopPropagation();
  };
  const suggestions = currentRefinement ? hits.map(({ keyword }) => keyword) : [];

  const combinedSuggestions = getCombinedSuggestions(suggestions, currentRefinement);

  const ref = () => {
    const autofocus = !keyboardShortcutsOpen;
    if (autofocus) {
      document.getElementById('searchInput').focus();
    } else {
      document.getElementById('searchInput');
    }
  };

  return (
    <Autosuggest
      highlightFirstSuggestion
      suggestions={combinedSuggestions}
      inputProps={inputProps}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      renderSuggestionsContainer={renderSuggestionsContainer}
      alwaysRenderSuggestions
      onSuggestionHighlighted={onSuggestionHighlighted}
      onSuggestionSelected={onSuggestionSelected}
      ref={ref}
    />
  );
};

export default connectAutoComplete(KeywordAutocomplete);
