// @flow
import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import PlayPauseButton from './components/PlayPauseButton/PlayPauseButton';
import MicroPartsButton from './components/MicroPartsButton/MicroPartsButton';
import BookmarkButton from './components/BookmarkButton/BookmarkButton';
import DownloadButton from './components/DownloadButton/DownloadButton';
import ShareButton from './components/ShareButton/ShareButton';
import {
  LANDSCAPE_DEVICE,
  LARGE_DEVICE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../styles/responsive';
import { useAudioPlaybackContext } from '../../../AudioPlayback/AudioPlayback';
import { useDownloadSong } from '../../../../hooks';
import { useSong } from '../../../SongContextWrapper/SongContextWrapper';

const Container = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 6px;
    padding: 0 23px;
    justify-content: space-between;

    ${LANDSCAPE_DEVICE} {
      justify-content: center;
    }
  }
`;

const Control = styled('div')`
  display: flex;
  align-items: center;

  ${LARGE_DEVICE_BREAKPOINT} {
    margin: 0 15px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    ${LANDSCAPE_DEVICE} {
      margin: 0 10px;
    }
  }
`;

const playPauseClass = css`
  ${SMALL_DEVICE_BREAKPOINT} {
    ${LANDSCAPE_DEVICE} {
      margin: 0 15px;
    }
  }
`;

const microPartsClass = css`
  ${SMALL_DEVICE_BREAKPOINT} {
    visibility: none;
  }
`;

type Props = {
  handleShareModalState: any,
};

const SongActions = ({ handleShareModalState }: Props) => {
  const song = useSong();
  const { viewingMicroparts } = useAudioPlaybackContext();
  const [download, downloadBusy] = useDownloadSong(song);
  return (
    <Container>
      <Control className={microPartsClass}>
        <MicroPartsButton active={viewingMicroparts} />
      </Control>
      <Control>
        <BookmarkButton />
      </Control>
      <Control className={playPauseClass}>
        <PlayPauseButton />
      </Control>
      <Control>
        <DownloadButton busy={downloadBusy} />
      </Control>
      <Control>
        <ShareButton handleShareModalState={handleShareModalState} />
      </Control>
    </Container>
  );
};

export default SongActions;
