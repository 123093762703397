// @flow
import React from 'react';
import styled, { css, cx } from 'react-emotion';
import { hideOnLargeDeviceCss, SMALL_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import { transparentize } from 'polished';
import {
  businessBgColor,
  darkColor,
  enterpriseBgColor,
  guestBgColor,
  lightColor,
  personalBgColor,
} from '../../../../../styles/config/colors';
import { hugeTextCss, monoFontCss } from '../../../../../styles/typography/typography';
import {
  isBusinessSubscription,
  isCreatorProSubscription,
  isCreatorSubscription,
  isEnterpriseSubscription,
} from '../../../../../utils/subscriptions';
import { useTranslation } from 'react-i18next';
import { useSubscriptionsContext } from '../../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import {
  getSeatInterval,
  getSubscriptionPlanKey,
} from '../../../../../api/firebase/user/subscriptions';
import { INTERVAL_MONTHLY_DURATION } from '../../../data';
import PaymentMethodRadioButton from '../../../../../components/RadioButton/components/PaymentMethodRadioButton';
import BillingCyclesNav from './BillingCyclesNav';

const Container = styled('section')`
  ${hideOnLargeDeviceCss};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: none;
`;

const pricingHeading = css`
  ${hugeTextCss};
`;

const Pricing = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;
  background-color: ${transparentize(0.95, darkColor)};
  border-radius: 2px;
  width: 100%;
  margin-top: 20px;
  h3 {
    ${pricingHeading};
    font-size: 30px;
    line-height: 45px;
    margin-left: 10px;
    margin-right: 1px;
    vertical-align: bottom;
    font-family: 'Roboto Mono', sans-serif;
  }

  & sub {
    font-size: 14px;
    position: relative;
    top: 6px;
  }

  & div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-end;
    margin-bottom: 7px;
    margin-left: 15px;
    opacity: 0.6;
  }

  p {
    ${monoFontCss};
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 14px;
    padding-bottom: 4px;
  }
`;

const PromotionLabel = styled('div')`
  text-align: center;
  margin-top: 20px;
  border-radius: 2px;
  ${personalBgColor}
  background-size: cover;
  background-position: center;
  min-height: 100px;

  ${SMALL_DEVICE_BREAKPOINT} {
    min-height: unset;
    width: 100%;
  }

  & div {
    width: 100%;
    height: 100%;
    background-color: ${transparentize(0.5, lightColor)};
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  & h6 {
    height: 30%;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.25px;
    padding: 10px 0px 2px 0px;
    color: ${darkColor};
  }

  & p {
    height: 70%;
    font-size: 12px;
    padding: 0px 7px 10px 7px;
    line-height: 20px;
  }
`;

const guestPlanCss = css`
  background-color: ${guestBgColor};
`;

const personalPlanCss = css`
  background-color: ${personalBgColor};
`;

const businessPlanCss = css`
  background-color: ${businessBgColor};
`;

const enterprisePlanCss = css`
  background-color: ${enterpriseBgColor};
`;

const BillingCyclesWrapper = styled('div')`
  margin: auto;
  margin-top: 0px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .radio-button-group {
      margin-top: 20px;
      margin-left: 0;
      padding-left: 0;
    }
  }
`;

const hiddenBillingCycles = css`
  display: none;
`;

const paymentMethodButtonCss = css`
  width: 100%;
`;

const MobilePricing = ({
  selectOption = 'oneTimePayment',
  selectedPlan,
  changingSubscription,
  handleOptionChange,
  subscriptionPlanKey,
  subscriptionInterval,
  action,
  subscriptionModalType,
}) => {
  const { t } = useTranslation();

  const { billingCycle } = useSubscriptionsContext();

  const promotionAvailable = false;

  const downgradingBusiness = changingSubscription
    ? isBusinessSubscription(getSubscriptionPlanKey(changingSubscription)) &&
      isCreatorProSubscription(selectedPlan)
    : false;
  const downgradingInterval = changingSubscription
    ? INTERVAL_MONTHLY_DURATION[billingCycle] <
        INTERVAL_MONTHLY_DURATION[getSeatInterval(changingSubscription)] &&
      selectedPlan === getSubscriptionPlanKey(changingSubscription)
    : false;

  const promotionalText = ((): string => {
    if (changingSubscription) {
      const isIntervalChange = getSubscriptionPlanKey(changingSubscription) === selectedPlan;
      if (isIntervalChange) {
        return (
          <>
            <h6>{t('subscriptionsPage.promotionalLabel.header', '')}</h6>
            <p>{t('subscriptionsPage.promotionalLabel.intervalChange', '')}</p>
          </>
        );
      }

      return (
        <>
          <h6>{t('subscriptionsPage.promotionalLabel.header', '')}</h6>
          <p>{t('subscriptionsPage.promotionalLabel.upgradePlan', '')}</p>
        </>
      );
    }
    return (
      <>
        <h6>{t('subscriptionsPage.promotionalLabel.header', '')}</h6>
        <p>{t('subscriptionsPage.promotionalLabel.newPlan', '')}</p>
      </>
    );
  })();

  const getPricingPlanAnnuallyPrice = (plan: string) => {
    if (isCreatorProSubscription(plan)) {
      return t('general.pricing.price.loyalMember.annually', '');
    }

    if (isBusinessSubscription(plan)) {
      return t('general.pricing.price.loyalMemberPlus.annually', '');
    }

    if (isEnterpriseSubscription(plan)) {
      return t('general.pricing.price.enterprise.annually', '');
    }
  };

  const getPricingPlanMonthlyPrice = (plan: string) => {
    if (isCreatorProSubscription(plan)) {
      return t('general.pricing.price.loyalMember.monthly', '');
    }

    if (isBusinessSubscription(plan)) {
      return t('general.pricing.price.loyalMemberPlus.monthly', '');
    }

    if (isEnterpriseSubscription(plan)) {
      return t('general.pricing.price.enterprise.monthly', '');
    }
  };

  const getPricingPlanAnnuallyOrMonthlyPrice = (selectOption, plan) => {
    if (selectOption === 'oneTimePayment') {
      return getPricingPlanAnnuallyPrice(plan);
    }

    if (selectOption === 'splitPayment') {
      return getPricingPlanMonthlyPrice(plan);
    }
  };

  const getPricingPlanAvailableIntervals = (): string[] => {
    const planIntervals = [{ interval: null }, { interval: 'annually' }, { interval: 'annually' }];

    return planIntervals.map(planInterval => planInterval.interval);
  };

  return (
    <Container>
      <Pricing>
        <h3>{getPricingPlanAnnuallyOrMonthlyPrice(selectOption, selectedPlan)}</h3>
        {!isCreatorSubscription(selectedPlan) && (
          <sub>{t('general.pricing.billingSubtext.monthly', '')}</sub>
        )}
      </Pricing>
      <>
        {promotionAvailable && !downgradingBusiness && !downgradingInterval && (
          <PromotionLabel
            className={cx({
              [guestPlanCss]: isCreatorSubscription(selectedPlan),
              [personalPlanCss]: isCreatorProSubscription(selectedPlan),
              [businessPlanCss]: isBusinessSubscription(selectedPlan),
              [enterprisePlanCss]: isEnterpriseSubscription(selectedPlan),
            })}
          >
            <div>{promotionalText}</div>
          </PromotionLabel>
        )}
      </>
      <BillingCyclesWrapper
        className={cx({ [hiddenBillingCycles]: isEnterpriseSubscription(selectedPlan) })}
      >
        {!isEnterpriseSubscription(selectedPlan) && (
          <PaymentMethodRadioButton
            className={paymentMethodButtonCss}
            selectOption={selectOption}
            handleOptionChange={handleOptionChange}
          />
        )}
        <BillingCyclesNav
          selectedPlan={selectedPlan}
          subscriptionPlanKey={subscriptionPlanKey}
          billingCycle={billingCycle}
          subscriptionInterval={subscriptionInterval}
          availableIntervals={getPricingPlanAvailableIntervals()}
          selectOption={selectOption}
          action={action}
          subscriptionModalType={subscriptionModalType}
        />
      </BillingCyclesWrapper>
    </Container>
  );
};

export default MobilePricing;
