// @flow
import React, { useEffect, useState } from 'react';
import { css } from 'emotion';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'react-emotion';
import { slimModalCss } from '../../../../../modals/components/Modal/Modal';
import ClassicModalLayout from '../../../../../modals/components/ClassicModalLayout/ClassicModalLayout';
import { BANNERS } from '../../../../../images/paths';
import { formatSeconds } from '../../../../../utils/time';
import SquareCheckbox from '../../../../../components/forms/inputs/SquareCheckbox';
import { buttonResetCss } from '../../../../../components/Button/Button';
import { bodyTextCss, monoFontCss, monoNavCss } from '../../../../../styles/typography/typography';
import { LargeHeading } from '../../../../../styles/typography/components/Heading/Heading';
import {
  analyticsShareLink,
  analyticsShareSource,
  analyticsSongsShare,
  useSongAnalyticsDimensions,
} from '../../../../../analytics/events';
import SocialShareOptions from '../../../../../modals/components/ShareSongModal/components/SocialShareOptions/SocialShareOptions';
import { MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT } from '../../../../../styles/responsive';
import { useAnalyticsMixpanelContext } from '../../../../../analytics/components/MixpanelWrapper';
import {
  analyticsMixpanelSongShare,
  useMixpanelSongAnalyticsDimensions,
} from '../../../../../analytics/mixpanel';
import { useDownloadContext } from '../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import { useSubscriptionsContext } from '../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useUserId } from '../../../../../auth/components/AuthWrapper/AuthWrapper';
import {
  useDisplaySongShareContext,
  useDisplaySongShareSongShareURL,
} from '../DisplaySongShareContext/DisplaySongShareContext';
import { useTranslation } from 'react-i18next';

const bannerClass = css`
  background-color: #191941;
  background-image: url(${BANNERS.share});
`;

const CopyUrlContainer = styled('div')`
  position: relative;
  height: 60px;
  border: 1px solid rgba(240, 240, 235, 0.05);
  border-radius: 3px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;

  a {
    text-decoration: none;
    display: block;
    padding-top: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

const Heading = styled(LargeHeading)`
  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    margin-bottom: 15px;
    margin-top: -7px;
  }
`;

const LinkWrapper = styled('div')`
  flex: 1;
  padding-right: 10px;
  overflow: hidden;
`;

const CopyButton = styled('button')`
  ${buttonResetCss};
  ${monoNavCss};
  text-transform: uppercase;
`;

const TimestampCheckbox = styled('label')`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const TimestampLabel = styled('div')`
  ${bodyTextCss};
  margin-left: 10px;

  span {
    ${monoFontCss};
  }
`;

const ShareOptions = styled('div')`
  margin-top: 30px;

  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    margin-top: 20px;
  }
`;

type Props = {
  onClose: () => void,
};

const ShareSongModal = ({ onClose }: Props) => {
  const { getAudioProgress } = useDisplaySongShareContext();
  const [copied, setCopied] = useState(false);
  const [audioProgress] = useState(getAudioProgress());
  const [timestampEnabled, setTimestampEnabled] = useState(false);
  const { t } = useTranslation();

  let unmounted = false;
  const dimensions = useSongAnalyticsDimensions();
  const timestamp = timestampEnabled ? Math.round(audioProgress) : 0;
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const mixpanelSongDimensions = useMixpanelSongAnalyticsDimensions();
  const downloadContext = useDownloadContext();
  const { userRole } = useSubscriptionsContext();
  const userId = useUserId();

  let shareURL = useDisplaySongShareSongShareURL();

  if (timestamp) {
    shareURL = `${shareURL}?t=${timestamp}`;
  }

  useEffect(() => {
    return () => {
      unmounted = true;
    };
  }, []);

  const handleTimestampCheckboxChange = event => {
    setTimestampEnabled(event.target.checked);
  };

  const onShared = () => {
    analyticsSongsShare(dimensions);
    analyticsShareLink(shareURL, dimensions);
  };

  const handleCopied = () => {
    setCopied(true);
    analyticsMixpanelSongShare(
      mixpanel,
      moengage,
      mixpanelSongDimensions,
      downloadContext,
      userRole,
      'Link',
      timestampEnabled,
      userId
    );
    analyticsShareSource('Link', dimensions);
    onShared();
    setTimeout(() => {
      if (!unmounted) {
        setCopied(false);
      }
    }, 2000);
  };

  return (
    <ClassicModalLayout
      className={slimModalCss}
      onClose={onClose}
      label={t('modal.shareTrack.header', '')}
      bannerClassName={bannerClass}
      fullSized={false}
    >
      <div>
        <header>
          <Heading>{t('modal.shareTrack.header', '')}</Heading>
        </header>
        <CopyUrlContainer>
          <LinkWrapper>
            <a href={shareURL} target="_blank" rel="noreferrer noopener">
              {shareURL}
            </a>
          </LinkWrapper>
          <CopyToClipboard text={shareURL} onCopy={handleCopied}>
            <CopyButton>
              {copied
                ? t('modal.shareTrack.action.copiedLink', '')
                : t('modal.shareTrack.action.copyLink', '')}
            </CopyButton>
          </CopyToClipboard>
        </CopyUrlContainer>
        <TimestampCheckbox>
          <SquareCheckbox checked={timestampEnabled} onChange={handleTimestampCheckboxChange} />
          <TimestampLabel>
            {t('modal.shareTrack.startAtTickBox', '')} <span>{formatSeconds(audioProgress)}</span>
          </TimestampLabel>
        </TimestampCheckbox>
        <ShareOptions>
          <SocialShareOptions
            onShared={onShared}
            url={shareURL}
            timestampEnabled={timestampEnabled}
          />
        </ShareOptions>
      </div>
    </ClassicModalLayout>
  );
};

export default ShareSongModal;
