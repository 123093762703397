// @flow
import React from 'react';
import { css, cx } from 'emotion';
import styled from 'react-emotion';
import { get } from 'lodash';
import { GlobalHotKeys } from 'react-hotkeys';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import BookmarkButton from '../../../FullSongCard/components/SongActions/components/BookmarkButton/BookmarkButton';
import SimilarSongsButton from '../../../FullSongCard/components/SongActions/components/SimilarSongsButton/SimilarSongsButton';
import { useDownloadSong } from '../../../../hooks';
import { USER_PERMISSIONS } from '../../../../../user/subscriptions/data';
import DownloadButton from '../../../FullSongCard/components/SongActions/components/DownloadButton/DownloadButton';
import DisabledDownloadButton from '../../../FullSongCard/components/SongActions/components/DisabledDownloadButton/DisabledDownloadButton';
import { useFreeTierRestrictionToast } from '../../../../../toasts/hooks';
import {
  getSongDurationFormatted,
  getSongID,
  getSongMicroparts,
} from '../../../../../api/algolia/song';
import { useDownloadedSongsContext } from '../../../../../components/DownloadedSongsContextWrapper/DownloadedSongsContextWrapper';
import { useSubscriptionsContext } from '../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useShowSignUp } from '../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useGlobalPlayerContext } from '../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import CloseIcon from '../../../../../assets/inline-assets/close.svg';
import SongActionButton from '../../../FullSongCard/components/SongActions/components/SongActionButton/SongActionButton';
import { formatSeconds } from '../../../../../utils/time';
import { useGlobalAudioPlaybackContext } from '../GlobalAudioPlayback/GlobalAudioPlayback';
import BrowseLinkButton from './components/BrowseLinkButton/BrowseLinkButton';
import ActionMenuButton from './components/ActionMenuButton/ActionMenuButton';
import {
  MEDIA_BREAKPOINTS,
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
} from '../../../../../styles/responsive';
import { isSmallDesktopDevice } from '../../../../../utils/device';
import { getPlayerKeyboardShortcuts } from '../../../../../utils/keyboardShortcuts';
import { songPlayingSelector } from '../../../../../store/globalPlayer';
import { conversionSourceAtom } from '../../../../../store/mixpanelAnalytics';
import DownloadMIDIButton from '../../../FullSongCard/components/SongActions/components/DownloadMIDIButton/DownloadMIDIButton';
import { useTranslation } from 'react-i18next';

const ControlsWrapper = styled('div')`
  width: 35%;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;

  ${MEDIA_BREAKPOINTS.maxRange1180} {
    width: 30%;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    width: 30%;
    margin-right: 10px;
  }
`;

const Control = styled('div')`
  &:not(:first-child) {
    margin-left: 5px;
  }
`;

export const Duration = styled('div')`
  &:not(:first-child) {
    margin-left: 5px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  margin-right 5px;
  color: rgba(15, 15, 20, 0.6);
  font-family: "Roboto Mono";
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 13px;
  text-align: right;

  ${MOBILE_DEVICE_BREAKPOINT} {
    display: none;
  }
`;

const hiddenMidiDownload = css`
  display: none;
`;

const GlobalPlayerActions = () => {
  const { t } = useTranslation();

  const songPlaying = useRecoilValue(songPlayingSelector);
  const { closeGlobalPlayer } = useGlobalPlayerContext();
  const { progress } = useGlobalAudioPlaybackContext();

  const {
    userRole,
    subscriptions,
    openChangeSubscriptionModal,
    selectPlan,
  } = useSubscriptionsContext();

  const [downloadSong, downloadBusy, downloadMidiBusy] = useDownloadSong(songPlaying);
  const downloadedContext = useDownloadedSongsContext();
  const showSignUp = useShowSignUp();
  const setConversionSource = useSetRecoilState(conversionSourceAtom);

  const toastUtilities = {
    subscriptions,
    openChangeSubscriptionModal,
    selectPlan,
    showSignUp,
    setConversionSource,
  };

  const freeTierRestrictionToast = useFreeTierRestrictionToast('parts', toastUtilities);

  const handleDisabledClick = () => {
    freeTierRestrictionToast();
  };

  const previousDownloadSongs = get(downloadedContext, 'downloadedSongIDs', []);
  const previousDownloadMidis = get(downloadedContext, 'downloadedMidis', []);
  const previouslyDownloadedSong = previousDownloadSongs.includes(getSongID(songPlaying));
  const previouslyDownloadedMidi = previousDownloadMidis.includes(getSongID(songPlaying));

  const disableMidi = songPlaying.audio.downloadMidi === undefined;

  const downloadButton = (() => {
    if (USER_PERMISSIONS[userRole].canDownloadAllTracks) {
      return (
        <DownloadButton
          condensed
          onClick={() => downloadSong()}
          previouslyDownloaded={previouslyDownloadedSong}
          busy={downloadBusy}
          topLabel
        />
      );
    }
    return <DisabledDownloadButton onClick={handleDisabledClick} topLabel />;
  })();

  const midiDownloadButton = (() => {
    return (
      <DownloadMIDIButton
        condensed
        onClick={() => downloadSong('MIDI')}
        previouslyDownloaded={previouslyDownloadedMidi}
        busy={downloadMidiBusy}
        topLabel
      />
    );
  })();

  const songDuration = getSongDurationFormatted(songPlaying);
  const loopsAvailable = getSongMicroparts(songPlaying).length > 0;

  const keyboardShortcutHandlers = (() => {
    return {
      close_player: closeGlobalPlayer,
      download_song: downloadSong,
    };
  })();

  return (
    <>
      <GlobalHotKeys
        keyMap={getPlayerKeyboardShortcuts('results')}
        handlers={keyboardShortcutHandlers}
        allowChanges
      />
      <ControlsWrapper>
        <Duration>{` ${formatSeconds(progress)} / ${songDuration}`}</Duration>
        {!isSmallDesktopDevice() && (
          <>
            <Control className={cx({ [hiddenMidiDownload]: disableMidi })}>
              {midiDownloadButton}
            </Control>
            <Control>
              <BookmarkButton condensed shortcutEnabled topLabel />
            </Control>
            <Control>{downloadButton}</Control>
            <Control>
              <SimilarSongsButton condensed shortcutEnabled topLabel />
            </Control>
            <Control>
              <BrowseLinkButton />
            </Control>
          </>
        )}
        <Control>
          <ActionMenuButton condensed loopsAvailable={loopsAvailable} />
        </Control>
        <Control>
          <SongActionButton
            condensed
            icon={<CloseIcon />}
            label={t('general.button.close', '')}
            onClick={closeGlobalPlayer}
            topLabel
            rightLabel
          />
        </Control>
      </ControlsWrapper>
    </>
  );
};

export default GlobalPlayerActions;
