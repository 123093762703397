// @flow
import React from 'react';
import { css } from 'emotion';
import styled from 'react-emotion';
import { LARGE_DEVICE_BREAKPOINT, SMALL_DEVICE_BREAKPOINT } from '../../../../styles/responsive';
import Button, { BUTTON_WIDTHS, PlainTextButton } from '../../../Button/Button';
import { useTranslation } from 'react-i18next';

const Footer = styled('footer')`
  margin-top: 20px;

  ${LARGE_DEVICE_BREAKPOINT} {
    display: flex;
    align-items: center;
  }
`;

const SubmitWrapper = styled('div')`
  button {
    background-color: #4d4d4d;
  }

  ${LARGE_DEVICE_BREAKPOINT} {
    margin-right: 20px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 20px;
  }
`;

const signInButtonLayout = css`
  background-color: #4d4d4d;
`;

type Props = {
  isSubmitting: boolean,
  onForgot: () => void,
};

const SignInFormMainOptions = ({ isSubmitting, onForgot, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <Footer {...props}>
      <SubmitWrapper data-cy={'signInSubmit'}>
        <Button
          className={signInButtonLayout}
          mobileWidth={BUTTON_WIDTHS.full}
          type="submit"
          loading={isSubmitting}
          cyData={'signInSubmit'}
        >
          {t('general.button.signIn', '')}
        </Button>
      </SubmitWrapper>
      <div>
        <PlainTextButton thin onClick={onForgot}>
          {t('general.signIn.forgotPassword', '')}
        </PlainTextButton>
      </div>
    </Footer>
  );
};

export default SignInFormMainOptions;
