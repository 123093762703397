// @flow
import React from 'react';
import styled from 'react-emotion';
import { ErrorMessage } from 'formik';
import { RoundedInput } from '../forms/inputs/RoundedInput';
import { LARGE_DEVICE_BREAKPOINT } from '../../styles/responsive';
import { useModalsContext } from '../../modals/components/ModalsWrapper/ModalsWrapper';
import SignInFormFunctionality from './components/SignInFormFunctionality/SignInFormFunctionality';
import DisplayErrorMessage from '../DisplayErrorMessage/DisplayErrorMessage';
import Auth0ErrorHandler from './components/Auth0ErrorHandler/Auth0ErrorHandler';
import SignInFormMainOptions from './components/SignInFormMainOptions/SignInFormMainOptions';
import { useTranslation } from 'react-i18next';

const InputWrapper = styled('div')`
  ${LARGE_DEVICE_BREAKPOINT} {
    max-width: 400px;
  }

  &:not(:first-child) {
    margin-top: 10px;
  }
`;

type Props = {
  onForgot: () => void,
  beforeAuth?: () => void,
};

const SignInForm = ({ onForgot, beforeAuth }: Props) => {
  const { t } = useTranslation();
  const { showForgotPasswordModal } = useModalsContext();

  return (
    <SignInFormFunctionality beforeAuth={beforeAuth}>
      {formProps => {
        const { errors, values, isSubmitting, handleChange, handleBlur, handleSubmit } = formProps;
        const handleForgot = () => {
          onForgot();
          showForgotPasswordModal(values.email);
        };
        return (
          <form onSubmit={handleSubmit}>
            <div>
              {errors && errors.auth0 && (
                <DisplayErrorMessage dataCy="authError">
                  <Auth0ErrorHandler error={errors.auth0} />
                </DisplayErrorMessage>
              )}
              <ErrorMessage name="email" component={DisplayErrorMessage} dataCy="usernameError" />
              <ErrorMessage
                name="password"
                component={DisplayErrorMessage}
                dataCy="passwordError"
              />
              <InputWrapper>
                <RoundedInput
                  id="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  type="email"
                  placeholder={t('general.text.emailAddress', '')}
                  data-cy="usernameInput"
                />
              </InputWrapper>
              <InputWrapper>
                <RoundedInput
                  id="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  type="password"
                  placeholder={t('general.text.password', '')}
                  data-cy="passwordInput"
                />
              </InputWrapper>
            </div>
            <SignInFormMainOptions isSubmitting={isSubmitting} onForgot={handleForgot} />
          </form>
        );
      }}
    </SignInFormFunctionality>
  );
};

export default SignInForm;
