// @flow
import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import DownArrowIcon from '../../../../../../../assets/inline-assets/down-arrow-icon.svg';
import { interactiveButtonCss } from '../../../../../../../components/Button/Button';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../../../styles/responsive';
import {
  monoNavCss,
  regularFontWeightCss,
} from '../../../../../../../styles/typography/typography';
import { useTranslation } from 'react-i18next';

type Props = {
  tags: Array<string>,
  sortValue: string,
  sort: (event: any) => void,
};

const color = `#d8d6d2`;

const textCss = css`
  ${monoNavCss};
  ${regularFontWeightCss};
`;

const Container = styled('div')`
  ${textCss};
  display: flex;
  ${SMALL_DEVICE_BREAKPOINT} {
    position: relative;
    top: 0px;
  }
`;

const SelectWrapper = styled('div')`
  position: relative;

  svg {
    position: absolute;
    top: 8px;
    right: 6px;
    display: block;
    pointer-events: none;
    opacity: 0.4;
  }
`;

const Select = styled('select')`
  ${interactiveButtonCss};
  font: inherit;
  color: inherit;
  ${textCss};
  margin: 0;
  border: 0;
  box-shadow: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  text-align: left;
  padding: 0;
  background-color: ${color};
  border-right: 2px solid ${color};
  border-left: 7px solid ${color};
  min-width: 60px;
  box-sizing: border-box;
`;

const SortSelect = styled(Select)`
  min-width: 150px;
`;

const CollectionsFilter = ({ tags, sortValue, sort }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <SelectWrapper>
        <SortSelect value={sortValue} onChange={sort}>
          <option value="all" key="all">
            {t('general.button.viewAll')}
          </option>
          {tags.map(t => {
            return (
              <option value={t} key={t}>
                {t}
              </option>
            );
          })}
        </SortSelect>
        <DownArrowIcon />
      </SelectWrapper>
    </Container>
  );
};

export default CollectionsFilter;
