// @flow
import React from 'react';
import styled from 'react-emotion';
import { FacebookShareButton, TumblrShareButton, TwitterShareButton } from 'react-share';
import { css } from 'emotion';
import { buttonResetCss } from '../../../../../components/Button/Button';
import { maxQuery, minQuery } from '../../../../../styles/responsive';
import { monoFontCss } from '../../../../../styles/typography/typography';
import { analyticsShareSource, useSongAnalyticsDimensions } from '../../../../../analytics/events';
import { SmallDeviceOnly } from '../../../../../components/responsive/SmallDeviceOnly';
import { useAnalyticsMixpanelContext } from '../../../../../analytics/components/MixpanelWrapper';
import {
  analyticsMixpanelSongShare,
  useMixpanelSongAnalyticsDimensions,
} from '../../../../../analytics/mixpanel';
import { useDownloadContext } from '../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import { useSubscriptionsContext } from '../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useUserId } from '../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useTranslation } from 'react-i18next';

const Container = styled('div')`
  display: flex;

  ${maxQuery(500)} {
    justify-content: space-between;
  }
`;

const ShareContainer = styled('div')`
  ${minQuery(500)} {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

const optionClass = css`
  ${buttonResetCss};
  ${monoFontCss};
  display: block;
  width: 60px;
  font-size: 10px;
  letter-spacing: 0.5px;
  text-align: center;
  text-decoration: none;

  ${minQuery(500)} {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

const Option = styled('button')`
  ${optionClass};
`;

const OptionIcon = styled('div')`
  width: 60px;
  height: 60px;
  margin-bottom: 5px;
  border: 1px solid rgba(240, 240, 235, 0.05);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
  }
`;

export const FacebookIcon = () => (
  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.896 0H1.104C.494 0 0 .494 0 1.104v17.792C0 19.506.494 20 1.104 20h9.579v-7.745H8.076V9.237h2.607V7.01c0-2.584 1.577-3.99 3.882-3.99 1.104 0 2.052.082 2.329.119v2.7h-1.598c-1.254 0-1.496.595-1.496 1.47v1.927h2.989l-.39 3.018h-2.6V20h5.097c.61 0 1.104-.494 1.104-1.104V1.104C20 .494 19.506 0 18.896 0"
      fill="#0F0F14"
      fillRule="evenodd"
    />
  </svg>
);

export const XIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 1200 1227"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
      fill="#0F0F14"
    />
  </svg>
);

export const TumblrIcon = () => (
  <svg width="11" height="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.754 20c-2.92 0-5.098-1.547-5.098-5.25V8.822H0v-3.21C2.923 4.83 4.144 2.241 4.286 0h3.033v5.09h3.54v3.732h-3.54v5.162c0 1.548.758 2.083 1.967 2.083H11V20H7.754"
      fill="#0F0F14"
      fillRule="evenodd"
    />
  </svg>
);

export const MessengerIcon = () => (
  <svg width="22" height="23" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 0C4.949 0 0 4.59 0 10.283c0 3.112 1.498 5.875 3.826 7.756v4.275l.703-.374 3.452-1.793c.964.258 1.967.418 3.019.418 6.051 0 11-4.59 11-10.282C22 4.59 17.051 0 11 0zm0 .957c5.57 0 10.043 4.188 10.043 9.326 0 5.137-4.474 9.326-10.043 9.326-1.035 0-2.036-.15-2.974-.419l-.18-.045-.164.09-2.9 1.51v-3.139l-.179-.135c-2.23-1.716-3.646-4.3-3.646-7.188C.957 5.145 5.43.957 11 .957zm-1.091 6.56l-5.754 6.098 5.171-2.899 2.765 2.96 5.68-6.158-5.037 2.824-2.825-2.824z"
      fill="#0F0F14"
      fillRule="nonzero"
    />
  </svg>
);

export const EmailIcon = () => (
  <svg width="24" height="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.482 15.932l7.126-7.126L12 11.23l3.392-2.423 7.126 7.126A2.003 2.003 0 0 1 22 16H2c-.18 0-.353-.024-.518-.068zM.068 14.518A2.003 2.003 0 0 1 0 14V2.657l6.958 4.97-6.89 6.891zm23.864 0l-6.89-6.89L24 2.658V14c0 .18-.024.353-.068.518zm-.5-13.913L12 8.77.567.605A1.994 1.994 0 0 1 2 0h20c.562 0 1.07.232 1.433.605z"
      fill="#0F0F14"
      fillRule="evenodd"
    />
  </svg>
);

type Props = {
  url: string,
  onShared: () => void,
  timestampEnabled: boolean,
};

const SocialShareOptions = ({ url, onShared, timestampEnabled }: Props) => {
  const { t } = useTranslation();

  const dimensions = useSongAnalyticsDimensions();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const mixpanelSongDimensions = useMixpanelSongAnalyticsDimensions();
  const downloadContext = useDownloadContext();
  const { userRole } = useSubscriptionsContext();
  const userId = useUserId();

  const emailHref = `mailto:?body=${url}`;

  const handleShareMessenger = () => {
    analyticsShareSource('Messenger', dimensions);
    window.open(`fb-messenger://share?link=${encodeURIComponent(url)}`);
    onShared();
  };

  const handleonShare = (destination: string) => {
    analyticsShareSource(destination, dimensions);
    onShared();
  };

  const sendShareMixpanelEvent = (destination: string) => {
    analyticsMixpanelSongShare(
      mixpanel,
      moengage,
      mixpanelSongDimensions,
      downloadContext,
      userRole,
      destination,
      timestampEnabled,
      userId
    );
  };

  return (
    <Container>
      <ShareContainer
        onClick={() => {
          sendShareMixpanelEvent('Facebook');
        }}
      >
        <FacebookShareButton
          url={url}
          className={optionClass}
          onClick={() => {
            handleonShare('Facebook');
          }}
        >
          <OptionIcon>
            <FacebookIcon />
          </OptionIcon>
          <div>{t('general.sns.facebook', '')}</div>
        </FacebookShareButton>
      </ShareContainer>
      <ShareContainer
        onClick={() => {
          sendShareMixpanelEvent('Twitter');
        }}
      >
        <TwitterShareButton
          url={url}
          className={optionClass}
          onClick={() => {
            handleonShare('Twitter');
          }}
        >
          <OptionIcon>
            <XIcon />
          </OptionIcon>
          <div>{t('general.sns.twitter', '')}</div>
        </TwitterShareButton>
      </ShareContainer>
      <ShareContainer
        onClick={() => {
          sendShareMixpanelEvent('Tumblr');
        }}
      >
        <TumblrShareButton
          url={url}
          className={optionClass}
          onClick={() => {
            handleonShare('Tumblr');
          }}
        >
          <OptionIcon>
            <TumblrIcon />
          </OptionIcon>
          <div>{t('general.sns.tumblr', '')}</div>
        </TumblrShareButton>
      </ShareContainer>
      <SmallDeviceOnly>
        <ShareContainer
          onClick={() => {
            sendShareMixpanelEvent('Messenger');
          }}
        >
          <Option onClick={handleShareMessenger}>
            <OptionIcon>
              <MessengerIcon />
            </OptionIcon>
            <div>{t('general.sns.messenger', '')}</div>
          </Option>
        </ShareContainer>
      </SmallDeviceOnly>
      <a
        href={emailHref}
        className={optionClass}
        onClick={() => {
          sendShareMixpanelEvent('Email');
          handleonShare('Email');
        }}
      >
        <OptionIcon>
          <EmailIcon />
        </OptionIcon>
        <div>{t('modal.shareTrack.email', '')}</div>
      </a>
    </Container>
  );
};

export default SocialShareOptions;
