/* eslint-disable no-underscore-dangle */
// @flow
import React from 'react';
import { ROUTES } from '../../routes';
import { useLocalePath } from '../LocaleWrapper/LocaleWrapper';
import { MusicNavLink } from '../SiteNavLinks/SiteNavLinks';
import { useTranslation } from 'react-i18next';

type Props = {
  children: any,
};

const MusicNavLinks = ({ children }: Props) => {
  const { t } = useTranslation();

  const localePath = useLocalePath();

  const links = [
    props => (
      <MusicNavLink to={ROUTES.genres.navigatePath({ localePath })} {...props}>
        {t('general.text.genre', '')}
      </MusicNavLink>
    ),
    props => (
      <MusicNavLink to={ROUTES.moods.navigatePath({ localePath })} {...props}>
        {t('general.text.mood', '')}
      </MusicNavLink>
    ),
    props => (
      <MusicNavLink to={ROUTES.instruments.navigatePath({ localePath })} {...props}>
        {t('general.text.instruments', '')}
      </MusicNavLink>
    ),
    props => (
      <MusicNavLink to={ROUTES.collections.navigatePath({ localePath })} {...props}>
        {t('general.text.collections', '')}
      </MusicNavLink>
    ),
    props => (
      <MusicNavLink to={ROUTES.sfx.navigatePath({ localePath })} {...props}>
        {t('general.text.soundEffects', '')}
      </MusicNavLink>
    ),
  ];
  return children(links);
};

export default MusicNavLinks;
