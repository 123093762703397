import React from 'react';
import { getSongMixType } from '../../../../../api/algolia/song';
import DropdownOptionSelect from '../../../../../components/DropdownOptionSelect/DropdownOptionSelect';
import { filterUniqueFilterOptions } from '../../../../../utils/loops';
import type { AlgoliaSongMdl } from '../../../../../api/algolia/song';
import { useTranslation } from 'react-i18next';

type Props = {
  loops: AlgoliaSongMdl,
  currentMixType: string,
  handleChange: (mixType: string) => void,
};

const LoopsMixTypeSelector = ({ loops, currentMixType, handleChange }: Props) => {
  const { t } = useTranslation();

  const loopTypes = loops.map(song => {
    return getSongMixType(song);
  });

  const allOptions = filterUniqueFilterOptions(t('general.text.allLoops', ''), loopTypes);
  const selectedFilterIndex = allOptions.findIndex(option => {
    return option.value === currentMixType;
  });

  const handleSelect = (event: any) => {
    handleChange(event.target.value);
  };

  return (
    <DropdownOptionSelect
      handleSelect={handleSelect}
      options={allOptions}
      selectedOption={allOptions[selectedFilterIndex]}
    />
  );
};

export default LoopsMixTypeSelector;
