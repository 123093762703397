// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import Modal, { fullSizeModalCss, fullSizeModalOverlayCss } from '../Modal/Modal';
import { darkColor, evokeBorderColor } from '../../../styles/config/colors';
import { boldFontWeightCss } from '../../../styles/typography/typography';
import { DarkCompactButton } from '../../../components/Button/Button';
import MobileFiltersList from '../../../routing/screens/ResultsScreen/components/ResultsView/components/ResultsAside/components/ResultsFilters/components/MobileFiltersList/MobileFiltersList';
import {
  getFilterMenuType,
  FILTER_TYPES,
} from '../../../routing/screens/ResultsScreen/components/ResultsView/components/ResultsAside/components/ResultsFilters/data';
import { getFilterLabel } from '../../../api/algolia/data';
import BackIcon from '../../../assets/inline-assets/back-arrow.svg';
import FilterSelectMenu from '../../../routing/screens/MusicScreen/views/SearchView/components/SearchFilters/components/FilterSelectMenu/FilterSelectMenu';
import FilterRange from '../../../routing/screens/MusicScreen/views/SearchView/components/SearchFilters/components/FilterRange/FilterRange';
import FilterBooleanMenu from '../../../routing/screens/MusicScreen/views/SearchView/components/SearchFilters/components/FilterBooleanMenu/FilterBooleanMenu';
import HiddenFilterMenu from '../../../routing/screens/MusicScreen/views/SearchView/components/SearchFilters/components/HiddenFilterMenu/HiddenFilterMenu';
import { useTranslation } from 'react-i18next';

const Container = styled('div')`
  margin: 10px;
  width: 95%;
  height: 98%;
  border: solid ${evokeBorderColor};
  border-radius: 3px;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  box-shadow: 0 10px 30px 0 rgba(255, 255, 255, 0.1);
}
`;

const ModalNav = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${transparentize(0.7, '#4d4d4d')};
  padding: 10px 5px 10px 15px;
`;

const BackButtonContainer = styled('div')`
  display: flex;
  align-items: ceter;
  width: 20%;
  padding-left: 4px;
`;

const BackButton = styled('button')`
  background-color: transparent;
  border: none;
  color: ${darkColor};
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0px;
`;

const Title = styled('div')`
  ${boldFontWeightCss}
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
`;

const DoneButtonContainer = styled('div')`
  width: 20%;
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
`;

const DoneButton = styled(DarkCompactButton)`
  min-width: 60px;
  height: 29px;
  color: ${darkColor};
  font-family: 'Roboto Mono';
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1.67px;
  line-height: 0px;
  text-align: center;
`;

const MenuView = styled('div')``;

interface IMobileResultsFiltersModalProps {
  onClose: () => void;
  appliedFilters: any;
  currentSongData: any;
  resultFacetStats: any;
  handleClearSongFilters?: any;
  resultFacets: any;
}

const MobileResultsFiltersModal = ({
  onClose,
  appliedFilters,
  currentSongData,
  resultFacetStats,
  resultFacets,
}: IMobileResultsFiltersModalProps) => {
  const { t } = useTranslation();

  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedfilterType, setSelectedFilterType] = useState(null);

  const handleSelectFilterMenu = (facetKey: string) => {
    const filterType = getFilterMenuType(facetKey);
    setSelectedFilterType(filterType);
    setSelectedFilter(facetKey);
  };

  const handleBackNav = () => {
    setSelectedFilterType(null);
    setSelectedFilter(null);
  };
  const filterHeader = selectedFilter
    ? t('resultsPage.text.filterHeader', '')
    : t('resultsPage.text.filtersTitle', '');

  return (
    <Modal
      open
      onClose={onClose}
      className={fullSizeModalCss}
      overlayClassName={fullSizeModalOverlayCss}
      shouldReturnFocusAfterClose={false}
    >
      <Container>
        <ModalNav>
          <BackButtonContainer>
            {selectedFilter && (
              <BackButton
                onClick={() => {
                  handleBackNav();
                }}
              >
                <BackIcon />
              </BackButton>
            )}
          </BackButtonContainer>
          <Title>
            <h6>
              {selectedFilter
                ? filterHeader.replace('$FACET_TYPE$', getFilterLabel(selectedFilter, t))
                : filterHeader}
            </h6>
          </Title>
          <DoneButtonContainer>
            <DoneButton onClick={onClose}>{t('general.button.done')}</DoneButton>
          </DoneButtonContainer>
        </ModalNav>
        <MenuView>
          <>
            {!selectedFilter && (
              <MobileFiltersList
                facetFilters={resultFacets}
                appliedFilters={appliedFilters}
                handleSelectFilterMenu={handleSelectFilterMenu}
                selectedFilter={selectedFilter}
              />
            )}
            {selectedFilter && selectedfilterType === FILTER_TYPES.filterSelect && (
              <FilterSelectMenu
                selectedFilter={selectedFilter}
                resultFacets={resultFacets}
                appliedFilters={appliedFilters}
                currentSongData={currentSongData}
              />
            )}
            {selectedFilter && selectedfilterType === FILTER_TYPES.filterRange && (
              <FilterRange
                selectedFilter={selectedFilter}
                resultFacetStats={resultFacetStats}
                appliedFilters={appliedFilters}
                currentSongData={currentSongData}
              />
            )}
            {selectedFilter && selectedfilterType === FILTER_TYPES.filterBoolean && (
              <FilterBooleanMenu
                selectedFilter={selectedFilter}
                resultFacets={resultFacets}
                appliedFilters={appliedFilters}
                currentSongData={currentSongData}
                trueLabel={t('resultsPage.text.stemsFilter', '')}
                falseLabel={t('resultsPage.text.fullMixesFilter', '')}
              />
            )}
            {selectedFilter && selectedfilterType === FILTER_TYPES.filterInternalHidden && (
              <HiddenFilterMenu />
            )}
          </>
        </MenuView>
      </Container>
    </Modal>
  );
};

export default MobileResultsFiltersModal;
