// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { GlobalHotKeys } from 'react-hotkeys';
import { useRecoilValue } from 'recoil';
import SongActionButton from '../../../../../FullSongCard/components/SongActions/components/SongActionButton/SongActionButton';
import PlaylistIcon from '../../../../../../../assets/inline-assets/playlist-icon.svg';
import { useGlobalPlayerContext } from '../../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { capitalize } from '../../../../../../../utils/text';
import { getPlayerKeyboardShortcuts } from '../../../../../../../utils/keyboardShortcuts';
import {
  playbackListTypeAtom,
  playbackListSlugAtom,
  playbackListNameAtom,
} from '../../../../../../../store/globalPlayer';
import { useTranslation } from 'react-i18next';
import { isSearchIndexSwitcherEnabled } from '../../../../../../../utils/env';

const BrowseLinkContainer = styled('div')`
  position: relative;
`;

const BrowseLinkButton = () => {
  const [hover, setHover] = useState(false);
  const { t } = useTranslation();

  const playbackListType = useRecoilValue(playbackListTypeAtom);
  const playbackListName = useRecoilValue(playbackListNameAtom);
  const playbackListSlug = useRecoilValue(playbackListSlugAtom);
  const { navigateToPlaybackList } = useGlobalPlayerContext();

  const handleHover = () => {
    setHover(!hover);
  };

  const getFindMusicSearchButton = (): string => {
    const findMusicSearchButtonText = t('findMusicPage.searchButton', '');

    if (isSearchIndexSwitcherEnabled() && window.sessionStorage.getItem('stgidx')) {
      return findMusicSearchButtonText + '(stg)';
    }

    return findMusicSearchButtonText;
  };

  const linkLabel = (() => {
    if (playbackListType === 'search') {
      return getFindMusicSearchButton();
    }

    if (playbackListType === 'similar') {
      return t('resultsPage.text.similarSongsHeader', '');
    }

    if (playbackListType === 'collection' || playbackListType === 'artist') {
      return playbackListName;
    }

    if (playbackListType === 'bookmarks') {
      return t('general.button.bookmark', '');
    }

    if (playbackListType === 'downloads') {
      return t('general.text.downloadHistory', '');
    }

    if (playbackListType === 'pack') {
      return t('general.button.pack', '');
    }

    return capitalize(playbackListSlug);
  })();

  const keyboardShortcutHandlers = (() => {
    return {
      navigate_playlist: navigateToPlaybackList,
    };
  })();

  return (
    <>
      <GlobalHotKeys
        keyMap={getPlayerKeyboardShortcuts('results')}
        handlers={keyboardShortcutHandlers}
        allowChanges
      />
      <BrowseLinkContainer onMouseEnter={handleHover} onMouseLeave={handleHover}>
        <SongActionButton
          condensed
          icon={<PlaylistIcon />}
          label={`♪ ${linkLabel}`}
          onClick={navigateToPlaybackList}
          topLabel
        />
      </BrowseLinkContainer>
    </>
  );
};

export default BrowseLinkButton;
