// @flow
import React from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { css, cx } from 'emotion';
import NextArrowIcon from '../../../../../../../assets/inline-assets/next-arrow-icon.svg';
import NextArrowLongIcon from '../../../../../../../assets/inline-assets/next-arrow-long-icon.svg';
import PrevArrowIcon from '../../../../../../../assets/inline-assets/prev-arrow-icon.svg';
import PrevArrowLongIcon from '../../../../../../../assets/inline-assets/prev-arrow-long-icon.svg';
import { buttonResetCss } from '../../../../../../../components/Button/Button';
import { smallHeadingCss } from '../../../../../../../styles/typography/components/Heading/Heading';
import { lightColor } from '../../../../../../../styles/config/colors';
import {
  MEDIA_BREAKPOINTS,
  MOBILE_DEVICE_BREAKPOINT,
} from '../../../../../../../styles/responsive';
import { useResultsManagerContext } from '../ResultsManager/ResultsManager';
import { useTranslation } from 'react-i18next';

const Container = styled('nav')`
  ${MEDIA_BREAKPOINTS.min1000} {
    margin-right: -15px;
  }
  ${MEDIA_BREAKPOINTS.max1000} {
    display: flex;
    margin-bottom: 5px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    align-items: flex-end;
  }
`;

const loadingClass = css`
  visibility: hidden;
  pointer-events: none;
`;

const NavButton = styled('button')`
  ${buttonResetCss};
  ${smallHeadingCss};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
  position: relative;
  transition: opacity 200ms ease-in-out;

  ${MEDIA_BREAKPOINTS.min1000} {
    min-width: 124px;

    &:not(:first-child) {
      margin-top: 20px;
    }

    svg {
      &:first-child {
        position: relative;
        left: -2px;
      }

      &:last-child {
        position: relative;
        right: 2px;
      }
    }
  }

  ${MEDIA_BREAKPOINTS.max1000} {
    padding: 15px 10px 0px 10px;

    &:last-child {
      margin-left: -1px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 4px;
    width: 50px;
    height: 50px;
    background-color: ${transparentize(0.95, lightColor)};
    border-radius: 50%;
    pointer-events: none;
    opacity: 0;
    transform: scale(0.75);
    transition: all 200ms ease-in;

    ${MEDIA_BREAKPOINTS.max1000} {
      top: 1px;
      width: 40px;
      height: 40px;
    }
  }

  &:disabled {
    opacity: 0.2;
    &::before {
      visibility: hidden;
    }
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
    &::before {
      opacity: 1;
      transform: scale(1);
      transition-duration: 250ms;
      transition-timing-function: ease-out;
    }
  }

  &:active {
    &::before {
      transform: scale(0.85);
      transition-duration: 200ms;
    }
  }

  &:first-child {
    ${MEDIA_BREAKPOINTS.max1000} {
      order: 2;
    }
    &::before {
      right: -2px;

      ${MEDIA_BREAKPOINTS.max1000} {
        right: -4px;
      }
    }
  }

  &:last-child {
    ${MEDIA_BREAKPOINTS.max1000} {
      order: 1;
    }
    &::before {
      left: -7px;

      ${MEDIA_BREAKPOINTS.max1000} {
        left: -6px;
      }
    }
  }

  span,
  svg {
    position: relative;
  }

  span {
    ${MEDIA_BREAKPOINTS.max1000} {
      display: none;
    }
  }
`;

const shortArrowClass = css`
  display: block;
  ${MEDIA_BREAKPOINTS.max1000} {
    display: none;
  }
`;

const longArrowClass = css`
  display: block;
  ${MEDIA_BREAKPOINTS.min1000} {
    display: none;
  }
`;

const ResultsNav = () => {
  const { t } = useTranslation();

  const {
    loading,
    noResults,
    nextEnabled,
    previousEnabled,
    goToNextSong,
    goToPreviousSong,
  } = useResultsManagerContext();

  return (
    <Container
      className={cx({
        [loadingClass]: loading,
      })}
    >
      <NavButton disabled={!nextEnabled || loading || noResults} onClick={goToNextSong}>
        <span>{t('resultsPage.button.nextTrack', '')}</span>
        <NextArrowIcon className={shortArrowClass} />
        <NextArrowLongIcon className={longArrowClass} />
      </NavButton>
      <NavButton disabled={!previousEnabled || loading || noResults} onClick={goToPreviousSong}>
        <PrevArrowIcon className={shortArrowClass} />
        <PrevArrowLongIcon className={longArrowClass} />
        <span>{t('resultsPage.button.prevTrack', '')}</span>
      </NavButton>
    </Container>
  );
};

export default ResultsNav;
