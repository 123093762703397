// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import {
  isCreatorSubscription,
  isCreatorProSubscription,
  isBusinessSubscription,
  isEnterpriseSubscription,
} from '../../../../../../utils/subscriptions';
import {
  businessPlanCss,
  enterprisePlanCss,
  personalPlanCss,
  guestPlanCss,
} from '../../../../../../user/subscriptions/components/PlanPreview/PlanPreview';
import { BILLING_CYCLES } from '../../../../../../user/subscriptions/data';
import {
  SMALL_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../../styles/responsive';
import EnterpriseContactModal from '../../../../../../modals/components/EnterpriseContactModal/EnterpriseContactModal';
import { useTranslation } from 'react-i18next';
import PricingPlanCard from './PricingPlanCard';
import { businessBgColor } from '../../../../../../styles/config/colors';

type Props = {
  planCode: string,
  selectedInterval: string,
  selectOption: string,
  handleIntervalChange: (string, boolean) => void,
  isNextAvailable: boolean,
  isPreviousAvailable: boolean,
  hasExternalLink?: boolean,
};

const PricingPlanContainer = styled('div')`
  width: 31.5%;
  max-width: 350px;
  min-width: 150px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 0px 15px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    margin-top: 20px;
    width: 55%;
    max-width: 600px;
    height: unset;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 100%;
  }
`;

const PlanBanner = styled('div')`
  width: 100%;
  border-radius: 5px 5px 0px 0px;
  background-size: cover;
  color: #fff;
  text-align: left;
  padding-left: 30px;
`;

const recommendBannerCss = css`
  border-radius: 0;
`;

const PlanLabel = styled('h2')`
  padding: auto;
  font-family: Roboto;
  font-size: 90%;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 41px;
`;

const PlanCapture = styled('p')`
  padding-bottom: 10px;
  font-size: 140%;
  font-weight: 800;
  line-height: 41px;
`;

const recommendPlanCss = css`
  margin-top: -39px;
  border: solid 4px ${businessBgColor};

  button {
    padding: 10px;
  }
`;

const RecommendPlanContainer = styled('div')`
  padding: 5px 30px;
`;

const RecommendPlanLabel = styled('p')`
  font-size: 90%;
  font-weight: 800;
`;

const PricingPlan = ({
  planCode,
  selectedInterval,
  selectOption,
  handleIntervalChange,
  isNextAvailable,
  isPreviousAvailable,
  hasExternalLink = false,
}: Props) => {
  const { t } = useTranslation();

  const getPricingPagePlanAvailableIntervals = [
    { interval: 'annually' },
    { interval: 'annually' },
    { interval: 'annually' },
  ];

  const availableIntervals = getPricingPagePlanAvailableIntervals;
  const planHasInterval = availableIntervals
    .map(obj => BILLING_CYCLES[obj.interval].cycle)
    .includes(selectedInterval);

  const [showEnterpriseModal, setShowEnterpriseModal] = useState(false);

  if (!isCreatorSubscription(planCode) && !planHasInterval) return null;

  const getPlanBannerLabel = planCode => {
    if (isCreatorSubscription(planCode)) {
      return t('general.planCode.member', '');
    }

    if (isCreatorProSubscription(planCode)) {
      return t('general.planCode.loyalMember', '');
    }

    if (isBusinessSubscription(planCode)) {
      return t('general.planCode.loyalMemberPlus', '');
    }

    if (isEnterpriseSubscription(planCode)) {
      return t('general.planCode.enterprise', '');
    }
  };

  const getBannerCapture = planCode => {
    if (isCreatorProSubscription(planCode)) {
      return t('pricing.plans.plan.bannerCapture.loyalMember.text', '');
    }

    if (isBusinessSubscription(planCode)) {
      return t('pricing.plans.plan.bannerCapture.loyalMemberPlus.text', '');
    }

    return '　';
  };

  return (
    <>
      <PricingPlanContainer
        className={cx({ [recommendPlanCss]: isBusinessSubscription(planCode) })}
      >
        {isBusinessSubscription(planCode) && (
          <RecommendPlanContainer>
            <RecommendPlanLabel>{t('pricing.recommendPlan.text', '')}</RecommendPlanLabel>
          </RecommendPlanContainer>
        )}
        <PlanBanner
          className={cx(
            {
              [guestPlanCss]: isCreatorSubscription(planCode),
              [personalPlanCss]: isCreatorProSubscription(planCode),
              [businessPlanCss]: isBusinessSubscription(planCode),
              [enterprisePlanCss]: isEnterpriseSubscription(planCode),
            },
            { [recommendBannerCss]: isBusinessSubscription(planCode) }
          )}
        >
          <PlanLabel>{getPlanBannerLabel(planCode)}</PlanLabel>
          <PlanCapture>{getBannerCapture(planCode)}</PlanCapture>
        </PlanBanner>

        <PricingPlanCard
          planCode={planCode}
          selectedInterval={selectedInterval}
          isPreviousAvailable={isPreviousAvailable}
          handleIntervalChange={handleIntervalChange}
          isNextAvailable={isNextAvailable}
          hasExternalLink={hasExternalLink}
          setShowEnterpriseModal={setShowEnterpriseModal}
          selectOption={selectOption}
        />
      </PricingPlanContainer>
      {showEnterpriseModal && (
        <EnterpriseContactModal
          onClose={() => {
            setShowEnterpriseModal(false);
          }}
        />
      )}
    </>
  );
};

export default PricingPlan;
