// @flow
import React from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import {
  useSubscriptions,
  useSubscriptionsContext,
} from '../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import type { UserSubscriptionMdl } from '../../../../api/firebase/user/subscriptions';
import { SUBSCRIPTIONS } from '../../data';
import { getSubscriptionPlanKey } from '../../../../api/firebase/user/subscriptions';
import GroupedSubscriptions from '../GroupedSubscriptions/GroupedSubscriptions';
import { buttonResetCss, interactiveTextButtonCss } from '../../../../components/Button/Button';
import { bodyTextCss } from '../../../../styles/typography/typography';
import { darkColor } from '../../../../styles/config/colors';
import { ICONS } from '../../../../images/paths';
import {
  isBusinessSubscription,
  isCreatorProSubscription,
  isCreatorSubscription,
  isEnterpriseSubscription,
  isEnterpriseBasicSubscription,
} from '../../../../utils/subscriptions';
import { useTranslation } from 'react-i18next';

const AddButton = styled('button')`
  ${buttonResetCss};
  ${interactiveTextButtonCss};
  ${bodyTextCss};
  border-top: 1px solid ${transparentize(0.3, '#dcdad3')};
  border-bottom: 1px solid ${transparentize(0.3, '#dcdad3')};
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 40px;
`;

const AddButtonIcon = styled('div')`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${transparentize(0.95, darkColor)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  img {
    display: block;
  }
`;

const Item = styled('div')`
  &:not(:first-child) {
    margin-top: 40px;
  }
`;

export type GroupedSubscriptionsMdl = {
  startingIndex: number,
  planKey: string,
  subscriptions: Array<UserSubscriptionMdl>,
};

const getGroupedSubscriptions = (
  subscriptions: Array<UserSubscriptionMdl>
): Array<GroupedSubscriptionsMdl> => {
  const creatorPlans = [];
  const creatorProPlans = [];
  const businessPlans = [];
  const enterprisePlans = [];
  const enterpriseBasicPlans = [];
  const groups = [];

  subscriptions.forEach((subscription: UserSubscriptionMdl) => {
    const planKey = getSubscriptionPlanKey(subscription);
    if (isCreatorSubscription(planKey)) {
      creatorPlans.push(subscription);
    } else if (isCreatorProSubscription(planKey)) {
      creatorProPlans.push(subscription);
    } else if (isBusinessSubscription(planKey)) {
      businessPlans.push(subscription);
    } else if (isEnterpriseSubscription(planKey)) {
      enterprisePlans.push(subscription);
    } else if (isEnterpriseBasicSubscription(planKey)) {
      enterpriseBasicPlans.push(subscription);
    }
  });

  if (creatorPlans.length > 0) {
    groups.push({
      startingIndex: 0,
      planKey: SUBSCRIPTIONS.creator.key,
      subscriptions: creatorPlans,
    });
  }

  if (creatorProPlans.length > 0) {
    const startingIndex = creatorPlans.length;
    groups.push({
      startingIndex,
      planKey: SUBSCRIPTIONS.creatorPro.key,
      subscriptions: creatorProPlans,
    });
  }

  if (businessPlans.length > 0) {
    const startingIndex = creatorPlans.length + creatorProPlans.length;
    groups.push({
      startingIndex,
      planKey: SUBSCRIPTIONS.business.key,
      subscriptions: businessPlans,
    });
  }

  if (enterprisePlans.length > 0) {
    const startingIndex = creatorPlans.length + creatorProPlans.length + businessPlans.length;
    groups.push({
      startingIndex,
      planKey: SUBSCRIPTIONS.enterprise.key,
      subscriptions: enterprisePlans,
    });
  }

  if (enterpriseBasicPlans.length > 0) {
    const startingIndex = creatorPlans.length + creatorProPlans.length + businessPlans.length;
    groups.push({
      startingIndex,
      planKey: SUBSCRIPTIONS.enterpriseBasic.key,
      subscriptions: enterpriseBasicPlans,
    });
  }

  return groups;
};

const UserSubscriptions = () => {
  const { t } = useTranslation();

  const subscriptions = useSubscriptions();
  const groupedSubscriptions = getGroupedSubscriptions(subscriptions);
  const { selectPlan } = useSubscriptionsContext();

  const hasEnterpriseBasicPlan =
    subscriptions.filter(
      element => getSubscriptionPlanKey(element) === SUBSCRIPTIONS.enterpriseBasic.key
    ).length >= 1;

  return (
    <div>
      <div>
        {groupedSubscriptions.map((group: GroupedSubscriptionsMdl) => {
          const { planKey, subscriptions: groupSubscriptions, startingIndex } = group;
          return (
            <Item key={planKey}>
              <GroupedSubscriptions
                groupStartingIndex={startingIndex}
                planKey={planKey}
                subscriptions={groupSubscriptions}
              />
            </Item>
          );
        })}
      </div>
      {!hasEnterpriseBasicPlan && (
        <AddButton
          onClick={() => {
            selectPlan(SUBSCRIPTIONS.creatorPro.key);
          }}
        >
          <AddButtonIcon>
            <img src={ICONS.plus} width={16} height={16} alt="plus icon" />
          </AddButtonIcon>
          <div>{t('general.text.addSubscription', '')}</div>
        </AddButton>
      )}
    </div>
  );
};

export default UserSubscriptions;
