// @flow
import React from 'react';
import { css } from 'emotion';
import SplitModalLayout, { SplitModalContentLayout } from '../SplitModalLayout/SplitModalLayout';
import Button from '../../../components/Button/Button';
import { SubscriptionModalFooter } from '../../../user/subscriptions/components/CancelSubscriptionModal/CancelSubscriptionModal';
import { isDesktopSafariBrowser } from '../../../utils/browser';
import { IMAGES } from '../../../images/paths';
import { useTranslation } from 'react-i18next';

const buttonLayout = css`
  background-color: #4d4d4d;
`;

type Props = {
  onClose: () => void,
};

const ExtensionShowcaseModal = ({ onClose }: Props) => {
  const { t } = useTranslation();

  const browser = isDesktopSafariBrowser()
    ? t('extension.safariLabel', '')
    : t('extension.chromeLabel', '');
  const downloadURL = isDesktopSafariBrowser()
    ? t('extension.safariDownloadUrl', '')
    : t('extension.downloadUrl', '');
  const imageSource = isDesktopSafariBrowser() ? IMAGES.safariExtension : IMAGES.chromeExtension;
  return (
    <SplitModalLayout onClose={onClose} imageSrc={imageSource}>
      <SplitModalContentLayout
        heading={t('extension.modal.header', '').replace('$VERSION$', browser)}
        description={t('extension.modal.description', '')}
        footer={
          <SubscriptionModalFooter>
            <Button className={buttonLayout} href={downloadURL} target="_blank">
              {t('extension.modal.link', '')}
            </Button>
          </SubscriptionModalFooter>
        }
      />
    </SplitModalLayout>
  );
};

export default ExtensionShowcaseModal;
