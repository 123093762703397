// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import { transparentize } from 'polished';
import { TextButton } from '../../../../../components/Button/Button';
import { LARGE_DEVICE_BREAKPOINT, SMALL_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import { darkColor } from '../../../../../styles/config/colors';
import { bodyTextCss, largeTextCss } from '../../../../../styles/typography/typography';
import { SmallHeading } from '../../../../../styles/typography/components/Heading/Heading';
import {
  getSongFeature,
  getSongLocation,
  getSongMaterial,
  getSongMovement,
  getSongTypeTags,
  getSongVideoTheme,
} from '../../../../../api/algolia/song';
import { useSong } from '../../../SongContextWrapper/SongContextWrapper';
import SearchLink from '../../../../../components/Link/components/SearchLink';
import { useTranslation } from 'react-i18next';

const Container = styled('section')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 25px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 9px;
  }
`;

const ToggleContainer = styled('div')`
  display: flex;
  justify-content: center;
`;

const toggleButtonClass = css`
  margin: 0 auto;
  display: inline-block;
  padding: 5px;
  width: auto;
  margin-top: -5px;
  margin-bottom: -5px;
`;

const DetailsCard = styled('div')`
  border: 1px solid rgba(15, 15, 20, 0.05);
  border-radius: 5px;
  padding: 24px 30px;
  margin-top: 45px;
  margin-bottom: 45px;
  width: 100%;
  max-width: 800px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 19px;
    margin-bottom: 20px;
    padding: 14px 10px 16px 20px;
  }
`;

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 28px;

  ${SMALL_DEVICE_BREAKPOINT} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 11px;
    grid-row-gap: 10px;

    & > div {
      padding-right: 10px;
    }
  }
`;

const Label = styled(SmallHeading)`
  color: ${transparentize(0.6, darkColor)};

  ${LARGE_DEVICE_BREAKPOINT} {
    margin-bottom: 9px;
  }
`;

const Value = styled('div')`
  ${bodyTextCss};
  text-transform: capitalize;
`;

const LargeValue = styled('div')`
  text-transform: capitalize;

  ${LARGE_DEVICE_BREAKPOINT} {
    ${largeTextCss};
  }
`;

const SfxDetails = () => {
  const { t } = useTranslation();

  const song = useSong();
  const [viewingDetails, setViewingDetails] = useState(true);

  const handleShowToggle = () => {
    setViewingDetails(!viewingDetails);
  };

  const getMovement = getSongMovement(song).join(', ');
  const movement = getMovement ? <SearchLink queryText={getMovement} /> : '-';

  const getVideoTheme = getSongVideoTheme(song).join(', ');
  const videoTheme = getVideoTheme ? <SearchLink queryText={getVideoTheme} /> : '-';

  const getLocation = getSongLocation(song).join(', ');
  const songLocation = getLocation ? <SearchLink queryText={getLocation} /> : '-';

  return (
    <Container>
      <ToggleContainer>
        <TextButton className={toggleButtonClass} onClick={handleShowToggle}>
          {viewingDetails
            ? t('general.button.hideDetails', '')
            : t('general.button.loadDetails', '')}
        </TextButton>
      </ToggleContainer>
      {viewingDetails && (
        <DetailsCard>
          <Grid>
            <div>
              <Label>{t('general.text.sfxTypes', '')}</Label>
              <LargeValue>{getSongTypeTags(song).join(', ') || '-'}</LargeValue>
            </div>
            <div>
              <Label>{t('general.text.sfxFeatures', '')}</Label>
              <LargeValue>{getSongFeature(song).join(', ') || '-'}</LargeValue>
            </div>
            <div>
              <Label>{t('general.text.materials', '')}</Label>
              <LargeValue>{getSongMaterial(song).join(', ') || '-'}</LargeValue>
            </div>

            <div>
              <Label>{t('general.text.movementSound', '')}</Label>
              <Value>{movement}</Value>
            </div>
            <div>
              <Label>{t('general.text.videoTheme', '')}</Label>
              <Value>{videoTheme}</Value>
            </div>
            <div>
              <Label>{t('general.text.location', '')}</Label>
              <Value>{songLocation}</Value>
            </div>
          </Grid>
        </DetailsCard>
      )}
    </Container>
  );
};

export default SfxDetails;
