import React from 'react';
import {
  getCuratedCollectionId,
  getCuratedCollectionIsCreatorCollection,
} from '../../../../../../cms/curatedCollection'; // TODO; コレクションのPrismic脱却対応
import { getMusicPageExpandedFeaturedCollections } from '../../../../../../cms/musicPage';
import { useMusicPage } from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import ExpandedCollection from './ExpandedCollection';

type Props = {
  showIndex?: number,
};

const ExpandedCollections = ({ showIndex = 0 }: Props) => {
  const musicPage = useMusicPage();
  // TODO; コレクションのPrismic脱却対応
  const collections = getMusicPageExpandedFeaturedCollections(musicPage);

  return (
    <>
      {collections
        .filter((colelction, index) => {
          return index === showIndex;
        })
        .filter(expandedCollection => {
          return (
            getCuratedCollectionIsCreatorCollection(expandedCollection) === true ||
            getCuratedCollectionIsCreatorCollection(expandedCollection) === null
          );
        })
        .map(collection => {
          return (
            <ExpandedCollection collection={collection} key={getCuratedCollectionId(collection)} />
          );
        })}
    </>
  );
};

export default ExpandedCollections;
