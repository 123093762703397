// @flow
import React from 'react';
import { css } from 'emotion';
import Button, { BUTTON_WIDTHS } from '../../../../../components/Button/Button';
import {
  HeaderOptionsContainer,
  HeaderOptionsInstruction,
} from '../../../SignInScreen/components/SignInHeaderOptions/SignInHeaderOptions';
import { useNavigate } from '../../../../hooks';
import { ROUTES } from '../../../../routes';
import { useTranslation } from 'react-i18next';

const signInButtonLayout = css`
  background-color: #4d4d4d;
`;

type Props = {
  path: string,
};

const SignUpHeaderOptions = ({ path }: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  if (`/${path}` === ROUTES.signup.signupPath) {
    const handleSignUp = () => {
      navigate(ROUTES.signin.navigatePath({}));
    };

    return (
      <HeaderOptionsContainer data-cy="signUpPageHeader">
        <HeaderOptionsInstruction>{t('signUpPage.prompt', '')}</HeaderOptionsInstruction>
        <Button className={signInButtonLayout} onClick={handleSignUp} width={BUTTON_WIDTHS.compact}>
          {t('general.button.signIn', '')}
        </Button>
      </HeaderOptionsContainer>
    );
  }

  if (`/${path}` === ROUTES.signupPaypal.signupPath) {
    const handleSkip = () => {
      navigate(ROUTES.signupCompleted.navigatePath({}));
    };

    return (
      <HeaderOptionsContainer>
        <Button width={BUTTON_WIDTHS.compact} onClick={handleSkip}>
          {t('general.button.skip', '')}
        </Button>
      </HeaderOptionsContainer>
    );
  }

  return null;
};

export default SignUpHeaderOptions;
