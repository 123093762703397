import React, { useEffect, useState } from 'react';
import styled, { css, cx } from 'react-emotion';
import { getSongID, getSongMixType, getSongName, isSongFullMix } from '../../../api/algolia/song';
import AudioPlayerWrapper from '../../../audio/components/AudioPlayerWrapper/AudioPlayerWrapper';
import { MOBILE_DEVICE_BREAKPOINT, SMALL_DEVICE_BREAKPOINT } from '../../../styles/responsive';
import SongTabs from './components/SongTabs/SongTabs';
import LoadMoreButton from './components/LoadMoreButton/LoadMoreButton';
import TabSong from './components/TabSong/TabSong';
import TabLoops from './components/TabLoops/TabLoops';
import LoadingDisplay from '../../../components/LoadingDisplay/LoadingDisplay';
import type { AlgoliaSongMdl } from '../../../api/algolia/song';
import {
  generateSongsTabMap,
  getTabbedLoopsCount,
  getTabbedSongsDefaultTab,
  getTabMappedSongsCount,
  getTabMappedSongsList,
  SONG_TAB_TYPES,
} from '../../../utils/songTabs';
import { PLAYER_TYPES } from '../../../song/data';
import TabbedSongListWrapper from './components/TabbedSongListWrapper/TabbedSongListWrapper';
import AudioCardWrapper from '../../../song/components/AudioCardWrapper/AudioCardWrapper';
import CondensedSongCard from '../../../song/components/CondensedSongCard/CondensedSongCard';
import { useTranslation } from 'react-i18next';

type Props = {
  songs: Array<AlgoliaSongMdl>,
  allowFullMixes?: Boolean,
  allowLoops?: boolean,
  allowSfx?: boolean,
  useFullMixName?: boolean,
  defaultTab?: string,
};

const StyledContainer = styled('section')`
  margin-top: 0px;
  margin-bottom: 100px;
  width: 100%;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
  }
`;

const SongsContainer = styled('div')`
  ${MOBILE_DEVICE_BREAKPOINT} {
    overflow-x: hidden;
  }
`;

const ListItem = styled('div')`
  position: relative;
  margin-bottom: 5px;
  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 10px;
  }
`;

const LoadingWrapper = styled('div')`
  height: 30vh;
`;

const addSidePadding = css`
  padding: 0 20px;
`;

const TabbedSongList = ({
  songs,
  allowFullMixes = true,
  allowLoops = false,
  allowSfx = true,
  useFullMixName = false,
  songListType,
  songListTitle,
  songListSlug,
  songlistKeywords = [],
  queueRestricted = false,
  restrictionType = '',
  keywords = [],
  mixpanelKeywords = [],
  magicKeywords = [],
  youtubeKeywords = [],
  youtubeVideoTitle = '',
  sectionOrigin = '',
  relatedCollection = '',
  defaultTab = SONG_TAB_TYPES.full_mixes,
}: Props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [songsMap, setSongsMap] = useState({});
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [selectedSongs, setSelectedSongs] = useState([]);
  const [selectedLoops, setSelectedLoops] = useState([]);
  const [loopsMixType, setLoopsMixType] = useState('all');
  const [displayQuantity, setDisplayQuantity] = useState(20);

  const totalSelectedSongs =
    selectedTab === SONG_TAB_TYPES.loops
      ? getTabbedLoopsCount(selectedLoops)
      : selectedSongs.length;
  const remainingSongsAvailable = totalSelectedSongs > displayQuantity;

  const playerType = (() => {
    if (selectedTab === SONG_TAB_TYPES.loops) {
      return PLAYER_TYPES.loops_loop;
    } else if (selectedTab === SONG_TAB_TYPES.sfx) {
      return PLAYER_TYPES.sfx;
    }
    return PLAYER_TYPES.mainResults;
  })();

  const mainFullMix = selectedSongs.filter(() => isSongFullMix)[0];
  const mainSongName = useFullMixName && mainFullMix ? getSongName(mainFullMix) : '';

  const handleTabClick = (tabType: string) => {
    if (selectedTab === tabType) return;
    setLoading(true);
    setDisplayQuantity(20);
    setSelectedTab(tabType);
  };

  const handleChangeMixType = (mixType: string) => {
    setLoading(true);
    setDisplayQuantity(20);
    setLoopsMixType(mixType);
  };

  const handleLoadMoreSongs = () => {
    setDisplayQuantity(displayQuantity + 20);
  };

  useEffect(() => {
    setSelectedSongs(getTabMappedSongsList(songsMap, selectedTab));

    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, [selectedTab]);

  useEffect(() => {
    const loopsCount = getTabMappedSongsCount(songsMap, SONG_TAB_TYPES.loops);
    const loopSongs = getTabMappedSongsList(songsMap, SONG_TAB_TYPES.loops);

    if (loopsCount === 0) {
      setSelectedLoops([]);
      return;
    }

    if (loopsMixType !== 'all') {
      const filteredSongs = loopSongs.filter(loopSong => {
        return getSongMixType(loopSong) === loopsMixType;
      });
      setSelectedLoops(filteredSongs);
    } else {
      setSelectedLoops(loopSongs);
    }

    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, [loopsMixType, songsMap]);

  useEffect(() => {
    const updateMap = generateSongsTabMap(songs, allowFullMixes, allowLoops, allowSfx);
    setSongsMap(updateMap);
  }, [songs]);

  useEffect(() => {
    const updatedTab = getTabbedSongsDefaultTab(songsMap, defaultTab);
    setSelectedTab(updatedTab);
  }, [songsMap]);

  return (
    <TabbedSongListWrapper
      songs={selectedSongs}
      songListType={songListType}
      songListTitle={songListTitle}
      songListSlug={songListSlug}
      songlistKeywords={songlistKeywords}
      queueRestricted={queueRestricted}
      restrictionType={restrictionType}
      keywords={keywords}
      mixpanelKeywords={mixpanelKeywords}
      magicKeywords={magicKeywords}
      youtubeKeywords={youtubeKeywords}
      youtubeVideoTitle={youtubeVideoTitle}
      sectionOrigin={sectionOrigin}
      relatedCollection={relatedCollection}
    >
      <StyledContainer
        className={cx({
          [addSidePadding]: songListType === 'collection' || songListType === 'artist',
        })}
      >
        <SongTabs
          activeTab={selectedTab}
          activeLoopsMixType={loopsMixType}
          songsMap={songsMap}
          handleClick={handleTabClick}
          handleMixTypeChange={handleChangeMixType}
        />
        {loading ? (
          <LoadingWrapper>
            <LoadingDisplay />
          </LoadingWrapper>
        ) : (
          <SongsContainer>
            {playerType === PLAYER_TYPES.loops_loop && (
              <AudioPlayerWrapper>
                {selectedLoops.map((song, index) => {
                  const songName = useFullMixName ? mainSongName : getSongName(song, false);
                  return (
                    <TabLoops
                      key={getSongID(song)}
                      song={song}
                      index={index}
                      songName={songName}
                      selectedLoops={selectedLoops}
                      displayQuantity={displayQuantity}
                      loopsMixType={loopsMixType}
                    />
                  );
                })}
              </AudioPlayerWrapper>
            )}
            {playerType === PLAYER_TYPES.sfx && (
              <AudioPlayerWrapper>
                {selectedSongs.slice(0, displayQuantity).map(song => {
                  return (
                    <AudioCardWrapper
                      autoload
                      song={song}
                      key={getSongID(song)}
                      mainAudioCard={false}
                      playerType={playerType}
                    >
                      <ListItem>
                        <CondensedSongCard song={song} onExpand={() => {}} />
                      </ListItem>
                    </AudioCardWrapper>
                  );
                })}
              </AudioPlayerWrapper>
            )}
            {playerType !== PLAYER_TYPES.loops_loop && playerType !== PLAYER_TYPES.sfx && (
              <>
                {selectedSongs.slice(0, displayQuantity).map((song, index) => {
                  return <TabSong song={song} index={index} key={getSongID(song)} />;
                })}
              </>
            )}
            <LoadMoreButton
              label={t('general.button.loadMore', '')}
              handleClick={handleLoadMoreSongs}
              show={remainingSongsAvailable}
            />
          </SongsContainer>
        )}
      </StyledContainer>
    </TabbedSongListWrapper>
  );
};

export default TabbedSongList;
