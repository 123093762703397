// @flow
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../../../../../../modals/components/ClassicModalLayout/ClassicModalLayout';
import { useYouTubeAPIHandler } from '../../YouTubeAPIHandler';
import Button, { BUTTON_WIDTHS } from '../../../../../../components/Button/Button';
import GenericCopy from '../../../../../../styles/typography/components/GenericCopy/GenericCopy';
import LoadingDisplay from '../../../../../../components/LoadingDisplay/LoadingDisplay';
import { useSubscriptionsContext } from '../../../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import {
  YouTubeChannelAlreadyConnectedError,
  YouTubeChannelDuplicateRequestError,
  YouTubeChannelNoAvaliableChannelError,
} from '../../../../../../errors/errors';
import { lightColor } from '../../../../../../styles/config/colors';
import { getFormattedCount } from '../../../AddedSubscription/components/YouTubeChannelSearch/YouTubeChannelSearch';
import { useTranslation } from 'react-i18next';

const ErrorContainer = styled('div')`
  margin-top: 20px;
`;

const ChannelCard = styled('div')`
  display: flex;
  flex-direction: row;
  border-top: 0.5px solid ${transparentize(0.9, lightColor)};
  border-bottom: 0.5px solid ${transparentize(0.9, lightColor)};
  margin: 43px 0px 32px 0px;
  padding: 10px 0px;
`;

const YouTubeChannelIcon = styled('div')`
  display: flex;
  align-items: center;
  margin: 0px 8px;

  img {
    border-radius: 100%;
    height: 50px;
    width: 50px;
  }
`;

const YouTubeChannelInfo = styled('div')`
  display: flex;
  flex-direction: column;
  line-height: 20px;
`;

const YouTubeChannelName = styled('p')`
  font-size: 14px;
  margin-top: 5px;
`;

const YouTubeChannelDetailsWrapper = styled('div')`
  font-size: 10px;
  color: ${transparentize(0.5, lightColor)};
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 3px;

  p {
    word-spacing: 5px;

    span {
      font-size: 8px;
    }
  }
`;

const DuplicateErrorCopy = styled('div')`
  padding-right: 160px;
`;

const ActionsWrapper = styled('div')`
  margin-top: 40px;
`;

const CancelButton = styled(Button)`
  background-color: ${transparentize(0.95, lightColor)};
  color: ${lightColor};
  margin-right: 10px;
`;

const SupportButton = styled(Button)`
  padding: 0px;

  span {
    width: 100%;
    h6 {
      a {
        display: block;
        padding: 10px 15px;
        text-decoration: none;
      }
    }
  }
`;

const RetryWrapper = styled('div')`
  margin-top: 20px;
`;

const LoadingContainer = styled('div')`
  height: 200px;
`;

const ErrorDisplay = ({
  busy,
  error,
  retry,
  channelInfo,
  onClose,
}: {
  busy: boolean,
  error: any,
  retry: () => void,
  channelInfo: any,
  onClose: () => void,
}) => {
  const { t } = useTranslation();

  const alreadyConnected = error instanceof YouTubeChannelAlreadyConnectedError;
  const duplicateRequest = error instanceof YouTubeChannelDuplicateRequestError;
  const noAvailableChannel = error instanceof YouTubeChannelNoAvaliableChannelError;

  // eslint-disable-next-line no-nested-ternary
  const message = alreadyConnected
    ? t('modal.connectYouTubeChannel.alreadyConnect.message', '')
    : duplicateRequest
    ? t('modal.connectYouTubeChannel.duplicateRequestMessage', '')
    : noAvailableChannel
    ? t('modal.connectYouTubeChannel.emptyChannelErrorMessage', '')
    : t('modal.connectYouTubeChannel.errorMessage', '');

  const buttonText =
    alreadyConnected || duplicateRequest
      ? t('modal.connectYouTubeChannel.alreadyConnect.retryButton', '')
      : t('modal.connectYouTubeChannel.retryButton', '');

  if (alreadyConnected || duplicateRequest) {
    return (
      <>
        <ChannelCard>
          <YouTubeChannelIcon>
            <img src={channelInfo.thumbnail} />
          </YouTubeChannelIcon>
          <YouTubeChannelInfo>
            <YouTubeChannelName>{channelInfo.title}</YouTubeChannelName>
            <YouTubeChannelDetailsWrapper>
              <p>
                {t('subscriptionsPage.youtubeConnection.channelDetails', '')
                  .replace('$SUBSCRIBERCOUNT$', getFormattedCount(channelInfo.subscriberCount))
                  .replace('$DOT$', parse('•'))
                  .replace('$VIDEOCOUNT$', getFormattedCount(channelInfo.videoCount))}
              </p>
            </YouTubeChannelDetailsWrapper>
          </YouTubeChannelInfo>
        </ChannelCard>
        <DuplicateErrorCopy>{alreadyConnected ? parse(message) : message}</DuplicateErrorCopy>
        <ActionsWrapper>
          <CancelButton onClick={onClose}>{t('general.button.cancel', '')}</CancelButton>
          <SupportButton>
            <a href={t('modal.connectYouTubeChannel.contactUs.link', '')} target="_blank">
              {t('modal.connectYouTubeChannel.contactUs.text', '')}
            </a>
          </SupportButton>
        </ActionsWrapper>
      </>
    );
  }

  return (
    <ErrorContainer>
      <GenericCopy>{parse(message)}</GenericCopy>
      <RetryWrapper>
        <Button mobileWidth={BUTTON_WIDTHS.full} loading={busy} onClick={retry}>
          {buttonText}
        </Button>
      </RetryWrapper>
    </ErrorContainer>
  );
};

type Props = {
  subscriptionKey: string,
  planKey: string,
  channelInfo?: any,
  isPrimaryChannel?: boolean,
  extraYouTubeChannels?: Array<any>,
  onClose: () => void,
};

const YouTubeChannelModal = ({
  subscriptionKey,
  planKey,
  channelInfo,
  isPrimaryChannel,
  extraYouTubeChannels,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const [busy, setBusy] = useState(false);
  const [error, setError] = useState();

  const { fetchChannels, fetchChannelFromChannelUrl } = useYouTubeAPIHandler();
  const {
    addYouTubeChannelToSubscription,
    addExtraYouTubeChannelToSubscription,
  } = useSubscriptionsContext();

  const handleClose = () => {
    if (busy) return;
    onClose();
  };

  const selectChannel = () => {
    if (busy) {
      return;
    }

    setBusy(true);
    setError(null);

    fetchChannels()
      .then(response => {
        if (response && response.items && response.items.length > 0) {
          if (response.items.length > 1) {
            // $FlowFixMe: removes type checking for Sentry as provisional solution
            Sentry.captureMessage(
              'More than 1 youtube channel fetched, there should only ever be 1'
            );
          }
          return addYouTubeChannelToSubscription(subscriptionKey, response.items[0], 'Auth');
        }
        return Promise.reject();
      })
      .then(() => {
        onClose();
      })
      .catch(channelError => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when fetching youtube channels');
        Sentry.captureException(channelError);
        console.error(channelError);
        setError(channelError);
        setBusy(false);
      });
  };

  const selectChannelInstantSearch = () => {
    if (busy) {
      return;
    }

    setBusy(true);
    setError(null);

    if (channelInfo) {
      fetchChannelFromChannelUrl(channelInfo.youtubeChannelId)
        .then(response => {
          if (response && response.items && response.items.length > 0) {
            if (response.items.length > 1) {
              // $FlowFixMe: removes type checking for Sentry as provisional solution
              Sentry.captureMessage(
                'More than 1 youtube channel fetched, there should only ever be 1'
              );
            }

            if (isPrimaryChannel) {
              return addYouTubeChannelToSubscription(subscriptionKey, response.items[0], 'URL');
            }

            return addExtraYouTubeChannelToSubscription(
              extraYouTubeChannels,
              subscriptionKey,
              response.items[0],
              'URL'
            );
          }

          return Promise.reject();
        })
        .then(() => {
          onClose();
        })
        .catch(channelError => {
          // $FlowFixMe: removes type checking for Sentry as provisional solution
          Sentry.captureMessage(
            'Something went wrong when fetching youtube channels from instant search'
          );
          Sentry.captureException(channelError);
          console.error(channelError);
          setError(channelError);
          setBusy(false);
        });
    }
  };

  useEffect(() => {
    selectChannelInstantSearch();
  }, []);

  return (
    <ClassicModalLayout onClose={handleClose} fullSized={false}>
      <ClassicModalContentLayout
        heading={
          error instanceof YouTubeChannelAlreadyConnectedError ||
          error instanceof YouTubeChannelDuplicateRequestError
            ? t('modal.connectYouTubeChannel.alreadyConnect.header', '')
            : t('modal.connectYouTubeChannel.header', '')
        }
      >
        {error ? (
          <ErrorDisplay
            error={error}
            busy={busy}
            retry={selectChannel}
            channelInfo={channelInfo}
            onClose={handleClose}
          />
        ) : (
          <LoadingContainer>
            <LoadingDisplay />
          </LoadingContainer>
        )}
      </ClassicModalContentLayout>
    </ClassicModalLayout>
  );
};

export default YouTubeChannelModal;
