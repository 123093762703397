// @flow
import React from 'react';
import { cx } from 'emotion';
import styled from 'react-emotion';
import onClickOutside from 'react-onclickoutside';
import { transparentize } from 'polished';
import { useSetRecoilState } from 'recoil';
import {
  smallMediumTextCss,
  smallPlainTextMonoCss,
} from '../../../../styles/typography/typography';
import { lightColor, creatorBgColor, darkColor } from '../../../../styles/config/colors';
import { CompactButton } from '../../../Button/Button';
import { useUserProfileContext } from '../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { useSubscriptionsContext } from '../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { SUBSCRIPTIONS } from '../../../../user/subscriptions/data';
import { conversionSourceAtom } from '../../../../store/mixpanelAnalytics';
import { Trans, useTranslation } from 'react-i18next';

const countLimitReached = 'countLimitReached';

const Container = styled('section')`
  background: #fff;
  border-radius: 5px;
  border: solid ${darkColor};
  width: 300px;
  overflow: hidden;
`;

const DownloadCountTitle = styled('h6')`
  ${smallMediumTextCss};
  padding: 13px 20px 3px 20px;
`;

const RemainingDownloadsInfo = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px;
  margin-bottom: 19px;
`;

const CountInformation = styled('p')`
  width: 195px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.16px;
  margin-top: 10px;

  & span {
    text-transform: uppercase;
    font-weight: 700;
  }
`;

const CountNumberWrapper = styled('div')`
  width: 50px;
  height: 50px;
  background-color: ${transparentize(0.9, lightColor)};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  border-radius: 2px;
  margin-left: 10px;
`;

const CountNumber = styled('h6')`
  ${smallPlainTextMonoCss};
  font-size: 28px;
  font-weight: 300;

  &.${countLimitReached} {
    opacity: 0.3;
  }
`;

const UpgradePromptWrapper = styled('div')`
  background-color: ${creatorBgColor};
  background-size: cover;
  background-position: center;
`;

const UpgradePromptInfo = styled('div')`
  padding: 18px 20px 0px 20px;
`;

const UpgradePrompTitle = styled('h6')`
  ${smallMediumTextCss};
  margin-bottom: 10px;
`;

const UpgradePromptDescription = styled('p')`
  font-size: 12px;
  line-height: 20px;
`;

const UpgradePromptButtonWrapper = styled('div')`
  padding: 15px 20px 20px 20px;
  display: flex;
  justify-content: center;

  & a {
    width: 100%;
  }
`;
const UpgradePromptButton = styled(CompactButton)`
  width: 100%;
  font-size: 10px;
  padding: 7px 0px;
`;

type Props = {
  onClose: () => void,
};

const parseDownloadCount = (countParagraph: string, number: number, downloadsLabel: string) => {
  const count = number.toString();
  const label = downloadsLabel;
  return countParagraph.replace('$DOWNLOADS_COUNT$', `${count} ${label}`);
};

const DownloadCounterMenu = ({ onClose }: Props) => {
  const { t } = useTranslation();

  const { remainingDailyDownloads } = useUserProfileContext();
  const { selectPlan, subscriptions, openChangeSubscriptionModal } = useSubscriptionsContext();
  const setConversionSource = useSetRecoilState(conversionSourceAtom);

  const handleClick = () => {
    setConversionSource('download_limit');
    if (subscriptions.length === 0) {
      selectPlan(SUBSCRIPTIONS.creatorPro.key);
    } else {
      const defaultSubscriptionKey = subscriptions[0].key;
      openChangeSubscriptionModal(defaultSubscriptionKey);
    }
  };

  DownloadCounterMenu.handleClickOutside = () => {
    onClose();
  };
  return (
    <Container>
      <DownloadCountTitle>{t('downloadCountMenu.header', '')}</DownloadCountTitle>
      <RemainingDownloadsInfo>
        <CountInformation>
          {remainingDailyDownloads > 0 ? (
            <Trans>
              {parseDownloadCount(
                t('downloadCountMenu.counter', ''),
                remainingDailyDownloads,
                t('general.text.download', '')
              )}
            </Trans>
          ) : (
            t('downloadCountMenu.limitReached', '')
          )}
        </CountInformation>
        <CountNumberWrapper>
          <CountNumber
            className={cx({
              [countLimitReached]: remainingDailyDownloads === 0,
            })}
          >
            {remainingDailyDownloads}
          </CountNumber>
        </CountNumberWrapper>
      </RemainingDownloadsInfo>
      <UpgradePromptWrapper>
        <UpgradePromptInfo>
          <UpgradePrompTitle>{t('downloadCountMenu.upgradePrompt.header', '')}</UpgradePrompTitle>
          <UpgradePromptDescription>
            {t('downloadCountMenu.upgradePrompt.description', '')}{' '}
          </UpgradePromptDescription>
        </UpgradePromptInfo>
        <UpgradePromptButtonWrapper>
          <UpgradePromptButton onClick={handleClick}>
            {t('general.button.upgradeNow', '')}
          </UpgradePromptButton>
        </UpgradePromptButtonWrapper>
      </UpgradePromptWrapper>
    </Container>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => DownloadCounterMenu.handleClickOutside,
};

export default onClickOutside(DownloadCounterMenu, clickOutsideConfig);
