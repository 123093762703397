// @flow
import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import { MediumHeading } from '../../../styles/typography/components/Heading/Heading';
import Button, {
  BUTTON_WIDTHS,
  DarkButton,
  shorterButtonClass,
} from '../../../components/Button/Button';
import MessageModal from '../MessageModal/MessageModal';
import { SmallerGenericCopy } from '../../../styles/typography/components/GenericCopy/GenericCopy';
import { useTranslation } from 'react-i18next';

const Container = styled('div')``;

const Options = styled('div')`
  display: flex;
  margin-top: 17px;
`;

const Option = styled('div')`
  &:not(:first-child) {
    margin-left: 10px;
  }
`;

const buttonClass = css`
  ${shorterButtonClass};
`;

const Description = styled('div')`
  margin-top: 13px;
`;

type Props = {
  newKeywords: Array<string>,
  confirmKeywordRemoval?: {
    setKeywordsQuery: (Array<string>) => void,
    setMagicQuery: (Array<string>) => void,
    handleClearSongFilters: string => void,
    isMagicKeyword: boolean,
    isYouTubeKeyword?: boolean,
    handleRemoveYouTubeKeyword?: () => void,
  },
  onClose: () => void,
};

const LeaveResultFiltersModal = ({
  onClose,
  newKeywords,
  confirmKeywordRemoval = {
    setKeywordsQuery: () => {},
    setMagicQuery: () => {},
    handleClearSongFilters: () => {},
    isMagicKeyword: false,
    isYouTubeKeyword: false,
    handleRemoveYouTubeKeyword: () => {},
  },
}: Props) => {
  const { t } = useTranslation();

  const handleRemoveKeyword = () => {
    const {
      setKeywordsQuery,
      setMagicQuery,
      handleClearSongFilters,
      isMagicKeyword,
      isYouTubeKeyword,
      handleRemoveYouTubeKeyword,
    } = confirmKeywordRemoval;

    if (isYouTubeKeyword) {
      handleRemoveYouTubeKeyword();
      handleClearSongFilters('removeKeyword');
    }

    if (isMagicKeyword) {
      setMagicQuery(newKeywords);
      handleClearSongFilters('removeKeyword');
    } else {
      setKeywordsQuery(newKeywords);
      handleClearSongFilters('removeKeyword');
    }

    onClose();
  };

  return (
    <MessageModal onClose={onClose}>
      <Container>
        <div>
          <MediumHeading>{t('modal.leaveResults.filtered.header', '')}</MediumHeading>
          <Description>
            <SmallerGenericCopy>
              {t('modal.leaveResults.filtered.instruction', '')}
            </SmallerGenericCopy>
          </Description>
        </div>
        <Options>
          <Option>
            <DarkButton width={BUTTON_WIDTHS.small} className={buttonClass} onClick={onClose}>
              {t('general.button.keep', '')}
            </DarkButton>
          </Option>
          <Option>
            <Button
              width={BUTTON_WIDTHS.small}
              className={buttonClass}
              onClick={handleRemoveKeyword}
            >
              {t('general.button.remove', '')}
            </Button>
          </Option>
        </Options>
      </Container>
    </MessageModal>
  );
};

export default LeaveResultFiltersModal;
