// @flow
import React, { useState } from 'react';
import styled, { css, cx } from 'react-emotion';
import { useTranslation } from 'react-i18next';
import { MEDIA_BREAKPOINTS } from '../../../styles/responsive';

const TabGroup = styled('div')`
  width: 296px;
  margin: 30px auto 69px auto;
  padding: 4px;
  border-radius: 4px;
  background-color: #4d4d4d;

  ${MEDIA_BREAKPOINTS.max1000} {
    margin: 30px auto;
  }
`;

const TabItems = styled('ul')`
  display: flex;
  justify-content: center;

  li:not(:first-of-type) {
    margin-left: 8px;
  }

  button {
    width: 140px;
    height: 30px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
`;

const noActiveButtonCss = css`
  background-color: #4d4d4d;
  color: #fff;
  font-weight: 400;
`;

const activeButtonCss = css`
  background-color: #fff;
  color: #000;
  font-weight: 800;
`;

type PaymentTabNavigationProps = {
  selectOption: string,
  handleOptionChange: (event: any) => void,
};

const PaymentTabNavigation = ({ handleOptionChange }: PaymentTabNavigationProps) => {
  const { t } = useTranslation();
  const [active, setActive] = useState('oneTimePayment');

  return (
    <TabGroup>
      <TabItems role="tablist">
        <li>
          <label>
            <button
              className={cx({
                [activeButtonCss]: active === 'splitPayment',
                [noActiveButtonCss]: active !== 'splitPayment',
              })}
              role="tab"
              onClick={() => {
                setActive('splitPayment');
                handleOptionChange('splitPayment');
              }}
            >
              <span>{t('general.radioButton.splitPayment', '')}</span>
            </button>
          </label>
        </li>

        <li>
          <label>
            <button
              className={cx({
                [activeButtonCss]: active === 'oneTimePayment',
                [noActiveButtonCss]: active !== 'oneTimePayment',
              })}
              role="tab"
              onClick={() => {
                setActive('oneTimePayment');
                handleOptionChange('oneTimePayment');
              }}
            >
              <span>{t('general.radioButton.oneTimePayment', '')}</span>
            </button>
          </label>
        </li>
      </TabItems>
    </TabGroup>
  );
};

export default PaymentTabNavigation;
