// @flow
import React from 'react';
import { getKeywordsFromSongDetails, getSongID } from '../../../../../../../../api/algolia/song';
import SimilarSongsIcon from '../../../../../../../../assets/inline-assets/similar-songs-icon.svg';
import { useModalsContext } from '../../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useNavigate } from '../../../../../../../../routing/hooks';
import { ROUTES } from '../../../../../../../../routing/routes';
import { useSubscriptionsContext } from '../../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { USER_PERMISSIONS } from '../../../../../../../../user/subscriptions/data';
import { useSong } from '../../../../../../SongContextWrapper/SongContextWrapper';
import InlineButton from './InlineButton';
import { useTranslation } from 'react-i18next';

const SimilarSearchInlineButton = () => {
  const { t } = useTranslation();

  const song = useSong();
  const navigate = useNavigate();
  const { userRole } = useSubscriptionsContext();
  const { showSubscriptionRequired } = useModalsContext();

  const similarityKeywords = getKeywordsFromSongDetails(song);
  const similarSongId = getSongID(song);

  const similaritySearchAvailable = similarityKeywords.length > 0;

  const handleSimilarSearch = () => {
    if (!similaritySearchAvailable) {
      return;
    }

    if (!USER_PERMISSIONS[userRole].similarSearch) {
      showSubscriptionRequired();
      return;
    }
    navigate(ROUTES.similarSearch.navigatePath({ songId: similarSongId }));
  };

  const label = similaritySearchAvailable
    ? t('general.button.similarSearch', '')
    : t('general.button.noSimilarSearch', '');

  return (
    <InlineButton
      label={label}
      icon={<SimilarSongsIcon />}
      action={handleSimilarSearch}
      disabledButton={!similaritySearchAvailable}
    />
  );
};

export default SimilarSearchInlineButton;
