// @flow
import React from 'react';
import { useSetRecoilState } from 'recoil';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../ClassicModalLayout/ClassicModalLayout';
import Button from '../../../components/Button/Button';
import { SubscriptionModalFooter } from '../../../user/subscriptions/components/CancelSubscriptionModal/CancelSubscriptionModal';
import { useSubscriptionsContext } from '../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { SUBSCRIPTIONS } from '../../../user/subscriptions/data';
import { useAuthContext } from '../../../auth/components/AuthWrapper/AuthWrapper';
import { storeCurrentLocationAsAuthDestination } from '../../../auth/destination';
import { useNavigate } from '../../../routing/hooks';
import { ROUTES } from '../../../routing/routes';
import { conversionSourceAtom } from '../../../store/mixpanelAnalytics';
import { css } from 'emotion';
import { useTranslation } from 'react-i18next';

const signUpButtonLayout = css`
  background-color: #4d4d4d;
`;

type Props = {
  onClose: () => void,
  feature: string,
};

const PermissionRestrictedModal = ({ onClose, feature }: Props) => {
  const { t } = useTranslation();

  const { selectPlan, subscriptions, openChangeSubscriptionModal } = useSubscriptionsContext();
  const { isAuthenticated, setSignUpSourceData } = useAuthContext();
  const upgradeEnabled = subscriptions.length > 0;
  const navigate = useNavigate();
  const setConversionSource = useSetRecoilState(conversionSourceAtom);

  const instructions = (() => {
    if (feature === 'popularitySorting') {
      return upgradeEnabled && isAuthenticated
        ? t('modal.sortingRestricted.planInstructions.upgrade', '')
        : t('modal.sortingRestricted.planInstructions.add', '');
    }

    if (feature === 'similarSearch') {
      return upgradeEnabled && isAuthenticated
        ? t('modal.sortingRestricted.similarSearchRestriction.upgrade', '')
        : t('modal.sortingRestricted.similarSearchRestriction.add', '');
    }

    if (feature === 'customOrder') {
      return upgradeEnabled && isAuthenticated
        ? t('modal.sortingRestricted.planInstructions.customOrder.upgrade', '')
        : t('modal.sortingRestricted.planInstructions.customOrder.add', '');
    }

    return '';
  })();

  const actionText = (() => {
    if (!isAuthenticated) {
      return t('general.button.freeSignUp', '');
    }

    if (upgradeEnabled) {
      return t('general.button.upgradeNow', '');
    }

    return t('general.text.addSubscription', '');
  })();

  const handleAdd = () => {
    onClose();

    if (feature === 'popularitySorting') {
      setConversionSource('sorting_popularity');
    }

    if (feature === 'similarSearch') {
      setConversionSource('similar_search');
    }

    if (feature === 'customOrder') {
      setConversionSource('custom_order');
      selectPlan(SUBSCRIPTIONS.business.key);
      return;
    }

    selectPlan(SUBSCRIPTIONS.creatorPro.key);
  };

  const handleUpgrade = () => {
    const defaultSubscriptionKey = subscriptions[0].key;
    onClose();

    if (feature === 'popularitySorting') {
      setConversionSource('sorting_popularity');
    }

    if (feature === 'similarSearch') {
      setConversionSource('similar_search');
    }

    if (feature === 'custom_order') {
      setConversionSource('custom_order');
    }

    openChangeSubscriptionModal(defaultSubscriptionKey);
  };

  const handleSignUp = () => {
    if (feature === 'popularitySorting') {
      setConversionSource('sorting_popularity');
      setSignUpSourceData({ signUpSource: 'Sorting', signUpCampaign: '' });
    }

    if (feature === 'similarSearch') {
      setConversionSource('similar_search');
      setSignUpSourceData({ signUpSource: 'Similar Search', signUpCampaign: '' });
    }

    if (feature === 'customOrder') {
      setConversionSource('custom_order');
      setSignUpSourceData({ signUpSource: 'Custom Order', signUpCampaign: '' });
    }

    storeCurrentLocationAsAuthDestination();
    onClose();
    navigate(ROUTES.signup.navigatePath({}));
  };

  const handleClick = () => {
    if (!isAuthenticated) {
      handleSignUp();
      return;
    }

    if (upgradeEnabled) {
      handleUpgrade();
    } else {
      handleAdd();
    }
  };

  return (
    <ClassicModalLayout onClose={onClose} fullSized={false}>
      <ClassicModalContentLayout
        heading={t('modal.sortingRestricted.header', '')}
        description={instructions}
        footer={
          <SubscriptionModalFooter>
            <Button className={signUpButtonLayout} onClick={handleClick}>
              {actionText}
            </Button>
          </SubscriptionModalFooter>
        }
      />
    </ClassicModalLayout>
  );
};

export default PermissionRestrictedModal;
