// @flow
import React, { useEffect } from 'react';
import ErrorInfo from '../../../ErrorInfo/ErrorInfo';
import Button from '../../../../../components/Button/Button';
import { ErrorLayout } from '../GenericError/GenericError';
import { useLocalePath } from '../../../../../routing/components/LocaleWrapper/LocaleWrapper';
import { ROUTES } from '../../../../../routing/routes';
import { useTranslation } from 'react-i18next';

const NotFoundError = () => {
  const { t } = useTranslation();
  const localePath = useLocalePath();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = ROUTES.landing.navigatePath({ localePath });
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <ErrorLayout>
      <ErrorInfo
        heading={t('general.error.404.header', '')}
        descriptions={t('general.error.404.message', { defaultValue: [], returnObjects: true })}
        footer={
          <Button href={ROUTES.landing.navigatePath({ localePath })}>
            {t('general.error.404.button.home', '')}
          </Button>
        }
      />
    </ErrorLayout>
  );
};

export default NotFoundError;
