// @flow
import React from 'react';
import { useRecoilValue } from 'recoil';
import PlaylistIcon from '../../../../../../../../assets/inline-assets/playlist-icon.svg';
import { useGlobalPlayerContext } from '../../../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { capitalize } from '../../../../../../../../utils/text';
import InlineButton from './InlineButton';
import {
  playbackListTypeAtom,
  playbackListSlugAtom,
  playbackListNameAtom,
} from '../../../../../../../../store/globalPlayer';
import { useTranslation } from 'react-i18next';
import { isSearchIndexSwitcherEnabled } from '../../../../../../../../utils/env';

type Props = {
  hideTooltip: () => void,
};

const BrowseLinkInlineButton = ({ hideTooltip }: Props) => {
  const { t } = useTranslation();

  const playbackListType = useRecoilValue(playbackListTypeAtom);
  const playbackListName = useRecoilValue(playbackListNameAtom);
  const playbackListSlug = useRecoilValue(playbackListSlugAtom);

  const { navigateToPlaybackList } = useGlobalPlayerContext();

  const getFindMusicSearchButton = (): string => {
    const findMusicSearchButtonText = t('findMusicPage.searchButton', '');

    if (isSearchIndexSwitcherEnabled() && window.sessionStorage.getItem('stgidx')) {
      return findMusicSearchButtonText + '(stg)';
    }

    return findMusicSearchButtonText;
  };

  const linkLabel = (() => {
    if (playbackListType === 'search') {
      return getFindMusicSearchButton();
    }

    if (playbackListType === 'similar') {
      return t('resultsPage.text.similarSongsHeader', '');
    }

    if (playbackListType === 'collection' || playbackListType === 'artist') {
      return playbackListName;
    }

    if (playbackListType === 'bookmarks') {
      return t('general.button.bookmark', '');
    }

    if (playbackListType === 'downloads') {
      return t('general.text.downloadHistory', '');
    }

    if (playbackListType === 'pack') {
      return `♪ ${t('general.button.pack', '')}`;
    }

    return capitalize(playbackListSlug);
  })();

  const handleBrowseNav = () => {
    if (playbackListType === 'bookmarks' || playbackListType === 'downloads') {
      hideTooltip();
    }
    navigateToPlaybackList();
    hideTooltip();
  };

  return <InlineButton label={linkLabel} icon={<PlaylistIcon />} action={handleBrowseNav} />;
};

export default BrowseLinkInlineButton;
