// @flow
import React from 'react';
import styled from 'react-emotion';
import LayoutWrapper from '../../../components/LayoutWrapper/LayoutWrapper';
import InfoScreen from './components/InfoScreen/InfoScreen';
import PageComponent from '../../components/PageComponent/PageComponent';
import { LOCALES } from '../../../locales';
import { ROUTES } from '../../routes';
import { getLocalisedCurrentPath } from '../../../components/SiteFooter/SiteFooter';
import MusicLicenseLink from './components/MusicLicenseLink/MusicLicenseLink';
import { SUBSCRIPTIONS } from '../../../user/subscriptions/data';
import { useTranslation } from 'react-i18next';

const LocaleLinks = styled('div')`
  margin-bottom: 50px;
  text-transform: uppercase;
  font-size: 18px;
  text-decoration: none;

  & a {
    text-decoration: none;
  }

  & a:hover {
    text-decoration: underline;
  }
`;

const Content = () => {
  const { t } = useTranslation();

  const plans = [
    SUBSCRIPTIONS.creatorPro.key,
    SUBSCRIPTIONS.business.key,
    SUBSCRIPTIONS.enterprise.key,
  ];

  const prisingPlans = ['free1', 'pro1', 'business1', 'enterprise1'];
  const subscriptionPricingPlans = prisingPlans.filter(pricingPlan => plans.includes(pricingPlan));

  return (
    <LayoutWrapper bodyPadding>
      <InfoScreen>
        <LocaleLinks>
          <a href={getLocalisedCurrentPath(ROUTES.terms.path, '')}>
            {t('general.text.english', '')}
          </a>
          &nbsp;/&nbsp;
          <a href={getLocalisedCurrentPath(ROUTES.terms.path, LOCALES.japanese.path)}>
            {t('general.text.japanese', '')}
          </a>
        </LocaleLinks>

        {subscriptionPricingPlans.map(uid => {
          return <MusicLicenseLink key={uid} planCode={uid} />;
        })}

        {/* TODO: 20230523 復活させる可能性があるため、コメントアウトで対応。 */}
        {/* <MusicLicenseLink>
          {parse(getSingleTrackLimitedMediaMusicLicenseLink(copy))}
        </MusicLicenseLink>
        <MusicLicenseLink>{parse(getSingleTrackAllMediaMusicLicenseLink(copy))}</MusicLicenseLink>
        <MusicLicenseLink>{parse(getExclusiveRightsTrackLicenseLink(copy))}</MusicLicenseLink> */}
      </InfoScreen>
    </LayoutWrapper>
  );
};

type Props = {
  path: string,
};

const TermsScreen = ({ path }: Props) => {
  return (
    <PageComponent path={path}>
      <Content />
    </PageComponent>
  );
};

export default TermsScreen;
