// @flow
import React, { useContext, useEffect, useState } from 'react';
import { get } from 'lodash';
import ls from 'local-storage';
import { useRecoilState } from 'recoil';
import { useUserId, useIsAuthenticated } from '../../../../auth/components/AuthWrapper/AuthWrapper';
import {
  doesSubscriptionHaveYouTubeChannelConnected,
  fetchFirestoreUserSubscriptions,
  getChannelId,
  getChannelYouTubeId,
  getChannelParentChannel,
  getSubscriptionChannelId,
  getSubscriptionPlanKey,
  getSubscriptionChannel,
  getSubscriptionExtraChannels,
  getSubscriptionStatus,
  getSeatInterval,
  getSubscriptionCoupons,
  getUserFreeTrialEligibility,
} from '../../../../api/firebase/user/subscriptions';
import type { UserSubscriptionMdl, ChannelMdl } from '../../../../api/firebase/user/subscriptions';
import AddSubscriptionModal from '../AddSubscriptionModal/AddSubscriptionModal';
import AddEnterpriseBasicSubscriptionModal from '../AddEnterpriseBasicSubscriptionModal/AddEnterpriseBasicSubscriptionModal';
import { firebaseApiHandler, useIsYouTubeChannelConnected } from '../../../../api/firebase/api';
import type { ChannelsCheckResponseMdl } from '../../../../api/firebase/api';
import {
  YouTubeChannelAlreadyConnectedError,
  YouTubeChannelDuplicateRequestError,
} from '../../../../errors/errors';
import type { YouTubeAPIChannelMdl } from '../../../../api/firebase/user/channels';
import {
  generateNewSubscription,
  getUpdatedSubscriptionsWithNewYouTubeChannel,
  getUpdatedSubscriptionsWithRemovedYouTubeChannel,
  mergeUpdatedChannelsWithSubscriptions,
  mergeUpdatedExtraChannelsWithSubscriptions,
  updateSubscriptionPlan,
  updateSubscriptionStatus,
  getUpdatedExtraYouTubeChannels,
  getUpdatedExtraYouTubeChannelsWithRemovedChannel,
  getUpdatedSubscriptionsWithNewPrimaryYouTubeChannel,
  getUpdatedExtraYouTubeChannelsWithSwappedChannel,
  mergeSubscriptionsWithRemovedScheduledChange,
} from './data';
import {
  SUBSCRIPTIONS,
  USER_ROLES,
  SUBSCRIPTION_HINTS,
  INTERVAL_MONTHLY_DURATION,
} from '../../data';
import { useLocale } from '../../../../routing/components/LocaleWrapper/LocaleWrapper';
import { useChargebeeContext } from '../ChargebeeWrapper/ChargebeeWrapper';
import CancelSubscriptionModal from '../CancelSubscriptionModal/CancelSubscriptionModal';
import {
  analyticsAccountAddPlan,
  analyticsAccountAddSubscription,
  analyticsAccountCancel,
  analyticsAccountConnectYoutube,
  analyticsAccountDowngrade,
  analyticsAccountPayment,
  analyticsAccountUpgrade,
  analyticsPlanIntervalChange,
} from '../../../../analytics/events';
import { SUBSCRIPTION_STATUSES } from '../../../../api/firebase/user/user';
import ChangeSubscriptionModal from '../ChangeSubscriptionModal/ChangeSubscriptionModal';
import DowngradeSubscriptionModal from '../DowngradeSubscriptionModal/DowngradeSubscriptionModal';
import ChangeIntervalSubscriptionModal from '../ChangeIntervalSubscriptionModal/ChangeIntervalSubscriptionModal';
import ChangeFreeTrialSubscriptionModal from '../ChangeFreeTrialSubscriptionModal/ChangeFreeTrialSubscriptionModal';
import type { AllocatedSeatMdl } from '../../../../api/firebase/user/user';
import {
  useUserProfileContext,
  useUserFreeTrialEligibility,
} from '../../../components/UserProfileWrapper/UserProfileWrapper';
import { getDaysUntilDate } from '../../../../utils/date';
import { useAnalyticsMixpanelContext } from '../../../../analytics/components/MixpanelWrapper';
import {
  analyticsMixpanelAccountAddPlan,
  analyticsMixpanelAccountUpgradePlan,
  analyticsMixpanelAccountScheduleDowngrade,
  analyticsMixpanelAccountUndoDowngrade,
  analyticsMixpanelAccountYouTubeConnection,
  analyticsMixpanelAccountYouTubeDisconnection,
  analyticsMixpanelAccountEndTrial,
  analyticsMixpanelAccountScheduleCancellation,
  analyticsMixpanelAccountUndoCancellation,
  analyticsMixpanelAccountCancelSubscription,
  updateMixpanelFreeTrialEligibility,
  getFreeTrialChangeType,
} from '../../../../analytics/mixpanel';
import AllowlistUnsuccessfulModal from '../AllowlistUnsuccessfulModal/AllowlistUnsuccessfulModal';
import {
  getReferralCouponId,
  getReferralCouponType,
  getReferralEligibility,
} from '../../../../api/firebase/referral/referral';
import { useNavigate } from '../../../../routing/hooks';
import { ROUTES } from '../../../../routing/routes';
import {
  isCreatorSubscription,
  isEnterpriseBasicSubscription,
} from '../../../../utils/subscriptions';
import { fetchFirestoreAllowlist } from '../../../../api/firebase/user/allowlist';
import { conversionSourceAtom } from '../../../../store/mixpanelAnalytics';

export type SubscriptionsContextState = {
  loaded: boolean,
  loading: boolean,
  subscriptions: Array<UserSubscriptionMdl>,
  fetchUserSubscriptions: () => Promise<Array<UserSubscriptionMdl>>,
  selectPlan: string => void,
  addYouTubeChannelToSubscription: (string, YouTubeAPIChannelMdl, string) => Promise<any>,
  addExtraYouTubeChannelToSubscription: (
    Array<any>,
    string,
    YouTubeAPIChannelMdl,
    string
  ) => Promise<any>,
  removeYouTubeChannelFromSubscription: (string, string, Array<any>) => Promise<any>,
  removeExtraYouTubeChannelFromSubscription: (Array<any>, string, string) => Promise<any>,
  addNewSubscription: (string, string) => Promise<any>,
  addEnterprisePlaceholderSubscription: () => Promise<any>,
  openCancelSubscriptionModal: string => void,
  removeSubscription: (string, boolean) => Promise<any>,
  removeScheduledChanges: string => Promise<any>,
  openChangeSubscriptionModal: (string, extrChannels?: Array<ChannelMdl>) => void,
  openAllowlistUnsuccessfulModal: (deniedChannels: Array<string>) => void,
  downgradeSubscription: (string, string, string) => Promise<any>,
  upgradeSubscription: (string, string, string) => Promise<any>,
  showDowngradeModal: (string, string) => void,
  showChangingIntervalModal: (string, string) => void,
  showFreeTrialChangeModal: (string, string, string) => void,
  updateSubscriptionInterval: (string, string, string) => Promise<any>,
  isAtLeastOneSocialAccountConnected: () => boolean,
  isPaidCustomer: boolean,
  isFreeCustomer: boolean,
  hasSubscription: boolean,
  billingCycle: string,
  setBillingCycle: (string | null) => void,
  selectedPlanNumber: string,
  setSelectedPlanNumber: string => void,
  userRole: string,
  addExtraYouTubeChannelToSubscription: (Array<any>, string, YouTubeAPIChannelMdl) => Promise<any>,
  downgradingExtraChannels: Array<any>,
  swapPrimaryChannelOnDowngrade: (Array<any>, string, any) => Promise<any>,
  isThereFreeTrialSubscription: boolean,
  confirmAllowlistUnsuccess: boolean,
  fetchChannelAllowlist: (
    mainYouTubeChannelId: string,
    ExtraYouTubeChannelIDs: Array<string>
  ) => Promise<AllowlistDataMdl>,
  referralCoupon: null | ReferralCouponDataMDL,
  setReferralCoupon: ReferralCouponDataMDL => void,
  showReferralBanner: boolean,
  setShowReferralBanner: boolean => void,
};

export const SubscriptionsContext = React.createContext<any>();

export const useSubscriptionsContext = (): SubscriptionsContextState => {
  return useContext(SubscriptionsContext);
};

export const useSubscriptions = (): Array<UserSubscriptionMdl> => {
  return useSubscriptionsContext().subscriptions;
};

export const useUserHasSubscriptions = (): boolean => {
  const subscriptions = useSubscriptions();
  return subscriptions && subscriptions.length > 0;
};

export const useDoesUserOnlyHaveBusinessSubscription = (): boolean => {
  const subscriptions = useSubscriptions();
  const businessSubscriptions = subscriptions.filter((subscription: UserSubscriptionMdl) => {
    return SUBSCRIPTIONS.business.plan_codes.includes(getSubscriptionPlanKey(subscription));
  });
  return businessSubscriptions.length > 0 && businessSubscriptions.length === subscriptions.length;
};

export const isSubscriptionFreeTrial = (subscription: UserSubscriptionMdl): boolean => {
  const isFreeTrial =
    !SUBSCRIPTIONS.creator.plan_codes.includes(getSubscriptionPlanKey(subscription)) &&
    (getSubscriptionStatus(subscription) === SUBSCRIPTION_STATUSES.trial ||
      getSubscriptionStatus(subscription) === SUBSCRIPTION_STATUSES.trial_cancelled);
  return isFreeTrial;
};

export const useFreeTrialSubscriptions = (): Array<UserSubscriptionMdl> => {
  const subscriptions = useSubscriptions();
  const freeTrialSubscriptions = subscriptions.filter(subscription => {
    return isSubscriptionFreeTrial(subscription);
  });
  return freeTrialSubscriptions;
};

export const useConnectedYouTubeChannels = (
  subscriptions: Array<UserSubscriptionMdl>
): Array<string> => {
  const youtubeChannels = [];
  subscriptions.forEach(subscription => {
    const isYouTubeConnected = doesSubscriptionHaveYouTubeChannelConnected(subscription);
    const extraChannels = getSubscriptionExtraChannels(subscription);

    if (isYouTubeConnected) {
      youtubeChannels.push(subscription.channel.youtube.name);
    }

    if (extraChannels.length > 0) {
      extraChannels.forEach(extraChannel => {
        youtubeChannels.push(extraChannel.youtube.name);
      });
    }
  });

  return youtubeChannels;
};

export const getNextScheduledChangeSubscription = (
  subscriptions: Array<UserSubscriptionMdl>,
  statusTypes: Array<string>
): UserSubscriptionMdl | null => {
  const subsScheduled = subscriptions.filter(subscription => {
    return statusTypes.includes(getSubscriptionStatus(subscription));
  });
  if (subsScheduled.length <= 0) {
    return null;
  }

  let nextScheduledChangeSub;
  let currentCancellationValidUntil;
  let newCancellationValidUntil;

  subsScheduled.forEach(subscription => {
    if (!SUBSCRIPTIONS.creator.plan_codes.includes(getSubscriptionPlanKey(subscription))) {
      if (nextScheduledChangeSub) {
        if (
          getSubscriptionStatus(nextScheduledChangeSub) === SUBSCRIPTION_STATUSES.cancel_pending ||
          getSubscriptionStatus(nextScheduledChangeSub) === SUBSCRIPTION_STATUSES.change_pending
        ) {
          currentCancellationValidUntil = get(nextScheduledChangeSub, 'seat.validUntilSeconds', 0);
        } else {
          currentCancellationValidUntil = get(
            nextScheduledChangeSub,
            'seat.trialPlanValidUntilSeconds',
            0
          );
        }

        if (
          getSubscriptionStatus(subscription) === SUBSCRIPTION_STATUSES.cancel_pending ||
          getSubscriptionStatus(subscription) === SUBSCRIPTION_STATUSES.change_pending
        ) {
          newCancellationValidUntil = get(subscription, 'seat.validUntilSeconds', 0);
        } else {
          newCancellationValidUntil = get(subscription, 'seat.trialPlanValidUntilSeconds', 0);
        }
        if (newCancellationValidUntil < currentCancellationValidUntil) {
          nextScheduledChangeSub = subscription;
        }
      } else {
        nextScheduledChangeSub = subscription;
      }
    }
  });

  return nextScheduledChangeSub || null;
};

type Props = {
  children: any,
};

const isUserPaidCustomer = (subscriptions: Array<UserSubscriptionMdl>): boolean => {
  return (
    subscriptions.filter((subscription: UserSubscriptionMdl) => {
      return !SUBSCRIPTIONS.creator.plan_codes.includes(getSubscriptionPlanKey(subscription));
    }).length > 0
  );
};

const isUserFreeCustomer = (subscriptions: Array<UserSubscriptionMdl>): boolean => {
  return (
    subscriptions.filter((subscription: UserSubscriptionMdl) => {
      return isCreatorSubscription(getSubscriptionPlanKey(subscription));
    }).length > 0
  );
};

const filterDeprecatedFreeSubscriptions = (
  subscriptions: Array<UserSubscriptionMdl>
): Array<UserSubscriptionMdl> => {
  return subscriptions.filter(subscription => {
    return !isCreatorSubscription(getSubscriptionPlanKey(subscription));
  });
};

const SubscriptionsContextWrapper = ({ children }: Props) => {
  const promotionalDefaultInterval = '';
  const defaultInterval = promotionalDefaultInterval || 'annual';

  const [updatingSubscriptions, setUpdatingSubscriptions] = useState(false);
  const [isYouTubeChannelConnected] = useIsYouTubeChannelConnected();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState();
  const [billingCycle, setBillingCycle] = useState(defaultInterval);
  const [cancellingSubscription, setCancellingSubscription] = useState();
  const [changingSubscription, setChangingSubscription] = useState();
  const [downgradingSubscription, setDowngradingSubscription] = useState();
  const [openAllowModal, setopenAllowModal] = useState(false);
  const [confirmAllowlistUnsuccess, setConfirmAllowlistUnsuccess] = useState(false);
  const [dataFromOpenedAllowlistUnsuccess, setDataFromOpenedAllowlistUnsuccess] = useState([]);
  const [changingIntervalSubscription, setChangingIntervalSubscription] = useState();
  const [changingFreeTrialSubscription, setChangingFreeTrialSubscription] = useState();
  const [selectedPlanNumber, setSelectedPlanNumber] = useState();
  const [userRole, setUserRole] = useState(USER_ROLES.guest);
  const [downgradingExtraChannels, setDowngradingExtraChannels] = useState([]);
  const [referralCoupon, setReferralCoupon] = useState(null);
  const [showReferralBanner, setShowReferralBanner] = useState(true);

  const { chargebeeInstance } = useChargebeeContext();
  const authenticated = useIsAuthenticated();
  const locale = useLocale();
  const userId = useUserId();
  const { user } = useUserProfileContext() ? useUserProfileContext() : { user: null };
  const [conversionSource, setConversionSource] = useRecoilState(conversionSourceAtom);
  const { mixpanel, moengage } = useAnalyticsMixpanelContext()
    ? useAnalyticsMixpanelContext()
    : { mixpanel: null, moengage: null };

  const isPaidCustomer = isUserPaidCustomer(subscriptions);
  const isFreeCustomer = isUserFreeCustomer(subscriptions);
  const hasSubscription = subscriptions.length > 0;
  const isUserFreeTrialEligible = useUserProfileContext()
    ? useUserFreeTrialEligibility()
    : { isUserFreeTrialEligible: null };
  const navigate = useNavigate();
  const getSubscription = (subscriptionKey: string): UserSubscriptionMdl => {
    return subscriptions.find((checkSubscription: UserSubscriptionMdl) => {
      return checkSubscription.key === subscriptionKey;
    });
  };

  const getSubscriptionIndex = (subscriptionKey: string): number => {
    return subscriptions.findIndex((checkSubscription: UserSubscriptionMdl) => {
      return checkSubscription.key === subscriptionKey;
    });
  };

  const selectPlan = (planKey: string) => {
    setSelectedPlan(planKey);
  };

  const getUserRoles = (userSubscriptions: Array<UserSubscriptionMdl>): string => {
    let newUserRole = USER_ROLES.guest;

    const enterpriseSubscriptions = userSubscriptions.filter(
      (subscription: UserSubscriptionMdl) => {
        return (
          SUBSCRIPTIONS.enterprise.plan_codes.includes(getSubscriptionPlanKey(subscription)) ||
          SUBSCRIPTIONS.enterpriseBasic.plan_codes.includes(getSubscriptionPlanKey(subscription))
        );
      }
    );

    if (enterpriseSubscriptions.length >= 1) {
      newUserRole = USER_ROLES.enterprise;
      return newUserRole;
    }

    const businessSubscriptions = userSubscriptions.filter((subscription: UserSubscriptionMdl) => {
      return SUBSCRIPTIONS.business.plan_codes.includes(getSubscriptionPlanKey(subscription));
    });

    if (businessSubscriptions.length >= 1) {
      newUserRole = USER_ROLES.business;
      return newUserRole;
    }

    const creatorProSubscriptions = userSubscriptions.filter(
      (subscription: UserSubscriptionMdl) => {
        return SUBSCRIPTIONS.creatorPro.plan_codes.includes(getSubscriptionPlanKey(subscription));
      }
    );

    if (creatorProSubscriptions.length >= 1) {
      newUserRole = USER_ROLES.creatorPro;
      return newUserRole;
    }

    if (authenticated && userId && userSubscriptions.length === 0) {
      newUserRole = USER_ROLES.noSubscriptions;
      return newUserRole;
    }

    return newUserRole;
  };

  const getUserSubscriptionPlans = (
    userSubscriptions: Array<UserSubscriptionMdl>
  ): Array<string> => {
    const subscriptionPlans = [];

    userSubscriptions.forEach(subscription => {
      const subscriptionPlan = getSubscriptionPlanKey(subscription);

      if (!subscriptionPlans.includes(subscriptionPlan)) {
        subscriptionPlans.push(subscriptionPlan);
      }
    });

    return subscriptionPlans;
  };

  const fetchUserSubscriptions = (): Promise<any> => {
    setLoading(true);
    return fetchFirestoreUserSubscriptions(userId)
      .then(response => {
        const filteredSubscriptions = filterDeprecatedFreeSubscriptions(response);
        setSubscriptions(filteredSubscriptions);
      })
      .finally(() => {
        setLoaded(true);
        setLoading(false);
      });
  };

  const fetchChannelAllowlist = async (
    mainYouTubeChannelId: string,
    ExtraYouTubeChannelIDs: Array<string>
  ): Promise<AllowlistDataMdl> => {
    return fetchFirestoreAllowlist(mainYouTubeChannelId, ExtraYouTubeChannelIDs);
  };

  const updateStoredSubscriptions = async (
    updatedSubscriptions: Array<UserSubscriptionMdl>,
    update = false
  ) => {
    setSubscriptions(updatedSubscriptions);

    if (update) {
      await fetchUserSubscriptions();
    }
  };

  const removeYouTubeChannelFromSubscription = (
    subscriptionKey: string,
    subscriptionPlan: string,
    extraChannels: Array<any>
  ): Promise<any> => {
    if (updatingSubscriptions) {
      return Promise.reject();
    }

    setUpdatingSubscriptions(true);

    //  Case: if user is trying to disconnect a YT channel in a primary connection that has extra channels associated with it
    //  Outcome: we need to set one of the extra channels as the primary YT connection as there always needs to be a primary YT connection

    if (SUBSCRIPTIONS.business.plan_codes.includes(subscriptionPlan) && extraChannels.length > 0) {
      const newPrimaryYTChannel = extraChannels[0];
      return setExtraYouTubeChannelAsPrimaryYouTubeChannel(
        extraChannels,
        subscriptionKey,
        newPrimaryYTChannel
      ).finally(() => {
        setUpdatingSubscriptions(false);
      });
    }

    //  Case: regular disconnection of a primary YT channel with no extra channels associated
    //  Outcome: primary channel disconnected

    const updatedChannels = getUpdatedSubscriptionsWithRemovedYouTubeChannel(
      subscriptions,
      subscriptionKey
    );

    return firebaseApiHandler
      .updateUserChannels(updatedChannels)
      .then(response => {
        const channels = response.userChannels;
        const mergedSubscriptions = mergeUpdatedChannelsWithSubscriptions(subscriptions, channels);
        updateStoredSubscriptions(mergedSubscriptions);
        return mergedSubscriptions;
      })
      .then(mergedSubscriptions => {
        if (mixpanel && moengage) {
          const youtubeChannels = useConnectedYouTubeChannels(mergedSubscriptions);
          analyticsMixpanelAccountYouTubeDisconnection(
            mixpanel,
            moengage,
            subscriptionPlan,
            'Direct',
            userId,
            youtubeChannels,
            false
          );
        }
      })
      .finally(() => {
        setUpdatingSubscriptions(false);
      });
  };

  const addYouTubeChannelToSubscription = async (
    subscriptionKey: string,
    channel: YouTubeAPIChannelMdl,
    connectionMethod: string
  ) => {
    if (updatingSubscriptions) {
      return Promise.reject();
    }
    analyticsAccountConnectYoutube(channel.id);
    setUpdatingSubscriptions(true);
    const filteredSubscriptions = subscriptions.filter(subscription => {
      return subscriptionKey === subscription.key;
    });

    const currentSubscription = filteredSubscriptions[0];
    const subscriptionPlan = getSubscriptionPlanKey(currentSubscription);
    const subscriptionHint = SUBSCRIPTIONS.creator.plan_codes.includes(subscriptionPlan)
      ? SUBSCRIPTION_HINTS.free
      : SUBSCRIPTION_HINTS.paid;

    try {
      const youtubeConnectionIDs = [];

      subscriptions.forEach(subscription => {
        const subscriptionChannel = getSubscriptionChannel(subscription);
        const extraChannels = getSubscriptionExtraChannels(subscription);

        const primaryYouTubeConnectionId = getChannelYouTubeId(subscriptionChannel.youtube);
        youtubeConnectionIDs.push(primaryYouTubeConnectionId);

        extraChannels.forEach(extraChannel => {
          const extraYouTubeConnectionId = getChannelYouTubeId(extraChannel.youtube);
          if (extraYouTubeConnectionId) {
            youtubeConnectionIDs.push(extraYouTubeConnectionId);
          }
        });
      });

      const requestContainsDuplicateChannel = youtubeConnectionIDs.includes(channel.id);
      if (requestContainsDuplicateChannel) {
        setUpdatingSubscriptions(false);
        throw new YouTubeChannelDuplicateRequestError(
          'Two channels with the same Id have been requested'
        );
      }
    } catch (error) {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage(
        'Something went wrong when checking if YT channel already exists in other subscriptions'
      );
      Sentry.captureException(error);
      setUpdatingSubscriptions(false);
      throw error;
    }

    try {
      await isYouTubeChannelConnected(channel.id, subscriptionHint).then(
        (response: ChannelsCheckResponseMdl) => {
          if (!response.connectable) {
            setUpdatingSubscriptions(false);
            throw new YouTubeChannelAlreadyConnectedError('Channel already connected.');
          }
        }
      );
    } catch (error) {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage(
        'Something went wrong when checking if youtube channel is already connected'
      );
      Sentry.captureException(error);
      setUpdatingSubscriptions(false);
      throw error;
    }

    const updatedChannels = getUpdatedSubscriptionsWithNewYouTubeChannel(
      subscriptions,
      subscriptionKey,
      channel
    );

    return firebaseApiHandler
      .updateUserChannels(updatedChannels)
      .then(response => {
        const channels = response.userChannels;
        const mergedSubscriptions = mergeUpdatedChannelsWithSubscriptions(subscriptions, channels);
        updateStoredSubscriptions(mergedSubscriptions);
        return mergedSubscriptions;
      })
      .then(mergedSubscriptions => {
        if (mixpanel && moengage) {
          const youtubeChannels = useConnectedYouTubeChannels(mergedSubscriptions);
          analyticsMixpanelAccountYouTubeConnection(
            mixpanel,
            moengage,
            connectionMethod,
            subscriptionPlan,
            userId,
            youtubeChannels
          );
        }
      })
      .finally(() => {
        setUpdatingSubscriptions(false);
      });
  };

  const addExtraYouTubeChannelToSubscription = async (
    extraYouTubeConnections: Array<any>,
    subscriptionKey: string,
    channel: YouTubeAPIChannelMdl,
    connectionMethod: string
  ) => {
    if (updatingSubscriptions) {
      return Promise.reject();
    }

    analyticsAccountConnectYoutube(channel.id);
    setUpdatingSubscriptions(true);

    const filteredSubscriptions = subscriptions.filter(subscription => {
      return subscriptionKey === subscription.key;
    });

    const currentSubscription = filteredSubscriptions[0];
    const subscriptionPlan = getSubscriptionPlanKey(currentSubscription);
    const subscriptionHint = SUBSCRIPTIONS.creator.plan_codes.includes(subscriptionPlan)
      ? SUBSCRIPTION_HINTS.free
      : SUBSCRIPTION_HINTS.paid;

    //  error handling: catches if the new extra YT channel connection already exists on any of the user's primary / extra channels

    try {
      const youtubeConnectionIDs = [];

      subscriptions.forEach(subscription => {
        const subscriptionChannel = getSubscriptionChannel(subscription);
        const extraChannels = getSubscriptionExtraChannels(subscription);

        const primaryYouTubeConnectionId = getChannelYouTubeId(subscriptionChannel.youtube);
        youtubeConnectionIDs.push(primaryYouTubeConnectionId);

        extraChannels.forEach(extraChannel => {
          const extraYouTubeConnectionId = getChannelYouTubeId(extraChannel.youtube);
          if (extraYouTubeConnectionId) {
            youtubeConnectionIDs.push(extraYouTubeConnectionId);
          }
        });
      });

      const requestContainsDuplicateChannel = youtubeConnectionIDs.includes(channel.id);
      if (requestContainsDuplicateChannel) {
        setUpdatingSubscriptions(false);
        throw new YouTubeChannelDuplicateRequestError(
          'Two channels with the same Id have been requested'
        );
      }
    } catch (error) {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage(
        'Something went wrong when checking if YT channel already exists in other subscriptions'
      );
      Sentry.captureException(error);
      setUpdatingSubscriptions(false);
      throw error;
    }

    try {
      await isYouTubeChannelConnected(channel.id, subscriptionHint).then(
        (response: ChannelsCheckResponseMdl) => {
          if (!response.connectable) {
            setUpdatingSubscriptions(false);
            throw new YouTubeChannelAlreadyConnectedError('Channel already connected.');
          }
        }
      );
    } catch (error) {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage(
        'Something went wrong when checking if youtube channel is already connected'
      );
      Sentry.captureException(error);
      setUpdatingSubscriptions(false);
      throw error;
    }

    const updatedExtraChannels = getUpdatedExtraYouTubeChannels(
      extraYouTubeConnections,
      subscriptionKey,
      channel
    );

    return firebaseApiHandler
      .updateExtraChannels(subscriptionKey, updatedExtraChannels)
      .then(response => {
        const mergedSubscriptions = mergeUpdatedExtraChannelsWithSubscriptions(
          subscriptions,
          response.userChannels,
          subscriptionKey
        );
        updateStoredSubscriptions(mergedSubscriptions);
        return mergedSubscriptions;
      })
      .then(mergedSubscriptions => {
        if (mixpanel && moengage) {
          const youtubeChannels = useConnectedYouTubeChannels(mergedSubscriptions);
          analyticsMixpanelAccountYouTubeConnection(
            mixpanel,
            moengage,
            connectionMethod,
            subscriptionPlan,
            userId,
            youtubeChannels
          );
        }
        setUpdatingSubscriptions(false);
      });
  };

  const removeExtraYouTubeChannelFromSubscription = (
    extraChannels: Array<any>,
    subscriptionKey: string,
    youtubeSubscriptionId: string
  ): Promise<any> => {
    if (updatingSubscriptions) {
      return Promise.reject();
    }

    setUpdatingSubscriptions(true);

    const updatedExtraYouTubeChannels = getUpdatedExtraYouTubeChannelsWithRemovedChannel(
      extraChannels,
      subscriptionKey,
      youtubeSubscriptionId
    );

    const currentSubscription = getSubscription(subscriptionKey);
    const currentSubscriptionPlan = getSubscriptionPlanKey(currentSubscription);

    return firebaseApiHandler
      .updateExtraChannels(subscriptionKey, updatedExtraYouTubeChannels)
      .then(response => {
        const mergedSubscriptions = mergeUpdatedExtraChannelsWithSubscriptions(
          subscriptions,
          response.userChannels,
          subscriptionKey
        );
        updateStoredSubscriptions(mergedSubscriptions);
        return mergedSubscriptions;
      })
      .then(mergedSubscriptions => {
        if (mixpanel && moengage) {
          const youtubeChannels = useConnectedYouTubeChannels(mergedSubscriptions);
          analyticsMixpanelAccountYouTubeDisconnection(
            mixpanel,
            moengage,
            currentSubscriptionPlan,
            'Direct',
            userId,
            youtubeChannels,
            false
          );
        }
      })
      .finally(() => {
        setUpdatingSubscriptions(false);
      });
  };

  const setExtraYouTubeChannelAsPrimaryYouTubeChannel = (
    extraChannels: Array<any>,
    subscriptionKey: string,
    extraChannel: any
  ): Promise<any> => {
    if (updatingSubscriptions) {
      return Promise.reject();
    }

    setUpdatingSubscriptions(true);

    const extraChannelYouTubeId = extraChannel.youtube.id;

    const updatedExtraYouTubeChannels = getUpdatedExtraYouTubeChannelsWithRemovedChannel(
      extraChannels,
      subscriptionKey,
      extraChannelYouTubeId
    );

    return firebaseApiHandler
      .updateExtraChannels(subscriptionKey, updatedExtraYouTubeChannels)
      .then(response => {
        const channels = response.userChannels;
        const mergedSubscriptionsWithRemovedExtraChannel = mergeUpdatedExtraChannelsWithSubscriptions(
          subscriptions,
          channels,
          subscriptionKey
        );
        return useExtraYouTubeChannelAsPrimaryYouTubeChannel(
          subscriptionKey,
          extraChannel.youtube,
          mergedSubscriptionsWithRemovedExtraChannel
        );
      });
  };

  const swapPrimaryChannelOnDowngrade = (
    extraChannels: Array<any>,
    subscriptionKey: string,
    extraChannel: any
  ): Promise<any> => {
    if (updatingSubscriptions) {
      return Promise.reject();
    }

    setUpdatingSubscriptions(true);
    const extraChannelYouTubeId = extraChannel.youtube.id;
    const newPrimaryYTChannel = extraChannel.youtube;

    const updatedExtraYouTubeChannels = getUpdatedExtraYouTubeChannelsWithSwappedChannel(
      subscriptions,
      extraChannels,
      subscriptionKey,
      extraChannelYouTubeId
    );

    return firebaseApiHandler
      .updateExtraChannels(subscriptionKey, updatedExtraYouTubeChannels)
      .then(response => {
        const channels = response.userChannels;
        const mergedSubscriptionsWithSwappedChannels = mergeUpdatedExtraChannelsWithSubscriptions(
          subscriptions,
          channels,
          subscriptionKey
        );
        return useExtraYouTubeChannelAsPrimaryYouTubeChannel(
          subscriptionKey,
          extraChannel.youtube,
          mergedSubscriptionsWithSwappedChannels
        );
      })
      .then(() => {
        const updatedDowngradingSubscription = {
          subscription: {
            ...downgradingSubscription.subscription,
            channel: {
              ...downgradingSubscription.subscription.channel,
              youtube: newPrimaryYTChannel,
            },
            extraChannels: updatedExtraYouTubeChannels,
          },
          planKey: downgradingSubscription.planKey,
        };
        setDowngradingSubscription(updatedDowngradingSubscription);
      })
      .finally(() => {
        setUpdatingSubscriptions(false);
      });
  };

  const useExtraYouTubeChannelAsPrimaryYouTubeChannel = async (
    subscriptionKey: string,
    channel: any,
    updatedSubscriptions: any
  ) => {
    const filteredSubscriptions = subscriptions.filter(subscription => {
      return subscriptionKey === subscription.key;
    });

    const currentSubscription = filteredSubscriptions[0];
    const currentSubscriptionPlan = getSubscriptionPlanKey(currentSubscription);
    const subscriptionHint = SUBSCRIPTIONS.creator.plan_codes.includes(currentSubscriptionPlan)
      ? SUBSCRIPTION_HINTS.free
      : SUBSCRIPTION_HINTS.paid;

    try {
      await isYouTubeChannelConnected(channel.id, subscriptionHint).then(
        (response: ChannelsCheckResponseMdl) => {
          if (!response.connectable) {
            setUpdatingSubscriptions(false);
            throw new YouTubeChannelAlreadyConnectedError('Channel already connected.');
          }
        }
      );
    } catch (error) {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage(
        'Something went wrong when checking if youtube channel is already connected'
      );
      Sentry.captureException(error);
      setUpdatingSubscriptions(false);
      throw error;
    }

    const updatedChannels = getUpdatedSubscriptionsWithNewPrimaryYouTubeChannel(
      subscriptions,
      subscriptionKey,
      channel
    );
    return firebaseApiHandler.updateUserChannels(updatedChannels).then(response => {
      const channels = response.userChannels;
      updateStoredSubscriptions(
        mergeUpdatedChannelsWithSubscriptions(updatedSubscriptions, channels)
      );
    });
  };

  const handleChargebeeCheckout = (
    hostedPagePromise: any,
    hostedPageExpected: boolean,
    planKey: string
  ): Promise<any> => {
    console.log(hostedPagePromise, hostedPageExpected, planKey);
    return new Promise((resolve, reject) => {
      let promiseResponse;

      const close = () => {
        chargebeeInstance.closeAll();
        if (promiseResponse && promiseResponse.onComplete && promiseResponse.hostedPage) {
          promiseResponse
            .onComplete(promiseResponse.hostedPage.id)
            .then(() => {
              resolve();
            })
            .catch(error => {
              // $FlowFixMe: removes type checking for Sentry as provisional solution
              Sentry.captureMessage(
                'Something went wrong when resolving onComplete for handleChargebeeCheckout.'
              );
              Sentry.captureException(error);
              reject(error);
            });
        } else {
          resolve();
        }
      };

      chargebeeInstance.openCheckout({
        // iframe_only: true,
        hostedPage: () => {
          return hostedPagePromise.then(response => {
            promiseResponse = response;
            if (response.hostedPage) {
              return response.hostedPage;
            }
            if (hostedPageExpected) {
              // $FlowFixMe: removes type checking for Sentry as provisional solution
              Sentry.captureMessage(`Expected a hosted page but didn't receive one.`);
              reject();
            } else {
              close();
            }
          });
        },
        success: () => {
          // success callback
          analyticsAccountPayment(planKey);
          close();
        },
        loaded: () => console.log('loaded'),
        error: error => {
          // $FlowFixMe: removes type checking for Sentry as provisional solution
          Sentry.captureMessage(`Something went wrong with chargebee's open checkout.`);
          Sentry.captureException(error);
          reject(error);
        },
        close: () => {
          reject();
        },
      });
    });
  };

  const handleAddNewSubscription = async (planKey: string) => {
    const updatedSubscriptions = subscriptions.slice();
    updatedSubscriptions.push({
      channel: null,
      seat: null,
    });

    let updatedChannels;

    try {
      updatedChannels = await firebaseApiHandler.updateUserChannels(updatedSubscriptions);
    } catch (error) {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage('Something went wrong when updating user channels');
      Sentry.captureException(error);
      setUpdatingSubscriptions(false);
      console.error(`addNewSubscription`, error);
      throw error;
    }

    const preExistingIds: Array<string> = subscriptions.map(subscription =>
      getSubscriptionChannelId(subscription)
    );

    const newChannel = updatedChannels.userChannels.find(channel => {
      //  Need to make sure that the when the new subscription is updated, the new one does not grab an extra channel information and attaches it to the new free channel
      if (!getChannelParentChannel(channel)) {
        return !preExistingIds.includes(channel.id);
      }
    });

    const newSubscription = generateNewSubscription(planKey, newChannel);

    return { newChannel, newSubscription };
  };

  const fetchAndUpdateSubscription = (
    chargebeeKey: string,
    subscription: UserSubscriptionMdl
  ): Promise<UserSubscriptionMdl> => {
    return firebaseApiHandler
      .fetchChargebeeSubscription(chargebeeKey)
      .then((chargebeeResponse: AllocatedSeatMdl) => {
        return {
          ...subscription,
          seat: chargebeeResponse,
        };
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage(
          'Something went wrong when fetching and updating stored subscription'
        );
        Sentry.captureException(error);
        console.error(error);
        return Promise.resolve(subscription);
      });
  };

  const updateStoredPlan = (planKey: string, subscriptionKey: string) => {
    const subscription = getSubscription(subscriptionKey);
    const subscriptionIndex = getSubscriptionIndex(subscriptionKey);
    const updatedSubscription = updateSubscriptionPlan(subscription, planKey);
    const updatedSubscriptions = subscriptions.slice();
    updatedSubscriptions[subscriptionIndex] = updatedSubscription;
    updateStoredSubscriptions(updatedSubscriptions, false);
  };

  const fetchAndUpdateStoredSubscription = (
    chargebeeKey: string,
    channelId: string,
    planKey: string,
    interval: string,
    previousPlanKey: string,
    isFreeTrial: boolean
  ): Promise<UserSubscriptionMdl> => {
    // $FlowFixMe
    return firebaseApiHandler
      .fetchChargebeeSubscription(chargebeeKey)
      .then((chargebeeResponse: AllocatedSeatMdl) => {
        const updatedSubscriptions = subscriptions.slice();
        const subscriptionIndex = updatedSubscriptions.findIndex(
          (subscription: UserSubscriptionMdl) => {
            return subscription.key === channelId;
          }
        );
        const subscription = updatedSubscriptions[subscriptionIndex];
        const previousSubscriptionInterval = getSeatInterval(subscription);
        const updatedSubscription = {
          ...subscription,
          seat: chargebeeResponse,
        };
        updatedSubscriptions.splice(subscriptionIndex, 1, updatedSubscription);
        if (mixpanel && moengage) {
          const subscriptionPlans = getUserSubscriptionPlans(updatedSubscriptions);
          const subscriptionTier = getUserRoles(updatedSubscriptions);
          const coupons = getSubscriptionCoupons(updatedSubscription);
          const couponNames = coupons.map(coupon => {
            return coupon.id;
          });
          const subscriptionsData = {
            subscriptionTier,
            subscriptionPlans,
          };

          const isIntervalDowngrade =
            INTERVAL_MONTHLY_DURATION[interval] <
              INTERVAL_MONTHLY_DURATION[previousSubscriptionInterval] &&
            planKey === previousPlanKey;

          if (!isIntervalDowngrade) {
            analyticsMixpanelAccountUpgradePlan(
              mixpanel,
              moengage,
              planKey,
              previousPlanKey,
              interval,
              previousSubscriptionInterval,
              couponNames,
              conversionSource,
              userId,
              subscriptionsData
            );
            setConversionSource('subscriptions');
          }
        }
        return updateStoredSubscriptions(updatedSubscriptions);
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when fetching subscription');
        Sentry.captureException(error);
        console.error(error);
        updateStoredPlan(planKey, channelId);
      });
  };

  const handlePaidNewSubscription = async (planKey: string, interval: string) => {
    const { newChannel, newSubscription } = await handleAddNewSubscription(planKey);
    const channelId = getChannelId(newChannel);
    const referralCouponCode = getReferralCouponId(referralCoupon);
    return firebaseApiHandler
      .setChannelPlan(channelId, planKey, interval, locale, referralCouponCode)
      .then(({ hostedPage }) => {
        const response = {
          onComplete: (hostedPageId?: string): Promise<any> => {
            return fetchAndUpdateSubscription(hostedPageId, newSubscription)
              .then(subscription => {
                const coupons = getSubscriptionCoupons(subscription);
                const couponNames = coupons.map(coupon => {
                  return coupon.id;
                });
                const mergedSubscriptions = subscriptions.concat([subscription]);
                updateStoredSubscriptions(mergedSubscriptions);
                return { mergedSubscriptions, couponNames };
              })
              .then(response => {
                if (mixpanel && moengage) {
                  const numberSubscribed = response.mergedSubscriptions.length;
                  const subscriptionPlans = getUserSubscriptionPlans(response.mergedSubscriptions);
                  const subscriptionTier = getUserRoles(response.mergedSubscriptions);
                  const subscriptionsData = {
                    subscriptionTier,
                    subscriptionPlans,
                    numberSubscribed,
                  };
                  analyticsAccountAddSubscription(planKey);
                  analyticsMixpanelAccountAddPlan(
                    mixpanel,
                    moengage,
                    planKey,
                    interval,
                    response.couponNames,
                    conversionSource,
                    userId,
                    subscriptionsData
                  );
                  setConversionSource('subscriptions');
                }
                if (response.couponNames.includes(referralCouponCode)) {
                  ls.remove('REFERRAL_COUPON_DATA');
                  setReferralCoupon(null);
                }
                if (getUserFreeTrialEligibility(user)) {
                  updateMixpanelFreeTrialEligibility(mixpanel, moengage, false, userId);
                }
              });
          },
        };
        if (hostedPage) {
          return {
            ...response,
            hostedPage,
          };
        }
        return response;
      });
  };

  const handleFreeNewSubscription = async (planKey: string) => {
    const { newSubscription } = await handleAddNewSubscription(planKey);
    const mergedSubscriptions = subscriptions.concat([newSubscription]);
    await updateStoredSubscriptions(mergedSubscriptions);
    return mergedSubscriptions;
  };

  const addNewSubscription = async (planKey: string, interval: string) => {
    if (updatingSubscriptions) {
      return Promise.reject();
    }

    analyticsAccountAddPlan(planKey);

    setUpdatingSubscriptions(true);

    if (!SUBSCRIPTIONS.creator.plan_codes.includes(planKey)) {
      return handleChargebeeCheckout(
        handlePaidNewSubscription(planKey, interval),
        true,
        planKey
      ).finally(() => {
        setUpdatingSubscriptions(false);
      });
    }

    return handleFreeNewSubscription(planKey)
      .then(mergedSubscriptions => {
        if (SUBSCRIPTIONS.creator.plan_codes.includes(planKey)) {
          if (mixpanel && moengage) {
            const numberSubscribed = mergedSubscriptions.length;
            const subscriptionPlans = getUserSubscriptionPlans(mergedSubscriptions);
            const subscriptionTier = getUserRoles(mergedSubscriptions);
            const subscriptionsData = {
              subscriptionTier,
              subscriptionPlans,
              numberSubscribed,
            };
            analyticsMixpanelAccountAddPlan(
              mixpanel,
              moengage,
              planKey,
              null,
              [],
              conversionSource,
              userId,
              subscriptionsData
            );
            setConversionSource('subscriptions');
          }
        }
      })
      .finally(() => {
        setUpdatingSubscriptions(false);
      });
  };

  const addEnterprisePlaceholderSubscription = async () => {
    const { newSubscription } = await handleAddNewSubscription('free2');

    const newChannel = getSubscriptionChannel(newSubscription);
    return getChannelId(newChannel);
  };

  const openCancelSubscriptionModal = (subscriptionKey: string) => {
    const subscription = getSubscription(subscriptionKey);
    setCancellingSubscription(subscription);
  };

  const openChangeSubscriptionModal = (subscriptionKey: string, extraChannels?: any) => {
    const subscription = getSubscription(subscriptionKey);
    let viewingPlan = SUBSCRIPTIONS.creatorPro.key;

    if (SUBSCRIPTIONS.creatorPro.plan_codes.includes(getSubscriptionPlanKey(subscription))) {
      viewingPlan = SUBSCRIPTIONS.business.key;
    }

    if (extraChannels) {
      if (extraChannels.length > 0) {
        setDowngradingExtraChannels(extraChannels);
      }
    } else {
      setDowngradingExtraChannels([]);
    }

    setSelectedPlan(viewingPlan);
    setChangingSubscription(subscription);
  };

  const removeScheduledChanges = async (subscriptionKey: string): Promise<any> => {
    if (updatingSubscriptions) {
      return Promise.reject();
    }
    setUpdatingSubscriptions(true);

    const subscription = subscriptions.find((checkSubscription: UserSubscriptionMdl) => {
      return checkSubscription.key === subscriptionKey;
    });
    const subscriptionStatus = getSubscriptionStatus(subscription);
    const subscriptionPlanKey = getSubscriptionPlanKey(subscription);
    const isSubscriptionFreeTrial = subscriptionStatus === SUBSCRIPTION_STATUSES.trial_cancelled;

    return firebaseApiHandler
      .removeScheduledChanges(getSubscriptionChannelId(subscription))
      .then(async () => {
        // Undo cancellation on trial is not fetching most updated Firestore data
        // Will undone cancellations directly on UI and then on next fetch to Firestore it will bring the most updated information
        const updatedSubscriptions = mergeSubscriptionsWithRemovedScheduledChange(
          subscription,
          subscriptions
        );
        await setSubscriptions(updatedSubscriptions);
        return updatedSubscriptions;
      })
      .then(updatedSubscriptions => {
        if (
          subscriptionStatus === SUBSCRIPTION_STATUSES.cancel_pending ||
          subscriptionStatus === SUBSCRIPTION_STATUSES.trial_cancelled
        ) {
          const nextCancellationSubscription = getNextScheduledChangeSubscription(
            updatedSubscriptions,
            [SUBSCRIPTION_STATUSES.cancel_pending, SUBSCRIPTION_STATUSES.trial_cancelled]
          );
          const nextCancellationSubscriptionType = nextCancellationSubscription
            ? getSubscriptionPlanKey(nextCancellationSubscription)
            : null;
          let nextCancellationSubscriptionDate = 0;

          if (nextCancellationSubscription) {
            nextCancellationSubscriptionDate =
              getSubscriptionStatus(nextCancellationSubscription) ===
              SUBSCRIPTION_STATUSES.cancel_pending
                ? get(nextCancellationSubscription, 'seat.validUntilSeconds', 0) * 1000
                : get(nextCancellationSubscription, 'seat.trialPlanValidUntilSeconds', 0) * 1000;
          }

          analyticsMixpanelAccountUndoCancellation(
            mixpanel,
            moengage,
            subscriptionPlanKey,
            isSubscriptionFreeTrial,
            userId,
            nextCancellationSubscriptionType,
            nextCancellationSubscriptionDate
          );
        } else {
          const nextDowngradeSubscription = getNextScheduledChangeSubscription(
            updatedSubscriptions,
            [SUBSCRIPTION_STATUSES.change_pending]
          );
          const nextDowngradeSubscriptionType = nextDowngradeSubscription
            ? getSubscriptionPlanKey(nextDowngradeSubscription)
            : null;
          const nextDowngradeSubscriptionDate = nextDowngradeSubscription
            ? get(nextDowngradeSubscription, 'seat.validUntilSeconds', 0) * 1000
            : null;
          analyticsMixpanelAccountUndoDowngrade(
            mixpanel,
            moengage,
            subscriptionPlanKey,
            userId,
            nextDowngradeSubscriptionType,
            nextDowngradeSubscriptionDate
          );
        }
        setUpdatingSubscriptions(false);
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when removing scheduled changes');
        Sentry.captureException(error);
        setUpdatingSubscriptions(false);
        console.error(error);
        throw error;
      });
  };

  const handleChangePlan = async (
    planKey: string,
    channelId: string,
    interval: string,
    previousPlanKey: string,
    previousInterval: string,
    isFreeTrial: boolean,
    isYouTubeConnected: boolean,
    daysRemainingOnTrial: number | null
  ): Promise<any> => {
    return handleChargebeeCheckout(
      firebaseApiHandler
        .setChannelPlan(channelId, planKey, interval, locale)
        .then(({ hostedPage }) => {
          const response = {
            onComplete: (hostedPageId?: string): Promise<any> => {
              if (isFreeTrial) {
                if (mixpanel && moengage) {
                  const freeTrialChangeType = getFreeTrialChangeType(
                    planKey,
                    previousPlanKey,
                    interval,
                    previousInterval
                  );
                  analyticsMixpanelAccountEndTrial(
                    mixpanel,
                    moengage,
                    planKey,
                    isYouTubeConnected,
                    daysRemainingOnTrial,
                    freeTrialChangeType,
                    userId
                  );
                }
              }
              return fetchAndUpdateStoredSubscription(
                hostedPageId,
                channelId,
                planKey,
                interval,
                previousPlanKey,
                isFreeTrial
              );
            },
          };
          if (hostedPage) {
            return {
              ...response,
              hostedPage,
            };
          }
          return response;
        }),
      false,
      planKey
    );
  };

  const showDowngradeModal = (subscriptionKey: string, planKey: string) => {
    const subscription = getSubscription(subscriptionKey);
    setDowngradingSubscription({
      subscription,
      planKey,
    });
  };

  const showChangingIntervalModal = (subscriptionKey: string, planKey: string) => {
    const subscription = getSubscription(subscriptionKey);
    setChangingIntervalSubscription({
      subscription,
      planKey,
    });
  };

  const showFreeTrialChangeModal = (
    subscriptionKey: string,
    planKey: string,
    actionState: string
  ) => {
    const subscription = getSubscription(subscriptionKey);
    setChangingFreeTrialSubscription({
      subscription,
      planKey,
      actionState,
    });
  };

  const upgradeSubscription = async (
    subscriptionKey: string,
    planKey: string,
    interval: string
  ) => {
    if (updatingSubscriptions) {
      return Promise.reject();
    }
    setUpdatingSubscriptions(true);

    const subscription = getSubscription(subscriptionKey);
    const previousSubscriptionInterval = getSeatInterval(subscription);
    const isCurrentSubscriptionFreeTrial =
      getSubscriptionStatus(subscription) === SUBSCRIPTION_STATUSES.trial ? true : false;
    const isYouTubeConnected = doesSubscriptionHaveYouTubeChannelConnected(subscription);
    const daysRemainingOnTrial = isCurrentSubscriptionFreeTrial
      ? getDaysUntilDate(get(subscription, 'seat.trialPlanValidUntilSeconds', 0) * 1000)
      : null;
    const previousPlanKey = getSubscriptionPlanKey(subscription);
    analyticsAccountUpgrade(previousPlanKey);

    return handleChangePlan(
      planKey,
      getSubscriptionChannelId(subscription),
      interval,
      previousPlanKey,
      previousSubscriptionInterval,
      isCurrentSubscriptionFreeTrial,
      isYouTubeConnected,
      daysRemainingOnTrial
    ).finally(() => {
      setUpdatingSubscriptions(false);
    });
  };

  const downgradeSubscription = async (
    subscriptionKey: string,
    planKey: string,
    interval: string
  ) => {
    if (updatingSubscriptions) {
      return Promise.reject();
    }
    setUpdatingSubscriptions(true);

    const subscription = getSubscription(subscriptionKey);
    const previousSubscriptionInterval = getSeatInterval(subscription);

    await firebaseApiHandler.setChannelPlan(
      getSubscriptionChannelId(subscription),
      planKey,
      interval,
      locale
    );
    const previousPlanKey = getSubscriptionPlanKey(subscription);
    analyticsAccountDowngrade(previousPlanKey);
    const subscriptionIndex = subscriptions.findIndex(findSubscription => {
      return findSubscription.key === subscriptionKey;
    });

    const updatedSubscription = updateSubscriptionStatus(
      subscription,
      SUBSCRIPTION_STATUSES.change_pending
    );
    const updatedSubscriptions = subscriptions.slice();
    updatedSubscriptions[subscriptionIndex] = updatedSubscription;
    await updateStoredSubscriptions(updatedSubscriptions);

    const nextDowngradeSubscription = getNextScheduledChangeSubscription(updatedSubscriptions, [
      SUBSCRIPTION_STATUSES.change_pending,
    ]);
    const nextDowngradeSubscriptionType = nextDowngradeSubscription
      ? getSubscriptionPlanKey(nextDowngradeSubscription)
      : null;
    const nextDowngradeSubscriptionDate = nextDowngradeSubscription
      ? get(nextDowngradeSubscription, 'seat.validUntilSeconds', 0) * 1000
      : null;

    if (mixpanel && moengage) {
      analyticsMixpanelAccountScheduleDowngrade(
        mixpanel,
        moengage,
        planKey,
        previousPlanKey,
        interval,
        previousSubscriptionInterval,
        userId,
        nextDowngradeSubscriptionType,
        nextDowngradeSubscriptionDate
      );
    }
    setUpdatingSubscriptions(false);
  };

  const updateSubscriptionInterval = async (
    subscriptionKey: string,
    planKey: string,
    interval: string
  ) => {
    if (updatingSubscriptions) {
      return Promise.reject();
    }
    setUpdatingSubscriptions(true);

    const subscription = getSubscription(subscriptionKey);
    const previousSubscriptionInterval = getSeatInterval(subscription);
    const isCurrentSubscriptionFreeTrial =
      getSubscriptionStatus(subscription) === SUBSCRIPTION_STATUSES.trial;
    const isYouTubeConnected = doesSubscriptionHaveYouTubeChannelConnected(subscription);
    const daysRemainingOnTrial = isCurrentSubscriptionFreeTrial
      ? getDaysUntilDate(get(subscription, 'seat.trialPlanValidUntilSeconds', 0) * 1000)
      : null;

    return handleChangePlan(
      planKey,
      getSubscriptionChannelId(subscription),
      interval,
      planKey,
      previousSubscriptionInterval,
      isCurrentSubscriptionFreeTrial,
      isYouTubeConnected,
      daysRemainingOnTrial
    ).then(() => {
      analyticsPlanIntervalChange(getSubscriptionPlanKey(subscription));
      setUpdatingSubscriptions(false);
    });
  };

  const removeSubscription = async (subscriptionKey: string, isTrialSubscription: boolean) => {
    if (updatingSubscriptions) {
      return Promise.reject();
    }
    setUpdatingSubscriptions(true);

    const subscriptionIndex = subscriptions.findIndex(findSubscription => {
      return findSubscription.key === subscriptionKey;
    });
    const subscription = subscriptions[subscriptionIndex];
    await firebaseApiHandler.setChannelPlan(
      getSubscriptionChannelId(subscription),
      null,
      null,
      locale
    );

    const subscriptionPlanKey = getSubscriptionPlanKey(subscription);
    const isSubscriptionFreeTrial =
      getSubscriptionStatus(subscription) === SUBSCRIPTION_STATUSES.trial ? true : false;
    analyticsAccountCancel(subscriptionPlanKey);
    const updatedSubscriptions = subscriptions.slice();

    if (SUBSCRIPTIONS.creator.plan_codes.includes(subscriptionPlanKey)) {
      updatedSubscriptions.splice(subscriptionIndex, 1);
      await updateStoredSubscriptions(updatedSubscriptions);
      const subscriptionPlans = getUserSubscriptionPlans(updatedSubscriptions);
      const subscriptionTier = getUserRoles(updatedSubscriptions);
      const numberSubscribed = updatedSubscriptions.length;

      const subscriptionsData = {
        subscriptionPlans,
        subscriptionTier,
        numberSubscribed,
      };

      const isYouTubeConnected = doesSubscriptionHaveYouTubeChannelConnected(subscription);
      const youtubeChannels = useConnectedYouTubeChannels(updatedSubscriptions);

      analyticsMixpanelAccountCancelSubscription(
        mixpanel,
        moengage,
        subscriptionPlanKey,
        userId,
        subscriptionsData
      );

      if (isYouTubeConnected) {
        analyticsMixpanelAccountYouTubeDisconnection(
          mixpanel,
          moengage,
          subscriptionPlanKey,
          'Cancellation',
          userId,
          youtubeChannels,
          true
        );
      }
    } else {
      const updatedSubscriptionStatus = !isTrialSubscription
        ? SUBSCRIPTION_STATUSES.cancel_pending
        : SUBSCRIPTION_STATUSES.trial_cancelled;
      const updatedSubscription = updateSubscriptionStatus(subscription, updatedSubscriptionStatus);
      updatedSubscriptions[subscriptionIndex] = updatedSubscription;
      await updateStoredSubscriptions(updatedSubscriptions);

      const nextCancellationSubscription = getNextScheduledChangeSubscription(
        updatedSubscriptions,
        [SUBSCRIPTION_STATUSES.cancel_pending, SUBSCRIPTION_STATUSES.trial_cancelled]
      );
      const nextCancellationSubscriptionType = nextCancellationSubscription
        ? getSubscriptionPlanKey(nextCancellationSubscription)
        : null;
      let nextCancellationSubscriptionDate = 0;

      if (nextCancellationSubscription) {
        nextCancellationSubscriptionDate =
          getSubscriptionStatus(nextCancellationSubscription) ===
          SUBSCRIPTION_STATUSES.cancel_pending
            ? get(nextCancellationSubscription, 'seat.validUntilSeconds', 0) * 1000
            : get(nextCancellationSubscription, 'seat.trialPlanValidUntilSeconds', 0) * 1000;
      }

      analyticsMixpanelAccountScheduleCancellation(
        mixpanel,
        moengage,
        subscriptionPlanKey,
        isSubscriptionFreeTrial,
        userId,
        nextCancellationSubscriptionType,
        nextCancellationSubscriptionDate
      );
    }

    setUpdatingSubscriptions(false);
  };

  const handleClose = () => {
    setSelectedPlanNumber(null);
    setSelectedPlan(null);
  };

  const handleCloseChangeModal = () => {
    setChangingSubscription(null);
    setSelectedPlan(null);
  };

  const handleCloseCancelModal = () => {
    setSelectedPlanNumber(null);
    setCancellingSubscription(null);
  };

  const handleCloseDowngradingModal = () => {
    setDowngradingExtraChannels([]);
    setSelectedPlanNumber(null);
    setDowngradingSubscription(null);
  };

  const openAllowlistUnsuccessfulModal = deniedChannels => {
    setDataFromOpenedAllowlistUnsuccess(deniedChannels);
    setopenAllowModal(true);
  };
  const closeAllowlistUnsuccessfulModal = subscription => {
    setopenAllowModal(false);
  };
  const confirmAllowlistUnsuccessHandler = () => {
    setConfirmAllowlistUnsuccess(true);
  };

  const handleCloseChangingIntervalModal = () => {
    setSelectedPlan(null);
    setChangingIntervalSubscription(null);
  };

  const handleCloseChangeFreeTrialSubscriptionModal = () => {
    setSelectedPlan(null);
    setChangingFreeTrialSubscription(null);
  };

  const isAtLeastOneSocialAccountConnected = (): boolean => {
    const accountConnectedSubscriptions = subscriptions.filter(
      (subscription: UserSubscriptionMdl) => {
        return doesSubscriptionHaveYouTubeChannelConnected(subscription);
      }
    );
    return accountConnectedSubscriptions.length > 0;
  };

  useEffect(() => {
    if (userId) {
      fetchUserSubscriptions();
    }
  }, [userId]);

  useEffect(() => {
    const coupon = ls.get('REFERRAL_COUPON_DATA');
    if (coupon) {
      setReferralCoupon(coupon);
    }
  }, [authenticated]);

  useEffect(() => {
    if (referralCoupon) {
      if (userId && user) {
        const referralType = getReferralCouponType(referralCoupon);
        const isEligibleForReferral = getReferralEligibility(referralType, isUserFreeTrialEligible);

        if (!isEligibleForReferral) {
          ls.remove('REFERRAL_COUPON_DATA');
          setReferralCoupon(null);
          navigate(ROUTES.music.navigatePath({}));
        } else {
          setReferralCoupon(referralCoupon);
          setShowReferralBanner(true);
        }
      }
    }
  }, [referralCoupon, userId, isUserFreeTrialEligible]);

  useEffect(() => {
    if (subscriptions.length) {
      const updatedUserRole = getUserRoles(subscriptions);
      setUserRole(updatedUserRole);
    } else if (userId) {
      setUserRole(USER_ROLES.noSubscriptions);
    } else {
      setUserRole(USER_ROLES.guest);
    }
  }, [subscriptions, userId]);

  return (
    <SubscriptionsContext.Provider
      value={{
        loaded,
        loading,
        subscriptions,
        fetchUserSubscriptions,
        selectPlan,
        addYouTubeChannelToSubscription,
        addExtraYouTubeChannelToSubscription,
        removeYouTubeChannelFromSubscription,
        removeExtraYouTubeChannelFromSubscription,
        addNewSubscription,
        addEnterprisePlaceholderSubscription,
        openCancelSubscriptionModal,
        removeSubscription,
        removeScheduledChanges,
        openChangeSubscriptionModal,
        downgradeSubscription,
        showDowngradeModal,
        showChangingIntervalModal,
        showFreeTrialChangeModal,
        upgradeSubscription,
        updateSubscriptionInterval,
        isAtLeastOneSocialAccountConnected,
        isPaidCustomer,
        isFreeCustomer,
        hasSubscription,
        billingCycle,
        setBillingCycle,
        selectedPlanNumber,
        setSelectedPlanNumber,
        userRole,
        downgradingExtraChannels,
        swapPrimaryChannelOnDowngrade,
        openAllowlistUnsuccessfulModal,
        confirmAllowlistUnsuccess,
        fetchChannelAllowlist,
        referralCoupon,
        setReferralCoupon,
        showReferralBanner,
        setShowReferralBanner,
      }}
    >
      {children}
      {changingSubscription && (
        <ChangeSubscriptionModal
          subscription={changingSubscription}
          selectPlan={selectPlan}
          selectedPlan={selectedPlan}
          onClose={handleCloseChangeModal}
        />
      )}
      {selectedPlan && !changingSubscription && (
        <AddSubscriptionModal
          selectPlan={selectPlan}
          selectedPlan={selectedPlan}
          onClose={handleClose}
        />
      )}
      {selectedPlan && isEnterpriseBasicSubscription(selectedPlan) && !changingSubscription && (
        <AddEnterpriseBasicSubscriptionModal
          selectPlan={selectPlan}
          selectedPlan={selectedPlan}
          onClose={handleClose}
        />
      )}
      {cancellingSubscription && (
        <CancelSubscriptionModal
          onClose={handleCloseCancelModal}
          subscription={cancellingSubscription}
        />
      )}
      {downgradingSubscription && (
        <DowngradeSubscriptionModal
          subscription={downgradingSubscription.subscription}
          newPlanKey={downgradingSubscription.planKey}
          onClose={handleCloseDowngradingModal}
        />
      )}
      {changingIntervalSubscription && (
        <ChangeIntervalSubscriptionModal
          subscription={changingIntervalSubscription.subscription}
          newPlanInterval={billingCycle}
          onClose={handleCloseChangingIntervalModal}
        />
      )}
      {changingFreeTrialSubscription && (
        <ChangeFreeTrialSubscriptionModal
          subscription={changingFreeTrialSubscription.subscription}
          actionState={changingFreeTrialSubscription.actionState}
          newPlanKey={changingFreeTrialSubscription.planKey}
          newPlanInterval={billingCycle}
          onClose={handleCloseChangeFreeTrialSubscriptionModal}
        />
      )}
      {openAllowModal && (
        <AllowlistUnsuccessfulModal
          deniedChannels={dataFromOpenedAllowlistUnsuccess}
          onClose={closeAllowlistUnsuccessfulModal}
          onConfirm={confirmAllowlistUnsuccessHandler}
        />
      )}
    </SubscriptionsContext.Provider>
  );
};

export default SubscriptionsContextWrapper;
