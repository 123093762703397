// @flow
import React from 'react';
import { css, cx } from 'emotion';
import styled from 'react-emotion';
import FullMixIcon from '../../../../../assets/inline-assets/type-fullmix-icon.svg';
import PartComboIcon from '../../../../../assets/inline-assets/type-part-combo-icon.svg';
import PartMixIcon from '../../../../../assets/inline-assets/type-part-icon.svg';
import {
  bodyTextCss,
  mediumHeadingCss,
  monoFontCss,
} from '../../../../../styles/typography/typography';
import { LARGE_DEVICE_BREAKPOINT, SMALL_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import type { AlgoliaSongMdl } from '../../../../../api/algolia/song';
import { getSongMixType, getSongType } from '../../../../../api/algolia/song';
import { useTranslation } from 'react-i18next';

export const SONG_TYPES = {
  fullMix: 'full_mix',
  rhythmPart: 'rhythm_part',
  bassPart: 'bass_part',
  harmonyPart: 'harmony_part',
  melodyPart: 'melody_part',
  dnbPart: 'dnb_part',
  karaokePart: 'karaoke_part',
};

const SongIcon = ({ type }) => {
  if (type === SONG_TYPES.fullMix) {
    return <FullMixIcon />;
  }
  if (type === SONG_TYPES.dnbPart || type === SONG_TYPES.karaokePart) {
    return <PartComboIcon />;
  }
  return <PartMixIcon />;
};

const Container = styled('div')`
  ${mediumHeadingCss};
  display: flex;
  align-items: center;

  ${LARGE_DEVICE_BREAKPOINT} {
    margin-top: -2px;
    margin-left: -4px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    ${bodyTextCss};
    line-height: 18px;
  }

  svg {
    display: block;
    margin-right: 11px;
  }

  span {
    ${monoFontCss};
    display: inline-block;
    margin-left: 5px;
    opacity: 0.4;
    letter-spacing: initial;
  }
`;

const condensedClass = css`
  ${bodyTextCss};

  ${LARGE_DEVICE_BREAKPOINT} {
    margin: 0;
  }

  svg {
    width: 18px;
    height: 18px;
    margin-right: 7px;
    margin-top: -2px;
  }
`;

type Props = {
  condensed?: boolean,
  song: AlgoliaSongMdl,
};

const SongType = ({ condensed = false, song }: Props) => {
  const { t } = useTranslation();

  const songType = getSongType(song);
  const mixType = getSongMixType(song);

  return (
    <Container
      className={cx({
        [condensedClass]: condensed,
      })}
    >
      <SongIcon type={songType} />
      {mixType} {song.isPart && !condensed && <span>{t('general.text.part', '')}</span>}
    </Container>
  );
};

export default SongType;
