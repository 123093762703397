// @flow
import React, { useEffect } from 'react';
import styled from 'react-emotion';
import BrowseSongsManager from '../../../../../../../song/components/GlobalSongPlayer/components/BrowseSongsManager/BrowseSongsManager';
import { DarkButton } from '../../../../../../../components/Button/Button';
import { useDownloadsManagerContext } from '../DownloadHistoryManager/DownloadHistoryManager';
import GroupedTracksList from '../GroupedTracksList/GroupedTracksList';
import GroupOfTracks from '../GroupOfTracks/GroupOfTracks';
import { getHistoryDateFormat } from '../../../../../../../utils/date';
import { useTranslation } from 'react-i18next';

const SongsListContainer = styled('div')`
  width: 100%;
  margin: 0 auto;
`;

const LoadWrapper = styled('div')`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

const DownloadHistorySongs = () => {
  const { t } = useTranslation();

  const {
    numberOfVisibleGroups,
    setNumberOfVisibleGroups,
    groupedDownloads,
    viewingDownloads,
    remainingGroups,
  } = useDownloadsManagerContext();

  const handleLoadMoreGroups = () => {
    const newSongsShown = numberOfVisibleGroups + 10;
    setNumberOfVisibleGroups(newSongsShown);
  };

  useEffect(() => {
    setNumberOfVisibleGroups(10);
  }, [viewingDownloads]);

  const allDownloadSongs = [].concat(
    ...groupedDownloads.map(group => {
      return group.tracks;
    })
  );

  return (
    <>
      {groupedDownloads.length > 0 && (
        <BrowseSongsManager
          songs={allDownloadSongs}
          songListType="downloads"
          songListTitle="downloads"
          songListSlug="downloads"
          songlistKeywords={[]}
          sectionOrigin="downloads"
        >
          <SongsListContainer>
            <GroupedTracksList>
              {groupedDownloads.slice(0, numberOfVisibleGroups).map((group: GroupedSongs) => {
                const groupKey = `${group.date}`;
                return (
                  <GroupOfTracks
                    key={groupKey}
                    groupKey={groupKey}
                    firstHeading={getHistoryDateFormat(group.date)}
                    secondHeading=""
                    tracks={group.tracks}
                  />
                );
              })}
            </GroupedTracksList>
            {remainingGroups && (
              <LoadWrapper>
                <DarkButton onClick={handleLoadMoreGroups}>
                  {t('general.button.loadMore', '')}
                </DarkButton>
              </LoadWrapper>
            )}
          </SongsListContainer>
        </BrowseSongsManager>
      )}
    </>
  );
};

export default DownloadHistorySongs;
