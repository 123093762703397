// @flow
import React from 'react';
import styled from 'react-emotion';
import { get } from 'lodash';
import { cx } from 'emotion';
import { transparentize } from 'polished';
import { buttonResetCss } from '../../../Button/Button';
import { monoNavCss } from '../../../../styles/typography/typography';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../styles/responsive';
import { darkColor, lightColor, brightColor } from '../../../../styles/config/colors';
import { useFreeTrialSubscriptions } from '../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { getDaysUntilDate } from '../../../../utils/date';

const trialCountOpen = 'trialCountOpen';

const Container = styled('button')`
  ${buttonResetCss};
  ${monoNavCss};
  border-radius: 3px;
  width: 123px;
  height: 39px;
  background-color: #4d4d4d;
  border: 0.5px solid ${transparentize(0.9, lightColor)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-image: ${props => (props.image ? `url('${props.image}')` : '')};
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;

  ${SMALL_DEVICE_BREAKPOINT} {
    display none;
  }

  &.${trialCountOpen} {
    background-color: #25272c;
    border: 0.5px solid ${transparentize(1, lightColor)};
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  }

  &:focus {
    outline: none;
  }
`;

const Label = styled('p')`
  text-transform: uppercase;
`;

const Count = styled('div')`
  background-color: ${brightColor};
  color: ${darkColor};
  font-family: Roboto Mono Regular, sans-serif;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 1px;
  width: 13px;
  height: 13px;
  font-size: 9px;
  line-height: 11px;
  display: flex;
  justify-content: center;
  letter-spacing: 0;
  font-weight: 400;

  p {
    width: 100%;
    height: 100%;
    text-align: center;
  }
`;

type Props = {
  isTrialCountOpen: boolean,
};

const TrialCounter = ({ isTrialCountOpen }: Props) => {
  const freeTrialSubscription = useFreeTrialSubscriptions()[0];
  const remainingTrialDayCount = getDaysUntilDate(
    get(freeTrialSubscription, 'seat.trialPlanValidUntilSeconds', 0) * 1000
  );

  return (
    <Container className={cx({ [trialCountOpen]: isTrialCountOpen })} type="button">
      <Count>
        <p>{remainingTrialDayCount >= 0 ? remainingTrialDayCount : 0}</p>
      </Count>
    </Container>
  );
};

export default TrialCounter;
