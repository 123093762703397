// @flow
import React, { useState } from 'react';
import { css, cx } from 'emotion';
import { CenteredMediumContainer } from '../../../../../styles/layout';
import { LargeHeading } from '../../../../../styles/typography/components/Heading/Heading';
import PaymentMethod from '../ProfileSubscriptionsView/components/PaymentMethod/PaymentMethod';
import BillingHistory from '../ProfileSubscriptionsView/components/BillingHistory/BillingHistory';
import LoadingDisplay, {
  LoadingContainer,
} from '../../../../../components/LoadingDisplay/LoadingDisplay';
import PageComponent from '../../../../components/PageComponent/PageComponent';
import { useTranslation } from 'react-i18next';

const hiddenClass = css`
  display: none;
`;

const paymentMethodClass = css`
  margin-top: 70px;
`;

type Props = {
  uri: string,
};

const ProfileBillingView = ({ uri }: Props) => {
  const { t } = useTranslation();

  const [paymentLoaded, setPaymentLoaded] = useState(false);
  const [billingLoaded, setBillingLoaded] = useState(false);
  const isLoading = !paymentLoaded || !billingLoaded;

  return (
    <PageComponent path={uri}>
      <CenteredMediumContainer
        className={cx({
          [hiddenClass]: isLoading,
        })}
        responsiveFullWidth
      >
        <header>
          <LargeHeading>{t('billing.header', '')}</LargeHeading>
        </header>
        <PaymentMethod
          className={paymentMethodClass}
          onLoad={() => {
            setPaymentLoaded(true);
          }}
        />
        <BillingHistory
          onLoad={() => {
            setBillingLoaded(true);
          }}
        />
      </CenteredMediumContainer>
      {isLoading && (
        <LoadingContainer fixed>
          <LoadingDisplay />
        </LoadingContainer>
      )}
    </PageComponent>
  );
};

export default ProfileBillingView;
