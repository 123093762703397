// @flow

import { get } from 'lodash';
import { graphql } from 'gatsby';
import type { CuratedCollectionRelatedCollectionDocumentGraphMdl } from './curatedCollection';

export type CollectionsPageGraphMdl = {
  common_related_collections: Array<CuratedCollectionRelatedCollectionDocumentGraphMdl>,
};

export const getCollectionsPageCommonRelatedCollections = (
  collection: CollectionsPageGraphMdl
): Array<CuratedCollectionRelatedCollectionDocumentGraphMdl> => {
  return get(collection, 'data.common_related_collections', []) || [];
};

export const query = graphql`
  fragment CollectionsPage on PrismicCollectionsPage {
    data {
      common_related_collections {
        related_collection {
          document {
            uid
            data {
              collection_image {
                url
              }
              collection_title {
                text
              }
              collection_songs {
                text
              }
            }
          }
        }
      }
    }
  }
`;
