// @flow
import React, { useEffect } from 'react';
import SearchIcon from '../../../../../../../../../assets/inline-assets/search-icon.svg';
import { useMusicSearchBarContext } from '../../../../../../../../../components/SiteHeader/components/SiteNav/components/MusicSearchBarWrapper/MusicSearchBarWrapper';
import { useResultsManagerContext } from '../../../ResultsManager/ResultsManager';
import SearchResultsMessageLayout from '../SearchResultsMessageLayout/SearchResultsMessageLayout';
import { useTranslation } from 'react-i18next';

const EmptySearch = () => {
  const { t } = useTranslation();

  const { handleOpenSearch } = useMusicSearchBarContext();
  const { handleClearSongFilters, appliedFilters } = useResultsManagerContext();

  useEffect(() => {
    handleOpenSearch();

    if (Object.keys(appliedFilters).length > 0) {
      handleClearSongFilters();
    }
  }, []);

  return (
    <SearchResultsMessageLayout
      title={t('noResultsPage.emptySearch.header', '')}
      description={t('noResultsPage.emptySearch.message', '')}
      icon={<SearchIcon />}
      buttonText={t('general.button.backToBrowse', '')}
      navigateBack
    />
  );
};

export default EmptySearch;
