// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { transparentize } from 'polished';
import {
  getFacetKey,
  getFilterLabel,
  getAppliedFiltersExclusiveList,
  getAppliedFiltersInclusiveList,
  getFacetStatsMax,
  getFacetStatsMin,
  getGroupedBooleanFilters,
  GROUPED_FILTER_LABELS,
} from '../../../../../../../../../api/algolia/data';
import { darkColor } from '../../../../../../../../../styles/config/colors';
import { mediumFontWeightCss } from '../../../../../../../../../styles/typography/typography';
import CloseIcon from '../../../../../../../../../assets/inline-assets/close.svg';
import PlusIcon from '../../../../../../../../../assets/inline-assets/plus-icon.svg';
import MinusIcon from '../../../../../../../../../assets/inline-assets/minus-icon.svg';
import { useResultsManagerContext } from '../../../ResultsManager/ResultsManager';
import { formatSeconds } from '../../../../../../../../../utils/time';
import { USER_PERMISSIONS } from '../../../../../../../../../user/subscriptions/data';
import { useSubscriptionsContext } from '../../../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import PadlockIcon from '../../../../../../../../../assets/inline-assets/padlock-icon.svg';
import { useLocale } from '../../../../../../../../components/LocaleWrapper/LocaleWrapper';
import { useIsAuthenticated } from '../../../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { SmallHeading } from '../../../../../../../../../styles/typography/components/Heading/Heading';
import { TextButton } from '../../../../../../../../../components/Button/Button';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../../../../../styles/responsive';
import { useTranslation } from 'react-i18next';

const isGroupApplied = (appliedFitlers, groupedFilters) => {
  let groupIsApplied = true;
  groupedFilters.forEach(groupedFilter => {
    const groupedFilterIsApplied = appliedFitlers.includes(groupedFilter);
    if (!groupedFilterIsApplied) {
      groupIsApplied = false;
    }
  });
  return groupIsApplied;
};

type Props = {
  appliedFilters: any,
  handleOpenModalMenu: string => void,
  selectedFilter: string,
};

type InteractiveFilterProps = {
  facetKey: string,
  appliedValue?: string,
  handleOpenModalMenu: string => void,
  selectedFilter: string,
};

type GroupedInteractiveFilterProps = {
  facetKey: string,
  excludedFilters?: Array<string>,
  includedFilters?: Array<string>,
  handleOpenModalMenu: string => void,
  isSelectedFilter: boolean,
  groupedLabel: string,
  groupedFilters: any,
};

type InternalFilterProps = {
  filterKey: string,
  handleOpenModalMenu: string => void,
};

const Header = styled('header')`
  padding: 15px 15px 20px 15px;

  ${SMALL_DEVICE_BREAKPOINT} {
    justify-content: center;
  }
`;

const HeadingOption = styled('div')`
  line-height: 20px;
  margin-left: 8px;
`;

const FilterListWrapper = styled('ul')`
  display: flex;
  max-height: 465px;
  overflow-x: scroll;
  scrollbar-width: thin;
  scrollbar-color: black white;
  overflow-y: auto;
  width: 100%;
  justify-content: center;

  &.bannerOverflow {
    max-height: 390px;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dad8d3;
  }
`;

const InteractiveFilterWrapper = styled('div')`
  display: block;
`;

const InteractiveFilter = styled('li')`
  color: ${transparentize(0.6, darkColor)};
  padding: 15px 15px 20px 15px;

  &:hover {
    cursor: pointer;
    color: ${darkColor};
    text-decoration: underline;
  }
`;

const PadlockContainer = styled('span')`
  margin-left: 10px;
`;

const activeInteractiveFilter = css`
  opcaity: 1;
  background: ${transparentize(0.9, darkColor)};
  color: ${darkColor};
  border-radius: 2px 0 0 2px;
  font-weight: ${mediumFontWeightCss};
`;

const hasActiveFilters = css`
  color: ${darkColor};
  ${mediumFontWeightCss};
`;

const restrictedFilter = css`
  text-decoration: line-through;
  color: ${transparentize(0.6, darkColor)} !important;

  &:hover {
    cursor: pointer;
    text-decoration: line-through;
    color: ${transparentize(0.6, darkColor)} !important;
  }
`;

const StyledIconFilter = styled('div')`
  display: flex;
  margin-left: 5px;
  padding: 2px 5px;
  cursor: pointer;
  width: fit-content;
  border-radius: 2px;

  &:hover {
    background-color: ${transparentize(0.9, darkColor)};

    & .closeIcon {
      display: inline;
      top: -1px;
    }

    & .plusMinusIcon {
      display: none;
    }
  }
`;

const Icon = styled('div')`
  width: 10px;
  margin-right: 2px;
  & svg {
    margin-right: 5px;
    position: relative;
  }

  &.plusIcon svg {
    top: -1px;
  }

  &.minusIcon svg {
    top: -4px;
  }

  & .closeIcon {
    display: none;
    transform: scale(0.45) translateY(10px) translateX(-10px);
  }
`;

const AppliedFilter = styled('li')`
  text-transform: capitalize;
  font-weight: 400;
  max-width: 149px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const clearButton = css`
  opacity: 0.4;
`;

const noFiltersApplied = css`
  display: none;
`;

export const getFormattedRangeLabel = (facetKey: string, min: number, max: number): string => {
  if (facetKey === 'audio.duration') {
    return `${formatSeconds(min / 1000)}-${formatSeconds(max / 1000)}`;
  }
  return `${min}-${max}`;
};

type AppliedRangeFilterProps = {
  minValue: number,
  maxValue: number,
  facetKey: string,
  resetSongsFilter: () => void,
};

const AppliedRangeFilter = ({
  minValue,
  maxValue,
  facetKey,
  resetSongsFilter,
}: AppliedRangeFilterProps) => {
  return (
    <StyledIconFilter
      key={`min:${minValue}max:${maxValue}`}
      className="plusIcon"
      onClick={() => {
        resetSongsFilter(facetKey);
      }}
    >
      <Icon className="plusIcon">
        <CloseIcon className="closeIcon" />
        <PlusIcon className="plusMinusIcon" />
      </Icon>
      <AppliedFilter>{getFormattedRangeLabel(facetKey, minValue, maxValue)}</AppliedFilter>
    </StyledIconFilter>
  );
};

type AppliedTextFilterProps = {
  facetKey: string,
  filter: string,
  isInclusive: boolean,
  handleRemoveSongsFilter: () => void,
};

const AppliedTextFilter = ({
  facetKey,
  filter,
  isInclusive,
  handleRemoveSongsFilter,
}: AppliedTextFilterProps) => {
  const className = isInclusive ? 'plusIcon' : 'minusIcon';
  const filterAction = isInclusive ? 'included' : 'excluded';
  return (
    <StyledIconFilter
      key={filter}
      className={className}
      onClick={() => {
        handleRemoveSongsFilter(facetKey, filter, filterAction);
      }}
    >
      <Icon className={className}>
        <CloseIcon className="closeIcon" />
        {isInclusive ? (
          <PlusIcon className="plusMinusIcon" />
        ) : (
          <MinusIcon className="plusMinusIcon" />
        )}
      </Icon>
      <AppliedFilter>{filter}</AppliedFilter>
    </StyledIconFilter>
  );
};

const FilterItem = ({ facetKey, handleOpenModalMenu, selectedFilter }: InteractiveFilterProps) => {
  const { t } = useTranslation();

  const { appliedFilters, resultFacets } = useResultsManagerContext();

  const excludedFilters = getAppliedFiltersExclusiveList(appliedFilters, facetKey) || [];
  const includedFilters = getAppliedFiltersInclusiveList(appliedFilters, facetKey) || [];

  const minValue = getFacetStatsMin(appliedFilters, facetKey);
  const maxValue = getFacetStatsMax(appliedFilters, facetKey);

  const isSelectedFilter = facetKey === selectedFilter;
  const { handleRemoveSongsFilter, resetSongsFilter } = useResultsManagerContext();
  const handleOpenFilterMenu = () => {
    handleOpenModalMenu(facetKey);
  };
  const { userRole } = useSubscriptionsContext();

  const userHasFilterPermission = USER_PERMISSIONS[userRole].filteringPermissions.includes(
    facetKey
  );

  const filterAvailable = resultFacets[facetKey] ? true : false;

  return (
    <>
      {filterAvailable && (
        <InteractiveFilterWrapper>
          <InteractiveFilter
            className={cx({
              [activeInteractiveFilter]: isSelectedFilter,
              [hasActiveFilters]:
                includedFilters.length > 0 || excludedFilters.length > 0 || (maxValue, minValue),
              [restrictedFilter]: !userHasFilterPermission,
            })}
            onClick={handleOpenFilterMenu}
          >
            {getFilterLabel(facetKey, t)}
            {!userHasFilterPermission && (
              <PadlockContainer className="padlockIcon">
                <PadlockIcon />
              </PadlockContainer>
            )}
          </InteractiveFilter>
          {maxValue !== 0 && minValue !== 0 && (
            <AppliedRangeFilter
              minValue={minValue}
              maxValue={maxValue}
              facetKey={facetKey}
              resetSongsFilter={resetSongsFilter}
            />
          )}
          {includedFilters.map(includedFilter => {
            return (
              <AppliedTextFilter
                key={includedFilter}
                facetKey={facetKey}
                filter={includedFilter}
                isInclusive
                handleRemoveSongsFilter={handleRemoveSongsFilter}
              />
            );
          })}
          {excludedFilters.map(excludedFilter => {
            return (
              <AppliedTextFilter
                key={excludedFilter}
                facetKey={facetKey}
                filter={excludedFilter}
                isInclusive={false}
                handleRemoveSongsFilter={handleRemoveSongsFilter}
              />
            );
          })}
        </InteractiveFilterWrapper>
      )}
    </>
  );
};

const GroupedFilterItem = ({
  facetKey,
  excludedFilters = [],
  includedFilters = [],
  handleOpenModalMenu,
  isSelectedFilter,
  groupedLabel,
  groupedFilters,
}: GroupedInteractiveFilterProps) => {
  const { t } = useTranslation();
  const { handleRemoveSongsFilter, removeFilterGroup } = useResultsManagerContext();
  const locale = useLocale();

  const handleOpenFilterMenu = () => {
    handleOpenModalMenu(facetKey);
  };
  const { userRole } = useSubscriptionsContext();
  const userHasFilterPermission = USER_PERMISSIONS[userRole].filteringPermissions.includes(
    facetKey
  );

  const currentGroupedFilters = groupedFilters[groupedLabel];
  const includedGroupApplied = isGroupApplied(includedFilters, currentGroupedFilters);
  const excludedGroupApplied = isGroupApplied(excludedFilters, currentGroupedFilters);
  const filtersToRemove = getGroupedBooleanFilters(locale, facetKey);

  return (
    <InteractiveFilterWrapper>
      <InteractiveFilter
        className={cx({
          [activeInteractiveFilter]: isSelectedFilter,
          [hasActiveFilters]: includedFilters.length > 0 || excludedFilters.length > 0,
          [restrictedFilter]: !userHasFilterPermission,
        })}
        onClick={handleOpenFilterMenu}
      >
        {getFilterLabel(facetKey, t)}
        {!userHasFilterPermission && (
          <PadlockContainer className="padlockIcon">
            <PadlockIcon />
          </PadlockContainer>
        )}
      </InteractiveFilter>
      {includedGroupApplied ? (
        <>
          <div>
            <StyledIconFilter
              key={groupedLabel}
              className="plusIcon"
              onClick={() => {
                removeFilterGroup(
                  facetKey,
                  filtersToRemove[groupedLabel],
                  groupedLabel,
                  'included'
                );
              }}
            >
              <Icon className="plusIcon">
                <CloseIcon className="closeIcon" />
                <PlusIcon className="plusMinusIcon" />
              </Icon>
              <AppliedFilter>{groupedLabel}</AppliedFilter>
            </StyledIconFilter>
          </div>
          <div>
            {includedFilters.map(includedFilter => {
              return (
                <div key={includedFilter}>
                  {!currentGroupedFilters.includes(includedFilter) && (
                    <AppliedTextFilter
                      facetKey={facetKey}
                      filter={includedFilter}
                      isInclusive
                      handleRemoveSongsFilter={handleRemoveSongsFilter}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div>
          {includedFilters.map(includedFilter => {
            return (
              <AppliedTextFilter
                key={includedFilter}
                facetKey={facetKey}
                filter={includedFilter}
                isInclusive
                handleRemoveSongsFilter={handleRemoveSongsFilter}
              />
            );
          })}
        </div>
      )}

      {excludedGroupApplied ? (
        <>
          <div>
            <StyledIconFilter
              key={groupedLabel}
              onClick={() => {
                removeFilterGroup(
                  facetKey,
                  filtersToRemove[groupedLabel],
                  groupedLabel,
                  'excluded'
                );
              }}
            >
              <Icon className="minusIcon">
                <CloseIcon className="closeIcon" />
                <MinusIcon className="plusMinusIcon" />
              </Icon>
              <AppliedFilter>{groupedLabel}</AppliedFilter>
            </StyledIconFilter>
          </div>
          <div>
            {excludedFilters.map(excludedFilter => {
              return (
                <div key={excludedFilter}>
                  {!currentGroupedFilters.includes(excludedFilter) && (
                    <AppliedTextFilter
                      facetKey={facetKey}
                      filter={excludedFilter}
                      isInclusive={false}
                      handleRemoveSongsFilter={handleRemoveSongsFilter}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div>
          {excludedFilters.map(excludedFilter => {
            return (
              <AppliedTextFilter
                key={excludedFilter}
                facetKey={facetKey}
                filter={excludedFilter}
                isInclusive={false}
                handleRemoveSongsFilter={handleRemoveSongsFilter}
              />
            );
          })}
        </div>
      )}
    </InteractiveFilterWrapper>
  );
};

const InternalFilter = ({ filterKey, handleOpenModalMenu }: InternalFilterProps) => {
  const { t } = useTranslation();

  const { showHiddenSongs, handleShowHiddenSongs } = useResultsManagerContext();

  const handleClick = () => {
    handleOpenModalMenu(filterKey);
  };

  const getInternalFilterLabel = (filterKey: string) => {
    if (filterKey === 'hidden') {
      return t('resultPage.text.hidden', '');
    }

    return '';
  };

  return (
    <InteractiveFilterWrapper>
      <InteractiveFilter
        className={cx({
          [activeInteractiveFilter]: false,
          [hasActiveFilters]: showHiddenSongs,
        })}
        onClick={handleClick}
      >
        {getInternalFilterLabel(filterKey)}
      </InteractiveFilter>
      <div>
        {showHiddenSongs && (
          <StyledIconFilter
            key="hidden"
            className="plusIcon"
            onClick={() => {
              handleShowHiddenSongs(!showHiddenSongs);
            }}
          >
            <Icon className="plusIcon">
              <CloseIcon className="closeIcon" />
              <PlusIcon className="plusMinusIcon" />
            </Icon>
            <AppliedFilter>{t('resultsPage.text.showHiddenSongsFilterLabel', '')}</AppliedFilter>
          </StyledIconFilter>
        )}
      </div>
    </InteractiveFilterWrapper>
  );
};

const FiltersList = ({ appliedFilters, selectedFilter, handleOpenModalMenu }: Props) => {
  const { t } = useTranslation();

  const locale = useLocale();
  const authenticated = useIsAuthenticated();
  const { showHiddenSongs, handleClearSongFilters } = useResultsManagerContext();

  const allFacets = [
    'genre',
    'mood',
    'instruments',
    'mix_type',
    'energy',
    'sfx_type',
    'sfx_feature',
    'bpm',
    'length',
    'hidden',
  ];
  const standardFacets = [
    'genre',
    'mood',
    'instruments',
    'energy',
    'sfx_type',
    'sfx_feature',
    'bpm',
    'length',
  ];
  const groupedFacets = ['mix_type'];
  const internalFacets = ['hidden'];

  return (
    <FilterListWrapper>
      <Header>
        <SmallHeading>{t('resultsPage.text.filtersTitle', '')}</SmallHeading>

        <TextButton
          className={cx({
            [clearButton]: true,
            [noFiltersApplied]: Object.keys(appliedFilters).length <= 0 && !showHiddenSongs,
          })}
        >
          <HeadingOption
            onClick={() => {
              handleClearSongFilters();
            }}
          >
            {t('general.button.clear')}
          </HeadingOption>
        </TextButton>
      </Header>
      {allFacets.map(facet => {
        if (standardFacets.includes(facet)) {
          return (
            <FilterItem
              key={getFacetKey(facet)}
              facetKey={getFacetKey(facet)}
              handleOpenModalMenu={handleOpenModalMenu}
              selectedFilter={selectedFilter}
            />
          );
        }
        if (groupedFacets.includes(facet)) {
          return (
            <GroupedFilterItem
              key={getFacetKey(facet)}
              facetKey={getFacetKey(facet)}
              includedFilters={getAppliedFiltersInclusiveList(appliedFilters, getFacetKey(facet))}
              excludedFilters={getAppliedFiltersExclusiveList(appliedFilters, getFacetKey(facet))}
              handleOpenModalMenu={handleOpenModalMenu}
              isSelectedFilter={getFacetKey(facet) === selectedFilter}
              groupedLabel={GROUPED_FILTER_LABELS[locale]['All Parts']}
              groupedFilters={getGroupedBooleanFilters(locale, getFacetKey(facet))}
            />
          );
        }
        if (authenticated && internalFacets.includes(facet)) {
          return (
            <InternalFilter
              filterKey={facet}
              handleOpenModalMenu={handleOpenModalMenu}
              key={getFacetKey(facet)}
            />
          );
        }

        return null;
      })}
    </FilterListWrapper>
  );
};

export default FiltersList;
