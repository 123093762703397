// @flow
import React from 'react';
import SongActionButton from '../SongActionButton/SongActionButton';
import MicropartsIcon from '../../../../../../../assets/inline-assets/microparts-icon.svg';
import MicropartsActiveIcon from '../../../../../../../assets/inline-assets/microparts-active-icon.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  active?: boolean,
};

const MicroPartsButton = ({ active = false, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <SongActionButton
      icon={active ? <MicropartsActiveIcon /> : <MicropartsIcon />}
      label={t('general.button.microparts', '')}
      {...props}
    />
  );
};

export default MicroPartsButton;
