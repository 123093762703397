// @flow
import React from 'react';
import SongActionButton from '../SongActionButton/SongActionButton';
import DownloadIcon from '../../../../../../../assets/inline-assets/download-icon.svg';
import DownloadingIcon from '../../../../../../../components/DownloadingIcon/DownloadingIcon';
import { useTranslation } from 'react-i18next';

type Props = {
  busy?: boolean,
  condensed?: boolean,
};

const DownloadButton = ({ busy = false, condensed = false, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <SongActionButton
      condensed={condensed}
      icon={busy ? <DownloadingIcon /> : <DownloadIcon />}
      label={t('general.button.download', '')}
      {...props}
    />
  );
};

export default DownloadButton;
