// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { css, keyframes } from 'emotion';
import parse from 'html-react-parser';
import Modal, {
  ModalContainer,
  wideModalCss,
  wideModalCssPos,
  wideModalOverlayCss,
} from '../Modal/Modal';
import CloseIcon from '../../../assets/inline-assets/close.svg';
import { buttonResetCss } from '../../../components/Button/Button';
import type { ModalProps } from '../Modal/Modal';
import { LargeHeading } from '../../../styles/typography/components/Heading/Heading';
import GenericCopy from '../../../styles/typography/components/GenericCopy/GenericCopy';
import { spacing } from '../../../styles/config/spacing';
import {
  MOBILE_DEVICE_BREAKPOINT,
  MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
  LARGE_DEVICE_BREAKPOINT,
} from '../../../styles/responsive';

const TopBanner = styled('div')`
  height: 50px;
  position: relative;
  color: #fff;
`;

export const ModalCloseWrapper = styled('div')`
  position: absolute;
  top: 19px;
  right: 19px;
`;

export const ModalCloseButton = styled('button')`
  ${buttonResetCss};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
  }
`;

export const Body = styled('div')`
  padding: 15px 50px 50px 50px;

  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    padding: 5px 20px 20px 20px;
  }
`;

const ContentHeader = styled('header')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const HeaderOption = styled('div')`
  ${SMALL_DEVICE_BREAKPOINT} {
    position: relative;
    top: -3px;
  }
`;

export const contentHeadingClass = css`
  margin-top: -13px;

  ${SMALL_DEVICE_BREAKPOINT} {
    flex: 1;
    margin-top: -7px;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
`;

const contentBodyClass = css`
  max-width: 400px;
  &:not(:first-child) {
    margin-top: ${spacing.medium}px;

    ${SMALL_DEVICE_BREAKPOINT} {
      margin-top: ${spacing.small}px;
    }
  }
`;

const contentBodyClassPos = css`
  max-width: 400px;
  margin-bottom: 20px;
  &:not(:first-child) {
    margin-top: ${spacing.medium}px;

    ${SMALL_DEVICE_BREAKPOINT} {
      margin-top: ${spacing.small}px;
    }
  }
`;

const PosModal = styled(`div`)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  ${SMALL_DEVICE_BREAKPOINT} {
    flex-direction: column-reverse;
  }
`;

const DescriptionArea = styled(`div`)`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ImgArea = styled(`div`)`
  position: relative;
  display: flex;
`;

const styledcatchcopy = css`
  font-size: 20px;
`;

const StyledArtistName = css`
  font-size: 50px;
`;

const StyledMushimegane = styled('div')`
  position: absolute;
  right: 0px;
  & img {
    filter: invert(1);
    width: 50px;
    height: auto;
  }
  &.hide {
    visibility: hidden;
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    position: absolute;
  }
`;

const ContentFooter = styled('footer')`
  margin-top: ${spacing.largePlus}px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: ${spacing.medium}px;
  }
`;

type ContentProps = {
  footer?: any,
  heading?: string,
  headerOption?: any,
  description?: any,
  children?: any,
};

export const ClassicModalContentLayout = ({
  footer,
  heading,
  headerOption = null,
  description,
  children = null,
}: ContentProps) => {
  return (
    <div>
      <ContentHeader>
        <LargeHeading className={contentHeadingClass} responsive>
          {heading}
        </LargeHeading>
        {headerOption && <HeaderOption>{headerOption}</HeaderOption>}
      </ContentHeader>
      {description && <GenericCopy className={contentBodyClass}>{description}</GenericCopy>}
      {footer && <ContentFooter>{footer}</ContentFooter>}
      {children && children}
    </div>
  );
};

const FadeKeyframes = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
`;

const ZoomArea = styled(`div`)`
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 100%;
height: 100%;
background-color: white;
display: flex;
justify-content center;
& img {
  ${LARGE_DEVICE_BREAKPOINT} {
    height: 100%;
    width: auto;
    margin: auto;
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    height: 85%;
    margin: auto;
  }
  }
  &.hide {
    visibility: hidden;
  }
  &.show {
    position: fixed;
    visibility: visible;
    animation-delay: 2s;
    animation: ${FadeKeyframes} 1.0s ease-in-out;
  }
`;

const BeforeImgArea = styled(`div`)`
  &.small {
    width: 300px;
    img {
      margin: auto;
      position: absolute;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  &.big {
    img {
      width: 300px;
      height: 430px;
      opacity: 0;
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    &.small {
      height: 500px;
      margin: 10px;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &.big {
      img {
        width: 200%;
        height: 200%;
        opacity: 0;
      }
    }
    margin-bottom: 40px;
  }
`;

const MvArea = styled(`div`)`
  ${SMALL_DEVICE_BREAKPOINT} {
    width: 100%;
    height: 100%;
    margin: auto;
  }
  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 100%;
    height: 100%;
  }
`;

export const ClassicModalContentLayoutPos = ({
  footer,
  heading,
  catchcopy,
  headerOption = null,
  description,
  children = null,
  mushimegane,
  wholeBodyImage,
  linkToMv,
}: ContentProps) => {
  const [active, setActive] = useState(false);
  const classToggle = () => {
    setActive(!active);
  };
  return (
    <div>
      <ContentHeader>
        <LargeHeading className={contentHeadingClass} responsive>
          <p className={styledcatchcopy}>{catchcopy} </p>
          <p className={StyledArtistName}>{heading}</p>
        </LargeHeading>
        {headerOption && <HeaderOption>{headerOption}</HeaderOption>}
      </ContentHeader>
      <PosModal>
        <DescriptionArea>
          {description && (
            <GenericCopy className={contentBodyClassPos}>{parse(description)}</GenericCopy>
          )}
          <MvArea>
            <iframe
              src={linkToMv}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
              allowFullscreen
            ></iframe>
          </MvArea>
        </DescriptionArea>
        <ImgArea>
          <StyledMushimegane onClick={classToggle}>
            <img src={mushimegane} />
          </StyledMushimegane>
          <BeforeImgArea className={active ? 'big' : 'small'} onClick={classToggle}>
            <img src={wholeBodyImage} />
          </BeforeImgArea>
        </ImgArea>
        <ZoomArea className={active ? 'show' : 'hide'} onClick={classToggle}>
          <img src={wholeBodyImage} />
        </ZoomArea>
      </PosModal>
      {footer && <ContentFooter>{footer}</ContentFooter>}
      {children && children}
    </div>
  );
};

type Props = ModalProps & {
  children: any,
  bannerClassName?: string,
  bannerImage?: string,
  fullSized?: boolean,
};

export const ClassicModalLayoutPos = ({
  children,
  bannerClassName = '',
  bannerImage = '',
  onClose,
  fullSized = false,
  ...props
}: Props) => (
  <Modal
    className={wideModalCssPos}
    overlayClassName={wideModalOverlayCss}
    onClose={onClose}
    fullSized={fullSized}
    {...props}
  >
    <ModalContainer fullSized={fullSized}>
      <TopBanner className={bannerClassName} bannerImage={bannerImage}>
        <ModalCloseWrapper>
          <ModalCloseButton onClick={onClose}>
            <CloseIcon />
          </ModalCloseButton>
        </ModalCloseWrapper>
      </TopBanner>
      <Body>{children}</Body>
    </ModalContainer>
  </Modal>
);

const ClassicModalLayout = ({
  children,
  bannerClassName = '',
  bannerImage = '',
  onClose,
  fullSized = false,
  ...props
}: Props) => (
  <Modal
    className={wideModalCss}
    overlayClassName={wideModalOverlayCss}
    onClose={onClose}
    fullSized={fullSized}
    {...props}
  >
    <ModalContainer fullSized={fullSized}>
      <TopBanner className={bannerClassName} bannerImage={bannerImage}>
        <ModalCloseWrapper>
          <ModalCloseButton onClick={onClose}>
            <CloseIcon />
          </ModalCloseButton>
        </ModalCloseWrapper>
      </TopBanner>
      <Body className="classic-modal-body">{children}</Body>
    </ModalContainer>
  </Modal>
);

export default ClassicModalLayout;
