// @flow
import React from 'react';
import { getMusicPageFeaturedInstruments } from '../../../../../../cms/musicPage';
import { useMusicPage } from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import FeaturedTagSection from './FeaturedTagSection';
import { useTranslation } from 'react-i18next';

type Props = {
  viewAll?: boolean,
  viewAllLink?: boolean,
  oversizedTile?: boolean,
  page: string,
  rows: number,
};

const FeaturedInstruments = ({
  viewAll = false,
  viewAllLink = false,
  oversizedTile = false,
  page,
  rows,
}: Props) => {
  const { t } = useTranslation();

  const musicPage = useMusicPage();
  const sectionTitle = t('musicPage.header.featuredInstruments', '');
  const tags = getMusicPageFeaturedInstruments(musicPage);
  return (
    <FeaturedTagSection
      tagCategory="instruments"
      title={sectionTitle}
      tags={tags}
      viewAll={viewAll}
      viewAllLink={viewAllLink}
      oversizedTile={oversizedTile}
      page={page}
      rows={rows}
    />
  );
};

export default FeaturedInstruments;
