// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../../../../modals/components/ClassicModalLayout/ClassicModalLayout';
import type { UserSubscriptionMdl } from '../../../../api/firebase/user/subscriptions';
import {
  getSubscriptionNextBillingDate,
  getSubscriptionPlanKey,
} from '../../../../api/firebase/user/subscriptions';
import Button, { DarkButton } from '../../../../components/Button/Button';
import { LARGE_DEVICE_BREAKPOINT, SMALL_DEVICE_BREAKPOINT } from '../../../../styles/responsive';
import { useSubscriptionsContext } from '../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { Trans, useTranslation } from 'react-i18next';
import { getLocalisedCurrentPath } from '../../../../components/SiteFooter/SiteFooter';
import { ROUTES } from '../../../../routing/routes';
import { LOCALES } from '../../../../locales';
import {
  isBusinessSubscription,
  isCreatorProSubscription,
  isCreatorSubscription,
  isEnterpriseBasicSubscription,
  isEnterpriseSubscription,
} from '../../../../utils/subscriptions';

export const SubscriptionModalFooter = styled('div')`
  ${LARGE_DEVICE_BREAKPOINT} {
    display: flex;

    > button {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    display: flex;
    flex-direction: column;

    > button {
      &:first-child {
        order: 2;
        margin-top: 10px;
      }

      &:last-child {
        order: 1;
      }
    }
  }
`;

type Props = {
  onClose: () => void,
  subscription: UserSubscriptionMdl,
};

const CancelSubscriptionModal = ({ onClose, subscription }: Props) => {
  const { t } = useTranslation();
  const [busy, setBusy] = useState(false);

  const { removeSubscription } = useSubscriptionsContext();

  const handleClose = () => {
    if (busy) return;
    onClose();
  };

  const handleConfirm = () => {
    if (busy) return;
    setBusy(true);
    removeSubscription(subscription.key, false)
      .then(() => {
        handleClose();
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when removing subscription');
        Sentry.captureException(error);
        console.error(error);
        setBusy(false);
      });
  };

  const getPricingPlanName = (plan: string) => {
    if (isCreatorSubscription(plan)) {
      return t('general.planCode.member', '');
    }

    if (isCreatorProSubscription(plan)) {
      return t('general.planCode.loyalMember', '');
    }

    if (isBusinessSubscription(plan)) {
      return t('general.planCode.loyalMemberPlus', '');
    }

    if (isEnterpriseSubscription(plan)) {
      return t('general.planCode.enterprise', '');
    }

    if (isEnterpriseBasicSubscription(plan)) {
      return t('general.planCode.enterpriseBasic', '');
    }
  };

  const planName = getPricingPlanName(getSubscriptionPlanKey(subscription));
  const date = getSubscriptionNextBillingDate(subscription);
  const title = t('modal.subscription.cancel.header', '');

  const instructions = (
    <Trans
      components={{
        license_link: <a href={getLocalisedCurrentPath(ROUTES.terms.path, '')}></a>,
        license_link_JA: (
          <a href={getLocalisedCurrentPath(ROUTES.terms.path, LOCALES.japanese.path)}></a>
        ),
        license_link_CN: (
          <a href={getLocalisedCurrentPath(ROUTES.terms.path, LOCALES.chinese.path)}></a>
        ),
      }}
    >
      {t('modal.subscription.cancel.instruction', '')
        .replace('$PLAN$', planName)
        .replace('$DATE$', date)}
    </Trans>
  );

  return (
    <ClassicModalLayout onClose={handleClose} fullSized={false}>
      <ClassicModalContentLayout
        heading={title}
        description={instructions}
        footer={
          <SubscriptionModalFooter>
            <DarkButton onClick={handleClose}>{t('general.button.cancel', '')}</DarkButton>
            <Button loading={busy} onClick={handleConfirm}>
              {t('general.button.confirm', '')}
            </Button>
          </SubscriptionModalFooter>
        }
      />
    </ClassicModalLayout>
  );
};

export default CancelSubscriptionModal;
