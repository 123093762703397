// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { CenteredMediumContainer } from '../../../../../styles/layout';
import { LargeHeading } from '../../../../../styles/typography/components/Heading/Heading';
import { GeneralDescription } from '../../../../../styles/shared';
import {
  MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../styles/responsive';
import { smallMediumTextCss, smallMonoCss } from '../../../../../styles/typography/typography';
import SquareCheckbox from '../../../../../components/forms/inputs/SquareCheckbox';
import Button from '../../../../../components/Button/Button';
import {
  useUserProfileContext,
  useUserProfileDetails,
} from '../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import {
  getProfileEmailSettingsNewMusic,
  getProfileEmailSettingsUpdates,
} from '../../../../../api/firebase/user/profile';
import { useFirestoreUpdateUserProfileData } from '../../../../../api/firebase/user/user';
import { useAnalyticsMixpanelContext } from '../../../../../analytics/components/MixpanelWrapper';
import { updateMixpanelEmailPreferences } from '../../../../../analytics/mixpanel';
import { useUserId } from '../../../../../auth/components/AuthWrapper/AuthWrapper';
import PageComponent from '../../../../components/PageComponent/PageComponent';
import { useTranslation } from 'react-i18next';

const Preferences = styled('section')`
  margin-top: 47px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 35px;
  }
`;

const ButtonWrapper = styled('div')`
  margin-top: 63px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 35px;
  }
`;

const Preference = styled('div')`
  &:not(:first-child) {
    margin-top: 27px;
  }

  > label {
    display: flex;
  }
`;

const Description = styled(GeneralDescription)`
  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    margin-top: 10px;
  }
`;

const PreferenceInfo = styled('div')`
  flex: 1;

  h3 {
    ${smallMediumTextCss};
  }

  p {
    ${smallMonoCss};
    margin-top: 5px;
  }
`;

const InputWrapper = styled('div')`
  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    padding-top: 25px;
  }
`;

type Props = {
  uri: string,
};

const ProfileEmailSettingsView = ({ uri }: Props) => {
  const [updating, setUpdating] = useState(false);
  const { t } = useTranslation();

  const profile = useUserProfileDetails();
  const [musicNewsChecked, setMusicNewsChecked] = useState(
    getProfileEmailSettingsNewMusic(profile)
  );
  const [evokeUpdatesChecked, setEvokeUpdatesChecked] = useState(
    getProfileEmailSettingsUpdates(profile)
  );

  const [updateProfile] = useFirestoreUpdateUserProfileData();
  const { updateUserProfileData } = useUserProfileContext();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const userId = useUserId();

  const handleSave = () => {
    if (updating) return;
    setUpdating(true);

    const newValues = {
      emailSettings: {
        newmusic: musicNewsChecked,
        updates: evokeUpdatesChecked,
      },
    };

    updateProfile(newValues)
      .then(() => {
        updateUserProfileData(newValues);
        updateMixpanelEmailPreferences(mixpanel, moengage, userId, newValues);
        setUpdating(false);
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when updating user profile');
        Sentry.captureException(error);
        console.error(error);
        setUpdating(false);
      });
  };

  return (
    <PageComponent path={uri}>
      <CenteredMediumContainer responsiveFullWidth>
        <header>
          <LargeHeading>{t('editSettingsPage.header', '')}</LargeHeading>
          <Description>
            <p>{t('editSettingsPage.instruction', '')}</p>
          </Description>
        </header>
        <section>
          <Preferences>
            <Preference>
              <label>
                <PreferenceInfo>
                  <h3>{t('editSettingsPage.newMusicHeader', '')}</h3>
                  <p>{t('editSettingsPage.newMusicMessage', '')}</p>
                </PreferenceInfo>
                <InputWrapper>
                  <SquareCheckbox
                    checked={musicNewsChecked}
                    onChange={event => {
                      setMusicNewsChecked(event.target.checked);
                    }}
                  />
                </InputWrapper>
              </label>
            </Preference>
            <Preference>
              <label>
                <PreferenceInfo>
                  <h3>{t('editSettingsPage.evokeMusicUpdates', '')}</h3>
                  <p>{t('editSettingsPage.evokeMusicMessage', '')}</p>
                </PreferenceInfo>
                <InputWrapper>
                  <SquareCheckbox
                    checked={evokeUpdatesChecked}
                    onChange={event => {
                      setEvokeUpdatesChecked(event.target.checked);
                    }}
                  />
                </InputWrapper>
              </label>
            </Preference>
          </Preferences>
          <ButtonWrapper>
            <Button onClick={handleSave} loading={updating}>
              {t('profile.button.save', '')}
            </Button>
          </ButtonWrapper>
        </section>
      </CenteredMediumContainer>
    </PageComponent>
  );
};

export default ProfileEmailSettingsView;
