// @flow
import React, { useState, useEffect } from 'react';
import styled from 'react-emotion';
import BrowseSongsManager from '../../../song/components/GlobalSongPlayer/components/BrowseSongsManager/BrowseSongsManager';
import ExpandableDisplaySongCard from '../../../song/components/ExpandableDisplaySongCard/ExpandableDisplaySongCard';
import { SMALL_DEVICE_BREAKPOINT, MOBILE_DEVICE_BREAKPOINT } from '../../../styles/responsive';
import { getSongID } from '../../../api/algolia/song';
import { LightButton } from '../../../components/Button/Button';
import type { AlgoliaSongMdl } from '../../../api/algolia/song';
import { darkColor } from '../../../styles/config/colors';
import { spacing } from '../../../styles/config/spacing';
import { useTranslation } from 'react-i18next';

const SongsListContainer = styled('div')`
  width: 100%;
  margin: 0 auto;
`;

const SongListHeader = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  & h3 {
    color: ${darkColor};
    font-family: Roboto;
    font-size: 19px;
    letter-spacing: 0.24px;
    line-height: 23px;
  }
  & div {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  ${MOBILE_DEVICE_BREAKPOINT} {
    h3 {
      padding: 0px ${spacing.small}px;
    }
`;

const SongsList = styled('div')`
  margin-top: 20px;
  margin-bottom: 100px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 20px;
    margin-bottom: 50px;
    padding-left: 0px;
    padding-right: 0px;
  }
  ${MOBILE_DEVICE_BREAKPOINT} {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    overflow-x: hidden;
  }
`;

const ListItem = styled('div')`
  position: relative;
  margin-bottom: 5px;
  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 10px;
  }
`;

const LoadWrapper = styled('div')`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

const ViewMoreButton = styled('h6')`
  color: rgba(15, 15, 20, 0.4);
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 16px;
  margin-left: 15px;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
    color: ${darkColor};
    cursor: pointer;
  }
  ${MOBILE_DEVICE_BREAKPOINT} {
    margin-left: 0px;
  }
`;

type Props = {
  title: string,
  songs: Array<AlgoliaSongMdl>,
  listType: string,
  listTitle: string,
  listSlug: string,
  listKeywords: Array<string>,
  onViewMore: () => void,
  showAll: boolean,
  hasSorting?: boolean,
  showCount?: boolean,
  sectionOrigin?: string,
};

const SongList = ({
  title,
  songs,
  listType,
  listTitle,
  listSlug,
  listKeywords,
  onViewMore,
  showAll,
  sectionOrigin,
}: Props) => {
  const [songsShown, setSongsShown] = useState(20);
  const { t } = useTranslation();

  const visibleSongs = songs.slice(0, songsShown);
  const remainingSongs = songs.length > songsShown;

  const handleLoadMoreSongs = () => {
    const newSongsShown = songsShown + 20;
    setSongsShown(newSongsShown);
  };

  useEffect(() => {
    if (showAll) {
      setSongsShown(songs.length);
      return;
    }
    setSongsShown(20);
  }, [showAll]);

  return (
    <>
      {songs.length > 0 && (
        <BrowseSongsManager
          songs={songs}
          songListType={listType}
          songListTitle={listTitle}
          songListSlug={listSlug}
          songlistKeywords={listKeywords}
          sectionOrigin={sectionOrigin}
        >
          <SongsListContainer>
            <SongListHeader>
              <div>
                <h3>{title}</h3>
                {!showAll && (
                  <ViewMoreButton
                    onClick={() => {
                      onViewMore();
                    }}
                  >
                    {t('general.button.viewAll', '')}
                  </ViewMoreButton>
                )}
              </div>
            </SongListHeader>
            <SongsList>
              {visibleSongs.map((song, index) => {
                return (
                  <ListItem key={getSongID(song)}>
                    <ExpandableDisplaySongCard song={song} songPosition={index} />
                  </ListItem>
                );
              })}
            </SongsList>
            {remainingSongs && showAll && (
              <LoadWrapper>
                <LightButton onClick={handleLoadMoreSongs}>
                  {t('general.button.loadMore', '')}
                </LightButton>
              </LoadWrapper>
            )}
          </SongsListContainer>
        </BrowseSongsManager>
      )}
    </>
  );
};

export default SongList;
