// @flow
import React from 'react';
import { getSongID } from '../../../../../../../../api/algolia/song';
import {
  useHideTrack,
  useUnhideTrack,
} from '../../../../../../../../api/firebase/user/hiddenTracks';
import HideIcon from '../../../../../../../../assets/inline-assets/hide-icon.svg';
import HiddenIcon from '../../../../../../../../assets/inline-assets/hidden-icon.svg';
import { useUserHiddenTracks } from '../../../../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { useSong } from '../../../../../../SongContextWrapper/SongContextWrapper';
import InlineButton from './InlineButton';
import { useTranslation } from 'react-i18next';

const HideTrackInlineButton = () => {
  const { t } = useTranslation();

  const song = useSong();
  const [handleHideTrack] = useHideTrack(song);
  const [handleUnhideTrack] = useUnhideTrack(song);
  const userHiddenSongs = useUserHiddenTracks();
  const songIsHidden = Object.keys(userHiddenSongs).includes(getSongID(song));

  const handleToggleHidden = () => {
    if (songIsHidden) {
      handleUnhideTrack();
    } else {
      handleHideTrack();
    }
  };

  return (
    <InlineButton
      label={songIsHidden ? t('general.button.unhide', '') : t('general.button.hideTrack', '')}
      icon={songIsHidden ? <HideIcon /> : <HiddenIcon />}
      action={handleToggleHidden}
    />
  );
};

export default HideTrackInlineButton;
