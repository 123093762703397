// @flow
import React, { useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import ShareIcon from '../../../../../../../../assets/inline-assets/share-icon.svg';
import { isMobile } from '../../../../../../../../utils/device';
import { getPlayerKeyboardShortcuts } from '../../../../../../../../utils/keyboardShortcuts';
import InlineButton from '../../../../../../FullDisplaySongCard/components/SongActions/components/ActionMenuButton/components/InlineButton';
import { useGlobalShareSongShareURL } from '../../../../GlobalShareContextWrapper/GlobalShareContextWrapper';
import ShareSongModal from './ShareSongModal';
import { useTranslation } from 'react-i18next';

type Props = {
  hideTooltip: () => void,
  shortcutEnabled: boolean,
};

const GlobalShareInlineButton = ({ hideTooltip, shortcutEnabled = false }: Props) => {
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const { t } = useTranslation();

  const shareURL = useGlobalShareSongShareURL();

  const handleOpen = () => {
    if (isMobile() && navigator.share) {
      navigator
        .share({
          title: 'evokemusic.ai',
          url: shareURL,
        })
        .then(() => {
          console.log('shared...');
        })
        .catch(error => {
          // $FlowFixMe: removes type checking for Sentry as provisional solution
          Sentry.captureMessage('Something went wrong when sharing via navigator.share');
          Sentry.captureException(error);
          console.error(error);
        });
    } else {
      setShareModalOpen(true);
    }
  };

  const handleClose = () => {
    hideTooltip();
    setShareModalOpen(false);
  };

  const shareShortcut = () => {
    if (shareModalOpen) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  const keyboardShortcutHandlers = (() => {
    return {
      share_song: shareShortcut,
    };
  })();

  return (
    <>
      {shortcutEnabled && (
        <GlobalHotKeys
          keyMap={getPlayerKeyboardShortcuts('results')}
          handlers={keyboardShortcutHandlers}
          allowChanges
        />
      )}
      <InlineButton
        label={t('general.button.share', '')}
        icon={<ShareIcon />}
        action={handleOpen}
      />
      {shareModalOpen && <ShareSongModal onClose={handleClose} />}
    </>
  );
};

export default GlobalShareInlineButton;
