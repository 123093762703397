// @flow
import React from 'react';
import styled from 'react-emotion';
import type { UserSubscriptionMdl } from '../../../../api/firebase/user/subscriptions';
import PlanPreview from '../PlanPreview/PlanPreview';
import AddedSubscription from '../AddedSubscription/AddedSubscription';
import { getSubscriptionChannelId } from '../../../../api/firebase/user/subscriptions';
import {
  isBusinessSubscription,
  isCreatorProSubscription,
  isCreatorSubscription,
  isEnterpriseBasicSubscription,
  isEnterpriseSubscription,
} from '../../../../utils/subscriptions';
import { useTranslation } from 'react-i18next';

const Subscriptions = styled('section')`
  margin-top: 10px;
  li {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
`;

type Props = {
  planKey: string,
  subscriptions: Array<UserSubscriptionMdl>,
  groupStartingIndex: number,
};

const GroupedSubscriptions = ({ planKey, subscriptions, groupStartingIndex }: Props) => {
  const { t } = useTranslation();

  const getPricingPlanName = (plan: string) => {
    if (isCreatorSubscription(plan)) {
      return t('general.planCode.member', '');
    }

    if (isCreatorProSubscription(plan)) {
      return t('general.planCode.loyalMember', '');
    }

    if (isBusinessSubscription(plan)) {
      return t('general.planCode.loyalMemberPlus', '');
    }

    if (isEnterpriseSubscription(plan)) {
      return t('general.planCode.enterprise', '');
    }

    if (isEnterpriseBasicSubscriptio(plan)) {
      return t('general.planCode.enterpriseBasic', '');
    }
  };

  return (
    <div>
      <div>
        <PlanPreview disabled plan={planKey} name={getPricingPlanName(planKey)} />
      </div>
      <Subscriptions>
        <ul>
          {subscriptions.map((subscription, index) => {
            const seatMonthlyTotalCents = subscription.seat.monthlyTotalCents;
            const channelId = getSubscriptionChannelId(subscription);
            return (
              <li key={channelId}>
                <AddedSubscription
                  planNumber={groupStartingIndex + index + 1}
                  subscription={subscription}
                  monthlyTotalCents={seatMonthlyTotalCents}
                />
              </li>
            );
          })}
        </ul>
      </Subscriptions>
    </div>
  );
};

export default GroupedSubscriptions;
