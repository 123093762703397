// @flow
import React, { useState, useEffect } from 'react';
import styled from 'react-emotion';
import ls from 'local-storage';
import AuthRequiredWrapper from '../../../../../auth/components/AuthRequiredWrapper/AuthRequiredWrapper';
import { LargeHeading } from '../../../../../styles/typography/components/Heading/Heading';
import SubscriptionsHandler from '../../../../../user/subscriptions/components/SubscriptionsHandler/SubscriptionsHandler';
import Button from '../../../../../components/Button/Button';
import {
  useSubscriptionsContext,
  useUserHasSubscriptions,
} from '../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import {
  SMALL_DEVICE_BREAKPOINT,
  MOBILE_DEVICE_BREAKPOINT,
} from '../../../../../styles/responsive';
import NoConnectionsModal from '../../../../../modals/components/NoConnectionsModal/NoConnectionsModal';
import { useNavigate } from '../../../../hooks';
import { ROUTES } from '../../../../routes';
import PageComponent from '../../../../components/PageComponent/PageComponent';
import {
  usePlanQuery,
  useBillingQuery,
} from '../SignUpEnterpriseFormView/SignUpEnterpriseFormView';
import {
  isBusinessSubscription,
  isCreatorProSubscription,
  isCreatorSubscription,
  isEnterpriseSubscription,
  planKeyToPlanCode,
} from '../../../../../utils/subscriptions';
import { useChargebeeContext } from '../../../../../user/subscriptions/components/ChargebeeWrapper/ChargebeeWrapper';
import LoadingDisplay from '../../../../../components/LoadingDisplay/LoadingDisplay';
import { useLocalePath } from '../../../../components/LocaleWrapper/LocaleWrapper';
import { useUserProfileDetails } from '../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import {
  getProfileEmail,
  getProfileFirstName,
  getProfileLastName,
} from '../../../../../api/firebase/user/profile';
import {
  analyticsMixpanelEnterpriseContactRequest,
  userRoleToMixpanelRole,
} from '../../../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../../../analytics/components/MixpanelWrapper';
import { BILLING_CYCLES } from '../../../../../user/subscriptions/data';
import { useTranslation } from 'react-i18next';

export const SubscriptionsSection = styled('section')`
  margin-top: 75px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 42px;
  }
`;

const ContinueWrapper = styled('div')`
  margin-top: 51px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 41px;
  }
`;

const LoadingContainer = styled('div')`
  margin-top: 25%;

  ${MOBILE_DEVICE_BREAKPOINT} {
    margin-top: 50%;
  }
`;

type Props = {
  uri: string,
};

const SignUpEnterpriseSubscriptionsView = ({ uri }: Props) => {
  const [showConnectionWarning, setShowConnectionWarning] = useState(false);
  const [busy, setBusy] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const localePath = useLocalePath();
  const hasSubscriptions = useUserHasSubscriptions();
  const { addNewSubscription, selectPlan, setBillingCycle, userRole } = useSubscriptionsContext();
  const profile = useUserProfileDetails();
  const [preAttachedPlan, setPreAttachedPlan] = usePlanQuery();
  const [preAttachedBilling, setPreAttachedBilling] = useBillingQuery();
  const { chargebeeLoaded, chargebeeInstance } = useChargebeeContext();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();

  const handleEnterpriseClick = async () => {
    try {
      const firstName = getProfileFirstName(profile);
      const lastName = getProfileLastName(profile);
      const email = getProfileEmail(profile);
      const baseFormLink = t('pricing.plans.plan.formLink.enterprise', '');
      const userTier = userRoleToMixpanelRole(userRole);
      // エンタープライズ(ベーシックではない方)のお問い合わせはGoogle Formを使用。氏名とEメールは自動入力されるように対応。
      const completeFormLink = `${baseFormLink}?usp=pp_url&entry.153525598=${firstName}&entry.471619541=${lastName}&entry.892833479=${email}`;

      if (window) {
        analyticsMixpanelEnterpriseContactRequest(
          mixpanel,
          moengage,
          email,
          firstName,
          lastName,
          'Sign Up Flow',
          userTier
        );
        setTimeout(() => {
          window.location = completeFormLink;
        }, 300);
      }
    } catch (error) {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage(
        'Something went wrong when creating placeholder Enterprise and redirecting user to Formcrafts'
      );
      Sentry.captureException(error);
      console.log(error);
      return;
    }
  };

  useEffect(() => {
    if (!ls.get('ALLOW_SIGNUP_SUBSCRIPTIONS_VIEW')) {
      navigate(ROUTES.subscriptions.navigatePath({ localePath }));
      return;
    }
    ls.remove('ALLOW_SIGNUP_SUBSCRIPTIONS_VIEW');
  }, []);

  useEffect(() => {
    const planCode = preAttachedPlan ? planKeyToPlanCode(preAttachedPlan) : '';
    if (isCreatorSubscription(planCode)) {
      setBillingCycle(preAttachedBilling);
      selectPlan(planCode);
      setPreAttachedPlan('');
      setPreAttachedBilling('');
    }
    if (isEnterpriseSubscription(planCode)) {
      setTimeout(() => {
        handleEnterpriseClick();
      }, 1000);
    }
  }, [preAttachedPlan, preAttachedBilling]);

  useEffect(() => {
    const openPlanCheckout = async () => {
      if (busy) return;
      setBusy(true);

      if (preAttachedPlan && preAttachedBilling && chargebeeLoaded && chargebeeInstance) {
        const planCode = planKeyToPlanCode(preAttachedPlan);

        if (isCreatorProSubscription(planCode) || isBusinessSubscription(planCode)) {
          const billingCycleRequested = Object.keys(BILLING_CYCLES).filter(billingCycle => {
            return BILLING_CYCLES[billingCycle].cycle === preAttachedBilling;
          })[0];

          const getPricingPlanAvailableIntervals = (): string[] => {
            const planIntervals = [
              { interval: null },
              { interval: 'annually' },
              { interval: 'annually' },
            ];

            return planIntervals.map(planInterval => planInterval.interval);
          };

          const isValidBilling = getPricingPlanAvailableIntervals().includes(billingCycleRequested);

          if (!isValidBilling) {
            setPreAttachedPlan('');
            setPreAttachedBilling('');
            setBusy(false);
            return;
          }

          addNewSubscription(planCode, preAttachedBilling)
            .then(() => {
              setPreAttachedPlan('');
              setPreAttachedBilling('');
            })
            .catch(error => {
              setBillingCycle(preAttachedBilling);
              selectPlan(planCode);
              // $FlowFixMe: removes type checking for Sentry as provisional solution
              Sentry.captureMessage(
                'Something went wrong automatically opening Chargbee checkout after sign up from pricing page'
              );
              Sentry.captureException(error);
              setPreAttachedPlan('');
              setPreAttachedBilling('');
              setBusy(false);
            });
        }
      }
      setBusy(false);
    };
    openPlanCheckout();
  }, [chargebeeLoaded, chargebeeInstance]);

  const nextStep = () => {
    navigate(ROUTES.signupCompleted.navigatePath({}));
  };

  const handleContinue = () => {
    nextStep();
  };

  const hideConnectionWarning = () => {
    setShowConnectionWarning(false);
  };

  return (
    <PageComponent path={uri}>
      <AuthRequiredWrapper>
        {!preAttachedPlan && !preAttachedBilling && (
          <div>
            <header>
              <LargeHeading>{t('general.text.addSubscription', '')}</LargeHeading>
            </header>
            <SubscriptionsSection>
              <SubscriptionsHandler />
              <ContinueWrapper>
                <Button disabled={!hasSubscriptions} onClick={handleContinue}>
                  {t('general.button.continue', '')}
                </Button>
              </ContinueWrapper>
            </SubscriptionsSection>
          </div>
        )}
        {preAttachedPlan && preAttachedBilling && (
          <LoadingContainer>
            <LoadingDisplay />
          </LoadingContainer>
        )}
        {showConnectionWarning && (
          <NoConnectionsModal onClose={hideConnectionWarning} onContinue={nextStep} />
        )}
      </AuthRequiredWrapper>
    </PageComponent>
  );
};

export default SignUpEnterpriseSubscriptionsView;
