// @flow
import React from 'react';
import styled from 'react-emotion';
import { LARGE_DEVICE_BREAKPOINT, SMALL_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import { mobileHeaderHeight } from '../../../../../components/SiteHeader/SiteHeader';
import FeaturedGenres from './components/FeaturedGenres';
import FeaturedMoods from './components/FeaturedMoods';
import FeaturedInstruments from './components/FeaturedInstruments';
import NewestCollections from './components/NewestCollections';
import BrowseLayout from '../../components/BrowseLayout/BrowseLayout';
import ExpandedCollections from './components/ExpandedCollections';
import FeaturedThemes from './components/FeaturedThemes';
import BrowseBannerContent from './components/BrowseBannerContent';
import AuthRestricted from '../../../../../auth/components/AuthRestricted/AuthRestricted';
import RecentUserCollections from './components/RecentUserCollections';
import FeaturedSfx from './components/FeaturedSfx';
import type { CuratedCollectionGraphMdl } from '../../../../../../cms/curatedCollection';
import {
  getCuratedCollectionId,
  getCuratedCollectionSongs,
  getCuratedCollectionTitle,
} from '../../../../../cms/curatedCollection';
import { useGlobalPlayerContext } from '../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';

export const BrowseViewContainer = styled('div')`
  ${LARGE_DEVICE_BREAKPOINT} {
    margin-top: ${126}px;
    margin-bottom: 150px;
    width: 100%;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: ${18 + mobileHeaderHeight}px;
    margin-bottom: 60px;
    width: 100%;
  }
`;

export const SearchContainer = styled('div')`
  width: 100%;
`;

export const FeaturedContent = styled('div')`
  width: 100%;

  & li.slide {
    background-color: unset !important;
  }
`;

export const BrowseViewMainContainer = styled('div')`
  display: flex;
  padding: 0 50px;
  width: 100%;
`;

type Props = {
  path: string,
};

const BrowseView = ({ path }: Props) => {
  const {
    changeSongIDs,
    updateGlobalPlayerPlaybackDetails,
    handleUpdateGlobalAnalyticsData,
  } = useGlobalPlayerContext();

  const handlePreviewCollection = (collection: CuratedCollectionGraphMdl, origin: string) => {
    const songs = getCuratedCollectionSongs(collection);
    if (songs.length < 1) return;

    // TODO; コレクションのPrismic脱却対応
    const collectionTitle = getCuratedCollectionTitle(collection);
    const collectionSlug = getCuratedCollectionId(collection);
    handleUpdateGlobalAnalyticsData([], [], [], '', origin, '', '');
    changeSongIDs(songs);
    updateGlobalPlayerPlaybackDetails('collection', collectionTitle, collectionSlug);
  };

  return (
    <BrowseLayout featured={<BrowseBannerContent />} path={path}>
      <AuthRestricted>
        <RecentUserCollections handlePreviewCollection={handlePreviewCollection} />
      </AuthRestricted>
      <NewestCollections handlePreviewCollection={handlePreviewCollection} />
      <ExpandedCollections showIndex={0} />
      <FeaturedThemes handlePreviewCollection={handlePreviewCollection} />
      <ExpandedCollections showIndex={1} />
      <FeaturedGenres viewAllLink page="featuredPage" rows={1} />
      <FeaturedMoods viewAllLink page="featuredPage" rows={1} />
      <FeaturedInstruments viewAllLink page="featuredPage" rows={1} />
      <FeaturedSfx viewAllLink page="featuteredPage" rows={1} />
    </BrowseLayout>
  );
};

export default BrowseView;
