import React from 'react';
import styled from 'react-emotion';
import { cx } from 'emotion';
import ls from 'local-storage';
import { referralBgColor } from '../../../../styles/config/colors';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../styles/responsive';
import type { ReferralCouponDataMDL } from '../../../../api/firebase/referral/referral';
import CloseIcon from '../../../../assets/inline-assets/close.svg';
import InfoIcon from '../../../../assets/inline-assets/info-icon.svg';
import { useSubscriptionsContext } from '../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';

type Props = {
  referralCoupon: ReferralCouponDataMDL,
  showReferralBanner: boolean,
  handleShowReferralConditions: () => void,
  setShowReferralBanner: boolean => void,
};

const HeaderBanner = styled('div')`
  height: 100px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  transition: height 0.5s ease-in;
  background-color: ${referralBgColor};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  & p {
    display: inline;
  }

  & a {
    text-decoration: underline;
    font-weight: 500;
  }

  & > svg {
    cursor: pointer;
    position: absolute;
    right: 50px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 0px 15px;

    & > svg {
      cursor: pointer;
      position: absolute;
      right: 20px;
    }
  }

  &.openBanner {
    height: 100px;
  }
`;

const HeaderCopy = styled('div')`
  margin: 0px 20%;

  & svg {
    position: relative;
    left: 15px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin: 20px;
    width: 80%;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.17px;
    line-height: 24px;
    text-align: center;

    & svg {
      position: relative;
      left: 10px;
    }
  }
`;

const ReferralBanner = ({
  showReferralBanner,
  handleShowReferralConditions,
  setShowReferralBanner,
}: Props) => {
  const { setReferralCoupon } = useSubscriptionsContext();

  const handleDismiss = () => {
    setReferralCoupon(null);
    setShowReferralBanner(false);
    ls.remove('REFERRAL_COUPON_DATA');
  };

  return (
    <>
      <HeaderBanner
        className={cx({
          ['openBanner']: showReferralBanner,
        })}
      >
        <HeaderCopy
          className={cx({
            ['openBanner']: showReferralBanner,
          })}
        >
          <div>
            <InfoIcon onClick={handleShowReferralConditions} />
          </div>
        </HeaderCopy>
        <CloseIcon onClick={handleDismiss} />
      </HeaderBanner>
    </>
  );
};

export default ReferralBanner;
