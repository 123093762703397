// @flow
import React from 'react';
import { css, cx } from 'emotion';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../../../../modals/components/ClassicModalLayout/ClassicModalLayout';
import Button, { BUTTON_WIDTHS, extraVerticalPaddingButtonClass } from '../../../Button/Button';
import { BANNERS } from '../../../../images/paths';
import { referralBgColor } from '../../../../styles/config/colors';
import type { ReferralCouponDataMDL } from '../../../../api/firebase/referral/referral';
import { useTranslation } from 'react-i18next';

const referralBannerCss = css`
  background-color: ${referralBgColor};
  background-image: url(${BANNERS.referralModal});
  background-repeat: no-repeat;
`;

type Props = {
  onClose: () => void,
  referralCoupon: ReferralCouponDataMDL,
};

const ReferralConditionsModal = ({ onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <ClassicModalLayout bannerClassName={cx(referralBannerCss)} onClose={onClose}>
      <ClassicModalContentLayout
        heading={''}
        description={''}
        footer={
          <Button
            onClick={onClose}
            className={extraVerticalPaddingButtonClass}
            mobileWidth={BUTTON_WIDTHS.full}
          >
            {t('general.button.close', '')}
          </Button>
        }
      />
    </ClassicModalLayout>
  );
};

export default ReferralConditionsModal;
