// @flow
import React, { useState } from 'react';
import type { AlgoliaSongMdl } from '../../../api/algolia/song';
import DisplayAudioCardWrapper from '../GlobalSongPlayer/components/DisplayAudioCardWrapper/DisplayAudioCardWrapper';
import CondensedDisplaySongCard from '../CondensedDisplaySongCard/CondensedDisplaySongCard';
import FullDisplaySongCard, {
  viewAllButtonClass,
} from '../FullDisplaySongCard/FullDisplaySongCard';
import { TextButton } from '../../../components/Button/Button';
import { useBrowseManagerContext } from '../GlobalSongPlayer/components/BrowseSongsManager/BrowseSongsManager';
import ProfileCondensedDisplaySongCard from '../../../routing/screens/ProfileScreen/components/ProfileCondensedDisplaySongCard/ProfileCondensedDisplaySongCard';
import { useTranslation } from 'react-i18next';

type Props = {
  song: AlgoliaSongMdl,
  songPosition: number,
  alwaysExpanded?: boolean,
  neverExpanded?: boolean,
  fullSongTitle?: string,
  hidePackActions?: boolean,
  checkSongPosition?: boolean,
};

const ExpandableDisplaySongCard = ({
  song,
  songPosition,
  alwaysExpanded = false,
  neverExpanded = false,
  fullSongTitle = '',
  hidePackActions = false,
  checkSongPosition = false,
}: Props) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const { songListType } = useBrowseManagerContext();

  const handleExpand = () => {
    setExpanded(true);
  };

  const handleMinimize = () => {
    setExpanded(false);
  };

  return (
    <DisplayAudioCardWrapper song={song}>
      {(expanded || alwaysExpanded) && !neverExpanded ? (
        <div>
          <FullDisplaySongCard
            song={song}
            sideAction={
              alwaysExpanded ? (
                <></>
              ) : (
                <TextButton className={viewAllButtonClass} onClick={handleMinimize}>
                  {t('general.button.minimize', '')}
                </TextButton>
              )
            }
            songPosition={songPosition}
            hidePackActions={hidePackActions}
            checkSongPosition={checkSongPosition}
          />
        </div>
      ) : songListType === 'downloads' || songListType === 'bookmarks' ? (
        <ProfileCondensedDisplaySongCard
          song={song}
          songPosition={songPosition}
          fullSongTitle={fullSongTitle}
          hidePackActions={hidePackActions}
          checkSongPosition={checkSongPosition}
        />
      ) : (
        <CondensedDisplaySongCard
          song={song}
          onExpand={handleExpand}
          songPosition={songPosition}
          neverExpanded
          fullSongTitle={fullSongTitle}
          hidePackActions={hidePackActions}
          checkSongPosition={checkSongPosition}
        />
      )}
    </DisplayAudioCardWrapper>
  );
};

export default ExpandableDisplaySongCard;
