// @flow
import React from 'react';
import styled, { css } from 'react-emotion';
import { Trans, useTranslation } from 'react-i18next';
import { SMALL_DEVICE_BREAKPOINT } from '../../../styles/responsive';

const Container = styled('div')`
  max-width: 1000px;
  width: 60%;
  margin: auto;

  p {
    padding-bottom: 15px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 90%;
  }
`;

const Header = styled('h2')`
  margin: 30px auto;
  font-size: 40px;
  font-weight: 800;
  line-height: 50px;
`;

const SectionHeader = styled('div')`
  margin: 40px auto 15px auto;
`;

const Footer = styled('div')`
  margin-top: 40px;
  margin-bottom: 30px;
`;

const headerStyle = css`
  font-weight: 600;
`;

const sentenceStyle = css`
  ul {
    list-style: inside;
    list-style-position: outside;
    padding-left: 20px;
  }
`;

type Props = {
  planName: string,
};

const LicenseScreen = ({ planName }: Props) => {
  const { t } = useTranslation();

  const getMemberLicenseSentence = () => {
    const chapters = t('license.member.chapters', { returnObjects: true, defaultValue: [] });

    retutn(
      <>
        <Header>{t('license.member.header', '')}</Header>
        {chapters.map((chapterObj, chapterIndex) => {
          return (
            <section>
              <SectionHeader>
                <h3>
                  <Trans
                    key={chapterIndex}
                    components={{
                      license_link: <a href="https://evokemusic.ai/">https://evokemusic.ai/</a>,
                      credit_link: (
                        <a href="https://www.evokemusic.ai/ja/">www.evokemusic.ai/ja/</a>
                      ),
                    }}
                  >
                    {chapterObj.header}
                  </Trans>
                </h3>
              </SectionHeader>
              {chapterObj.sections.map((section, sectionIndex) => {
                return (
                  <p>
                    <Trans key={sectionIndex}>{section}</Trans>
                  </p>
                );
              })}
            </section>
          );
        })}
        <Footer>
          <p>{t('license.member.footer.companyName', '')}</p>
          <p>{t('license.member.footer.updatedAt', '')}</p>
        </Footer>
      </>
    );
  };

  const getLoyalMemberLicenseSentence = () => {
    const chapters = t('license.loyalMember.chapters', { returnObjects: true, defaultValue: [] });

    return (
      <>
        <Header>{t('license.loyalMember.header', '')}</Header>
        {chapters.map((chapterObj, chapterIndex) => {
          return (
            <section>
              <SectionHeader>
                <h3>
                  <Trans key={chapterIndex}>{chapterObj.header}</Trans>
                </h3>
              </SectionHeader>
              {chapterObj.sections.map((section, sectionIndex) => {
                return (
                  <p>
                    <Trans
                      key={sectionIndex}
                      components={{
                        license_link: <a href="https://evokemusic.ai/">https://evokemusic.ai/</a>,
                        credit_link: (
                          <a href="https://www.evokemusic.ai/ja/">www.evokemusic.ai/ja/</a>
                        ),
                        credit_link_EN: <a href="https://www.evokemusic.ai/">www.evokemusic.ai</a>,
                        enterprise_credit_link: (
                          <a href="https://evokemusic.ai/ja/">www.evokemusic.ai</a>
                        ),
                        enterprise_credit_link_EN: (
                          <a href="https://evokemusic.ai/">www.evokemusic.ai</a>
                        ),
                      }}
                    >
                      {section}
                    </Trans>
                  </p>
                );
              })}
            </section>
          );
        })}
        <Footer>
          <p>{t('license.loyalMember.footer.companyName', '')}</p>
          <p>{t('license.loyalMember.footer.updatedAt', '')}</p>
        </Footer>
      </>
    );
  };

  const getLoyalMemberPlusLicenseSentence = () => {
    const chapters = t('license.loyalMemberPlus.chapters', {
      returnObjects: true,
      defaultValue: [],
    });

    return (
      <>
        <Header>{t('license.loyalMemberPlus.header', '')}</Header>
        {chapters.map((chapterObj, chapterIndex) => {
          return (
            <section>
              <SectionHeader>
                <h3>
                  <Trans key={chapterIndex}>{chapterObj.header}</Trans>
                </h3>
              </SectionHeader>
              {chapterObj.sections.map((section, sectionIndex) => {
                return (
                  <p>
                    <Trans
                      key={sectionIndex}
                      components={{
                        license_link: <a href="https://evokemusic.ai/">https://evokemusic.ai/</a>,
                        credit_link: (
                          <a href="https://www.evokemusic.ai/ja/">www.evokemusic.ai/ja/</a>
                        ),
                        enterprise_credit_link: (
                          <a href="https://evokemusic.ai/ja/">www.evokemusic.ai</a>
                        ),
                      }}
                    >
                      {section}
                    </Trans>
                  </p>
                );
              })}
            </section>
          );
        })}
        <Footer>
          <p>{t('license.loyalMemberPlus.footer.companyName', '')}</p>
          <p>{t('license.loyalMemberPlus.footer.updatedAt', '')}</p>
        </Footer>
      </>
    );
  };

  const getEnterpriseLicenseSentence = () => {
    const chapters = t('license.enterprise.chapters', { returnObjects: true, defaultValue: [] });

    return (
      <>
        <Header>{t('license.enterprise.header', '')}</Header>
        {chapters.map((chapterObj, chapterIndex) => {
          return (
            <section>
              <SectionHeader>
                <h3>
                  <Trans key={chapterIndex}>{chapterObj.header}</Trans>
                </h3>
              </SectionHeader>
              {chapterObj.sections.map((section, sectionIndex) => {
                return (
                  <p>
                    <Trans
                      key={sectionIndex}
                      components={{
                        license_link: <a href="https://evokemusic.ai/">https://evokemusic.ai/</a>,
                        credit_link: (
                          <a href="https://www.evokemusic.ai/ja/">www.evokemusic.ai/ja/</a>
                        ),
                        enterprise_credit_link: (
                          <a href="https://evokemusic.ai/ja/">www.evokemusic.ai</a>
                        ),
                      }}
                    >
                      {section}
                    </Trans>
                  </p>
                );
              })}
            </section>
          );
        })}
        <Footer>
          <p>{t('license.enterprise.footer.companyName', '')}</p>
          <p>{t('license.enterprise.footer.updatedAt', '')}</p>
        </Footer>
      </>
    );
  };

  const getCustomOrderLicenseSentence = () => {
    const chapters = t('license.customOrder.chapters', { returnObjects: true, defaultValue: [] });

    return (
      <>
        <Header>{t('license.customOrder.header', '')}</Header>
        {chapters.map((chapterObj, chapterIndex) => {
          return (
            <section>
              <SectionHeader className={headerStyle}>
                <h3>
                  <Trans key={chapterIndex}>{chapterObj.header}</Trans>
                </h3>
              </SectionHeader>
              {chapterObj.sections.map((section, sectionIndex) => {
                return (
                  <p className={sentenceStyle}>
                    <Trans key={sectionIndex}>{section}</Trans>
                  </p>
                );
              })}
            </section>
          );
        })}
        <Footer>
          <p>{t('license.customOrder.footer.companyName', '')}</p>
          <p>{t('license.customOrder.footer.updatedAt', '')}</p>
        </Footer>
      </>
    );
  };

  const getPrivacyPolicySentence = () => {
    const chapters = t('privacyPolicyPage.chapters', { returnObjects: true, defaultValue: [] });

    return (
      <>
        <Header>{t('privacyPolicyPage.header', '')}</Header>
        {chapters.map((chapterObj, chapterIndex) => {
          return (
            <section>
              <SectionHeader className={headerStyle}>
                <h3>
                  <Trans key={chapterIndex}>{chapterObj.header}</Trans>
                </h3>
              </SectionHeader>
              {chapterObj.sections.map((section, sectionIndex) => {
                return (
                  <p className={sentenceStyle}>
                    <Trans key={sectionIndex}>{section}</Trans>
                  </p>
                );
              })}
            </section>
          );
        })}
        <Footer>
          <p>{t('privacyPolicyPage.footer.companyName', '')}</p>
          <p>{t('privacyPolicyPage.footer.updatedAt', '')}</p>
        </Footer>
      </>
    );
  };

  const getLicenseSentence = (plan: string) => {
    if (plan === 'member') {
      return getMemberLicenseSentence();
    }

    if (plan === 'loyalMember') {
      return getLoyalMemberLicenseSentence();
    }

    if (plan === 'loyalMemberPlus') {
      return getLoyalMemberPlusLicenseSentence();
    }

    if (plan === 'enterprise') {
      return getEnterpriseLicenseSentence();
    }

    if (plan === 'customOrder') {
      return getCustomOrderLicenseSentence();
    }

    if (plan === 'privacyPolicy') {
      return getPrivacyPolicySentence();
    }

    return '';
  };

  return <Container>{getLicenseSentence(planName)}</Container>;
};

export default LicenseScreen;
