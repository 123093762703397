// @flow
import React, { useCallback } from 'react';
import styled, { css } from 'react-emotion';
import { useDropzone } from 'react-dropzone';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../../../../../styles/responsive';
import { smallMonoCss } from '../../../../../../../../../styles/typography/typography';
import { DarkButton } from '../../../../../../../../../components/Button/Button';
import { LargeProfileIcon } from '../../../../../../../../../components/ProfileIcon/ProfileIcon';
import { uploadUserAvatar } from '../../../../../../../../../api/firebase/user/profile';
import { useUserId } from '../../../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useTranslation } from 'react-i18next';

const ImageSection = styled('section')`
  display: flex;
  margin: 43px 0 50px 0;
  max-width: 400px;
`;

const ImageWrapper = styled('div')`
  margin-right: 35px;
  margin-left: -5px;

  &:focus {
    outline: none;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-right: 20px;
  }
`;

const ImageSide = styled('div')`
  padding-top: 9px;
`;

const ImageUploadDescription = styled('div')`
  ${smallMonoCss};
  margin-top: 12px;
`;

const profileIconClass = css`
  ${SMALL_DEVICE_BREAKPOINT} {
    width: 75px;
    height: 75px;
  }
`;

type Props = {
  image: string,
  setImage: string => void,
};

const ProfileImageUpload = ({ image, setImage }: Props) => {
  const { t } = useTranslation();

  const userID = useUserId();

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    console.log('upload files?...', acceptedFiles);

    if (!acceptedFiles || acceptedFiles.length === 0) {
      return;
    }

    const file = acceptedFiles[0];

    uploadUserAvatar(userID, file).then(url => {
      setImage(url);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop,
    maxSize: 20971520,
    multiple: false,
  });

  return (
    <ImageSection>
      <ImageWrapper {...getRootProps()}>
        <input {...getInputProps()} />
        <LargeProfileIcon className={profileIconClass} image={image} />
      </ImageWrapper>
      <ImageSide>
        <DarkButton {...getRootProps()}>
          <input {...getInputProps()} />
          {image ? t('general.button.imageUpdate', '') : t('general.button.imageUpload', '')}
        </DarkButton>
        <ImageUploadDescription>
          <p>{t('profile.upload.instruction', '')}</p>
        </ImageUploadDescription>
      </ImageSide>
    </ImageSection>
  );
};

export default ProfileImageUpload;
