// @flow
import React from 'react';
import SongActionButton from '../SongActionButton/SongActionButton';
import DownloadIcon from '../../../../../../../assets/inline-assets/download-icon.svg';
import DownloadingIcon from '../../../../../../../components/DownloadingIcon/DownloadingIcon';
import DownloadedSongIcon from '../../../../../../../assets/inline-assets/downloaded-song-icon.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  busy?: boolean,
  condensed?: boolean,
  previouslyDownloaded?: boolean,
  topLabel?: boolean,
};

const DownloadButton = ({
  busy = false,
  condensed = false,
  previouslyDownloaded = false,
  topLabel = false,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const icon = (() => {
    if (busy) {
      return <DownloadingIcon />;
    }

    if (previouslyDownloaded) {
      return <DownloadedSongIcon />;
    }

    return <DownloadIcon />;
  })();

  const label = (() => {
    if (previouslyDownloaded) {
      return t('general.button.redownloadWav', '');
    }

    return t('general.button.downloadWav', '');
  })();

  return (
    <SongActionButton
      condensed={condensed}
      icon={icon}
      label={label}
      topLabel={topLabel}
      {...props}
    />
  );
};

export default DownloadButton;
