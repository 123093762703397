// @flow
import React from 'react';
import { css } from 'emotion';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../../../../modals/components/ClassicModalLayout/ClassicModalLayout';
import { SubscriptionModalFooter } from '../CancelSubscriptionModal/CancelSubscriptionModal';
import Button, { DarkButton } from '../../../../components/Button/Button';
import { useLocale } from '../../../../routing/components/LocaleWrapper/LocaleWrapper';
import { LOCALES } from '../../../../locales';
import { useTranslation } from 'react-i18next';

type Props = {
  deniedChannels: Array<string>,
  onClose: () => void,
  onConfirm: () => void,
};

const styledLink = css`
  a {
    text-decoration: none;
  }
`;

const AllowlistUnsuccessfulModal = ({ deniedChannels, onClose, onConfirm }: Props) => {
  const { t } = useTranslation();

  const locale = useLocale();

  const handleClose = () => {
    onClose();
  };

  const unsuccesChannalsList = t('whitelist.unsuccessful.modalDescription', '').replace(
    '$CHANNEL_NAME$',
    deniedChannels.join(' - ')
  );

  return (
    <ClassicModalLayout
      label={t('whitelist.unsuccessful.modalTitle', '')}
      onClose={handleClose}
      fullSized={false}
    >
      <ClassicModalContentLayout
        heading={t('whitelist.unsuccessful.modalTitle', '')}
        description={unsuccesChannalsList}
        footer={
          <SubscriptionModalFooter>
            <DarkButton onClick={handleClose}>{t('general.button.done', '')}</DarkButton>
            <Button className={styledLink}>
              <a
                href={
                  locale === LOCALES.japanese.code
                    ? 'https://support.evokemusic.ai/ja-JP/support/tickets/new'
                    : 'https://support.evokemusic.ai/en/support/tickets/new'
                }
                target="_blank"
              >
                {t('general.button.contactSupport', '')}
              </a>
            </Button>
          </SubscriptionModalFooter>
        }
      />
    </ClassicModalLayout>
  );
};

export default AllowlistUnsuccessfulModal;
