// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { transparentize } from 'polished';
import {
  getFacetKey,
  getFilterLabel,
  getAppliedFiltersExclusiveList,
  getAppliedFiltersInclusiveList,
  getFacetStatsMax,
  getFacetStatsMin,
  getGroupedBooleanFilters,
  GROUPED_FILTER_LABELS,
} from '../../../../../../../../../../../api/algolia/data';
import { lightColor } from '../../../../../../../../../../../styles/config/colors';
import { mediumFontWeightCss } from '../../../../../../../../../../../styles/typography/typography';
import CloseIcon from '../../../../../../../../../../../assets/inline-assets/close.svg';
import PlusIcon from '../../../../../../../../../../../assets/inline-assets/plus-icon.svg';
import MinusIcon from '../../../../../../../../../../../assets/inline-assets/minus-icon.svg';
import { useResultsManagerContext } from '../../../../../ResultsManager/ResultsManager';
import { formatSeconds } from '../../../../../../../../../../../utils/time';
import { USER_PERMISSIONS } from '../../../../../../../../../../../user/subscriptions/data';
import { useSubscriptionsContext } from '../../../../../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import PadlockIcon from '../../../../../../../../../../../assets/inline-assets/padlock-icon.svg';
import { useLocale } from '../../../../../../../../../../components/LocaleWrapper/LocaleWrapper';
import { isGroupApplied } from '../FilterBooleanMenu/FilterBooleanMenu';
import { useIsAuthenticated } from '../../../../../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useTranslation } from 'react-i18next';

type Props = {
  facetFilters: any,
  appliedFilters: any,
  handleOpenModalMenu: string => void,
  selectedFilter: string,
};

type InteractiveFilterProps = {
  facetKey: string,
  excludedFilters?: Array<string>,
  includedFilters?: Array<string>,
  maxValue?: number,
  minValue?: number,
  appliedValue?: string,
  trueLabel?: string,
  falseLabel?: string,
  handleOpenModalMenu: string => void,
  isSelectedFilter: boolean,
};

type GroupedInteractiveFilterProps = {
  facetKey: string,
  excludedFilters?: Array<string>,
  includedFilters?: Array<string>,
  handleOpenModalMenu: string => void,
  isSelectedFilter: boolean,
  groupedLabel: string,
  groupedFilters: any,
};

type InternalFilterProps = {
  filterKey: string,
  handleOpenModalMenu: string => void,
};

const FilterListWrapper = styled('ul')`
  max-height: 465px;
  overflow-y: auto;
  width: 100%;

  &.bannerOverflow {
    max-height: 390px;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: grey;
  }
`;

const InteractiveFilter = styled('li')`
  color: ${transparentize(0.6, lightColor)};
  padding: 2px 0px 2px 10px;
  width: 100%;

  &:hover {
    cursor: pointer;
    color: ${lightColor};
    text-decoration: underline;
  }
`;

const PadlockContainer = styled('span')`
  margin-left: 10px;
`;

const activeInteractiveFilter = css`
  opcaity: 1;
  background: ${transparentize(0.9, lightColor)};
  color: ${lightColor};
  border-radius: 2px 0 0 2px;
  font-weight: ${mediumFontWeightCss};
`;

const hasActiveFilters = css`
  color: ${lightColor};
  ${mediumFontWeightCss};
`;

const restrictedFilter = css`
  text-decoration: line-through;
  color: ${transparentize(0.6, lightColor)} !important;

  &:hover {
    cursor: pointer;
    text-decoration: line-through;
    color: ${transparentize(0.6, lightColor)} !important;
  }
`;

const StyledIconFilter = styled('div')`
  display: flex;
  margin-left: 5px;
  padding: 2px 5px;
  cursor: pointer;
  width: fit-content;
  border-radius: 2px;

  &:hover {
    background-color: ${transparentize(0.9, lightColor)};

    & .closeIcon {
      display: inline;
      top: -1px;
    }

    & .plusMinusIcon {
      display: none;
    }
  }
`;

const Icon = styled('div')`
  width: 10px;
  margin-right: 2px;
  & svg {
    margin-right: 5px;
    position: relative;
  }

  &.plusIcon svg {
    top: -1px;
  }

  &.minusIcon svg {
    top: -4px;
  }

  & .closeIcon {
    display: none;
    transform: scale(0.45) translateY(10px) translateX(-10px);
  }
`;

const AppliedFilter = styled('li')`
  text-transform: capitalize;
  font-weight: 400;
  max-width: 149px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const getFormattedRangeLabel = (facetKey: string, min: number, max: number): string => {
  if (facetKey === 'audio.duration') {
    return `${formatSeconds(min / 1000)}-${formatSeconds(max / 1000)}`;
  }
  return `${min}-${max}`;
};

const FilterItem = ({
  facetKey,
  excludedFilters = [],
  includedFilters = [],
  maxValue = 0,
  minValue = 0,
  appliedValue = '',
  trueLabel = '',
  falseLabel = '',
  handleOpenModalMenu,
  isSelectedFilter,
}: InteractiveFilterProps) => {
  const { t } = useTranslation();

  const { handleRemoveSongsFilter, resetSongsFilter } = useResultsManagerContext();
  const handleOpenFilterMenu = () => {
    handleOpenModalMenu(facetKey);
  };
  const { userRole } = useSubscriptionsContext();

  const userHasFilterPermission = USER_PERMISSIONS[userRole].filteringPermissions.includes(
    facetKey
  );

  return (
    <>
      <InteractiveFilter
        className={cx({
          [activeInteractiveFilter]: isSelectedFilter,
          [hasActiveFilters]:
            includedFilters.length > 0 ||
            excludedFilters.length > 0 ||
            (maxValue, minValue) ||
            appliedValue,
          [restrictedFilter]: !userHasFilterPermission,
        })}
        onClick={handleOpenFilterMenu}
      >
        {getFilterLabel(facetKey, t)}
        {!userHasFilterPermission && (
          <PadlockContainer className="padlockIcon">
            <PadlockIcon />
          </PadlockContainer>
        )}
      </InteractiveFilter>
      {appliedValue && falseLabel && trueLabel ? (
        <StyledIconFilter
          key={`${facetKey}:${appliedValue}`}
          onClick={() => {
            resetSongsFilter(facetKey);
          }}
        >
          <Icon className="plusIcon">
            <CloseIcon className="closeIcon" />
            <PlusIcon className="plusMinusIcon" />
          </Icon>
          <AppliedFilter>{appliedValue === 'true' ? trueLabel : falseLabel}</AppliedFilter>
        </StyledIconFilter>
      ) : null}
      {maxValue && minValue && true ? (
        <StyledIconFilter
          key={`min:${minValue}max:${maxValue}`}
          className="plusIcon"
          onClick={() => {
            resetSongsFilter(facetKey);
          }}
        >
          <Icon className="plusIcon">
            <CloseIcon className="closeIcon" />
            <PlusIcon className="plusMinusIcon" />
          </Icon>
          <AppliedFilter>{getFormattedRangeLabel(facetKey, minValue, maxValue)}</AppliedFilter>
        </StyledIconFilter>
      ) : null}
      {includedFilters.map(includedFilter => {
        return (
          <StyledIconFilter
            key={includedFilter}
            className="plusIcon"
            onClick={() => {
              handleRemoveSongsFilter(facetKey, includedFilter, 'included');
            }}
          >
            <Icon className="plusIcon">
              <CloseIcon className="closeIcon" />
              <PlusIcon className="plusMinusIcon" />
            </Icon>
            <AppliedFilter>{includedFilter}</AppliedFilter>
          </StyledIconFilter>
        );
      })}
      {excludedFilters.map(excludedFilter => {
        return (
          <StyledIconFilter
            key={excludedFilter}
            onClick={() => {
              handleRemoveSongsFilter(facetKey, excludedFilter, 'excluded');
            }}
          >
            <Icon className="minusIcon">
              <CloseIcon className="closeIcon" />
              <MinusIcon className="plusMinusIcon" />
            </Icon>
            <AppliedFilter>{excludedFilter}</AppliedFilter>
          </StyledIconFilter>
        );
      })}
    </>
  );
};

const GroupedFilterItem = ({
  facetKey,
  excludedFilters = [],
  includedFilters = [],
  handleOpenModalMenu,
  isSelectedFilter,
  groupedLabel,
  groupedFilters,
}: GroupedInteractiveFilterProps) => {
  const { t } = useTranslation();

  const { handleRemoveSongsFilter, removeFilterGroup } = useResultsManagerContext();
  const locale = useLocale();

  const handleOpenFilterMenu = () => {
    handleOpenModalMenu(facetKey);
  };
  const { userRole } = useSubscriptionsContext();
  const userHasFilterPermission = USER_PERMISSIONS[userRole].filteringPermissions.includes(
    facetKey
  );

  const currentGroupedFilters = groupedFilters[groupedLabel];
  const includedGroupApplied = isGroupApplied(includedFilters, currentGroupedFilters);
  const excludedGroupApplied = isGroupApplied(excludedFilters, currentGroupedFilters);
  const filtersToRemove = getGroupedBooleanFilters(locale, facetKey);

  return (
    <>
      <InteractiveFilter
        className={cx({
          [activeInteractiveFilter]: isSelectedFilter,
          [hasActiveFilters]: includedFilters.length > 0 || excludedFilters.length > 0,
          [restrictedFilter]: !userHasFilterPermission,
        })}
        onClick={handleOpenFilterMenu}
      >
        {getFilterLabel(facetKey, t)}
        {!userHasFilterPermission && (
          <PadlockContainer className="padlockIcon">
            <PadlockIcon />
          </PadlockContainer>
        )}
      </InteractiveFilter>
      {includedGroupApplied ? (
        <>
          <div>
            <StyledIconFilter
              key={groupedLabel}
              className="plusIcon"
              onClick={() => {
                removeFilterGroup(
                  facetKey,
                  filtersToRemove[groupedLabel],
                  groupedLabel,
                  'included'
                );
              }}
            >
              <Icon className="plusIcon">
                <CloseIcon className="closeIcon" />
                <PlusIcon className="plusMinusIcon" />
              </Icon>
              <AppliedFilter>{groupedLabel}</AppliedFilter>
            </StyledIconFilter>
          </div>
          <div>
            {includedFilters.map(includedFilter => {
              return !currentGroupedFilters.includes(includedFilter) ? (
                <>
                  <StyledIconFilter
                    key={includedFilter}
                    className="plusIcon"
                    onClick={() => {
                      handleRemoveSongsFilter(facetKey, includedFilter, 'included');
                    }}
                  >
                    <Icon className="plusIcon">
                      <CloseIcon className="closeIcon" />
                      <PlusIcon className="plusMinusIcon" />
                    </Icon>
                    <AppliedFilter>{includedFilter}</AppliedFilter>
                  </StyledIconFilter>
                </>
              ) : (
                <></>
              );
            })}
          </div>
        </>
      ) : (
        <div>
          {includedFilters.map(includedFilter => {
            return (
              <StyledIconFilter
                key={includedFilter}
                className="plusIcon"
                onClick={() => {
                  handleRemoveSongsFilter(facetKey, includedFilter, 'included');
                }}
              >
                <Icon className="plusIcon">
                  <CloseIcon className="closeIcon" />
                  <PlusIcon className="plusMinusIcon" />
                </Icon>
                <AppliedFilter>{includedFilter}</AppliedFilter>
              </StyledIconFilter>
            );
          })}
        </div>
      )}

      {excludedGroupApplied ? (
        <>
          <div>
            <StyledIconFilter
              key={groupedLabel}
              onClick={() => {
                removeFilterGroup(
                  facetKey,
                  filtersToRemove[groupedLabel],
                  groupedLabel,
                  'excluded'
                );
              }}
            >
              <Icon className="minusIcon">
                <CloseIcon className="closeIcon" />
                <MinusIcon className="plusMinusIcon" />
              </Icon>
              <AppliedFilter>{groupedLabel}</AppliedFilter>
            </StyledIconFilter>
          </div>
          <div>
            {excludedFilters.map(excludedFilter => {
              return !currentGroupedFilters.includes(excludedFilter) ? (
                <>
                  <StyledIconFilter
                    key={excludedFilter}
                    onClick={() => {
                      handleRemoveSongsFilter(facetKey, excludedFilter, 'excluded');
                    }}
                  >
                    <Icon className="minusIcon">
                      <CloseIcon className="closeIcon" />
                      <MinusIcon className="plusMinusIcon" />
                    </Icon>
                    <AppliedFilter>{excludedFilter}</AppliedFilter>
                  </StyledIconFilter>
                </>
              ) : (
                <></>
              );
            })}
          </div>
        </>
      ) : (
        <div>
          {excludedFilters.map(excludedFilter => {
            return (
              <StyledIconFilter
                key={excludedFilter}
                onClick={() => {
                  handleRemoveSongsFilter(facetKey, excludedFilter, 'excluded');
                }}
              >
                <Icon className="minusIcon">
                  <CloseIcon className="closeIcon" />
                  <MinusIcon className="plusMinusIcon" />
                </Icon>
                <AppliedFilter>{excludedFilter}</AppliedFilter>
              </StyledIconFilter>
            );
          })}
        </div>
      )}
    </>
  );
};

const InternalFilter = ({ filterKey, handleOpenModalMenu }: InternalFilterProps) => {
  const { t } = useTranslation();

  const { showHiddenSongs, handleShowHiddenSongs } = useResultsManagerContext();

  const handleClick = () => {
    handleOpenModalMenu(filterKey);
  };

  const getInternalFilterLabel = (filterKey: string) => {
    if (filterKey === 'hidden') {
      return t('resultPage.text.hidden', '');
    }

    return '';
  };

  return (
    <>
      <InteractiveFilter
        className={cx({
          [activeInteractiveFilter]: false,
          [hasActiveFilters]: showHiddenSongs,
        })}
        onClick={handleClick}
      >
        {getInternalFilterLabel(filterKey)}
      </InteractiveFilter>
      <div>
        {showHiddenSongs && (
          <StyledIconFilter
            key="hidden"
            className="plusIcon"
            onClick={() => {
              handleShowHiddenSongs(!showHiddenSongs);
            }}
          >
            <Icon className="plusIcon">
              <CloseIcon className="closeIcon" />
              <PlusIcon className="plusMinusIcon" />
            </Icon>
            <AppliedFilter>{t('resultsPage.text.showHiddenSongsFilterLabel', '')}</AppliedFilter>
          </StyledIconFilter>
        )}
      </div>
    </>
  );
};

const FiltersList = ({
  facetFilters,
  appliedFilters,
  selectedFilter,
  handleOpenModalMenu,
  showFilterMenu,
  selectedfilterType,
}: Props) => {
  const locale = useLocale();
  const authenticated = useIsAuthenticated();
  return (
    <FilterListWrapper>
      <GroupedFilterItem
        key={getFacetKey('mix_type')}
        facetKey={getFacetKey('mix_type')}
        includedFilters={getAppliedFiltersInclusiveList(appliedFilters, getFacetKey('mix_type'))}
        excludedFilters={getAppliedFiltersExclusiveList(appliedFilters, getFacetKey('mix_type'))}
        handleOpenModalMenu={handleOpenModalMenu}
        isSelectedFilter={getFacetKey('mix_type') === selectedFilter}
        groupedLabel={GROUPED_FILTER_LABELS[locale]['All Parts']}
        groupedFilters={getGroupedBooleanFilters(locale, getFacetKey('mix_type'))}
      />
      <FilterItem
        key={getFacetKey('mood')}
        facetKey={getFacetKey('mood')}
        excludedFilters={getAppliedFiltersExclusiveList(appliedFilters, getFacetKey('mood'))}
        includedFilters={getAppliedFiltersInclusiveList(appliedFilters, getFacetKey('mood'))}
        handleOpenModalMenu={handleOpenModalMenu}
        isSelectedFilter={getFacetKey('mood') === selectedFilter}
      />
      <FilterItem
        key={getFacetKey('video_theme')}
        facetKey={getFacetKey('video_theme')}
        excludedFilters={getAppliedFiltersExclusiveList(appliedFilters, getFacetKey('video_theme'))}
        includedFilters={getAppliedFiltersInclusiveList(appliedFilters, getFacetKey('video_theme'))}
        handleOpenModalMenu={handleOpenModalMenu}
        isSelectedFilter={getFacetKey('video_theme') === selectedFilter}
      />
      <FilterItem
        key={getFacetKey('edit_style')}
        facetKey={getFacetKey('edit_style')}
        excludedFilters={getAppliedFiltersExclusiveList(appliedFilters, getFacetKey('edit_style'))}
        includedFilters={getAppliedFiltersInclusiveList(appliedFilters, getFacetKey('edit_style'))}
        handleOpenModalMenu={handleOpenModalMenu}
        isSelectedFilter={getFacetKey('edit_style') === selectedFilter}
      />
      <FilterItem
        key={getFacetKey('movement')}
        facetKey={getFacetKey('movement')}
        excludedFilters={getAppliedFiltersExclusiveList(appliedFilters, getFacetKey('movement'))}
        includedFilters={getAppliedFiltersInclusiveList(appliedFilters, getFacetKey('movement'))}
        handleOpenModalMenu={handleOpenModalMenu}
        isSelectedFilter={getFacetKey('movement') === selectedFilter}
      />
      <FilterItem
        key={getFacetKey('location')}
        facetKey={getFacetKey('location')}
        excludedFilters={getAppliedFiltersExclusiveList(appliedFilters, getFacetKey('location'))}
        includedFilters={getAppliedFiltersInclusiveList(appliedFilters, getFacetKey('location'))}
        handleOpenModalMenu={handleOpenModalMenu}
        isSelectedFilter={getFacetKey('location') === selectedFilter}
      />
      <FilterItem
        key={getFacetKey('energy')}
        facetKey={getFacetKey('energy')}
        excludedFilters={getAppliedFiltersExclusiveList(appliedFilters, getFacetKey('energy'))}
        includedFilters={getAppliedFiltersInclusiveList(appliedFilters, getFacetKey('energy'))}
        handleOpenModalMenu={handleOpenModalMenu}
        isSelectedFilter={getFacetKey('energy') === selectedFilter}
      />
      <FilterItem
        key={getFacetKey('genre')}
        facetKey={getFacetKey('genre')}
        excludedFilters={getAppliedFiltersExclusiveList(appliedFilters, getFacetKey('genre'))}
        includedFilters={getAppliedFiltersInclusiveList(appliedFilters, getFacetKey('genre'))}
        handleOpenModalMenu={handleOpenModalMenu}
        isSelectedFilter={getFacetKey('genre') === selectedFilter}
      />
      <FilterItem
        key={getFacetKey('instruments')}
        facetKey={getFacetKey('instruments')}
        excludedFilters={getAppliedFiltersExclusiveList(appliedFilters, getFacetKey('instruments'))}
        includedFilters={getAppliedFiltersInclusiveList(appliedFilters, getFacetKey('instruments'))}
        handleOpenModalMenu={handleOpenModalMenu}
        isSelectedFilter={getFacetKey('instruments') === selectedFilter}
      />
      <FilterItem
        key={getFacetKey('bpm')}
        facetKey={getFacetKey('bpm')}
        minValue={getFacetStatsMin(appliedFilters, getFacetKey('bpm'))}
        maxValue={getFacetStatsMax(appliedFilters, getFacetKey('bpm'))}
        handleOpenModalMenu={handleOpenModalMenu}
        isSelectedFilter={getFacetKey('bpm') === selectedFilter}
      />
      <FilterItem
        key={getFacetKey('length')}
        facetKey={getFacetKey('length')}
        minValue={getFacetStatsMin(appliedFilters, getFacetKey('length'))}
        maxValue={getFacetStatsMax(appliedFilters, getFacetKey('length'))}
        handleOpenModalMenu={handleOpenModalMenu}
        isSelectedFilter={getFacetKey('length') === selectedFilter}
      />
      {authenticated && (
        <>
          <InternalFilter filterKey="hidden" handleOpenModalMenu={handleOpenModalMenu} />
        </>
      )}
    </FilterListWrapper>
  );
};

export default FiltersList;
