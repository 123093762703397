// @flow
import React from 'react';
import styled from 'react-emotion';
import { ModalContainer } from '../../modals/components/Modal/Modal';
import LoadingIcon from '../LoadingDisplay/components/LoadingIcon/LoadingIcon';
import { useTranslation } from 'react-i18next';

const Container = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
`;

const Content = styled(ModalContainer)`
  max-width: 360px;
  text-align: center;

  p {
    margin-bottom: 24px;
    margin-top: -2px;
  }
`;

const AnimationWrapper = styled('div')`
  margin: 40px auto;
  display: flex;
  justify-content: center;
`;

const LoadingModal = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <AnimationWrapper>
          <LoadingIcon />
        </AnimationWrapper>
        <p>{t('general.loading.popup.message', '')}</p>
      </Content>
    </Container>
  );
};

export default LoadingModal;
