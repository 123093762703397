// @flow

import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useForgotPasswordFormSchema = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    email: Yup.string()
      .email(t('general.error.signInSignUp.validEmailRequired', ''))
      .required(t('general.error.signInSignUp.emailRequired', '')),
  });
};
