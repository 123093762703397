// @flow
import React from 'react';
import FaqsSection from '../../../../../../layouts/components/FaqsSection/FaqsSection';
import FaqItem from '../../../../../../layouts/components/FaqsSection/components/FaqItem/FaqItem';
import { useTranslation } from 'react-i18next';

const PricingFaqs = () => {
  const { t } = useTranslation();

  const faqsHeading = t('pricing.faqs.header', '');
  const faqs = t('pricing.faqs.section', { defaultValue: [], returnObjects: true });

  return (
    <FaqsSection heading={faqsHeading}>
      {faqs.map(faq => {
        return <FaqItem key={faq.title} question={faq.title} answer={faq.answer} />;
      })}
    </FaqsSection>
  );
};

export default PricingFaqs;
