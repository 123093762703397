import React from 'react';
import styled from 'react-emotion';
import TruncateMarkup from 'react-truncate-markup';
import { transparentize } from 'polished';
import { SmallMonoTextButton } from '../../../components/Button/Button';
import VerifiedIcon from '../../../assets/inline-assets/verified-icon.svg';
import {
  HideOnSmallDevice,
  ShowOnSmallDevice,
  SMALL_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../styles/responsive';
import { isSmallDeviceSize } from '../../../utils/device';
import { lightColor } from '../../../styles/config/colors';
import { useTranslation } from 'react-i18next';

const StyledLayoutWrapper = styled('div')`
  width: 100%;
  height: 350px;
  background-image: ${props => (props.coverUrl ? `url(${props.coverUrl})` : '')};
  background-size: cover;
  background-position: ${props => `center ${props.coverPosition}%`};
  margin-bottom: 80px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    margin-bottom: 110px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    background-position: center;
    height: 320px;
    margin-bottom: 20px;
  }
`;

const StyledOverlayWrapper = styled('div')`
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.3) 100%);
  display: flex;
  align-items: flex-end;

  ${SMALL_DEVICE_BREAKPOINT} {
    background: ${transparentize(0.6, lightColor)};
  }
`;

const StyledContentWraper = styled('div')`
  width: 1020px;
  padding: 0px 20px;
  margin: 0px auto;
  display: flex;
  align-items: flex-end;
  position: relative;
  top: 80px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    width: 100%;
    padding: 0px 40px;
    margin: 0px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    top: 0px;
  }
`;

const StyledThumbnailWrapper = styled('figure')`
  width: 220px;

  & img {
    border-radius: 5px;
    width: 220px;
    height: 220px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 180px;
    display: flex;
    justify-content: flex-end;

    & img {
      position: relative;
      top: 88px;
      width: 180px;
      height: 180px;
      border-radius: 10px;
    }
  }
`;

const StyledTextWrapper = styled('section')`
  max-width: 800px;
  padding: 0px 20px 0px 0px;
  margin: 50px 0px 0px 50px;

  & h2 {
    line-height: 1;
    font-size: 60px;
    font-weight: 300;
    margin-bottom: 60px;

    & svg {
      position: relative;
      bottom: -3px;
      margin-left: 5px;
    }

    & img {
      margin-left: 20px;
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    text-align: center;
    margin: 100px 0px 0px 0px;
    padding: 0px;

    & h2 {
      font-size: 35px;
      margin-bottom: 20px;

      & svg {
        transform: scale(0.8);
        position: relative;
        bottom: -5px;
        margin-left: 5px;
      }
    }

    & p {
      padding: 0px 20px;
    }
  }
`;

const StyledEllipsisButton = styled(SmallMonoTextButton)`
  margin-left: 10px;
  opacity: 0.6;
`;

const EllipsisButton = ({ label, handleClick }) => {
  return (
    <span>
      ...
      <StyledEllipsisButton onClick={handleClick}>{label}</StyledEllipsisButton>
    </span>
  );
};

const CoverTextLayout = ({ title, description, openDescriptionModal }) => {
  const { t } = useTranslation();

  return (
    <StyledTextWrapper>
      <h2>
        {title}
        <VerifiedIcon />
      </h2>
      <TruncateMarkup
        lines={isSmallDeviceSize() ? 4 : 2}
        ellipsis={
          <EllipsisButton
            handleClick={openDescriptionModal}
            label={t('general.button.showMore', '')}
          />
        }
      >
        <p>{description}</p>
      </TruncateMarkup>
    </StyledTextWrapper>
  );
};

const FullCoverArtLayout = ({
  coverUrl,
  coverPosition,
  thumbnailUrl,
  title,
  description,
  openDescriptionModal = () => {},
}) => {
  return (
    <>
      <StyledLayoutWrapper coverUrl={coverUrl} coverPosition={coverPosition}>
        <StyledOverlayWrapper>
          <StyledContentWraper>
            <StyledThumbnailWrapper>
              <img src={thumbnailUrl} alt={title} />
            </StyledThumbnailWrapper>
            <HideOnSmallDevice>
              <CoverTextLayout
                title={title}
                description={description}
                openDescriptionModal={openDescriptionModal}
              />
            </HideOnSmallDevice>
          </StyledContentWraper>
        </StyledOverlayWrapper>
      </StyledLayoutWrapper>
      <ShowOnSmallDevice>
        <CoverTextLayout
          title={title}
          description={description}
          openDescriptionModal={openDescriptionModal}
        />
      </ShowOnSmallDevice>
    </>
  );
};

export default FullCoverArtLayout;
