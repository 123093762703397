// @flow
import React, { useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import styled from 'react-emotion';
import parse from 'html-react-parser';
import { cx } from 'emotion';
import { darkButtonCss, rawButtonCss, shadowCss } from '../components/Button/Button';
import { darkColor, lightColor, brightColor } from '../styles/config/colors';
import { bodyTextCss, regularFontWeightCss } from '../styles/typography/typography';
import { SMALL_DEVICE_BREAKPOINT } from '../styles/responsive';
import DownloadIcon from '../assets/inline-assets/download-icon.svg';
import CheckmarkThickIcon from '../assets/inline-assets/checkmark-thick-icon.svg';
import { useUserProfileContext } from '../user/components/UserProfileWrapper/UserProfileWrapper';
import { getFilterMenuType } from '../routing/screens/ResultsScreen/components/ResultsView/components/ResultsAside/components/ResultsFilters/data';
import { capitalize } from '../utils/text';
import { useAuthContext } from '../auth/components/AuthWrapper/AuthWrapper';
import { SUBSCRIPTIONS } from '../user/subscriptions/data';
import { ICONS } from '../images/paths';
import { useTranslation } from 'react-i18next';

const ProfileChangesSavedToast = styled('div')`
  ${rawButtonCss};
  ${darkButtonCss};
  ${shadowCss};
  ${bodyTextCss};
  ${regularFontWeightCss};
  background-color: ${lightColor};
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  text-transform: initial;
  margin-bottom: 22px;
`;

const StyledIconToast = styled('div')`
  ${rawButtonCss};
  ${darkButtonCss};
  ${shadowCss};
  background-color: ${lightColor};
  color: ${darkColor};
  position: relative;
  border-left: 5px solid ${props => (props.accentColor ? props.accentColor : brightColor)};
  display: flex;
  justify-content: flex-start;
  margin-bottom: 22px;
  text-align: left;

  ${SMALL_DEVICE_BREAKPOINT} {
    min-width: 300px;
    max-width: 350px;
    margin-bottom: 150px;
  }

  h6 {
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    letter-spacing: 0.22px;
    line-height: 25px;
    font-weight: 500;
  }

  p {
    font-size: 11px;
    letter-spacing: 0px;
    line-height: 20px;
    font-weight: 400;
  }
`;

const StyledIconWrapper = styled('div')`
  background-color: ${props => (props.accentColor ? props.accentColor : brightColor)};
  height: 32px;
  width: 32px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    height: 19px;
    width: 19;
    path {
      stroke: ${lightColor};
    }
  }
`;

const StyledCount = styled('p')`
  margin-left: 15px;
  text-transform: none;

  &.quotaReached {
    width: 85%;
  }
`;

const FreeTierRestrictionToast = styled(ProfileChangesSavedToast)`
  display: none;
  margin-bottom: 36px;
  background-color: #fff;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;

  ${SMALL_DEVICE_BREAKPOINT} {
    display: block;
    width: 185px;
    height: 55px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.5px;
    color: ${lightColor};

    &.filters {
      width: 200px;
    }
  }
`;

const DarkMobileToast = styled(ProfileChangesSavedToast)`
  display: none;
  margin-bottom: 36px;
  background-color: black;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;

  ${SMALL_DEVICE_BREAKPOINT} {
    display: block;
    width: 210px;
    height: 39px;
    font-size: 15px;
    letter-spacing: 0.63px;
    line-height: 20px;
    text-align: center;
    color: ${lightColor};
  }
`;

const IconToastLayout = styled('div')`
  & img {
    margin-right: 10px;
  }
`;

const toastConfig = {
  appearance: 'success',
  autoDismiss: true,
};

const parseDownloadCount = (quotaExhausted: boolean, number: number): string => {
  const { t } = useTranslation();

  const remainingDownloads = number - 1;
  let toastText = '';

  const downloadsAvailable = number > 0;
  const updatedDownloadCount = number - 1;
  const updatedDownloadsAvailable = updatedDownloadCount > 0;

  if (!downloadsAvailable || quotaExhausted) {
    toastText = (
      <>
        <h6>{t('general.toast.noDownloadsRemaining.header', '')}</h6>
        <p>
          {t('general.toast.noDownloadsRemaining.text', '').replace(
            '$DOWNLOAD_COUNT$',
            `${remainingDownloads}`
          )}
        </p>
      </>
    );

    return toastText;
  }

  if (
    (downloadsAvailable && !updatedDownloadsAvailable && updatedDownloadCount === 0) ||
    (downloadsAvailable && updatedDownloadsAvailable && updatedDownloadCount > 1)
  ) {
    toastText = t('general.toast.multipleDownloadsRemaining', '').replace(
      '$DOWNLOAD_COUNT$',
      `${remainingDownloads}`
    );
  }

  if (downloadsAvailable && updatedDownloadsAvailable && updatedDownloadCount === 1) {
    toastText = t('general.toast.singleDownloadsRemaining', '').replace(
      '$DOWNLOAD_COUNT$',
      `${remainingDownloads}`
    );
  }

  return toastText;
};

export const useProfileChangesSavedToast = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const profileChangesSavedToast = (() => {
    return (
      <ProfileChangesSavedToast>{t('general.toast.savedChanges', '')}</ProfileChangesSavedToast>
    );
  })();

  const toast = () => {
    addToast(profileChangesSavedToast, toastConfig);
  };

  return toast;
};

export const useDesktopDownloadCounterToast = (quotaExhausted: boolean = false) => {
  const { addToast } = useToasts();
  const { remainingDailyDownloads } = useUserProfileContext()
    ? useUserProfileContext()
    : { remainingDailyDownloads: 0 };

  const copy = parseDownloadCount(quotaExhausted, remainingDailyDownloads);

  const downloadCounterToast = (() => {
    return (
      <StyledIconToast>
        <StyledIconWrapper>
          <DownloadIcon />
        </StyledIconWrapper>
        <StyledCount
          className={cx({
            ['quotaReached']: remainingDailyDownloads - 1 <= 0,
          })}
        >
          {copy}
        </StyledCount>
      </StyledIconToast>
    );
  })();

  const toast = () => {
    addToast(downloadCounterToast, toastConfig);
  };

  return toast;
};

export const useFreeTierRestrictionToast = (type: string, toastUtilities: any) => {
  const [toastId, setToastId] = useState('');
  const [toastClicked, setToastClicked] = useState(false);
  const { t } = useTranslation();

  const { addToast, removeToast } = useToasts();
  const { isAuthenticated, signUpSourceData, setSignUpSourceData } = useAuthContext();
  const {
    subscriptions,
    showSignUp,
    openChangeSubscriptionModal,
    selectPlan,
    setConversionSource,
  } = toastUtilities;
  const { currentToastId, setCurrentToastId } = useUserProfileContext();

  const copy =
    type === 'filters'
      ? t('general.tooltip.text.advancedAccess', '')
      : type === 'microparts'
      ? t('general.tooltip.text.downloadableMicroparts', '')
      : t('general.tooltip.text.downloadableParts', '');

  const toastClass =
    type === 'filters' ? 'filters' : type === 'microparts' ? 'microparts' : 'parts';

  const signUpSource =
    type === 'filters' ? 'Filters' : type === 'microparts' ? 'Microparts' : 'Download Parts';

  const conversionSource =
    type === 'filters'
      ? 'filters_type'
      : type === 'microparts'
      ? 'download_micropart'
      : 'download_quota';

  const handleToastClick = () => {
    setToastClicked(true);

    const upgradeEnabled = subscriptions.length > 0 ? true : false;
    if (!isAuthenticated && signUpSourceData.signUpSource !== 'Landing Page') {
      setSignUpSourceData({ signUpSource, signUpCampaign: '' });
      setConversionSource(conversionSource);
      showSignUp();
      return;
    }

    setConversionSource(conversionSource);

    if (upgradeEnabled) {
      const defaultSubscriptionKey = toastUtilities.subscriptions[0].key;
      openChangeSubscriptionModal(defaultSubscriptionKey);
    } else {
      selectPlan(SUBSCRIPTIONS.creatorPro.key);
    }
  };

  useEffect(() => {
    if (toastClicked && toastId === currentToastId) {
      removeToast(toastId);
      setToastClicked(false);
      setToastId('');
    }
  }, [currentToastId, toastId, toastClicked]);

  const freeTierRestrictionToast = (() => {
    return (
      <FreeTierRestrictionToast onClick={handleToastClick} className={toastClass}>
        {copy}
      </FreeTierRestrictionToast>
    );
  })();

  const toast = () => {
    addToast(freeTierRestrictionToast, toastConfig, newToastId => {
      setToastId(newToastId);
      setCurrentToastId(newToastId);
    });
  };

  return toast;
};

const getFilterToastCopy = (filterType: string, filterLabel: string, syntax: string) => {
  const { t } = useTranslation();

  const filterToastCopyMap = {
    filterBoolean: {
      excluded: t('resultsPage.toast.filterOptionExcluded', ''),
      included: t('resultsPage.toast.filterOptionIncluded', ''),
      removed: t('resultsPage.toast.filterRemoved', ''),
      cleared: t('resultsPage.toast.filtersCleared', ''),
    },
    filterRange: {
      included: t('resultsPage.toast.filterRangeIncluded', ''),
      removed: t('resultsPage.toast.filterRemoved', ''),
      cleared: t('resultsPage.toast.filtersCleared', ''),
    },
    filterSelect: {
      excluded: t('resultsPage.toast.filterOptionExcluded', ''),
      included: t('resultsPage.toast.filterOptionIncluded', ''),
      removed: t('resultsPage.toast.filterRemoved', ''),
      cleared: t('resultsPage.toast.filtersCleared', ''),
    },
    filterHidden: {
      included: filterLabel,
      removed: t('resultsPage.toast.filterRemoved', ''),
      cleared: t('resultsPage.toast.filtersCleared', ''),
    },
  };

  return filterToastCopyMap[filterType][syntax].replace('$FILTER$', capitalize(filterLabel));
};

export const useFilterChangeToast = (filterKey: string, filterLabel: string, syntax: string) => {
  const { addToast } = useToasts();
  const filterType = getFilterMenuType(filterKey);
  const filterToastCopy = getFilterToastCopy(filterType, filterLabel, syntax);
  const filterChangeToast = (() => {
    return <DarkMobileToast>{filterToastCopy}</DarkMobileToast>;
  })();

  const toast = () => {
    addToast(filterChangeToast, toastConfig);
  };

  return toast;
};

export const useEmojiRestrictedToast = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const emojiRestrictedToast = (() => {
    return (
      <DarkMobileToast>
        <IconToastLayout>{t('general.error.signInSignUp.emojiRestricted', '')}</IconToastLayout>
      </DarkMobileToast>
    );
  })();

  const toast = () => {
    addToast(emojiRestrictedToast, toastConfig);
  };

  return toast;
};

export const useEnterpriseFormCompleteToast = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const copy = (
    <>
      <h6>{t('general.toast.enterpriseConfirmation.header', '')}</h6>
      <p>{t('general.toast.enterpriseConfirmation.text', '')}</p>
    </>
  );

  const enterpriseConfirmationToast = (() => {
    return (
      <StyledIconToast accentColor={'#34A299'}>
        <StyledIconWrapper accentColor={'#34A299'}>
          <CheckmarkThickIcon />
        </StyledIconWrapper>
        <StyledCount>{copy}</StyledCount>
      </StyledIconToast>
    );
  })();

  const toast = () => {
    addToast(enterpriseConfirmationToast, toastConfig);
  };

  return toast;
};
