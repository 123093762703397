// @flow
import React from 'react';
import TabbedSongList from '../../../../../../../../../layouts/components/TabbedSongList/TabbedSongList';
import { SONG_TAB_TYPES } from '../../../../../../../../../utils/songTabs';
import { useResultsManagerContext } from '../../../ResultsManager/ResultsManager';

const SongResults = () => {
  const {
    allSongs,
    displayKeywords,
    browseSearchSlug,
    keywords,
    mixpanelSearchKeywords,
    magicKeywords,
    youtubeKeywords,
    youtubeVideoTitle,
    viewingResults,
  } = useResultsManagerContext();

  return (
    <>
      {allSongs.length > 0 && (
        <TabbedSongList
          songs={allSongs}
          songListType="search"
          songListTitle="Keywords"
          songListSlug={browseSearchSlug}
          songlistKeywords={displayKeywords}
          keywords={keywords}
          mixpanelKeywords={mixpanelSearchKeywords}
          magicKeywords={magicKeywords}
          youtubeKeywords={youtubeKeywords}
          youtubeVideoTitle={youtubeVideoTitle}
          sectionOrigin="results"
          defaultTab={viewingResults === 'sfx' ? SONG_TAB_TYPES.sfx : SONG_TAB_TYPES.full_mixes}
        />
      )}
    </>
  );
};

export default SongResults;
