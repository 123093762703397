// @flow
import React from 'react';
import styled from 'react-emotion';
import NoResultsLayout from '../../../../../ResultsScreen/components/ResultsView/components/NoResults/components/NoResultsLayout/NoResultsLayout';
import {
  LARGE_DEVICE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../../../styles/responsive';
import { useBookmarksManagerContext } from '../BookmarksManager/BookmarksManager';
import { useTranslation } from 'react-i18next';

const Container = styled('div')`
  ${LARGE_DEVICE_BREAKPOINT} {
    margin-top: -112px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: -70px;
  }
`;

const BookmarksNoResults = () => {
  const { t } = useTranslation();

  const { noBookmarks } = useBookmarksManagerContext();

  if (noBookmarks) {
    return (
      <Container>
        <NoResultsLayout
          heading={t('bookmarks.noBookmarks.header', '')}
          description={t('bookmarks.noBookmarks.message', '').replace(
            '$ICON$',
            '<span id="bookmark-icon"></span>'
          )}
        />
      </Container>
    );
  }

  return (
    <Container>
      <NoResultsLayout
        heading={t('bookmarks.noBookmarks.header', '')}
        description={t('bookmarks.noBookmarks.message', '').replace(
          '$ICON$',
          '<span id="bookmark-icon"></span>'
        )}
      />
    </Container>
  );
};

export default BookmarksNoResults;
