// @flow
import React, { useState, useEffect } from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import { transparentize } from 'polished';
import { get } from 'lodash';
import { Slider } from '@material-ui/core';
import 'react-input-range/lib/css/index.css';
import type { AppliedAlgoliaFilters } from '../../../../../../../../../../../api/algolia/search';
import type { AlgoliaSongMdl } from '../../../../../../../../../../../api/algolia/song';
import {
  getFacetStatsMax,
  getFacetStatsMin,
  getFilterLabel,
} from '../../../../../../../../../../../api/algolia/data';
import { lightColor } from '../../../../../../../../../../../styles/config/colors';
import {
  SMALL_DESKTOP_BREAKPOINT,
  MOBILE_DEVICE_BREAKPOINT,
} from '../../../../../../../../../../../styles/responsive';
import { useResultsManagerContext } from '../../../../../ResultsManager/ResultsManager';
import { formatSeconds } from '../../../../../../../../../../../utils/time';
import { TextButton } from '../../../../../../../../../../../components/Button/Button';
import { useFilterChangeToast } from '../../../../../../../../../../../toasts/hooks';
import { isMobile } from '../../../../../../../../../../../utils/device';
import { useTranslation } from 'react-i18next';

type Props = {
  resultFacets: any,
  resultFacetStats: any,
  selectedFilter: string,
  onClose?: () => void,
  appliedFilters: AppliedAlgoliaFilters,
  currentSongData: AlgoliaSongMdl,
};

const Body = styled('section')`
  padding: 15px 20px;
  width: 300px;
  height: 540px;
  display: flex;
  flex-direction: column;

  ${SMALL_DESKTOP_BREAKPOINT} {
    width: 100%;
    height: 80%;
  }
`;

const ResetContainer = styled('div')`
  height: 20px;
  margin-bottom: 10px;
`;

const ResetButton = styled(TextButton)`
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 1px;
`;

const sliderRoot = css`
  color: transparent;
  width: 100%;
  cursor: pointer;
  height: 2px;
  display: inline-block;
  padding: 13px 0;
  position: relative;
  box-sizing: content-box;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
`;

const inputRangeTrack = css`
  cursor: pointer;
  -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
  transition: left 0.3s ease-out, width 0.3s ease-out;
  width: 100%;
  height: 2px;
  display: block;
  opacity: 0.38;
  position: absolute;
  border-radius: 1px;
  border: 1px dotted ${transparentize(0.6, lightColor)};
`;

const inputRangeTrackActive = css`
  background-color: ${lightColor};
  cursor: pointer;
  height: 2px;
  display: block;
  position: absolute;
  border-radius: 1px;
`;

const sliderThumb = css`
  display: flex;
  outline: 0;
  position: absolute;
  box-sizing: border-box;
  margin-top: -5px;
  align-items: center;
  margin-left: -6px;
  border-radius: 0;
  justify-content: center;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid ${lightColor};

  & > span {
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover {
    box-shadow: none;
    & > span {
      background-color: ${transparentize(0.95, lightColor)};
      border-radius: 5px;
    }
  }

  &::after {
    visibility: hidden;
  }

  &:nth-child(6) {
    transform: rotate(90deg);
    left: 8px;

    ${SMALL_DESKTOP_BREAKPOINT} {
      left: 15px;
    }

    span {
      width: 60px;
      transform: rotate(90deg);
      top: 8px;
      left: -43px;
    }
  }

  &:nth-child(4) {
    left: 18px;
    ${SMALL_DESKTOP_BREAKPOINT} {
      left: 18px;
    }
    ${MOBILE_DEVICE_BREAKPOINT} {
      left: 25px;
    }
    transform: rotate(270deg);

    span {
      width: 60px;
      transform: rotate(270deg);
      top: 15px;
      left: -15px;
    }
  }

  &:last-of-type {
    left: 18px;
    ${SMALL_DESKTOP_BREAKPOINT} {
      left: 18px;
    }
    ${MOBILE_DEVICE_BREAKPOINT} {
      left: 25px;
    }
    transform: rotate(270deg);

    span {
      width: 60px;
      transform: rotate(270deg);
      top: 15px;
      left: -15px;
    }
  }
`;

const sliderMark = css`
  width: 12px;
  height: 2px;
  right: 8px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    right: 14.5px;
  }
`;

const sliderMarkLabel = css`
  display: none;
`;

const RangeInputContainer = styled('div')`
  width: 100%;
  height: 400px;
  margin: 40px 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  & .sliderRoot {
    ${sliderRoot}
  }

  & .sliderRail {
    ${inputRangeTrack};
  }

  & .sliderTrack {
    ${inputRangeTrackActive};
  }

  & .sliderThumb {
    ${sliderThumb};
  }

  & .sliderMark {
    ${sliderMark};
  }

  & .sliderMarkLabel {
    ${sliderMarkLabel}
  }
`;

const getMarkValue = (currentSongData, selectedFilter): number => {
  if (selectedFilter === 'audio.duration') {
    return currentSongData.audio.duration;
  }
  return currentSongData[selectedFilter];
};

const FilterRange = ({
  resultFacets,
  resultFacetStats,
  selectedFilter,
  onClose = () => {},
  appliedFilters,
  currentSongData,
}: Props) => {
  const {
    addRangeSongsFilter,
    resetSongsFilter,
    completeResultFacets,
  } = useResultsManagerContext();

  const minValue = getFacetStatsMin(completeResultFacets.facetStats, selectedFilter);
  const maxValue = getFacetStatsMax(completeResultFacets.facetStats, selectedFilter);

  const currentMin = getFacetStatsMin(appliedFilters, selectedFilter)
    ? getFacetStatsMin(appliedFilters, selectedFilter)
    : minValue;
  const currentMax = getFacetStatsMax(appliedFilters, selectedFilter)
    ? getFacetStatsMax(appliedFilters, selectedFilter)
    : maxValue;

  const [selectedRange, setSelectedRange] = useState([currentMin, currentMax]);
  const { t } = useTranslation();

  const includeFilterChangeToast = useFilterChangeToast(
    selectedFilter,
    getFilterLabel(selectedFilter, t),
    'included'
  );
  const removedFilterChangeToast = useFilterChangeToast(
    selectedFilter,
    getFilterLabel(selectedFilter, t),
    'removed'
  );

  const isFilterApplied = !!get(appliedFilters, selectedFilter, false);

  const handleNewRange = values => {
    addRangeSongsFilter(selectedFilter, values[1], values[0]);
    if (isMobile()) {
      includeFilterChangeToast();
    }
  };

  useEffect(() => {
    setSelectedRange([currentMin, currentMax]);
  }, [selectedFilter]);

  useEffect(() => {
    const newMin = getFacetStatsMin(appliedFilters, selectedFilter)
      ? getFacetStatsMin(appliedFilters, selectedFilter)
      : minValue;
    const newMax = getFacetStatsMax(appliedFilters, selectedFilter)
      ? getFacetStatsMax(appliedFilters, selectedFilter)
      : maxValue;
    setSelectedRange([newMin, newMax]);
  }, [completeResultFacets]);

  const handleResetRange = () => {
    setSelectedRange([minValue, maxValue]);
    resetSongsFilter(selectedFilter);
    if (isMobile()) {
      removedFilterChangeToast();
    }
  };

  const formatRangeLabel = (value: number): string => {
    if (selectedFilter === 'audio.duration') {
      return formatSeconds(value / 1000);
    }
    return `${value} BPM`;
  };

  const marks = [
    {
      value: getMarkValue(currentSongData, selectedFilter),
      label: formatRangeLabel(currentSongData[selectedFilter]),
    },
  ];

  return (
    <Body>
      <ResetContainer>
        {isFilterApplied && (
          <ResetButton onClick={handleResetRange}>{t('general.button.reset', '')}</ResetButton>
        )}
      </ResetContainer>
      <RangeInputContainer>
        <Slider
          value={selectedRange}
          max={maxValue}
          min={minValue}
          onChange={(event, values) => setSelectedRange([values[0], values[1]])}
          onChangeCommitted={(event, values) => handleNewRange([values[0], values[1]])}
          valueLabelDisplay="on"
          valueLabelFormat={value => {
            return formatRangeLabel(value);
          }}
          aria-labelledby="range-slider"
          orientation="vertical"
          marks={marks}
          classes={{
            root: 'sliderRoot',
            rail: 'sliderRail',
            track: 'sliderTrack',
            thumb: 'sliderThumb',
            mark: 'sliderMark',
            markLabel: 'sliderMarkLabel',
          }}
        />
      </RangeInputContainer>
    </Body>
  );
};

export default FilterRange;
