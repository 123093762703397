import { get } from 'lodash';

export const FACET_KEYS = {
  length: 'audio.duration',
  bpm: 'bpm',
  edit_style: 'keywords.manual.edit-style',
  energy: 'keywords.manual.energy',
  genre: 'keywords.manual.genre',
  instruments: 'keywords.manual.instruments',
  location: 'keywords.manual.location',
  mood: 'keywords.manual.mood',
  movement: 'keywords.manual.movement',
  video_theme: 'keywords.manual.video-theme',
  type: 'isPart',
  mix_type: 'mixType',
  sfx_type: 'keywords.manual.type',
  sfx_feature: 'keywords.manual.feature',
};

const MIX_TYPES = {
  en: {
    grouped: {
      'All Parts': ['Karaoke', 'Drum and Bass', 'Melody', 'Harmony', 'Bass', 'Rhythm'],
    },
    ungrouped: ['Full Mix', 'SFX'],
  },
  ja: {
    grouped: {
      すべて: ['カラオケ', 'ドラム＆ベース', 'メロディ', 'ハーモニー', 'ベース', 'リズム'],
    },
    ungrouped: ['フルミックス', '効果音'],
  },
  cn: {
    grouped: {
      'All Parts': ['Karaoke', 'Drum and Bass', 'Melody', 'Harmony', 'Bass', 'Rhythm'],
    },
    ungrouped: ['Full Mix', 'SFX'],
  },
};

export const GROUPED_FILTER_LABELS = {
  en: {
    'All Parts': 'All Parts',
  },
  ja: {
    'All Parts': 'すべて',
  },
  cn: {
    'All Parts': 'All Parts',
  },
};

export const BOOLEAN_FILTERS_VALUE_MAP = {
  mixType: MIX_TYPES,
};

export const getGroupedBooleanFilters = (locale: string, facetKey: string): Array<string> => {
  return get(BOOLEAN_FILTERS_VALUE_MAP, `${facetKey}.${locale}.grouped`, {});
};

export const getUngroupedBooleanFilters = (locale: string, facetKey: string): any => {
  return get(BOOLEAN_FILTERS_VALUE_MAP, `${facetKey}.${locale}.ungrouped`, []);
};

export const getFacetKey = (facetKey: string): string => {
  return FACET_KEYS[facetKey];
};

export const getFilterLabel = (facetKey: string, t?, isPlural?: true): string => {
  if (facetKey === FACET_KEYS.mood) {
    return !isPlural ? t('general.text.mood', '') : t('general.text.moods', '');
  }

  if (facetKey === FACET_KEYS.video_theme) {
    return !isPlural ? t('general.text.videoTheme', '') : t('general.text.videoThemes', '');
  }

  if (facetKey === FACET_KEYS.edit_style) {
    return !isPlural ? t('general.text.editStyle', '') : t('general.text.editStyles', '');
  }

  if (facetKey === FACET_KEYS.movement) {
    return !isPlural ? t('general.text.movement', '') : t('general.text.movements', '');
  }

  if (facetKey === FACET_KEYS.location) {
    return !isPlural ? t('general.text.location', '') : t('general.text.locations', '');
  }

  if (facetKey === FACET_KEYS.energy) {
    return !isPlural ? t('general.text.energy', '') : t('general.text.energies', '');
  }

  if (facetKey === FACET_KEYS.genre) {
    return !isPlural ? t('general.text.genre', '') : t('general.text.genres', '');
  }

  if (facetKey === FACET_KEYS.instruments) {
    return t('general.text.instruments', '');
  }

  if (facetKey === FACET_KEYS.bpm) {
    return t('general.text.bpm', '');
  }

  if (facetKey === FACET_KEYS.type || facetKey === FACET_KEYS.mix_type) {
    return t('general.text.type', '');
  }

  if (facetKey === FACET_KEYS.length) {
    return t('general.text.length', '');
  }

  if (facetKey === FACET_KEYS.sfx_type) {
    return !isPlural ? t('general.text.sfxType', '') : t('general.text.sfxTypes', '');
  }

  if (facetKey === FACET_KEYS.sfx_feature) {
    return !isPlural ? t('general.text.sfxFeature', '') : t('general.text.sfxFeatures', '');
  }

  return '';
};

export const getAppliedFiltersExclusiveList = (
  appliedFilters: AppliedAlgoliaFilters,
  filter: string
): Array<string> => {
  const appliedFilter = get(appliedFilters, filter, null);
  return get(appliedFilter, `excluded`, []);
};

export const getAppliedFiltersInclusiveList = (
  appliedFilters: AppliedAlgoliaFilters,
  filter: string
) => {
  const appliedFilter = get(appliedFilters, filter, null);
  return get(appliedFilter, `included`, []);
};

export const isFilterExcluded = (
  appliedFilters: AppliedAlgoliaFilters,
  filter: string,
  filterValueToCheck: string
): boolean => {
  const excludedFilters = getAppliedFiltersExclusiveList(appliedFilters, filter);
  return excludedFilters.includes(filterValueToCheck);
};

export const isFilterIncluded = (
  appliedFilters: AppliedAlgoliaFilters,
  filter: string,
  filterValueToCheck: string
): boolean => {
  const includedFilters = getAppliedFiltersInclusiveList(appliedFilters, filter);
  return includedFilters.includes(filterValueToCheck);
};

export const getFacetBooleanValue = (resultFacetStats: any, facetKey: string): string | null => {
  return get(resultFacetStats, facetKey, null);
};

export const getFacetStatsMax = (resultFacetStats: any, facetKey: string): number => {
  const facetStats = resultFacetStats[facetKey];
  return get(facetStats, `max`, 0);
};

export const getFacetStatsMin = (resultFacetStats: any, facetKey: string): number => {
  const facetStats = resultFacetStats[facetKey];
  return get(facetStats, `min`, 0);
};
