// @flow
import React, { useEffect, useState } from 'react';
import styled from 'react-emotion';
import { useAnalyticsMixpanelContext } from '../../../../../../../analytics/components/MixpanelWrapper';
import { analyticsMixpanelPreviewSongs } from '../../../../../../../analytics/mixpanel';
import { useGlobalPlayerContext } from '../../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { useUserId } from '../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import {
  getCuratedCollectionId,
  getCuratedCollectionImage,
  getCuratedCollectionSongs,
  getCuratedCollectionTitle,
} from '../../../../../../../cms/curatedCollection';
import {
  MEDIA_BREAKPOINTS,
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
} from '../../../../../../../styles/responsive';
import { isMobileDeviceSize } from '../../../../../../../utils/device';
import { useNavigate } from '../../../../../../hooks';
import { ROUTES } from '../../../../../../routes';
import PreviewTile from '../../../BrowseView/components/PreviewTile';
import PreviewTileRow from '../../../BrowseView/components/PreviewTileRow';
import type { CuratedCollectionGraphMdl } from '../../../../../../../cms/curatedCollection';
import type { AlgoliaCollectionResults } from '../../../../../../../api/algolia/collections';
import { searchAlgoliaCollections } from '../../../../../../../api/algolia/collections';
import { useLocale } from '../../../../../../components/LocaleWrapper/LocaleWrapper';
import { useCuratedCollections } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useTranslation } from 'react-i18next';

const Container = styled('article')`
  margin: 0px auto;
  max-width: 800px;

  ${MEDIA_BREAKPOINTS.max1260} {
    max-width: 800px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    max-width: unset;
    width: 100%;
    padding: 0px 40px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    padding: 0px 20px;
  }
`;

type Props = {
  songIds: string[],
  packSlug: string,
};

const PackCollections = ({ songIds, packSlug }: Props) => {
  const { t } = useTranslation();

  const [collections, setCollections] = useState([]);
  const {
    changeSongIDs,
    updateGlobalPlayerPlaybackDetails,
    handleUpdateGlobalAnalyticsData,
  } = useGlobalPlayerContext();
  const { mixpanel, moengage, handleUpdateCollectionAnalytics } = useAnalyticsMixpanelContext();
  const userId = useUserId();
  const navigate = useNavigate();
  const locale = useLocale();
  const curatedCollections = useCuratedCollections();

  useEffect(() => {
    if (songIds.length <= 0) return;
    const songFilter = `songs:${songIds.join(' OR songs:')}`;

    searchAlgoliaCollections('', songFilter, 50, locale).then(
      (response: AlgoliaCollectionResults) => {
        if (response.hits.length > 0) {
          const collectionResultIDs = response.hits.map(hit => hit.collectionSlug);
          const filteredCollectionResults = curatedCollections.filter(collection => {
            return collectionResultIDs.includes(getCuratedCollectionId(collection));
          });
          setCollections(filteredCollectionResults);
        }
      }
    );
  }, [songIds]);

  const navigateCollection = (collectionSlug: string) => {
    handleUpdateCollectionAnalytics([], [], [], [], '', 'packCollection', packSlug);
    navigate(ROUTES.collection.navigatePath({ collectionSlug }));
  };

  const handlePreviewCollection = (collection: CuratedCollectionGraphMdl) => {
    const songs = getCuratedCollectionSongs(collection);
    const collectionTitle = getCuratedCollectionTitle(collection);
    const collectionSlug = getCuratedCollectionId(collection);
    handleUpdateGlobalAnalyticsData([], [], [], '', 'packCollection', packSlug, '');
    changeSongIDs(songs);
    updateGlobalPlayerPlaybackDetails('collection', collectionTitle, collectionSlug);
  };

  return (
    <Container>
      {collections.length > 0 && (
        <PreviewTileRow
          title={t('collectionsPage.packCollectionsTitle', '')}
          columnTitle
          rows={1}
          showAll={isMobileDeviceSize()}
        >
          {collections.map(collection => {
            const slug = getCuratedCollectionId(collection);
            return (
              <PreviewTile
                key={slug}
                title={getCuratedCollectionTitle(collection)}
                slug={slug}
                bgImage={getCuratedCollectionImage(collection)}
                listType="collection"
                previewTile={() => {
                  analyticsMixpanelPreviewSongs(
                    mixpanel,
                    moengage,
                    'Collection',
                    slug,
                    'pack_collections',
                    userId
                  );
                  handlePreviewCollection(collection);
                }}
                navigateTile={() => {
                  navigateCollection(slug);
                }}
              />
            );
          })}
        </PreviewTileRow>
      )}
    </Container>
  );
};

export default PackCollections;
