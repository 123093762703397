// @flow
import React from 'react';
import styled from 'react-emotion';
import DownArrowIcon from '../../../../../../../../../assets/inline-assets/down-arrow-icon.svg';
import { useResultsManagerContext } from '../../../ResultsManager/ResultsManager';
import { Container, SelectWrapper, Select } from '../ResultsMainPagination/ResultsMainPagination';
import { getResultsSortingMethods } from '../../../data';
import { useModalsContext } from '../../../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useSubscriptionsContext } from '../../../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { USER_PERMISSIONS } from '../../../../../../../../../user/subscriptions/data';

const SortSelect = styled(Select)`
  min-width: 150px;
`;

const SortResultsDropdown = () => {
  const { sortingMethod, handleSort, similarSongQuery } = useResultsManagerContext();
  const { showSortingRestrictedModal } = useModalsContext();
  const { userRole } = useSubscriptionsContext();

  const handleSortOptionClick = event => {
    const newSortingMethod = event.target.value;

    if (USER_PERMISSIONS[userRole].sortingPermissions.includes(newSortingMethod)) {
      handleSort(event);
    } else {
      showSortingRestrictedModal();
    }
  };

  const isSimilaritySearch = similarSongQuery ? true : false;
  const isSortingDisabled = isSimilaritySearch;

  const resultsSortingMethods = getResultsSortingMethods();

  const getSortLabel = (selectedSortMethod: string): string => {
    if (selectedSortMethod === RESULTS_SORTING_METHODS.newest) {
      return t('resultsPage.sort.text.newest', '');
    }
    if (selectedSortMethod === RESULTS_SORTING_METHODS.popularity) {
      return t('resultsPage.sort.text.mostRelevant', '');
    }
    if (selectedSortMethod === RESULTS_SORTING_METHODS.oldest) {
      return t('resultsPage.sort.text.oldest', '');
    }
    if (selectedSortMethod === RESULTS_SORTING_METHODS.popularity_ascending) {
      return t('resultsPage.sort.text.leastRelevant', '');
    }
    return '';
  };

  return (
    <Container>
      {!isSimilaritySearch && (
        <SelectWrapper>
          <SortSelect
            disabled={isSortingDisabled}
            value={sortingMethod}
            onChange={handleSortOptionClick}
          >
            {Object.keys(resultsSortingMethods).map(sorting =>
              USER_PERMISSIONS[userRole].sortingPermissions.includes(sorting) ? (
                <option value={resultsSortingMethods[sorting]} key={resultsSortingMethods[sorting]}>
                  {`${getSortLabel(resultsSortingMethods[sorting])}`}
                </option>
              ) : (
                <option value={resultsSortingMethods[sorting]} key={resultsSortingMethods[sorting]}>
                  {`${getSortLabel(resultsSortingMethods[sorting])}`} &#x1f512;
                </option>
              )
            )}
          </SortSelect>
          <DownArrowIcon />
        </SelectWrapper>
      )}
    </Container>
  );
};

export default SortResultsDropdown;
