// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import { CompactButton, DarkCompactButton } from '../../../Button/Button';
import { useModalsContext } from '../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useIsAuthenticated } from '../../../../auth/components/AuthWrapper/AuthWrapper';
import ProfileIcon from '../../../ProfileIcon/ProfileIcon';
import DownloadCounter from '../DownloadCounter/DownloadCounter';
import TrialCounter from '../TrailCounter/TrialCounter';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import DownloadCounterMenu from '../DownloadCounterMenu/DownloadCounterMenu';
import TrialCounterMenu from '../TrialCounterMenu/TrialCounterMenu';
import MobileMenuButton from '../MobileMenuButton/MobileMenuButton';
import {
  hideOnSmallDesktopDeviceCss,
  showOnSmallDesktopDeviceCss,
  SMALL_DESKTOP_BREAKPOINT,
} from '../../../../styles/responsive';
import {
  useSubscriptionsContext,
  useFreeTrialSubscriptions,
} from '../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { USER_ROLES } from '../../../../user/subscriptions/data';
import { monoNavCss } from '../../../../styles/typography/typography';
import { useTranslation } from 'react-i18next';

const signInButtonLayout = css`
  background-color: #4d4d4d;
`;

export const SignInButton = (props: any) => {
  const { t } = useTranslation();

  const { setSignInOpen } = useModalsContext();

  return (
    <CompactButton
      className={signInButtonLayout}
      data-cy="signInButton"
      {...props}
      onClick={() => setSignInOpen(true)}
    >
      {t('general.button.signIn', '')}
    </CompactButton>
  );
};

export const SignUpButton = (props: any) => {
  const { t } = useTranslation();

  const { setSignUpOpen } = useModalsContext();

  return (
    <DarkCompactButton data-cy="signUpButton" {...props} onClick={() => setSignUpOpen(true)}>
      {t('general.button.signUp', '')}
    </DarkCompactButton>
  );
};

const Container = styled('div')`
  display: flex;
  align-items: center;
`;

const Option = styled('div')`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const OptionText = styled('h6')`
  ${monoNavCss};
  margin-right: 10px;
  text-transform: uppercase;
`;

const SignedOutOptions = ({ openMenu }: { openMenu: () => void }) => {
  return (
    <React.Fragment>
      <Container className={hideOnSmallDesktopDeviceCss}>
        <Option>
          <SignInButton />
        </Option>
        <Option>
          <SignUpButton />
        </Option>
      </Container>
      <Container className={showOnSmallDesktopDeviceCss}>
        <Option>
          <MobileMenuButton onClick={openMenu} />
        </Option>
      </Container>
    </React.Fragment>
  );
};

const ProfileMenuWrapper = styled('div')`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    margin-top: 18px;
  }
`;

const profileIconClass = css`
  ${hideOnSmallDesktopDeviceCss};
`;

const mobileButtonClass = css`
  ${showOnSmallDesktopDeviceCss};
`;

const DownloadCounterWrapper = styled('div')`
  position: absolute;
  top: 100%;
  right: 50px;
  margin-top: 8px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    margin-top: 18px;
  }
`;

const SignedInOptions = ({
  openMenu,
  openDownloadCount,
  isDownloadCountOpen,
  openTrialCounter,
  isTrialCounterOpen,
}: {
  openMenu: () => void,
  openDownloadCount: () => void,
  isDownloadCountOpen: boolean,
  openTrialCounter: () => void,
  isTrialCounterOpen: boolean,
}) => {
  const { t } = useTranslation();

  const { userRole } = useSubscriptionsContext();
  const freeTrialSubscriptions = useFreeTrialSubscriptions();

  return (
    <Container>
      {USER_ROLES.creator === userRole && (
        <Option onClick={openDownloadCount}>
          <OptionText>{t('general.button.upgradeNow', '')}</OptionText>
          <DownloadCounter isDownloadCountOpen={isDownloadCountOpen} />
        </Option>
      )}
      {freeTrialSubscriptions.length > 0 && (
        <Option onClick={openTrialCounter}>
          <TrialCounter isTrialCountOpen={isTrialCounterOpen} />
        </Option>
      )}
      <Option>
        <ProfileIcon className={profileIconClass} onClick={openMenu} dataCy={'profileIcon'} />
        <MobileMenuButton className={mobileButtonClass} onClick={openMenu} />
      </Option>
    </Container>
  );
};

const HeaderContainer = styled('div')`
  position: relative;
`;

type Props = {};

const HeaderOptions = () => {
  const isAuthenticated = useIsAuthenticated();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const { showDownloadCounter, setShowDownloadCounter } = useModalsContext();
  const { showTrialCounter, setShowTrialCounter } = useModalsContext();

  const handleOpenUserMenu = () => {
    setShowUserMenu(true);
  };

  const handleCloseUserMenu = () => {
    setShowUserMenu(false);
  };

  const handleOpenDownloadCount = () => {
    setShowDownloadCounter(true);
  };

  const handleCloseDownloadCount = () => {
    setShowDownloadCounter(false);
  };

  const handleOpenTrialCounter = () => {
    setShowTrialCounter(true);
  };

  const handleCloseTrialCounter = () => {
    setShowTrialCounter(false);
  };

  const content = isAuthenticated ? (
    <SignedInOptions
      openMenu={handleOpenUserMenu}
      openDownloadCount={handleOpenDownloadCount}
      isDownloadCountOpen={showDownloadCounter}
      openTrialCounter={handleOpenTrialCounter}
      isTrialCounterOpen={showTrialCounter}
    />
  ) : (
    <SignedOutOptions openMenu={handleOpenUserMenu} />
  );

  return (
    <HeaderContainer>
      {content}
      {showUserMenu && (
        <ProfileMenuWrapper>
          <ProfileMenu onClose={handleCloseUserMenu} />
        </ProfileMenuWrapper>
      )}
      {showDownloadCounter && (
        <DownloadCounterWrapper>
          <DownloadCounterMenu onClose={handleCloseDownloadCount} />
        </DownloadCounterWrapper>
      )}
      {showTrialCounter && (
        <DownloadCounterWrapper>
          <TrialCounterMenu onClose={handleCloseTrialCounter} />
        </DownloadCounterWrapper>
      )}
    </HeaderContainer>
  );
};

export default HeaderOptions;
