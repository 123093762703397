// @flow
import React, { useState } from 'react';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../../../../modals/components/ClassicModalLayout/ClassicModalLayout';
import {
  getSubscriptionChannelId,
  getSubscriptionNextBillingDate,
  getSubscriptionPlanKey,
  getSeatInterval,
} from '../../../../api/firebase/user/subscriptions';
import type { UserSubscriptionMdl } from '../../../../api/firebase/user/subscriptions';
import { SubscriptionModalFooter } from '../CancelSubscriptionModal/CancelSubscriptionModal';
import Button, { DarkButton } from '../../../../components/Button/Button';
import { useSubscriptionsContext } from '../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useLocale } from '../../../../routing/components/LocaleWrapper/LocaleWrapper';
import { useTranslation } from 'react-i18next';

type Props = {
  subscription: UserSubscriptionMdl,
  newPlanInterval: string,
  onClose: () => void,
};

const ChangeIntervalSubscriptionModal = ({ subscription, newPlanInterval, onClose }: Props) => {
  const { t } = useTranslation();
  const [busy, setBusy] = useState(false);

  const { updateSubscriptionInterval, billingCycle, setBillingCycle } = useSubscriptionsContext();
  const locale = useLocale();

  const getPricingPlanName = (plan: string) => {
    if (isCreatorSubscription(plan)) {
      return t('general.planCode.member', '');
    }

    if (isCreatorProSubscription(plan)) {
      return t('general.planCode.loyalMember', '');
    }

    if (isBusinessSubscription(plan)) {
      return t('general.planCode.loyalMemberPlus', '');
    }

    if (isEnterpriseSubscription(plan)) {
      return t('general.planCode.enterprise', '');
    }

    if (isEnterpriseBasicSubscription(plan)) {
      return t('general.planCode.enterpriseBasic', '');
    }
  };

  const planName = getPricingPlanName(getSubscriptionPlanKey(subscription));
  const interval1 = getSeatInterval(subscription);
  const interval2 = newPlanInterval;

  const promotionalDefaultInterval = '';
  const defaultInterval = promotionalDefaultInterval || 'annual';

  const getLocalisedInterval = (interval: string): string => {
    if (locale === 'ja') {
      if (interval === 'annual') {
        return t('general.button.oneTimePayment', '');
      }
      if (interval === 'biannual') {
        return t('general.button.biannually', '');
      }
      if (interval === 'quarterly') {
        return t('general.button.quarterly', '');
      }
      return t('general.button.splitPayment', '');
    }

    return interval;
  };

  const date = getSubscriptionNextBillingDate(subscription);

  const title = t('modal.subscription.changeInterval.header', '').replace('$PLAN$', planName);
  const description = t('modal.subscription.changeInterval.instruction', {
    defaultValue: [],
    returnObjects: true,
  }).map(text => (
    <p>
      {text
        .replace('$PLAN1$', planName)
        .replace('$INTERVAL1$', getLocalisedInterval(interval1))
        .replace('$PLAN2$', planName)
        .replace('$INTERVAL2$', getLocalisedInterval(interval2))
        .replace('$DATE$', date)}
    </p>
  ));

  const handleClose = () => {
    if (busy) return;
    setBillingCycle(defaultInterval);
    onClose();
  };

  const handleConfirm = () => {
    if (busy) return;
    setBusy(true);
    updateSubscriptionInterval(
      getSubscriptionChannelId(subscription),
      subscription.seat.plan,
      billingCycle
    )
      .then(() => {
        setBillingCycle(defaultInterval);
        onClose();
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when changing subscription interval');
        Sentry.captureException(error);
        console.error(error);
        setBusy(false);
      });
  };

  return (
    <ClassicModalLayout label={title} onClose={handleClose} fullSized={true}>
      <ClassicModalContentLayout
        heading={title}
        description={description}
        footer={
          <SubscriptionModalFooter>
            <DarkButton onClick={handleClose}>{t('general.button.cancel', '')}</DarkButton>
            <Button loading={busy} onClick={handleConfirm}>
              {t('general.button.confirm', '')}
            </Button>
          </SubscriptionModalFooter>
        }
      />
    </ClassicModalLayout>
  );
};

export default ChangeIntervalSubscriptionModal;
