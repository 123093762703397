// @flow
import React, { useState, useEffect } from 'react';
import ls from 'local-storage';
import { css } from 'emotion';
import styled from 'react-emotion';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../ClassicModalLayout/ClassicModalLayout';
import { PlainTextButton } from '../../../components/Button/Button';
import { useLocalePath } from '../../../routing/components/LocaleWrapper/LocaleWrapper';
import { ROUTES } from '../../../routing/routes';
import { BANNERS } from '../../../images/paths';
import SignInForm from '../../../components/SignInForm/SignInForm';
import { SMALL_DEVICE_BREAKPOINT } from '../../../styles/responsive';
import { storeCurrentLocationAsAuthDestination } from '../../../auth/destination';
import { useTranslation } from 'react-i18next';

export const signInModalBannerClass = css`
  background-color: #d0a895;
  background-image: url(${BANNERS.signin});
`;

export const SignInModalBody = styled('div')`
  margin-top: 43px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 13px;
  }
`;

const TokenErrorContainer = styled('div')`
  margin-top: 13px;
  margin-right: 120px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 20px;
    margin-right: 10px;
  }
`;

type Props = {
  onClose: () => void,
};

const SignInModal = ({ onClose }: Props) => {
  const { t } = useTranslation();

  const localePath = useLocalePath();
  const [isRenewTokenError, setIsRenewTokenError] = useState(false);
  const handleBeforeAuth = () => {
    storeCurrentLocationAsAuthDestination();
  };

  const handleClose = () => {
    onClose();
  };

  const handleSignIn = () => {
    onClose();
  };

  useEffect(() => {
    if (ls.get('KEY_TOKEN_RENEW_ERROR_MODAL_OPEN')) {
      setIsRenewTokenError(true);
    }
  }, []);

  useEffect(() => {
    ls.set('KEY_TOKEN_RENEW_ERROR_MODAL_OPEN', false);
  }, []);

  return (
    <ClassicModalLayout
      onClose={handleClose}
      label={t('general.signIn.header', '')}
      dataCy="signInModal"
    >
      <ClassicModalContentLayout
        heading={t('general.signIn.header', '')}
        headerOption={
          <PlainTextButton
            uppercase
            onClick={handleSignIn}
            to={ROUTES.signup.navigatePath({ localePath })}
            dataCy="modalSignUpLink"
          >
            {t('general.button.freeSignUp', '')}
          </PlainTextButton>
        }
      >
        {isRenewTokenError ? (
          <TokenErrorContainer>{t('general.signIn.againPrompt', '')}</TokenErrorContainer>
        ) : (
          ''
        )}
        <SignInModalBody>
          <SignInForm onForgot={onClose} beforeAuth={handleBeforeAuth} />
        </SignInModalBody>
      </ClassicModalContentLayout>
    </ClassicModalLayout>
  );
};

export default SignInModal;
