// @flow
import React, { useState } from 'react';
import ProfileSection from '../ProfileSection/ProfileSection';
import Button from '../../../../../../../components/Button/Button';
import { useUserEmail } from '../../../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { useResetPassword } from '../../../../../../../api/auth/account';
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const [passwordHasReset, setPasswordHasReset] = useState(false);
  const { t } = useTranslation();

  const email = useUserEmail();
  const [resetPassword, busy] = useResetPassword();

  const handleReset = () => {
    resetPassword(email).then(() => {
      setPasswordHasReset(true);
    });
  };

  const description = passwordHasReset
    ? t('profile.password.resetSuccessMessage', '').replace('$EMAIL$', <strong>${email}</strong>)
    : t('profile.password.instruction', '');

  return (
    <ProfileSection heading={t('profile.password.header', '')} description={description}>
      {!passwordHasReset && (
        <Button onClick={handleReset} loading={busy}>
          {t('general.button.passwordReset', '')}
        </Button>
      )}
    </ProfileSection>
  );
};

export default ResetPassword;
