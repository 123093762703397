// @flow
import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import { LargeHeading } from '../../../styles/typography/components/Heading/Heading';
import GenericCopy from '../../../styles/typography/components/GenericCopy/GenericCopy';
import { spacing } from '../../../styles/config/spacing';
import { MediumContainer } from '../../../styles/layout';
import Button from '../../../components/Button/Button';
import { useLocalePath } from '../../../routing/components/LocaleWrapper/LocaleWrapper';
import { ROUTES } from '../../../routing/routes';
import { useTranslation } from 'react-i18next';

const Footer = styled('footer')`
  margin-top: ${spacing.huge}px;
`;

const descriptionClass = css`
  margin-top: ${spacing.large}px;
`;

type Props = {
  heading: string,
  descriptions?: Array<string>,
  footer?: any,
};

const ErrorInfo = ({ heading, descriptions, footer }: Props) => (
  <MediumContainer>
    <LargeHeading mono>{heading}</LargeHeading>

    {descriptions && (
      <GenericCopy className={descriptionClass}>
        {descriptions.map((description, index) => (
          <p key={index}>{description}</p>
        ))}
      </GenericCopy>
    )}

    {footer && <Footer>{footer}</Footer>}
  </MediumContainer>
);

export default ErrorInfo;

export const GenericErrorInfo = () => {
  const { t } = useTranslation();

  return (
    <ErrorInfo
      heading={t('general.error.generic.header', '')}
      descriptions={t('general.error.generic.descriptions', {
        defaultValue: [],
        returnObjects: true,
      })}
    />
  );
};

export const GenericAuthErrorInfo = () => {
  const { t } = useTranslation();
  const localePath = useLocalePath();

  return (
    <ErrorInfo
      heading={t('general.error.auth.header', '')}
      descriptions={t('general.error.auth.descriptions', { defaultValue: [], returnObjects: true })}
      footer={
        <Button to={ROUTES.signin.navigatePath({ localePath })}>
          {t('general.button.signIn', '')}
        </Button>
      }
    />
  );
};
