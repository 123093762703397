import React from 'react';
import styled from 'react-emotion';
import { cx } from 'emotion';
import {
  FilterElement,
  FilterLabel,
  FilterButtonWrapper,
  AddFilterButton,
  activeFilterContainer,
  zeroCountFilter,
  activeFilterLabel,
  activeFilterButton,
} from '../FilterMenuOption/FilterMenuOption';
import AddFilterIcon from '../../../../../../../../../../../assets/inline-assets/include-filter.svg';
import { getFormattedCount } from '../../../../../../../../../../../user/subscriptions/components/AddedSubscription/components/YouTubeChannelSearch/YouTubeChannelSearch';
import { useFilterChangeToast } from '../../../../../../../../../../../toasts/hooks';
import { isMobile } from '../../../../../../../../../../../utils/device';
import { useTranslation } from 'react-i18next';

type Props = {
  filterKey: string,
  filterLabel: string,
  filterCount: number,
  isIncluded: boolean,
  handleFilter: () => void,
};

const InternalAddFilterButton = styled(AddFilterButton)`
  &:hover {
    & span {
      &.includeFilter {
        right: 35px;
      }
    }
  }
`;

const InternalFilterMenuOption = ({
  filterKey,
  filterLabel,
  filterCount,
  isIncluded,
  handleFilter,
}: Props) => {
  const { t } = useTranslation();

  const count = `(${getFormattedCount(filterCount)})`;
  const includeFilterChangeToast = useFilterChangeToast(filterKey, filterLabel, 'included');
  const removedFilterChangeToast = useFilterChangeToast(filterKey, filterLabel, 'removed');

  const handleInternalFilter = () => {
    handleFilter();
    if (isIncluded && isMobile()) {
      removedFilterChangeToast();
    } else if (!isIncluded && isMobile()) {
      includeFilterChangeToast();
    }
  };
  return (
    <FilterElement
      className={cx({
        [activeFilterContainer]: isIncluded,
      })}
    >
      <FilterLabel
        className={cx({
          [activeFilterLabel]: isIncluded,
          [zeroCountFilter]: filterCount === 0,
        })}
      >
        {`${filterLabel} ${count}`}
      </FilterLabel>
      {(filterCount > 0 || isIncluded) && (
        <FilterButtonWrapper>
          <InternalAddFilterButton
            className={cx('includeFilter', {
              [activeFilterButton]: isIncluded,
            })}
            onClick={handleInternalFilter}
          >
            <AddFilterIcon />
            <span className="includeFilter">{t('resultsPage.text.includeFilterLabel', '')}</span>
          </InternalAddFilterButton>
        </FilterButtonWrapper>
      )}
    </FilterElement>
  );
};

export default InternalFilterMenuOption;
