// @flow
import React, { useState, useEffect } from 'react';
import styled from 'react-emotion';
import { useGlobalPlayerContext } from '../../../../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import {
  getCuratedCollectionId,
  getCuratedCollectionImage,
  getCuratedCollectionSongs,
  getCuratedCollectionTitle,
  sortCollectionsAlphabetically,
} from '../../../../../../../../../cms/curatedCollection';
import type { CuratedCollectionGraphMdl } from '../../../../../../../../../cms/curatedCollection';
import { useCuratedCollections } from '../../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { darkColor } from '../../../../../../../../../styles/config/colors';
import { useNavigate } from '../../../../../../../../hooks';
import { ROUTES } from '../../../../../../../../routes';
import PreviewTile from '../../../../../BrowseView/components/PreviewTile';
import PreviewTileRow from '../../../../../BrowseView/components/PreviewTileRow';
import { analyticsMixpanelPreviewSongs } from '../../../../../../../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../../../../../../../analytics/components/MixpanelWrapper';
import { useUserId } from '../../../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useResultsManagerContext } from '../../../ResultsManager/ResultsManager';
import { getSliderRows } from '../../../../../../../../../utils/grid';
import { useTranslation } from 'react-i18next';

type Props = {
  onViewMore: string => void,
  showAll: boolean,
};

const Container = styled('div')`
  margin: 0px auto;
`;

export const ViewMoreWrapper = styled('div')`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

const ViewAllLink = styled('h6')`
  color: rgba(15, 15, 20, 0.4);
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 16px;
  margin-left: 15px;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
    color: ${darkColor};
    cursor: pointer;
  }
`;

const CollectionResults = ({ onViewMore, showAll }: Props) => {
  const { t } = useTranslation();

  const {
    changeSongIDs,
    updateGlobalPlayerPlaybackDetails,
    handleUpdateGlobalAnalyticsData,
  } = useGlobalPlayerContext();

  const {
    keywords,
    mixpanelSearchKeywords,
    magicKeywords,
    youtubeKeywords,
    youtubeVideoTitle,
    collectionResultIDs,
  } = useResultsManagerContext()
    ? useResultsManagerContext()
    : {
        keywords: [],
        mixpanelSearchKeywords: [],
        magicKeywords: [],
        youtubeKeywords: [],
        youtubeVideoTitle: '',
        collectionResultIDs: [],
      };

  const curatedCollections = sortCollectionsAlphabetically(useCuratedCollections());

  const filteredCollections = curatedCollections
    .filter((collection: CuratedCollectionGraphMdl) => {
      return collectionResultIDs.includes(getCuratedCollectionId(collection));
    })
    .sort((a, b) => {
      const indexA = collectionResultIDs.findIndex(c => c === a.uid);
      const indexB = collectionResultIDs.findIndex(c => c === b.uid);

      return indexA - indexB;
    });

  const [sortedCollections, setSortedCollections] = useState(filteredCollections);
  const navigate = useNavigate();
  const { mixpanel, moengage, handleUpdateCollectionAnalytics } = useAnalyticsMixpanelContext();
  const userId = useUserId();

  const handlePreviewCollection = (collection: CuratedCollectionGraphMdl) => {
    const songs = getCuratedCollectionSongs(collection);
    if (songs.length < 1) return;

    const collectionTitle = getCuratedCollectionTitle(collection);
    const collectionSlug = getCuratedCollectionId(collection);
    handleUpdateGlobalAnalyticsData(
      mixpanelSearchKeywords,
      magicKeywords,
      youtubeKeywords,
      youtubeVideoTitle,
      'collectionResults',
      '',
      ''
    );
    changeSongIDs(songs);
    updateGlobalPlayerPlaybackDetails('collection', collectionTitle, collectionSlug);
  };

  const navigateCollection = (collectionSlug: string) => {
    handleUpdateCollectionAnalytics(
      keywords,
      mixpanelSearchKeywords,
      magicKeywords,
      youtubeKeywords,
      youtubeVideoTitle,
      'collectionResults',
      ''
    );
    navigate(ROUTES.collection.navigatePath({ collectionSlug }));
  };

  useEffect(() => {
    const newCollections = sortedCollections.filter((collection: CuratedCollectionGraphMdl) => {
      return collectionResultIDs.includes(getCuratedCollectionId(collection));
    });
    setSortedCollections(newCollections);
  }, [collectionResultIDs]);

  return (
    <>
      {sortedCollections.length > 0 && (
        <Container>
          <PreviewTileRow
            title={t('general.text.collections', '')}
            viewAllLinkButton={
              !showAll && (
                <ViewAllLink onClick={() => onViewMore('collections')}>
                  {t('general.button.viewAll', '')}
                </ViewAllLink>
              )
            }
            showAllTiles={showAll}
            rows={
              showAll
                ? getSliderRows(sortedCollections.length)
                : sortedCollections.length < 5
                ? 1
                : 2
            }
          >
            {sortedCollections.map(collection => {
              const slug = getCuratedCollectionId(collection);
              return (
                <PreviewTile
                  key={slug}
                  title={getCuratedCollectionTitle(collection)}
                  slug={slug}
                  bgImage={getCuratedCollectionImage(collection)}
                  listType="collection"
                  previewTile={() => {
                    analyticsMixpanelPreviewSongs(
                      mixpanel,
                      moengage,
                      'Collection',
                      slug,
                      'Collection Results',
                      userId
                    );
                    handlePreviewCollection(collection);
                  }}
                  navigateTile={() => {
                    navigateCollection(slug);
                  }}
                />
              );
            })}
          </PreviewTileRow>
        </Container>
      )}
    </>
  );
};

export default CollectionResults;
