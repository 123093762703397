// @flow
import React from 'react';
import styled from 'react-emotion';
import { useRecoilValue } from 'recoil';
import { transparentize } from 'polished';
import { useIsCollectionSaved } from '../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import {
  useRemoveSavedCollection,
  useSaveCollection,
} from '../../../../api/firebase/user/collections';
import { LightButton } from '../../../../components/Button/Button';
import { useUserId } from '../../../../auth/components/AuthWrapper/AuthWrapper';
import { useAnalyticsMixpanelContext } from '../../../../analytics/components/MixpanelWrapper';
import { analyticsMixpanelBookmarkCollection } from '../../../../analytics/mixpanel';
import { useSubscriptionsContext } from '../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import {
  colelctionKeywordsSearchedAtom,
  colelctionMixpanelKeywordsSearchedAtom,
  collectionMagicKeywordsSearchedAtom,
  collectionSectionOriginAtom,
  collectionYoutubeKeywordsSearchedAtom,
  collectionYoutubeVideoTitleSearchedAtom,
  relatedCollectionSearchedAtom,
} from '../../../../store/mixpanelAnalytics';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../styles/responsive';
import { darkColor, lightColor } from '../../../../styles/config/colors';
import { useTranslation } from 'react-i18next';

type Props = {
  collectionSlug: string,
};

const Container = styled('div')`
  margin-top: 15px;
  width: 220px;
  & button {
    width: 100%;
    border: 1px solid ${transparentize(1, darkColor)};
    & span {
      white-space: nowrap;
    }
    &:hover {
      background-color: ${transparentize(1, lightColor)};
      border: 1px solid ${transparentize(0.85, darkColor)};
      color: ${darkColor};
    }
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    width: 255px;
    margin-top: 5px;
  }
`;

const SaveCollectionsButton = ({ collectionSlug }: Props) => {
  const { t } = useTranslation();

  const [saveCollection, saveCollectionBusy] = useSaveCollection(collectionSlug);
  const [removeSavedCollection, removeCollectionBusy] = useRemoveSavedCollection(collectionSlug);
  const isCollectionSaved = useIsCollectionSaved(collectionSlug);

  const userId = useUserId();
  const { userRole } = useSubscriptionsContext();

  const colelctionKeywordsSearched = useRecoilValue(colelctionKeywordsSearchedAtom);
  const colelctionMixpanelKeywordsSearched = useRecoilValue(colelctionMixpanelKeywordsSearchedAtom);
  const collectionMagicKeywordsSearched = useRecoilValue(collectionMagicKeywordsSearchedAtom);
  const collectionYoutubeKeywordsSearched = useRecoilValue(collectionYoutubeKeywordsSearchedAtom);
  const collectionYoutubeVideoTitleSearched = useRecoilValue(
    collectionYoutubeVideoTitleSearchedAtom
  );
  const collectionSectionOrigin = useRecoilValue(collectionSectionOriginAtom);
  const relatedCollectionSearched = useRecoilValue(relatedCollectionSearchedAtom);

  const { mixpanel, moengage } = useAnalyticsMixpanelContext();

  const downloadData = {
    keywords: colelctionKeywordsSearched,
    mixpanelKeywords: colelctionMixpanelKeywordsSearched,
    magicKeywords: collectionMagicKeywordsSearched,
    youtubeKeywords: collectionYoutubeKeywordsSearched,
    youtubeVideoTitle: collectionYoutubeVideoTitleSearched,
    sectionOrigin: collectionSectionOrigin || 'collection',
    relatedCollection: relatedCollectionSearched,
    location: 'collections',
  };

  const busy = saveCollectionBusy || removeCollectionBusy;

  const label = isCollectionSaved
    ? t('collectionsPage.removeSavedCollectionAction', '')
    : t('collectionsPage.saveCollectionAction', '');

  const action = () => {
    if (busy) return;
    if (isCollectionSaved) {
      removeSavedCollection();
    } else {
      saveCollection();
      analyticsMixpanelBookmarkCollection(
        mixpanel,
        moengage,
        userId,
        collectionSlug,
        downloadData,
        userRole
      );
    }
  };

  return (
    <Container>
      <LightButton type="button" onClick={action}>
        {label}
      </LightButton>
    </Container>
  );
};

export default SaveCollectionsButton;
