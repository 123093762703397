// @flow
import React, { useEffect, useState } from 'react';
import styled from 'react-emotion';
import { useWindowWidth as useThrottledWindowWidth } from '@react-hook/window-size/throttled';
import { useResultsManagerContext } from '../ResultsManager/ResultsManager';
import FiltersList from './components/FiltersList/FiltersList';
import {
  FILTER_TYPES,
  getFilterMenuType,
} from '../../../../../ResultsScreen/components/ResultsView/components/ResultsAside/components/ResultsFilters/data';
import Modal, {
  filterMenuModal,
  filterMenuModalOverlayCss,
  filterMenuModalSelect,
  hiddenFilterMenuModal,
  ModalContainer,
} from '../../../../../../../modals/components/Modal/Modal';
import FilterSelectMenu from './components/FilterSelectMenu/FilterSelectMenu';
import FilterBooleanMenu from './components/FilterBooleanMenu/FilterBooleanMenu';
import FilterRange from './components/FilterRange/FilterRange';
import HiddenFilterMenu from './components/HiddenFilterMenu/HiddenFilterMenu';
import { buttonResetCss } from '../../../../../../../components/Button/Button';
import { BREAKPOINTS, MEDIA_BREAKPOINTS } from '../../../../../../../styles/responsive';
import { useTranslation } from 'react-i18next';

const Container = styled('div')`
  ${MEDIA_BREAKPOINTS.max850} {
    display: none;
  }
`;

const FilterMenu = styled('div')`
  display: flex;
`;

const BackToResultsButtonWrapper = styled('div')`
  width: 100%;
  text-align: center;
`;

const BackButton = styled('button')`
  ${buttonResetCss}
  height: 29px;
  width: 139px;
  margin: 0px 0px 24px 10px;
  color: #0f0f14;
  font-family: 'Roboto Mono';
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1.67px;
  line-height: 15px;
  text-align: center;
  border-radius: 3px;
  border-radius: 3px;
  background-color: #dad8d3;
`;

// 楽曲検索時のフィルターナビゲーション
const SearchFilters = () => {
  const {
    resultFacets,
    appliedFilters,
    resultFacetStats,
    emptySearch,
    noSongResults,
    viewingResults,
    handleSectionChange,
  } = useResultsManagerContext();
  const { t } = useTranslation();

  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [selectedfilterType, setSelectedFilterType] = useState('');
  const width = useThrottledWindowWidth();

  const hasFiltersApplied = Object.keys(appliedFilters).length > 0;
  const noSongSearchResults = !emptySearch && !noSongResults;
  const showFiltersMenu = noSongSearchResults || (noSongResults && hasFiltersApplied);

  const handleOpenFilterMenu = (filterKey: string) => {
    const filterType = getFilterMenuType(filterKey);
    setSelectedFilter(filterKey);
    setSelectedFilterType(filterType);
    setShowFilterMenu(true);
  };

  const handleCloseFilterMenu = () => {
    setSelectedFilter('');
    setSelectedFilterType('');
    setShowFilterMenu(false);
  };

  const handleViewAll = () => {
    handleSectionChange('all');
  };

  useEffect(() => {
    if (width < BREAKPOINTS.d850 && showFilterMenu) {
      handleCloseFilterMenu();
    }
  }, [width]);

  // フィルターモーダル
  const FilterModal = () => {
    switch (selectedfilterType) {
      case FILTER_TYPES.filterSelect:
        return (
          <Modal
            onClose={handleCloseFilterMenu}
            className={filterMenuModalSelect}
            overlayClassName={filterMenuModalOverlayCss}
          >
            <ModalContainer>
              <FilterSelectMenu
                selectedFilter={selectedFilter}
                resultFacets={resultFacets}
                appliedFilters={appliedFilters}
              />
            </ModalContainer>
          </Modal>
        );
      case FILTER_TYPES.filterRange:
        return (
          <Modal
            onClose={handleCloseFilterMenu}
            className={filterMenuModal}
            overlayClassName={filterMenuModalOverlayCss}
          >
            <ModalContainer>
              <FilterRange
                selectedFilter={selectedFilter}
                resultFacetStats={resultFacetStats}
                appliedFilters={appliedFilters}
              />
            </ModalContainer>
          </Modal>
        );
      case FILTER_TYPES.filterBoolean:
        return (
          <Modal
            onClose={handleCloseFilterMenu}
            className={filterMenuModal}
            overlayClassName={filterMenuModalOverlayCss}
          >
            <ModalContainer>
              <FilterBooleanMenu
                selectedFilter={selectedFilter}
                resultFacets={resultFacets}
                appliedFilters={appliedFilters}
              />
            </ModalContainer>
          </Modal>
        );
      case FILTER_TYPES.filterInternalHidden:
        return (
          <Modal
            onClose={handleCloseFilterMenu}
            className={hiddenFilterMenuModal}
            overlayClassName={filterMenuModalOverlayCss}
          >
            <ModalContainer>
              <HiddenFilterMenu />
            </ModalContainer>
          </Modal>
        );
      default:
        return <></>;
    }
  };

  return (
    <Container>
      {showFiltersMenu && (
        <FilterMenu>
          <FiltersList
            appliedFilters={appliedFilters}
            handleOpenModalMenu={handleOpenFilterMenu}
            selectedFilter={selectedFilter}
          />
          {showFilterMenu && <FilterModal />}
        </FilterMenu>
      )}
      {showFiltersMenu && viewingResults === 'collections' && (
        <BackToResultsButtonWrapper>
          <BackButton type="button" onClick={handleViewAll}>
            {t('resultsPage.button.backToResults', '')}
          </BackButton>
        </BackToResultsButtonWrapper>
      )}
    </Container>
  );
};

export default SearchFilters;
