// @flow
import React from 'react';
import { useNavigate } from '../../../../../hooks';
import { ROUTES } from '../../../../../routes';
import { useCuratedCollections } from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getCuratedCollectionId,
  getCuratedCollectionImage,
  getCuratedCollectionTitle,
  getRecentlyUpdatedCollections,
} from '../../../../../../cms/curatedCollection'; // TODO; コレクションのPrismic脱却対応
import PreviewTile from './PreviewTile';
import type { CuratedCollectionGraphMdl } from '../../../../../../cms/curatedCollection';
import PreviewTileRow from './PreviewTileRow';
import { analyticsMixpanelPreviewSongs } from '../../../../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../../../../analytics/components/MixpanelWrapper';
import { useUserId } from '../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useTranslation } from 'react-i18next';

type Props = {
  handlePreviewCollection: (collection: CuratedCollectionGraphMdl, origin: string) => void,
};

const NewestCollections = ({ handlePreviewCollection }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mixpanel, moengage, handleUpdateCollectionAnalytics } = useAnalyticsMixpanelContext();
  const userId = useUserId();

  const curatedCollections = useCuratedCollections();
  const newestCollections = getRecentlyUpdatedCollections(curatedCollections);

  const navigateCollection = (collectionSlug: string) => {
    handleUpdateCollectionAnalytics([], [], [], [], '', 'newestCollection', '');
    navigate(ROUTES.collection.navigatePath({ collectionSlug }));
  };

  return (
    <PreviewTileRow
      title={t('musicPage.header.newCollections', '')}
      viewAllLink={ROUTES.collections.navigatePath({})}
      showAllTiles={false}
      rows={2}
    >
      {newestCollections.map(collection => {
        const slug = getCuratedCollectionId(collection);
        return (
          <PreviewTile
            key={slug}
            title={getCuratedCollectionTitle(collection)}
            slug={slug}
            bgImage={getCuratedCollectionImage(collection)}
            listType="collection"
            previewTile={() => {
              analyticsMixpanelPreviewSongs(
                mixpanel,
                moengage,
                'Collection',
                slug,
                'Newest Collections',
                userId
              );
              handlePreviewCollection(collection, 'newestCollection');
            }}
            navigateTile={() => {
              navigateCollection(slug);
            }}
          />
        );
      })}
    </PreviewTileRow>
  );
};

export default NewestCollections;
