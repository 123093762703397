// @flow
import React from 'react';
import styled from 'react-emotion';
import InternalFilterMenuOption from '../InternalFilterMenuOption/InternalFilterMenuOption';
import {
  Body,
  OptionsContainer,
  FilteringSubTitle,
  FilterOptions,
} from '../FilterSelectMenu/FilterSelectMenu';
import { useResultsManagerContext } from '../../../ResultsManager/ResultsManager';
import { useTranslation } from 'react-i18next';

const InternalFilterBody = styled(Body)`
  height: 150px;
  width: 300px;
`;

const HiddenFilterMenu = () => {
  const { t } = useTranslation();

  const { showHiddenSongs, handleShowHiddenSongs, hiddenTracksCount } = useResultsManagerContext();
  const handleHiddenSongsFilter = () => {
    handleShowHiddenSongs();
  };

  const getInternalFilterLabel = (filterKey: string) => {
    if (filterKey === 'hidden') {
      return t('resultPage.text.hidden', '');
    }

    return '';
  };

  return (
    <InternalFilterBody>
      <OptionsContainer>
        <FilteringSubTitle>{getInternalFilterLabel('hidden')}</FilteringSubTitle>
        <FilterOptions>
          <InternalFilterMenuOption
            filterKey="hidden"
            filterLabel={t('resultsPage.text.showHiddenSongsFilterLabel', '')}
            filterCount={hiddenTracksCount}
            isIncluded={showHiddenSongs}
            handleFilter={handleHiddenSongsFilter}
          />
        </FilterOptions>
      </OptionsContainer>
    </InternalFilterBody>
  );
};

export default HiddenFilterMenu;
