// @flow
import React, { useEffect } from 'react';
import { css } from 'emotion';
import styled from 'react-emotion';
import { transparentize } from 'polished';
// import TruncateMarkup from 'react-truncate-markup';
import { SMALL_DESKTOP_BREAKPOINT } from '../../../../../../../../../styles/responsive';
import { lightColor } from '../../../../../../../../../styles/config/colors';
import PlusIcon from '../../../../../../../../../assets/inline-assets/plus-icon.svg';
import { useKeywordsManagerContext } from '../../../KeywordsManager/KeywordsManager';
import { InteractiveKeywordNewUi } from '../../../../../../../ResultsScreen/components/ResultsView/components/ResultsAside/components/ResultsKeywords/ResultsKeywords';
import { useTranslation } from 'react-i18next';

export const clearButtonClass = css`
  opacity: 0.4;

  &:hover,
  &:focus {
    opacity: 1;
  }
`;

const Wrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  .ResultsKeywords-button {
    border: 2px solid #25272c;
    margin-top: 2px;
    ${SMALL_DESKTOP_BREAKPOINT} {
      margin-top: 10px;
      margin-left: 10px;
    }
  }
`;

const TruncateButton = styled('span')`
  text-align: center;
  display: flex;
  justify-contnet: center;
  align-items: center;
  margin: 5px 0px 0px 5px;
  border-radius: 2px;
  background-color: ${transparentize(0.9, lightColor)};
  padding: 5px 5px;
  width- fit-content;
  border-radius: 2px;
  cursor: pointer;

  ${SMALL_DESKTOP_BREAKPOINT} {
    margin: 10px 0px 0px 10px;
    padding: 0px 5px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

// const keywordWrapper = css`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   flex-direction: row;
// `;

type Props = {
  keywordLimit: number,
  setKeywordLimit: number => void,
  showAllKeywords: boolean,
  setShowTruncateButton: boolean => void,
  showTruncateButton: boolean,
  initialKeywordLimit: number,
  handleTruncate: () => void,
};

const SelectedKeywords = ({
  keywordLimit,
  setKeywordLimit,
  showAllKeywords,
  setShowTruncateButton,
  showTruncateButton,
  initialKeywordLimit,
  handleTruncate,
}: Props) => {
  const { t } = useTranslation();

  const { mergedUIKeywords, handleRemoveKeyword } = useKeywordsManagerContext();

  useEffect(() => {
    if (showAllKeywords) {
      setKeywordLimit(Math.max(mergedUIKeywords.length, initialKeywordLimit));
    }

    if (mergedUIKeywords.length <= initialKeywordLimit) {
      setShowTruncateButton(false);
    } else {
      setShowTruncateButton(true);
    }
  }, [mergedUIKeywords]);
  return (
    <Wrapper>
      {mergedUIKeywords.slice(0, keywordLimit).map(keyword => (
        <InteractiveKeywordNewUi
          icon={<PlusIcon />}
          iconStyle="pluseIcon"
          key={keyword}
          onClick={() => {
            handleRemoveKeyword(keyword);
          }}
          dataCy="selectedKeyword"
        >
          {keyword}
        </InteractiveKeywordNewUi>
      ))}
      {showTruncateButton && (
        <TruncateButton onClick={handleTruncate}>
          {showAllKeywords ? t('general.button.showLess', '') : t('general.button.showMore', '')}
        </TruncateButton>
      )}
      {/*
        <TruncateMarkup lines={5} ellipsis={<span>....</span>}>
          <div className={keywordWrapper}>
            {keywords.map(keyword => (
              <TruncateMarkup.Atom key={keyword}>
                <InteractiveKeywordNewUi
                  icon={<PlusIcon />}
                  iconStyle="pluseIcon"
                  key={keyword}
                  onClick={() => {
                    removeKeyword(keyword);
                  }}
                  dataCy="selectedKeyword"
                >
                  {keyword}
                </InteractiveKeywordNewUi>
              </TruncateMarkup.Atom>
            ))}
          </div>
        </TruncateMarkup>
      */}
    </Wrapper>
  );
};

export default SelectedKeywords;
