// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../../../../modals/components/ClassicModalLayout/ClassicModalLayout';
import type { UserSubscriptionMdl } from '../../../../api/firebase/user/subscriptions';
import {
  getSubscriptionPlanKey,
  getSubscriptionChannelId,
  getSubscriptionExtraChannels,
} from '../../../../api/firebase/user/subscriptions';
import Button, { DarkButton } from '../../../../components/Button/Button';
import { LARGE_DEVICE_BREAKPOINT, SMALL_DEVICE_BREAKPOINT } from '../../../../styles/responsive';
import { useSubscriptionsContext } from '../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { ACTIONS } from '../ChangeSubscriptionModal/ChangeSubscriptionModal';
import { SUBSCRIPTIONS } from '../../data';
import { useTranslation } from 'react-i18next';

export const SubscriptionModalFooter = styled('div')`
  ${LARGE_DEVICE_BREAKPOINT} {
    display: flex;

    > button {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    display: flex;
    flex-direction: column;

    > button {
      &:first-child {
        order: 2;
        margin-top: 10px;
      }

      &:last-child {
        order: 1;
      }
    }
  }
`;

type Props = {
  onClose: () => void,
  subscription: UserSubscriptionMdl,
  actionState: string,
  newPlanKey: string,
  newPlanInterval: string,
};

const ChangeFreeTrialSubscriptionModal = ({
  onClose,
  subscription,
  actionState,
  newPlanKey,
  newPlanInterval,
}: Props) => {
  const [busy, setBusy] = useState(false);
  const { t } = useTranslation();

  const {
    removeSubscription,
    billingCycle,
    setBillingCycle,
    upgradeSubscription,
    showDowngradeModal,
  } = useSubscriptionsContext();

  const promotionalDefaultInterval = '';
  const defaultInterval = promotionalDefaultInterval || 'annual';

  const actions = {
    close: t('general.button.cancel', ''),
    upgrade: t('general.button.upgrade', ''),
    downgrade: t('general.button.downgrade', ''),
    change: t('general.button.change', ''),
    cancel: t('general.button.confirm', ''),
    continue: t('general.button.continie', ''),
  };

  const handleClose = () => {
    if (busy) return;
    setBillingCycle(defaultInterval);
    onClose();
  };

  // Note in all of the below, we use the functionality of upgrading a subscriptions because we need any
  // free trials moving to a paid tier to use the same functionallity on the back end as an upgrade
  // (triggered immediately and open CB checkout)

  const handleCancellation = () => {
    if (busy) return;
    setBusy(true);
    removeSubscription(subscription.key, true)
      .then(() => {
        handleClose();
      })
      .catch(error => {
        Sentry.captureMessage('Something went wrong when removing subscription on free trial');
        Sentry.captureException(error);
        console.error(error);
        setBusy(false);
      });
  };

  const handleUpgrade = () => {
    if (busy) return;
    setBusy(true);
    upgradeSubscription(getSubscriptionChannelId(subscription), newPlanKey, newPlanInterval)
      .then(() => {
        setBillingCycle(defaultInterval);
        onClose();
      })
      .catch(error => {
        Sentry.captureMessage('Something went wrong when upgrading subscription on free trial');
        Sentry.captureException(error);
        console.error(error);
        setBusy(false);
      });
  };

  const handleDowngrade = () => {
    if (busy) return;
    setBusy(true);

    if (
      getSubscriptionExtraChannels(subscription).length > 0 &&
      SUBSCRIPTIONS.business.plan_codes.includes(getSubscriptionPlanKey(subscription))
    ) {
      const subscriptionKey = subscription.key;
      showDowngradeModal(subscriptionKey, newPlanKey);
      onClose();
      setBusy(false);
      return;
    }

    upgradeSubscription(getSubscriptionChannelId(subscription), newPlanKey, newPlanInterval)
      .then(() => {
        setBillingCycle(defaultInterval);
        onClose();
      })
      .catch(error => {
        Sentry.captureMessage('Something went wrong when downgrading subscription on free trial');
        Sentry.captureException(error);
        console.error(error);
        setBusy(false);
      });
  };

  const handleChangeInterval = () => {
    if (busy) return;
    setBusy(true);
    upgradeSubscription(
      getSubscriptionChannelId(subscription),
      subscription.seat.plan,
      billingCycle
    )
      .then(() => {
        setBillingCycle(defaultInterval);
        onClose();
      })
      .catch(error => {
        Sentry.captureMessage(
          'Something went wrong when changing subscription interval on free trial'
        );
        Sentry.captureException(error);
        console.error(error);
        setBusy(false);
      });
  };

  const handleAction = () => {
    if (actionState === ACTIONS.upgrade) {
      handleUpgrade();
      return;
    }
    if (actionState === ACTIONS.downgrade) {
      handleDowngrade();
      return;
    }
    if (actionState === ACTIONS.change) {
      handleChangeInterval();
      return;
    }
    handleCancellation();
  };

  const getPlanActionText = (): string => {
    if (actionState === ACTIONS.upgrade) {
      return actions.upgrade;
    }
    if (
      actionState === ACTIONS.downgrade &&
      getSubscriptionExtraChannels(subscription).length > 0 &&
      SUBSCRIPTIONS.business.plan_codes.includes(getSubscriptionPlanKey(subscription))
    ) {
      return actions.continue;
    }
    if (actionState === ACTIONS.downgrade) {
      return actions.downgrade;
    }
    if (actionState === ACTIONS.change) {
      return actions.change;
    }
    return actions.cancel;
  };

  return (
    <ClassicModalLayout onClose={handleClose} fullSized={false}>
      <ClassicModalContentLayout
        footer={
          <SubscriptionModalFooter>
            <DarkButton onClick={handleClose}>
              {actionState !== ACTIONS.upgrade_cancelled_trial
                ? t('general.button.cancel', '')
                : t('general.button.close', '')}
            </DarkButton>
            {actionState !== ACTIONS.upgrade_cancelled_trial && (
              <Button loading={busy} onClick={handleAction}>
                {getPlanActionText()}
              </Button>
            )}
          </SubscriptionModalFooter>
        }
      />
    </ClassicModalLayout>
  );
};

export default ChangeFreeTrialSubscriptionModal;
