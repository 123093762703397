// @flow
import React, { useState, useEffect } from 'react';
import styled from 'react-emotion';
import { get } from 'lodash';
import { cx, css } from 'emotion';
import { useSetRecoilState } from 'recoil';
import { smallPlainTextCss } from '../../../../../../../../../styles/typography/typography';
import { SMALL_DESKTOP_BREAKPOINT } from '../../../../../../../../../styles/responsive';
import type {
  AlgoliaSearchResults,
  AppliedAlgoliaFilters,
} from '../../../../../../../../../api/algolia/search';
import { searchAlgolia, getFilterQuery } from '../../../../../../../../../api/algolia/search';
import { FilteringSubTitle } from '../FilterSelectMenu/FilterSelectMenu';
import FilterMenuBooleanOption from '../FilterMenuBooleanOption/FilterMenuBooleanOption';
import { useResultsManagerContext } from '../../../ResultsManager/ResultsManager';
import { useLocale } from '../../../../../../../../components/LocaleWrapper/LocaleWrapper';
import { useSubscriptionsContext } from '../../../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import {
  USER_PERMISSIONS,
  SUBSCRIPTIONS,
} from '../../../../../../../../../user/subscriptions/data';
import {
  getGroupedBooleanFilters,
  getUngroupedBooleanFilters,
  getAppliedFiltersInclusiveList,
  getAppliedFiltersExclusiveList,
} from '../../../../../../../../../api/algolia/data';
import { useShowSignUp } from '../../../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useAuthContext } from '../../../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { getCountAfterHiddenSongs } from '../../../../../../../../../utils/hiddenSongs';
import { conversionSourceAtom } from '../../../../../../../../../store/mixpanelAnalytics';
import { useTranslation } from 'react-i18next';

const activeTooltip = 'activeTooltip';

const Body = styled('section')`
  ${smallPlainTextCss};
  padding: 15px;
  position: relative;
  width: 300px;
  height: 322px;

  &:hover {
    & .${activeTooltip} {
      opacity: 1;
      display: block;
      width: 100%;
    }
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    width: 100%;
    max-height: 610px;
    padding: 10px;
  }
`;

const expandedMenu = css`
  height: 340px;
`;

const SubGroup = styled('div')``;

const FilterRestrictionsWrapper = styled('div')`
  ${SMALL_DESKTOP_BREAKPOINT} {
    display: none;
  }
`;

const FilterRestrictionsTooltip = styled('div')`
  display: block;
  width: 100%;
  opacity: 0;
  background-color: white;
  font-size: 12px;
  border-radius: 3px;
  text-align: center;
  height: 36px;
  padding: 5px 20px;
  line-height: 14px;
  transition: opacity 200ms ease-in;
  cursor: pointer;
`;

const getUnfilteredCount = (keywords, appliedFilters, selectedFilter, locale) => {
  const updatedAppliedFilters = {
    ...appliedFilters,
  };

  delete updatedAppliedFilters[selectedFilter];
  const filterQuery = getFilterQuery(updatedAppliedFilters);

  return searchAlgolia(keywords.join(', '), locale, [], filterQuery).then(
    (response: AlgoliaSearchResults) => {
      return response.facets[selectedFilter];
    }
  );
};

const getGroupCount = (facetCount, unfilteredFacets, groupedFilters, countMap) => {
  let count = 0;

  groupedFilters.forEach(groupedFilter => {
    const groupedFacetCount = get(unfilteredFacets, groupedFilter, 0);
    const finalCount = getCountAfterHiddenSongs(
      'mixType',
      groupedFilter,
      countMap,
      groupedFacetCount
    );
    const groupedFilterCount = facetCount[groupedFilter] ? facetCount[groupedFilter] : finalCount;
    count += groupedFilterCount;
  });
  return count;
};

export const isGroupApplied = (appliedFitlers, groupedFilters) => {
  let groupIsApplied = true;
  groupedFilters.forEach(groupedFilter => {
    const groupedFilterIsApplied = appliedFitlers.includes(groupedFilter);
    if (!groupedFilterIsApplied) {
      groupIsApplied = false;
    }
  });
  return groupIsApplied;
};

type Props = {
  resultFacets: any,
  selectedFilter: string,
  onClose?: () => void,
  appliedFilters: AppliedAlgoliaFilters,
};

const FilterBooleanMenu = ({
  resultFacets,
  selectedFilter,
  onClose = () => {},
  appliedFilters,
}: Props) => {
  const { t } = useTranslation();

  const locale = useLocale();
  const {
    userRole,
    subscriptions,
    selectPlan,
    openChangeSubscriptionModal,
  } = useSubscriptionsContext();
  const { displayKeywords, hiddenTracksMap } = useResultsManagerContext();
  const showSignUp = useShowSignUp();
  const setConversionSource = useSetRecoilState(conversionSourceAtom);

  const { isAuthenticated, signUpSourceData, setSignUpSourceData } = useAuthContext();
  const [unfilteredFacets, setUnfilteredFacets] = useState(resultFacets[selectedFilter]);

  const userHasFilterPermission = USER_PERMISSIONS[userRole].filteringPermissions.includes(
    selectedFilter
  );
  const appliedIncludedValues = getAppliedFiltersInclusiveList(appliedFilters, selectedFilter);
  const appliedExcludedValues = getAppliedFiltersExclusiveList(appliedFilters, selectedFilter);
  const ungroupedFiltersList = getUngroupedBooleanFilters(locale, selectedFilter);
  const groupedFiltersList = getGroupedBooleanFilters(locale, selectedFilter);

  useEffect(() => {
    if (appliedIncludedValues.length === 0 && appliedExcludedValues.length === 0) {
      setUnfilteredFacets(resultFacets[selectedFilter]);
      return;
    }

    getUnfilteredCount(displayKeywords, appliedFilters, selectedFilter, locale).then(response => {
      setUnfilteredFacets(response);
    });
  }, [appliedFilters]);

  const handleUpgradeClick = () => {
    const upgradeEnabled = subscriptions.length > 0 ? true : false;
    if (!isAuthenticated && signUpSourceData.signUpSource !== 'Landing Page') {
      setSignUpSourceData({ signUpSource: 'Filters', signUpCampaign: '' });
      setConversionSource('filters_type');
      showSignUp();
      return;
    }

    setConversionSource('filters_type');

    if (upgradeEnabled) {
      const defaultSubscriptionKey = subscriptions[0].key;
      openChangeSubscriptionModal(defaultSubscriptionKey);
    } else {
      selectPlan(SUBSCRIPTIONS.creatorPro.key);
    }
  };

  return (
    <Body className={cx({ [expandedMenu]: !userHasFilterPermission })}>
      <div>
        <FilteringSubTitle>{t('general.text.types', '')}</FilteringSubTitle>
        <div>
          {ungroupedFiltersList.map(filter => {
            const facetCount = get(resultFacets, `${selectedFilter}.${filter}`, 0)
              ? get(resultFacets, `${selectedFilter}.${filter}`, 0)
              : get(unfilteredFacets, filter, 0);
            const finalCount = getCountAfterHiddenSongs(
              selectedFilter,
              filter,
              hiddenTracksMap,
              facetCount
            );
            return (
              <FilterMenuBooleanOption
                key={filter}
                facetKey={selectedFilter}
                filterKey={filter}
                filterLabel={filter}
                filterCount={finalCount}
                isIncluded={appliedIncludedValues.includes(filter)}
                isExcluded={appliedExcludedValues.includes(filter)}
                filterPermission={userHasFilterPermission}
              />
            );
          })}
        </div>
        <div>
          {Object.keys(groupedFiltersList).map(groupedFilter => {
            return (
              <>
                <FilterMenuBooleanOption
                  key={groupedFilter}
                  facetKey={selectedFilter}
                  filterKey={groupedFilter}
                  filterLabel={groupedFilter}
                  filterCount={getGroupCount(
                    get(resultFacets, selectedFilter, 0),
                    unfilteredFacets,
                    groupedFiltersList[groupedFilter],
                    hiddenTracksMap
                  )}
                  isIncluded={isGroupApplied(
                    appliedIncludedValues,
                    groupedFiltersList[groupedFilter]
                  )}
                  isExcluded={isGroupApplied(
                    appliedExcludedValues,
                    groupedFiltersList[groupedFilter]
                  )}
                  isGroupFilter
                  filterPermission={userHasFilterPermission}
                />
                <SubGroup>
                  {groupedFiltersList[groupedFilter].map(subgroupFilter => {
                    const facetCount = get(resultFacets, `${selectedFilter}.${subgroupFilter}`, 0)
                      ? get(resultFacets, `${selectedFilter}.${subgroupFilter}`, 0)
                      : get(unfilteredFacets, subgroupFilter, 0);
                    const finalCount = getCountAfterHiddenSongs(
                      selectedFilter,
                      subgroupFilter,
                      hiddenTracksMap,
                      facetCount
                    );
                    return (
                      <FilterMenuBooleanOption
                        key={subgroupFilter}
                        facetKey={selectedFilter}
                        filterKey={subgroupFilter}
                        filterLabel={subgroupFilter}
                        filterCount={finalCount}
                        isIncluded={appliedIncludedValues.includes(subgroupFilter)}
                        isExcluded={appliedExcludedValues.includes(subgroupFilter)}
                        isSubGroupFilter
                        filterPermission={userHasFilterPermission}
                      />
                    );
                  })}
                </SubGroup>
              </>
            );
          })}
        </div>
        {!userHasFilterPermission && (
          <FilterRestrictionsWrapper onClick={handleUpgradeClick}>
            <FilterRestrictionsTooltip className={activeTooltip}>
              {t('resultsPage.tooltip.advancedAccess', '')}
            </FilterRestrictionsTooltip>
          </FilterRestrictionsWrapper>
        )}
      </div>
    </Body>
  );
};

export default FilterBooleanMenu;
