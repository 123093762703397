// @flow
import React from 'react';
import { cx, css } from 'emotion';
import styled from 'react-emotion';
import { darkColor } from '../../../../../../styles/config/colors';
import { useNavigate } from '../../../../../hooks';
import { BREAKPOINTS, MOBILE_DEVICE_BREAKPOINT } from '../../../../../../styles/responsive';
import { spacing } from '../../../../../../styles/config/spacing';
import PreviewSlider from './PreviewSlider';
import CustomPreviewSliderArrow from './CustomPreviewSliderArrow';
import { useTranslation } from 'react-i18next';

type Props = {
  children: any,
  title: string,
  viewAllLink?: string,
  viewAllLinkButton?: any,
  showAllTiles?: boolean,
  rows?: number,
  hasSorting?: boolean,
  sortingMenu?: any,
  columnTitle?: boolean,
};

const Container = styled('div')`
  margin: 14px auto;

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 100%;
    padding: 0px;
  }
`;

const SectionHeading = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SectionInfo = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  & h3 {
    color: #0F0F24;
    font-family: Roboto;
    font-size: 19px;
    letter-spacing: 0.24px;
    line-height: 23px;
  }
}

${MOBILE_DEVICE_BREAKPOINT} {
  padding: 0px ${spacing.small}px;
}
`;

const SectionTitle = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const SortingMenu = styled('div')``;

const ViewAllLink = styled('h6')`
  color: rgba(15, 15, 20, 0.4);
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 16px;
  margin-left: 15px;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
    color: ${darkColor};
    cursor: pointer;
  }
`;

const columnTitleLayoutCss = css`
  ${MOBILE_DEVICE_BREAKPOINT} {
    flex-direction: column;

    & h6 {
      margin: 5px 0px 0px 0px;
    }
  }
`;

// スライダーのデフォルト設定
const defaultSettings = {
  slidesToScroll: 8,
  dots: false,
  arrows: true,
  slidesToShow: 8,
  useTransform: true,
  prevArrow: <CustomPreviewSliderArrow previous />,
  nextArrow: <CustomPreviewSliderArrow />,
  responsive: [
    {
      breakpoint: BREAKPOINTS.d1180 - 1,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 8,
      },
    },
    {
      breakpoint: BREAKPOINTS.d1000 - 1,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      },
    },
    {
      breakpoint: BREAKPOINTS.d850 - 1,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: BREAKPOINTS.d600 - 1,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

const PreviewTileRow = ({
  children,
  title,
  viewAllLink = '',
  viewAllLinkButton = null,
  showAllTiles = false,
  rows,
  hasSorting = false,
  sortingMenu = <></>,
  columnTitle = false,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateAll = () => {
    navigate(viewAllLink);
  };

  const isInfiniteGrid = (() => {
    if (showAllTiles) {
      return false;
    }
    return children.length >= 5;
  })();

  return (
    <Container>
      <SectionHeading>
        <SectionInfo>
          <SectionTitle className={cx({ [columnTitleLayoutCss]: columnTitle })}>
            <h3>{title}</h3>
            {viewAllLink && (
              <ViewAllLink onClick={navigateAll}>{t('general.button.viewAll', '')}</ViewAllLink>
            )}
            {viewAllLinkButton && <>{viewAllLinkButton}</>}
          </SectionTitle>
          {hasSorting && <SortingMenu>{sortingMenu}</SortingMenu>}
        </SectionInfo>
      </SectionHeading>
      <PreviewSlider settings={defaultSettings} rows={rows} infinite={isInfiniteGrid}>
        {children}
      </PreviewSlider>
    </Container>
  );
};

export default PreviewTileRow;
