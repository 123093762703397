// @flow
import React, { useState, useEffect } from 'react';
import styled from 'react-emotion';
import { useAnalyticsMixpanelContext } from '../../../../../../../analytics/components/MixpanelWrapper';
import { analyticsMixpanelPreviewSongs } from '../../../../../../../analytics/mixpanel';
import { useGlobalPlayerContext } from '../../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { useUserId } from '../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import {
  getCuratedCollectionId,
  getCuratedCollectionImage,
  getCuratedCollectionSongs,
  getCuratedCollectionTitle,
  sortCollectionsAlphabetically,
} from '../../../../../../../cms/curatedCollection';
import type { CuratedCollectionGraphMdl } from '../../../../../../../cms/curatedCollection';
import { useCuratedCollections } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { lightColor } from '../../../../../../../styles/config/colors';
import { useUserSavedCollections } from '../../../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { useNavigate } from '../../../../../../hooks';
import { ROUTES } from '../../../../../../routes';
import PreviewTile from '../../../../../MusicScreen/views/BrowseView/components/PreviewTile';
import PreviewTileRow from '../../../../../MusicScreen/views/BrowseView/components/PreviewTileRow';
import CollectionsSorting from '../../../../../MusicScreen/views/CollectionsView/components/CollectionsSorting/CollectionsSorting';
import type {
  SavedCollections,
  SavedCollectionMdl,
} from '../../../../../../../api/firebase/user/collections';
import { MOBILE_DEVICE_BREAKPOINT } from '../../../../../../../styles/responsive';
import { getSliderRows } from '../../../../../../../utils/grid';
import { useBookmarksManagerContext } from '../BookmarksManager/BookmarksManager';
import { useTranslation } from 'react-i18next';

const Container = styled('div')`
  margin: 0px auto 80px auto;
`;

export const ViewMoreWrapper = styled('div')`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

const ViewAllLink = styled('h6')`
  color: rgba(240, 240, 235, 0.4);
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 16px;
  margin-left: 15px;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
    color: ${lightColor};
    cursor: pointer;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    margin-left: 10px;
    margin-top: 5px;
  }
`;

const getSavedCollectionTimestamp = (savedCollection: SavedCollectionMdl) => {
  if (savedCollection.timestamp) {
    return savedCollection.timestamp.seconds * 1000;
  }
  const date = new Date();
  return date.getTime() / 1000;
};

const orderSavedCollectionsByMostRecent = (
  savedCollections: SavedCollections,
  collectionData: Array<CuratedCollectionGraphMdl>
) => {
  const sortedByDate = Object.keys(savedCollections).sort((a, b) => {
    return (
      new Date(getSavedCollectionTimestamp(savedCollections[b])) -
      new Date(getSavedCollectionTimestamp(savedCollections[a]))
    );
  });

  const sortedCollections = collectionData.sort((a, b) => {
    const indexA = sortedByDate.findIndex(el => el === getCuratedCollectionId(a));
    const indexB = sortedByDate.findIndex(el => el === getCuratedCollectionId(b));
    return indexA - indexB;
  });

  return sortedCollections;
};

const SavedCollectionsGrid = () => {
  const { t } = useTranslation();

  const allCollections = useCuratedCollections();
  const { mixpanel, moengage, handleUpdateCollectionAnalytics } = useAnalyticsMixpanelContext();
  const userId = useUserId();
  const navigate = useNavigate();
  const {
    changeSongIDs,
    updateGlobalPlayerPlaybackDetails,
    handleUpdateGlobalAnalyticsData,
  } = useGlobalPlayerContext();
  const { viewingBookmarks, handleBookmarksTab } = useBookmarksManagerContext();
  const [sortingMethod, setSortingMethod] = useState('newest');

  const userSavedCollections = useUserSavedCollections();
  const savedCollectionIds = Object.keys(userSavedCollections);
  const collections = allCollections.filter(collection => {
    return savedCollectionIds.includes(getCuratedCollectionId(collection));
  });

  const [sortedCollections, setSortedCollections] = useState(
    orderSavedCollectionsByMostRecent(userSavedCollections, collections)
  );

  const handlePreviewCollection = (collection: CuratedCollectionGraphMdl) => {
    const songs = getCuratedCollectionSongs(collection);
    if (songs.length < 1) return;

    const collectionTitle = getCuratedCollectionTitle(collection);
    const collectionSlug = getCuratedCollectionId(collection);
    handleUpdateGlobalAnalyticsData([], [], [], '', 'savedCollections', '', '');
    changeSongIDs(songs);
    updateGlobalPlayerPlaybackDetails('collection', collectionTitle, collectionSlug);
  };

  const navigateCollection = (collectionSlug: string) => {
    handleUpdateCollectionAnalytics([], [], [], [], '', 'savedCollections', '');
    navigate(ROUTES.collection.navigatePath({ collectionSlug }));
  };

  const handleSorting = (event: any) => {
    const newSortingMethod = event.target.value;

    if (newSortingMethod === 'alphabetical') {
      const newCollectionOrder = sortCollectionsAlphabetically(sortedCollections);
      setSortedCollections(newCollectionOrder);
    } else {
      const newCollectionOrder = orderSavedCollectionsByMostRecent(
        userSavedCollections,
        sortedCollections
      );
      setSortedCollections(newCollectionOrder);
    }

    setSortingMethod(newSortingMethod);
  };

  useEffect(() => {
    if (viewingBookmarks !== 'collections') {
      const newCollectionOrder = orderSavedCollectionsByMostRecent(
        userSavedCollections,
        sortedCollections
      );
      setSortedCollections(newCollectionOrder);
    }
  }, [viewingBookmarks]);

  const showAll = viewingBookmarks === 'collections';

  return (
    <>
      {sortedCollections.length > 0 && (
        <Container>
          <PreviewTileRow
            title={t('general.text.collections', '')}
            viewAllLinkButton={
              <>
                {sortedCollections.length > 4 && (
                  <>
                    {!showAll && (
                      <ViewAllLink onClick={() => handleBookmarksTab('collections')}>
                        {t('general.button.viewAll', '')}
                      </ViewAllLink>
                    )}
                  </>
                )}
              </>
            }
            showAllTiles={showAll}
            rows={showAll ? getSliderRows(sortedCollections.length) : 1}
            hasSorting={showAll}
            sortingMenu={<CollectionsSorting sortValue={sortingMethod} sort={handleSorting} />}
          >
            {sortedCollections.map(collection => {
              const slug = getCuratedCollectionId(collection);
              return (
                <PreviewTile
                  key={slug}
                  title={getCuratedCollectionTitle(collection)}
                  slug={slug}
                  bgImage={getCuratedCollectionImage(collection)}
                  listType="collection"
                  previewTile={() => {
                    analyticsMixpanelPreviewSongs(
                      mixpanel,
                      moengage,
                      'Collection',
                      slug,
                      'Saved Collections',
                      userId
                    );
                    handlePreviewCollection(collection);
                  }}
                  navigateTile={() => {
                    navigateCollection(slug);
                  }}
                />
              );
            })}
          </PreviewTileRow>
        </Container>
      )}
    </>
  );
};

export default SavedCollectionsGrid;
