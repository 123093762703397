// @flow
import React, { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { LargeHeading } from '../../../../../styles/typography/components/Heading/Heading';
import { CenteredMediumContainer } from '../../../../../styles/layout';
import { GeneralDescription } from '../../../../../styles/shared';
import SubscriptionsHandler from '../../../../../user/subscriptions/components/SubscriptionsHandler/SubscriptionsHandler';
import { useSubscriptionsContext } from '../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import LoadingDisplay, {
  LoadingContainer,
} from '../../../../../components/LoadingDisplay/LoadingDisplay';
import { SubscriptionsSection } from '../../../SignUpScreen/views/SignUpSubscriptionsView/SignUpSubscriptionsView';
import { useModalsContext } from '../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import PageComponent from '../../../../components/PageComponent/PageComponent';
import { useEnterpriseFormCompleteToast } from '../../../../../toasts/hooks';
import { getSubscriptionPlanKey } from '../../../../../api/firebase/user/subscriptions';
import { SUBSCRIPTIONS } from '../../../../../user/subscriptions/data';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  uri: string,
};

export const useEnterpriseQuery = () => {
  return useQueryParam('enterprise', StringParam);
};

const ProfileSubscriptionsView = ({ uri }: Props) => {
  const { t } = useTranslation();

  const { loaded, subscriptions, fetchUserSubscriptions } = useSubscriptionsContext();
  const { setShowDownloadCounter } = useModalsContext();
  const [enterpriseQuery, setEnterpriseQuery] = useEnterpriseQuery();
  const toast = useEnterpriseFormCompleteToast();
  const hasEnterpriseBasicPlan =
    subscriptions.filter(
      element => getSubscriptionPlanKey(element) === SUBSCRIPTIONS.enterpriseBasic.key
    ).length >= 1;

  useEffect(() => {
    fetchUserSubscriptions();
    setShowDownloadCounter(false);
  }, []);

  useEffect(() => {
    if (enterpriseQuery === 'complete') {
      toast();
      setTimeout(() => {
        setEnterpriseQuery();
      }, 2000);
    }
  }, [enterpriseQuery]);

  if (!loaded) {
    return (
      <LoadingContainer fixed>
        <LoadingDisplay />
      </LoadingContainer>
    );
  }

  return (
    <PageComponent path={uri}>
      <CenteredMediumContainer responsiveFullWidth>
        <header>
          <LargeHeading>{t('subscriptionsPage.header.subscriptions', '')}</LargeHeading>
          {!hasEnterpriseBasicPlan && (
            <GeneralDescription>
              <Trans
                components={{
                  terms_link_JA: <a href="https://evokemusic.ai/ja/terms" target="_blank"></a>,
                  terms_link: <a href="https://evokemusic.ai/terms" target="_blank"></a>,
                }}
              >
                {t('subscriptionsPage.instruction.subscriptions', '')}
              </Trans>
            </GeneralDescription>
          )}
        </header>
        <SubscriptionsSection>
          <SubscriptionsHandler />
        </SubscriptionsSection>
      </CenteredMediumContainer>
    </PageComponent>
  );
};

export default ProfileSubscriptionsView;
