// @flow
import React from 'react';
import styled from 'react-emotion';
import PlanPreview from '../PlanPreview/PlanPreview';
import { useSubscriptionsContext } from '../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { SUBSCRIPTIONS } from '../../data';
import { useTranslation } from 'react-i18next';

const Container = styled('section')`
  ul li {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
`;

const SelectEnterpriseSubscription = props => {
  const { t } = useTranslation();

  const { selectPlan } = useSubscriptionsContext();

  const viewSubscription = () => {
    const selectedPlan = SUBSCRIPTIONS.enterpriseBasic.key;
    selectPlan(selectedPlan);
  };

  return (
    <Container {...props}>
      <ul>
        <li>
          <PlanPreview
            plan={'enterprise2_basic'}
            name={t('general.planCode.enterpriseBasic', '')}
            price={t('pricing.plans.plan.subTitle.enterpriseBasic', '')}
            description={t('pricing.plans.plan.briefDescription.enterpriseBasic', '')}
            onClick={() => {
              viewSubscription();
            }}
          />
        </li>
      </ul>
    </Container>
  );
};

export default SelectEnterpriseSubscription;
