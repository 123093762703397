// @flow
import React from 'react';
import { graphql } from 'gatsby';
import { Router } from '@reach/router';
import App from '../components/App/App';
import EmbedApp from '../components/EmbedApp/EmbedApp';
import CopyContextWrapper from '../copy/components/CopyContextWrapper/CopyContextWrapper';
import type { AppData } from '../copy/components/CopyContextWrapper/CopyContextWrapper';
import ErrorBoundary from '../errors/components/ErrorBoundary/ErrorBoundary';
import SiteMeta from '../components/SiteMeta/SiteMeta';
import { PATHS } from '../routing/routes';

type Props = {
  data: AppData,
};

export default ({ data }: Props) => (
  <ErrorBoundary>
    <CopyContextWrapper data={data}>
      <Router basepath="/">
        <EmbedApp path={PATHS.embedSong} key={PATHS.embedSong} />
        <EmbedApp path={PATHS.embedSongJapanese} key={PATHS.embedSong} />
        <EmbedApp path={PATHS.embedSongChinese} key={PATHS.embedSong} />
        <App default path={PATHS.mainApp} key={PATHS.mainApp} />
      </Router>
      <SiteMeta />
    </CopyContextWrapper>
  </ErrorBoundary>
);

export const pageQuery = graphql`
  query SiteQuery {
    prismicMusicPage(lang: { eq: "en-au" }) {
      ...MusicPage
    }
    jpPrismicMusicPage: prismicMusicPage(lang: { eq: "ja-jp" }) {
      ...MusicPage
    }
    prismicCollectionsPage(lang: { eq: "en-au" }) {
      ...CollectionsPage
    }
    jpPrismicCollectionsPage: prismicCollectionsPage(lang: { eq: "ja-jp" }) {
      ...CollectionsPage
    }
    allPrismicCuratedCollection(filter: { lang: { eq: "en-au" } }) {
      edges {
        node {
          ...CuratedCollection
        }
      }
    }
    jpAllPrismicCuratedCollection: allPrismicCuratedCollection(filter: { lang: { eq: "ja-jp" } }) {
      edges {
        node {
          ...CuratedCollection
        }
      }
    }
    allPrismicArtistProfile(filter: { lang: { eq: "en-au" } }) {
      edges {
        node {
          ...ArtistProfile
        }
      }
    }
    jpAllPrismicArtistProfile: allPrismicArtistProfile(filter: { lang: { eq: "ja-jp" } }) {
      edges {
        node {
          ...ArtistProfile
        }
      }
    }
  }
`;
