// @flow
import React from 'react';
import { useNavigate } from '../../../../../hooks';
import { ROUTES } from '../../../../../routes';
import { useMusicPage } from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getCuratedCollectionId,
  getCuratedCollectionImage,
  getCuratedCollectionTitle,
} from '../../../../../../cms/curatedCollection';
import PreviewTile from './PreviewTile';
import PreviewTileRow from './PreviewTileRow';
import { getMusicPageFeaturedThemes } from '../../../../../../cms/musicPage';
import { analyticsMixpanelPreviewSongs } from '../../../../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../../../../analytics/components/MixpanelWrapper';
import { useUserId } from '../../../../../../auth/components/AuthWrapper/AuthWrapper';
import type { CuratedCollectionGraphMdl } from '../../../../../../cms/curatedCollection';

type Props = {
  handlePreviewCollection: (collection: CuratedCollectionGraphMdl, origin: string) => void,
};

const FeaturedThemes = ({ handlePreviewCollection }: Props) => {
  const navigate = useNavigate();
  const musicPage = useMusicPage();
  const { mixpanel, moengage, handleUpdateCollectionAnalytics } = useAnalyticsMixpanelContext();
  const userId = useUserId();
  const featuredThemes = getMusicPageFeaturedThemes(musicPage);

  const navigateCollection = (collectionSlug: string) => {
    handleUpdateCollectionAnalytics([], [], [], [], '', 'featuredThemes', '');
    navigate(ROUTES.collection.navigatePath({ collectionSlug }));
  };

  return (
    <>
      {featuredThemes.map((featuredTheme, index) => {
        return (
          <PreviewTileRow
            key={index}
            title={featuredTheme.data.theme_title.text}
            showAllTiles={false}
            rows={1}
          >
            {featuredTheme.data.theme_content.map(collection => {
              const collectionData = collection.theme_item.document[0];
              const slug = getCuratedCollectionId(collectionData);
              return (
                <PreviewTile
                  key={slug}
                  title={getCuratedCollectionTitle(collectionData)}
                  slug={slug}
                  bgImage={getCuratedCollectionImage(collectionData)}
                  listType="collection"
                  previewTile={() => {
                    analyticsMixpanelPreviewSongs(
                      mixpanel,
                      moengage,
                      'Collection',
                      slug,
                      'Featured Themes',
                      userId
                    );
                    handlePreviewCollection(collectionData, 'featuredThemes');
                  }}
                  navigateTile={() => {
                    navigateCollection(slug);
                  }}
                />
              );
            })}
          </PreviewTileRow>
        );
      })}
    </>
  );
};

export default FeaturedThemes;
