// @flow
import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useAnalyticsMixpanelContext } from '../../../../../../../../analytics/components/MixpanelWrapper';
import {
  analyticsMixpanelViewMicroparts,
  useMixpanelSongAnalyticsDimensions,
} from '../../../../../../../../analytics/mixpanel';
import { getSongShareSlug } from '../../../../../../../../api/algolia/song';
import MicropartsIcon from '../../../../../../../../assets/inline-assets/microparts-icon.svg';
import { useGlobalPlayerContext } from '../../../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { useUserId } from '../../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useDownloadContext } from '../../../../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import { useModalsContext } from '../../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useSubscriptionsContext } from '../../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { getPlayerKeyboardShortcuts } from '../../../../../../../../utils/keyboardShortcuts';
import InlineButton from '../../../../../../FullDisplaySongCard/components/SongActions/components/ActionMenuButton/components/InlineButton';
import { useSongContext } from '../../../../../../SongContextWrapper/SongContextWrapper';
import { useTranslation } from 'react-i18next';

const ViewLoopsInlineButton = () => {
  const { t } = useTranslation();

  const { song } = useSongContext();
  const { showLoopsModal } = useModalsContext();
  const { pauseGlobalPlayback } = useGlobalPlayerContext();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const mixpanelSongDimensions = useMixpanelSongAnalyticsDimensions();
  const downloadContext = useDownloadContext();
  const { userRole } = useSubscriptionsContext();
  const userId = useUserId();

  const handleOpenLoopsModal = () => {
    const songFamily = getSongShareSlug(song);
    showLoopsModal(songFamily, downloadContext, () => pauseGlobalPlayback());

    analyticsMixpanelViewMicroparts(
      mixpanel,
      moengage,
      mixpanelSongDimensions,
      downloadContext,
      userRole,
      userId
    );
  };

  const keyboardShortcutHandlers = (() => {
    return {
      toggle_microparts: handleOpenLoopsModal,
    };
  })();

  return (
    <>
      <GlobalHotKeys
        keyMap={getPlayerKeyboardShortcuts('results')}
        handlers={keyboardShortcutHandlers}
        allowChanges
      />
      <InlineButton
        label={t('general.button.viewLoops', '')}
        icon={<MicropartsIcon />}
        action={handleOpenLoopsModal}
      />
    </>
  );
};

export default ViewLoopsInlineButton;
