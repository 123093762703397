// @flow
import React from 'react';
import PageIntroSection from '../../../../../../layouts/components/PageIntroSection/PageIntroSection';
import { useTranslation } from 'react-i18next';
// import CampaignBanner from '../../../../../../components/Campaign/CampaignBanner';

const PricingHeader = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* キャンペーンバナー（キャンペーン期間のみ使用） */}
      {/* <CampaignBanner /> */}
      <PageIntroSection heading={t('pricing.header.content', '')} />
    </>
  );
};

export default PricingHeader;
