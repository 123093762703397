// @flow
import React from 'react';
import SuggestedKeywords from './components/SuggestedKeywords/SuggestedKeywords';

const KeywordsAside = () => {
  return (
    <div>
      <SuggestedKeywords />
    </div>
  );
};

export default KeywordsAside;
