// @flow
import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import AuthRequiredWrapper from '../../../../../auth/components/AuthRequiredWrapper/AuthRequiredWrapper';
import { LargeHeading } from '../../../../../styles/typography/components/Heading/Heading';
import GenericCopy from '../../../../../styles/typography/components/GenericCopy/GenericCopy';
import PaypalEmailForm from './components/PaypalEmailForm/PaypalEmailForm';
import { useNavigate } from '../../../../hooks';
import { ROUTES } from '../../../../routes';
import PageComponent from '../../../../components/PageComponent/PageComponent';
import { Trans, useTranslation } from 'react-i18next';

const headingClass = css`
  margin-bottom: 20px;
`;

const FormSection = styled('section')`
  margin-top: 73px;
`;

type Props = {
  uri: string,
};

const SignUpPaypalView = ({ uri }: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onSubmit = () => {
    navigate(ROUTES.signupCompleted.navigatePath({}));
  };

  const paypalInstructionText = () => {
    return t('paypal.instruction', { returnObjects: true, defaultValue: [] }).map((text, index) => (
      <p>
        <Trans
          key={index}
          components={{
            paypal_link: (
              <a href="https://www.paypal.com" target="_blank">
                www.paypal.com
              </a>
            ),
          }}
        >
          {text}
        </Trans>
      </p>
    ));
  };

  return (
    <PageComponent path={uri}>
      <AuthRequiredWrapper>
        <header>
          <LargeHeading className={headingClass}>{t('paypal.header', '')}</LargeHeading>
          <GenericCopy>{paypalInstructionText()}</GenericCopy>
        </header>
        <FormSection>
          <PaypalEmailForm
            onUpdate={onSubmit}
            inputPlaceholderText={t('paypal.placeholder', '')}
            saveButtonText={t('general.button.submit', '')}
          />
        </FormSection>
      </AuthRequiredWrapper>
    </PageComponent>
  );
};

export default SignUpPaypalView;
