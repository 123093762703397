// @flow
import React, { useState } from 'react';
import { shuffle } from 'lodash';
import {
  getMusicPageFeaturedCollections,
  getMusicPageFeaturedArtists,
} from '../../../../../../cms/musicPage';
import { useMusicPage } from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getCuratedCollectionId } from '../../../../../../cms/curatedCollection';
import FullScreenCarousel from '../../../../../../layouts/components/FullScreenCarousel/FullScreenCarousel';
import FeaturedCarouselItem from './FeaturedCarouselItem/FeaturedCarouselItem';
import { getFeaturedDataContent } from '../../../../../../utils/featuredContent';

const BrowseBannerContent = () => {
  const musicPage = useMusicPage();
  const featuredCollections = getMusicPageFeaturedCollections(musicPage);
  const featuredArtists = getMusicPageFeaturedArtists(musicPage);

  const [featuredItems] = useState(shuffle(featuredCollections.concat(featuredArtists)));

  return (
    <div style={{ padding: 0 }}>
      <FullScreenCarousel>
        {featuredItems.map(collection => {
          const id = getCuratedCollectionId(collection);
          const itemData = getFeaturedDataContent(collection);
          return <FeaturedCarouselItem key={id} itemData={itemData} />;
        })}
      </FullScreenCarousel>
    </div>
  );
};

export default BrowseBannerContent;
