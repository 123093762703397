// @flow
import React from 'react';
import SongActionButton from '../SongActionButton/SongActionButton';
import BookmarkIcon from '../../../../../../../assets/inline-assets/bookmark-icon.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  condensed?: boolean,
};

const BookmarkButton = ({ condensed = false }: Props) => {
  const { t } = useTranslation();
  const icon = <BookmarkIcon />;

  const label = t('general.button.bookmark', '');

  return <SongActionButton condensed={condensed} icon={icon} label={label} />;
};

export default BookmarkButton;
