// @flow
import React from 'react';
import styled, { css } from 'react-emotion';
import { useTranslation } from 'react-i18next';

import { ICONS, IMAGES } from '../../../../../../images/paths';

const Container = styled('div')`
  margin-top: 80px;
`;

const Header = styled('header')`
  margin: auto;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
`;

const CorpLogos = styled('div')`
  width: 80%;
  margin: 15px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const CorpLogo = styled('div')`
  display: inline-flex;
  vertical-align: middle;
  padding: 10px 15px;
  margin: auto 10px;
`;

const rolandLogoCss = css`
  width: 130px;
`;

const candioLogoCss = css`
  width: 90px;
`;

const bsazlLogoCss = css`
  width: 100px;

  g path {
    fill: #000;
  }
`;

const tokyofmLogoCss = css`
  width: 130px;
`;

const PricingCorpLogos = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>{t('pricing.corpLogos.header')}</Header>
      <CorpLogos>
        <CorpLogo>
          <img className={rolandLogoCss} src={ICONS.roland} />
        </CorpLogo>
        <CorpLogo>
          <img className={candioLogoCss} src={IMAGES.candeo} />
        </CorpLogo>
        <CorpLogo>
          <img className={bsazlLogoCss} src={ICONS.bsazl} />
        </CorpLogo>
        <CorpLogo>
          <img className={tokyofmLogoCss} src={ICONS.tokyofm} />
        </CorpLogo>
      </CorpLogos>
    </Container>
  );
};

export default PricingCorpLogos;
